import { NxSVG } from "web/common/components/NxSvg";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { useMyProfileEditUseAvatarModal } from "./components/MyProfileEditUseAvatarModalContext";

interface MyProfileUserAvatarProps {
  firstName: string;
  lastName: string;
  imageUrl: string | undefined;
}

export const MyProfileUserAvatar = ({
  firstName,
  lastName,
  imageUrl,
}: MyProfileUserAvatarProps) => {
  const { showModal } = useMyProfileEditUseAvatarModal();

  const initialImage = imageUrl;

  return (
    <div
      className="relative h-16 w-16 cursor-pointer"
      onClick={() => {
        showModal({
          initialImage: initialImage,
          name: firstName,
          lastName: lastName,
        });
      }}
    >
      <div className="w-full h-full overflow-hidden rounded-full border border-nx-main-500  ">
        {initialImage ? (
          <img
            className="w-full h-full object-cover"
            src={initialImage}
            alt=""
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full bg-nx-main-600/20">
            <span className="text-2xl text-nx-main-600 font-normal">
              {`${firstName[0]}${lastName[0]}`}
            </span>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 right-0 w-5 h-5  flex items-center justify-center rounded-full bg-nx-main-500 ">
        <NxSVG className="absolute w-3 h-3 mb-0.5 fill-nx-white">
          <IconEdit />
        </NxSVG>
      </div>
    </div>
  );
};
