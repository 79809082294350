import { IconEsg } from "web/common/fonts/icons/components/IconEsg";
import {
  ItemEntity,
  WidgetCategoryEntity,
} from "../entities/WidgetCategoryEntity";
import { IconCulture } from "web/common/fonts/icons/components/IconCulture";
import { WidgetType } from "../entities/WidgetType";

export function GetWidgetCategoriesUsecase() {
  return [
    new WidgetCategoryEntity({
      id: "esg",
      icon: <IconEsg />,
      name: "ESG",
      description: "Sustentabilidade, Social e Governança",
      items: [
        new ItemEntity({
          id: 0,
          name: "Trabalho análogo à escravidão",
          type: WidgetType.slave,
          description: "ESG",
          value: 100,
        }),
      ],
    }),
    new WidgetCategoryEntity({
      id: "culture",
      icon: <IconCulture />,
      name: "Agricultura",
      description: "Produtividade, mercado, análise de solo e logística",
      items: [
        new ItemEntity({
          id: 0,
          name: "Identificação de pivôs",
          description: "Defina a área que será analisada",
          type: WidgetType.pivot,
          value: 5,
        }),
        new ItemEntity({
          id: 1,
          name: "Produtividade",
          description: "Defina a área que será analisada",
          type: WidgetType.pivot,
          value: 20,
        }),
        new ItemEntity({
          id: 2,
          name: "Plantio",
          description: "Defina a área que será analisada",
          type: WidgetType.pivot,
          value: 10,
        }),
      ],
    }),
  ];
}
