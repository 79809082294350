import { CredentialEntity } from "../entities/CredentialEntity";

export interface AuthLocalRepository {
  hasLogged(): boolean;
  getCredentials(): Promise<CredentialEntity>;
  clearLogged(): void;
}

export class AuthLocalRepositoryImpl implements AuthLocalRepository {
  hasLogged(): boolean {
    var logged =
      sessionStorage.getItem("credential") ??
      localStorage.getItem("credential");
    return !!logged;
  }

  async getCredentials(): Promise<CredentialEntity> {
    var logged =
      sessionStorage.getItem("credential") ??
      localStorage.getItem("credential") ??
      JSON.stringify({});
    var credentials: CredentialEntity = JSON.parse(logged);
    return credentials;
  }

  clearLogged(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
