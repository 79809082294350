import { NxSVG } from "web/common/components/NxSvg";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { useTranslation } from "react-i18next";
import { AgentsModalProvider } from "../context/AgentsModalProvider";
import { AgentHeader } from "../components/AgentHeader/AgentHeader";
import { AgentTable } from "../components/AgentTable/AgentTable";

export function AgentsPage() {
  const { t } = useTranslation();
  
  return (
    <AgentsModalProvider>
      <div className="pl-6 pr-8 pt-8">
        <AgentHeader/>
        <div className="pb-4 w-72">
          <NxFormField
            placeholder={t("entitySearchPlaceholder")}
            prefix={
              <NxSVG className="stroke-nx-gray-400">
                <IconSearch />
              </NxSVG>
            }
          />
        </div>
      </div>
      <AgentTable />
    </AgentsModalProvider>
  );
}

