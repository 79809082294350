import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconClosedEye } from "web/common/fonts/icons/components/IconClosedEye";
import { IconOpenedEye } from "web/common/fonts/icons/components/IconOpenedEye";
import { validatePassword } from "web/common/forms/validators/ValidatePassword";
import { t } from "i18next";
import { useEditAccountHook } from "../../hooks/useEditAccountHook";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { useCheckCredentialPassword } from "web/features/auth/features/login/presentation/hooks/UseCheckCredentialPassword";

interface MyProfileEditPasswordModalProps {
  isOpen: boolean;
  onClosedTapped: () => void;
  email: string;
}

type IEditPasswordInput = {
  currentPassword: string;
  newPassword: string;
};

export const MyProfileEditPasswordModal = ({
  isOpen,
  onClosedTapped,
  email,
}: MyProfileEditPasswordModalProps) => {
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { editAccount, accountState } = useEditAccountHook();
  const { showSnackbar } = useNxSnackBarContext();

  const { checkCredentialPassword, checkCredentialPasswordState } =
    useCheckCredentialPassword();

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditPasswordInput>({
    mode: "onSubmit",
  });

  const handleSubmitData = (data: IEditPasswordInput) => {
    editAccount({
      password: data.newPassword,
    });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setTimeout(() => {
      onClosedTapped();
      reset();
    }, 3500);
  };

  useEffect(() => {
    if (accountState.isLoading) {
      setLoading(true);
    }
  }, [accountState.isLoading]);

  useEffect(() => {
    if (accountState.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        showIcon: true,
        label: t("errorSnackbar"),
      });
      setLoading(false);
    }
  }, [accountState.isError, showSnackbar]);

  useEffect(() => {
    if (checkCredentialPasswordState.isError) {
      setLoading(false);
    }
  }, [checkCredentialPasswordState.isError]);

  useEffect(() => {
    if (accountState.isSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        showIcon: true,
        label: t("successSnackbar"),
      });
      setLoading(false);
      onClosedTapped();
    }
  }, [onClosedTapped, showSnackbar, accountState.isSuccess]);

  const validatePasswordCurrente = (value: string) => {
    if (!value) {
      return t("requiredPassword");
    }

    checkCredentialPassword({
      email: email,
      password: value,
      maintainLogin: false,
    });

    if (checkCredentialPasswordState.isError) {
      setLoading(false);
      return t("invalidCurrentPassword");
    }

    if (checkCredentialPasswordState.isSuccess) {
      return true;
    }

    return true;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    validatePasswordCurrente(value);
  };

  return (
    <div>
      <NxModal showModal={isOpen} onOutsideClick={onClosedTapped}>
        <div className="w-[330px] flex flex-col gap-3">
          <NxModalTitle
            title={t("editPassword")}
            subtitle={t("personalData")}
            onClosedTapped={onClosedTapped}
          />

          <form
            className="flex flex-col gap-3"
            onSubmit={handleSubmit(handleSubmitData)}
          >
            <NxFormField
              type={isCurrentPasswordVisible ? "text" : "password"}
              labelText={t("currentPassword")}
              register={register("currentPassword", {
                validate: validatePasswordCurrente,
              })}
              error={errors.currentPassword}
              onChange={handlePasswordChange}
              icon={
                <div
                  onClick={toggleCurrentPasswordVisibility}
                  className="cursor-pointer"
                >
                  {isCurrentPasswordVisible ? (
                    <IconOpenedEye />
                  ) : (
                    <IconClosedEye />
                  )}
                </div>
              }
            />

            <NxFormField
              type={isNewPasswordVisible ? "text" : "password"}
              labelText={t("newPassword")}
              register={register("newPassword", { validate: validatePassword })}
              error={errors.newPassword}
              icon={
                <div
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer"
                >
                  {isNewPasswordVisible ? <IconOpenedEye /> : <IconClosedEye />}
                </div>
              }
            />

            <NxFilledButton>
              {loading ? (
                <CircularProgressBar
                  width={16}
                  height={16}
                  className="stroke-nx-white"
                />
              ) : (
                t("save")
              )}
            </NxFilledButton>
          </form>
        </div>
      </NxModal>
    </div>
  );
};
