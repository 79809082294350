import { t } from "i18next";
import { useEffect, useState } from "react";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxSVG } from "web/common/components/NxSvg";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CheckBoxField } from "web/common/components/TextFields/CheckField";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import { CSName } from "web/common/utils/ClassName";
import {
  useGetAllAgentsHook,
  GetAllAgentsLoading,
  GetAllAgentsSuccess,
} from "web/features/agents/presentation/hooks/UseGetAllAgentsHook";
import { GetLocationsLoading, GetLocationsSuccess, useGetLocationsHook } from "web/features/locations/presentation/hooks/UseGetLocationsHook";
import { ItemEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";

type Params = {
  item: ItemEntity;
  onPrevious: () => void;
  onClose: () => void;
};

interface SelectedItem {
  id: string;
  value: string;
}

export function PivotHectarePage({ item, onPrevious, onClose }: Params) {
  const [selectedItems, setItems] = useState<SelectedItem[]>([]);
  const { getAllAgents, getAllAgentsState } = useGetAllAgentsHook();
  const { getLocations, getLocationState } = useGetLocationsHook();

  useEffect(() => {
    getAllAgents();
    getLocations();
  }, []);

  const getEntitiesList = () => {
    const { unarchived } = (getAllAgentsState as GetAllAgentsSuccess);

    if (unarchived !== undefined && unarchived.length > 0)
      return (
        <div className="ml-4">
          <div className="mb-2">
            <label
              className={CSName(FontStyles.category)
                .combine("text-nx-gray-700 dark:text-nx-gray-400 text-start")
                .build()}
            >
              {t("entities")}
            </label>
          </div>
          {unarchived.map((u) => (
            <div
              className="grid grid-flow-col place-content-between mx-4 mb-2 mt-2"
              key={u.id}
            >
              <div className="flex flex-col">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine("text-nx-gray-700 dark:text-nx-white text-start")
                    .build()}
                >
                  {u.name}
                </label>
                <label
                  className={CSName(FontStyles.caption)
                    .combine(
                      "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                    )
                    .build()}
                >
                  {u.category}
                </label>
              </div>
              <CheckBoxField
                className={"bg-nx-focus-light dark:bg-nx-main-400/20"}
                onClick={(c) => {
                  if (c) {
                    selectedItems.push({
                      id: u.id,
                      value: u.name,
                    });
                    setItems([...selectedItems]);
                  } else {
                    setItems([...selectedItems.filter((s) => s.id !== u.id)]);
                  }
                }}
              />
            </div>
          ))}
        </div>
      );
    else
      return (
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 my-4 mx-4")
            .build()}
        >
          {t("noEntities")}
        </label>
      );
  };
  

  const getLocationsList = () => {
    const { restored } = (getLocationState as GetLocationsSuccess);

    if (restored !== undefined && restored.length > 0)
      return (
        <div className="ml-4">
          <div className="mb-2">
            <label
              className={CSName(FontStyles.category)
                .combine("text-nx-gray-700 dark:text-nx-gray-400 text-start")
                .build()}
            >
              {t("location")}
            </label>
          </div>
          {restored.map((r) => (
            <div
              className="grid grid-flow-col place-content-between mr-4 mb-2 mt-2"
              key={r.id}
            >
              <div className="flex flex-col">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine("text-nx-gray-700 dark:text-nx-white text-start")
                    .build()}
                >
                  {r.properties.name}
                </label>
                <label
                  className={CSName(FontStyles.caption)
                    .combine(
                      "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                    )
                    .build()}
                >
                500 Hectares
                </label>
              </div>
              <CheckBoxField
                className={"bg-nx-focus-light dark:bg-nx-main-400/20"}
                onClick={(c) => {
                  if (c) {
                    selectedItems.push({
                      id: r.id!,
                      value: r.properties.name,
                    });
                    setItems([...selectedItems]);
                  } else {
                    setItems([...selectedItems.filter((s) => s.id !== r.id)]);
                  }
                }}
              />
            </div>
          ))}
        </div>
      );
    else
      return (
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 my-4 mx-4 ")
            .build()}
        >
          {t("noEntities")}
        </label>
      );
  };

  return (
    <div>
      <div className="flex flex-row justify-between mb-4">
        <div className="flex flex-row ">
          <NxSVG
            className="w-5 h-5 stroke-nx-main-600 dark:stroke-nx-main-500 mr-4 mt-1 cursor-pointer"
            onClick={onPrevious}
          >
            <IconArrowLeft />
          </NxSVG>
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-start dark:text-nx-main-200 text-nx-dark-600")
                .build()}
            >
              {item.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("selectEntities")}
            </label>
          </div>
        </div>
        <NxSVG
          onClick={onClose}
          className="w-5 h-5 cursor-pointer stroke-nx-main-600"
        >
          <IconClose />
        </NxSVG>
      </div>
      <div className="bg-nx-gray-50 dark:bg-nx-dark-1200 w-full h-fit pt-1 pb-1 flex flex-col rounded mt-2 mb-6">
        {(getAllAgentsState instanceof GetAllAgentsLoading || getLocationState instanceof GetLocationsLoading) && (
          <div className="flex flex-col m-auto my-4">
            <CircularProgressBar
              className="stroke-nx-main-600"
              width={20}
              height={20}
            />
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("loadingData")}
            </label>
          </div>
        )}
        {(getAllAgentsState instanceof GetAllAgentsSuccess && getLocationState instanceof GetLocationsSuccess)  && (
            <div>
                {getEntitiesList()}
                <div className="mt-4 mb-4 bg-nx-gray-500 h-[1px] ml-2 mr-4 rounded"/>
                {getLocationsList()}
            </div>
        )}
      </div>
      <label
        className={CSName(FontStyles.caption)
          .combine("dark:text-nx-gray-200 text-nx-gray-400")
          .build()}
      >
        {`${t("hectaresTotal")} 0`}
      </label>
      <div className="mt-2 flex flex-row justify-between">
        <div className="flex flex-row">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-gray-400")
              .build()}
          >
            {t("appointmentCost")}
          </label>
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("dark:text-nx-main-200 text-nx-dark-600 ml-2 mr-1")
              .build()}
          >
            2000
          </label>
          <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
            <IconCoin />
          </NxSVG>
        </div>
        <div className="flex flex-row">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-gray-400")
              .build()}
          >
            {t("availableCredits")}
          </label>
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("dark:text-nx-main-200 text-nx-dark-600 ml-2 mr-1")
              .build()}
          >
            2000
          </label>
          <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
            <IconCoin />
          </NxSVG>
        </div>
      </div>
      <NxFilledButton
        className="mt-2 py-3"
        onClick={() => {
          onClose();
        }}
      >
        <label
          className={CSName(FontStyles.bodyBold)
            .combine("cursor-pointer")
            .build()}
        >
          {t("next")}
        </label>
      </NxFilledButton>
    </div>
  );
}
