import { useState } from "react";
import { TableEntity } from "../../domain/entities/TableEntity";
import { AgentEntity } from "../../../features/agents/domain/entities/AgentEntity";

abstract class Sort {
  orderByAsc: boolean = true;
  constructor(orderByAsc: boolean) {
    this.orderByAsc = orderByAsc;
  }
}

export class SortByName extends Sort {
  constructor({ orderByAsc }: Partial<SortByName>) {
    super(orderByAsc!);
  }
}

export class SortByWidgets extends Sort {
  constructor({ orderByAsc }: Partial<SortByWidgets>) {
    super(orderByAsc!);
  }
}

export class SortByMembers extends Sort {
  constructor({ orderByAsc }: Partial<SortByMembers>) {
    super(orderByAsc!);
  }
}

export class SortByCategory extends Sort {
  constructor({ orderByAsc }: Partial<SortByCategory>) {
    super(orderByAsc!);
  }
}

export class SortByDate extends Sort {
  constructor({ orderByAsc }: Partial<SortByDate>) {
    super(orderByAsc!);
  }
}

export interface SortByParameters {
  instance: Sort;
  entity: TableEntity<AgentEntity>;
}

export const useSortBy = () => {
  const [sortState, setState] = useState<Sort>();

  return {
    sortState: sortState,
    sortedBy: {
      name: sortState instanceof SortByName,
      widgets: sortState instanceof SortByWidgets,
      members: sortState instanceof SortByMembers,
      category: sortState instanceof SortByCategory,
      date: sortState instanceof SortByDate,
    },
    tableEntity: (param: SortByParameters) => {
      setState(param.instance);
      if (param.instance instanceof SortByName) {
        const unarchived = param.entity.unarchived.sort((a, b) => {
          if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
          else return b.name! < a.name! ? -1 : 1;
        });

        const archived = param.entity.archived.sort((a, b) => {
          if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
          else return b.name! < a.name! ? -1 : 1;
        });

        return {
          unarchived: unarchived,
          archived: archived,
        };
      }

      if (param.instance instanceof SortByDate) {
        const unarchived = param.entity.unarchived.sort((a, b) => {
          if (param.instance.orderByAsc)
            return a.createdAt! < b.createdAt! ? -1 : 1;
          else return b.createdAt! < a.createdAt! ? -1 : 1;
        });

        const archived = param.entity.archived.sort((a, b) => {
          if (param.instance.orderByAsc)
            return a.createdAt! < b.createdAt! ? -1 : 1;
          else return b.createdAt! < a.createdAt! ? -1 : 1;
        });

        return {
          unarchived: unarchived,
          archived: archived,
        };
      }
      return param.entity;
    },
  };
};
