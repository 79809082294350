import { IconUndo } from "web/common/fonts/icons/components/IconUndo";
import { NxSVG } from "../../NxSvg";
import { IconCheck } from "web/common/fonts/icons/components/IconCheck";
import { IconErrorFilled } from "web/common/fonts/icons/components/IconErrorFilled";
import { MapComponentController } from "../controller/MapController";
import { useTranslation } from "react-i18next";

interface MapEditToolbarProps {
  controller: MapComponentController;
  isEditing: boolean;
  isDrawing: boolean;
}

export const MapEditToolbar = ({
  controller,
  isDrawing,
  isEditing,
}: MapEditToolbarProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col absolute  z-[999]  top-6 right-[10px] gap-2">
      {isDrawing && (
        <div
          className="flex flex-row gap-2 w-300 h-200 bg-nx-main-100 px-4 py-2 rounded cursor-pointer"
          onClick={() => {
            controller.undo();
          }}
        >
          <span className="text-sm font-medium text-nx-main-700">
            {t("redo")}
          </span>
          <NxSVG className="w-5 h-5" fill="#CAE6FF">
            <IconUndo />
          </NxSVG>
        </div>
      )}
      {isEditing && (
        <>
          <div
            className="flex flex-row gap-2 w-300 h-200 justify-center bg-nx-danger-100 px-4 py-2 rounded cursor-pointer"
            onClick={() => {
              controller.cancelEditMode();
            }}
          >
            <span className="text-sm font-medium text-nx-danger-700">
              {t("cancel")}
            </span>
            <NxSVG className="w-5 h-5 fill-nx-danger-700 ">
              <IconErrorFilled />
            </NxSVG>
          </div>
          <div
            className="flex flex-row gap-2 w-300 h-200 justify-center items-center bg-nx-main-100 px-4 py-2 rounded cursor-pointer"
            onClick={() => {
              controller.saveEditMode();
            }}
          >
            <span className="text-sm font-medium text-nx-main-700">
              {t("save")}
            </span>
            <NxSVG className="w-5 h-5 fill-nx-main-700 ">
              <IconCheck />
            </NxSVG>
          </div>
        </>
      )}
    </div>
  );
};
