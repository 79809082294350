export const IconCattle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M4.85584 7.5V7.5C3.27861 7.5 2 6.22139 2 4.64416V2.5L2.95375 3.53401C3.5217 4.14976 4.32124 4.5 5.15892 4.5H14.7449C15.5348 4.5 16.2928 4.18845 16.8545 3.63298L18 2.5V4.5C18 6.15685 16.6569 7.5 15 7.5H14.9667"
        stroke="stroke"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15.5V13.9243C13.5 13.6526 13.6079 13.3921 13.8 13.2L13.9393 13.0607C14.6185 12.3815 15 11.4604 15 10.5V10.5V7C15 5.61929 13.8807 4.5 12.5 4.5V4.5H7.5V4.5C6.11929 4.5 5 5.61929 5 7V10.5V10.5C5 11.4604 5.38153 12.3815 6.06066 13.0607L6.2 13.2C6.39209 13.3921 6.5 13.6526 6.5 13.9243V15.5"
        stroke="stroke"
      />
      <path
        d="M6.5 15.6509C6.5 14.7392 7.23916 14 8.15095 14C8.3284 14 8.50468 14.0286 8.67302 14.0847L9.05132 14.2108C9.66712 14.4161 10.3329 14.4161 10.9487 14.2108L11.327 14.0847C11.4953 14.0286 11.6716 14 11.8491 14C12.7608 14 13.5 14.7392 13.5 15.6509V16.3491C13.5 17.2608 12.7608 18 11.8491 18C11.6716 18 11.4953 17.9714 11.327 17.9153L10.9487 17.7892C10.3329 17.5839 9.66712 17.5839 9.05132 17.7892L8.67302 17.9153C8.50468 17.9714 8.3284 18 8.15095 18C7.23916 18 6.5 17.2608 6.5 16.3491V15.6509Z"
        stroke="stroke"
      />
      <circle cx="11.5" cy="16" r="0.5" fill="#667C8E" />
      <path
        d="M13.0085 9.25C13.0085 9.66421 12.6728 10 12.2585 10C11.8443 10 11.5085 9.66421 11.5085 9.25C11.5085 8.83579 11.8443 8.5 12.2585 8.5C12.6728 8.5 13.0085 8.83579 13.0085 9.25Z"
        fill="stroke"
      />
      <circle cx="7.75" cy="9.25" r="0.75" fill="stroke" />
      <circle cx="8.5" cy="16" r="0.5" fill="stroke" />
      <path
        d="M4 7.5H5V10.5C5 11.0523 4.55228 11.5 4 11.5H3V8.5C3 7.94772 3.44772 7.5 4 7.5Z"
        stroke="stroke"
      />
      <path
        d="M16 7.5H15V10.5C15 11.0523 15.4477 11.5 16 11.5H17V8.5C17 7.94772 16.5523 7.5 16 7.5Z"
        stroke="stroke"
      />
    </svg>
  );
};
