import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { CSName } from "web/common/utils/ClassName";
import { IconRestore } from "../../../../../common/fonts/icons/components/IconRestore";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { LocationTbodyParams } from "./params/LocationTbodyParams";
import { useLocationsModal } from "../../context/LocationsModalProvider";
import {
  HideLocationModal,
  ShowArchiveModal,
  ShowCreateLocationModal,
  ShowLocationsWidgetsModal,
} from "../../context/abstraction/LocationModal";
import { LocationEntity } from "web/features/locations/domain/entities/LocationEntity";
import { WidgetsLocationModal } from "../WidgetsLocationModal/WidgetsLocationModal";
import { ArchiveLocationModal } from "../ArchiveLocationModal/ArchiveLocationModal";
import { format } from "date-fns/format";
import {
  LocationRestored,
  useRestoreLocationHook,
} from "../../hooks/UseRestoreLocationHook";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";

interface LocationTbodyController {
  location?: LocationEntity;
  isRestoring?: boolean;
}

export function LocationTbody(params: LocationTbodyParams) {
  const { resotreLocationState, restoreLocation } = useRestoreLocationHook();
  const [isRestoring, setRestoring] = useState<boolean>();
  const { modal, setModal } = useLocationsModal();
  const [controller, setController] = useState<LocationTbodyController>();
  const { t } = useTranslation();

  useEffect(() => {
    if (modal instanceof HideLocationModal)
      setController({
        isRestoring: undefined,
        location: undefined,
      });
  }, [modal]);

  useEffect(() => {
    if (resotreLocationState instanceof LocationRestored) {
      setRestoring(true);
      setModal(
        new HideLocationModal({
          reload: true,
        })
      );
    }
  }, [resotreLocationState, setModal]);

  const setLoading = () => {
    setController({
      isRestoring: false,
      location: controller?.location,
    });
    setRestoring(false);
    restoreLocation(params.data.id ?? "");
  };

  return (
    <div
      className={CSName("pl-6 pt-8 flex flex-row text-nx-gray-200")
        .combine(
          params.showBorder
            ? "border-b-[0.5px] border-nx-gray-100 dark:border-nx-gray-500"
            : ""
        )
        .combine(
          params.showBorder
            ? "border-b-[0.5px] border-nx-gray-100 dark:border-nx-gray-500"
            : ""
        )
        .build()}
    >
      <div className={CSName("w-[24%]").combine().build()}>
        <div
          className="flex flex-row pb-4 cursor-pointer"
          onClick={params.onClick}
        >
          <div className="flex flex-col mr-4">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "text-start text-nx-black dark:text-nx-white cursor-pointer"
                )
                .build()}
            >
              {params.data.properties.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "text-start text-nx-gray-400 dark:text-nx-gray-200 cursor-pointer"
                )
                .build()}
            >
              {params.data.type}
            </label>
          </div>
        </div>
      </div>
      <div className="w-[53.5%]">
        <div className="flex flex-row mr-4">
          {params.data.properties.widgetIds.length === 0 && (
            <label
            className={CSName(FontStyles.caption)
              .combine(
                " rounded-full border-dashed border px-3 py-1.5 bg-nx-gray-200/20 dark:bg-nx-black/20 border-nx-gray-300 text-nx-gray-300 truncate  max-w-28"
              )
              .build()}
          >
            {t('noWidgets')}
          </label>
          )}
          {params.data.properties.widgetIds?.map((e, index) => {
            ///TODO: Replace key with the unique ID for the widget
            if (index < 3)
              return (
                <label
                  key={index}
                  className={CSName(FontStyles.caption)
                    .combine(
                      "rounded-full px-3 py-1.5 bg-nx-focus-dark text-nx-main-500 dark:text-nx-main-200 truncate mr-1 max-w-28"
                    )
                    .build()}
                >
                  {e}
                </label>
              );
            return <div key={index}></div>;
          })}
          {params.data.properties.widgetIds?.length > 2 && (
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "text-nx-main-600 dark:text-nx-main-500 place-content-center cursor-pointer"
                )
                .build()}
              onClick={() => {
                setModal(new ShowLocationsWidgetsModal());
                setController({
                  isRestoring: controller?.isRestoring,
                  location: params.data,
                });
              }}
            >
              {t("more")} {params.data.properties.widgetIds!.length - 3}...
            </label>
          )}
        </div>
      </div>
      <div className="w-[18%] text-start">
        <label
          className={CSName(FontStyles.caption)
            .combine("text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {format(params.data.properties.createdAt!, "dd/MM/yyyy")}
        </label>
      </div>
      <div className="mr-3 text-start">
        <NxDropElement
          dimension={{
            x: 100,
            y: 10,
          }}
          options={[
            {
              icon: <IconEdit />,
              label: t("edit"),
              onClick: () => {
                setModal(new ShowCreateLocationModal({ content: params.data }));
              },
            },
            {
              icon: !params.isArchived ? <IconArchive /> : <IconRestore />,
              label: !params.isArchived ? t("archive") : t("restore"),
              onClick: () => {
                if (!params.isArchived) {
                  setController({
                    isRestoring: controller?.isRestoring,
                    location: params.data,
                  });
                  setModal(new ShowArchiveModal());
                } else {
                  setLoading();
                }
              },
            },
          ]}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
            <IconMore />
          </NxSVG>
        </NxDropElement>

        <ArchiveLocationModal
          id={params.data.id!}
          isOpen={
            modal instanceof ShowArchiveModal &&
            controller?.location !== undefined
          }
        />
        <WidgetsLocationModal
          isOpen={
            modal instanceof ShowLocationsWidgetsModal &&
            controller?.location !== undefined
          }
          widgetsList={[]}
        />
      </div>
      <NxSnackbar
        showIcon={true}
        showSnackbar={isRestoring === false}
        type={NxSnackbarType.loading}
        time={4000}
        label={t("restoring")}
      />
      <NxSnackbar
        showIcon={true}
        showSnackbar={isRestoring === true}
        type={NxSnackbarType.success}
        label={t('restoreWithSuccess')}
        onSnackbarClose={() => {}}
      />
    </div>
  );
}
