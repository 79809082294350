import { useEffect, useRef, useState } from "react";

import {
  FeatureGroup,
  MapContainer,
  TileLayer,
  ZoomControl,
  useMap,
} from "react-leaflet";

import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import { CSName } from "web/common/utils/ClassName";

import L from "leaflet";
import { MapDrawModes } from "./types/MapDrawModes";
import { DrawLayer } from "./types/DrawLayer";
import { MapComponentController } from "./controller/MapController";
import { MapEditToolbar } from "./components/MapEditToolbar";

interface HandleCreatedProps {
  onCreated: (map: L.Map) => void;
}

const HandleCreated = ({ onCreated }: HandleCreatedProps) => {
  const map = useMap();
  useEffect(() => {
    onCreated(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);
  return null;
};

interface MapComponentProps {
  isReadOnly?: boolean;
  zoomControl?: boolean;
  initialEditMode?: MapDrawModes;
  className: string;
  initialLayers?: DrawLayer[];
  onMonted?: (controller: MapComponentController) => void;
  onDrawCreated?: (e: DrawLayer) => void;
  onEditCreated?: (e: DrawLayer) => void;
}

export const MapComponent = ({
  className,
  initialEditMode,
  initialLayers,
  onMonted,
  onDrawCreated,
  onEditCreated,
  isReadOnly,
  zoomControl = true,
}: MapComponentProps) => {
  const controller = useRef<MapComponentController>();
  const mapRef = useRef<L.Map>();

  const [{ isDrawing, isEditing }, setTooBarState] = useState<{
    isDrawing: boolean;
    isEditing: boolean;
  }>({ isDrawing: false, isEditing: false });

  const _onDrawCreated = (e: any) => {
    e.layer.on("click", () => {
      controller.current!.enableEditMode();
    });

    onDrawCreated?.(
      DrawLayer.fromGeoJSON(e.layer, controller.current?.currentMode!)
    );
  };

  function onMounted(mapControll: any) {
    controller.current = new MapComponentController({
      control: mapControll,
      isReadOnly: isReadOnly ?? false,
      mapRef: mapRef.current!,
    });
    if (initialEditMode) {
      controller.current.enableMode(initialEditMode);
    }
    if (initialLayers) {
      initialLayers.forEach((layer) => {
        controller.current?.addLayer(layer);
      });
      const firstLayer = initialLayers[0];
      controller.current.fitBounds(firstLayer);
    }
    onMonted?.(controller.current);
  }


  return (
    <div className={CSName("relative").combine(className).build()}>
      <MapContainer
        className={className}
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        scrollWheelZoom={true}
        zoomControl={false}
        touchZoom={zoomControl}
        doubleClickZoom={zoomControl}
        dragging={zoomControl}
        boxZoom={zoomControl}
        tap={zoomControl}
      >
        <HandleCreated
          onCreated={(map) => {
            mapRef.current = map;
          }}
        />
        <TileLayer
          url={`${process.env.REACT_APP_MAP_STYLE}?access_token=${process.env.REACT_APP_MAP_TOKEN}`}
          tileSize={512}
          maxZoom={18}
          zoomOffset={-1}
        />
        <FeatureGroup>
          <EditControl
            onMounted={(controll: any) => onMounted(controll)}
            onEditStart={() => {
              setTooBarState({ isDrawing: false, isEditing: true });
            }}
            onEditStop={() => {
              setTooBarState({ isDrawing: false, isEditing: false });
            }}
            onEdited={(e) => {
              setTooBarState({ isDrawing: false, isEditing: false });
              const layers = e.layers.getLayers();
              if (!layers.length) return;
              onEditCreated?.(
                DrawLayer.fromGeoJSON(
                  layers[0],
                  controller.current?.currentMode!
                )
              );
            }}
            onDrawStart={() => {
              setTooBarState({ isDrawing: true, isEditing: false });
            }}
            onCreated={_onDrawCreated}
            position="topright"
            draw={{}}
          />
          {zoomControl && <ZoomControl position="bottomright" />}
        </FeatureGroup>
      </MapContainer>
      {!isReadOnly && (
        <MapEditToolbar
          isDrawing={isDrawing}
          isEditing={isEditing}
          controller={controller.current!}
        />
      )}
    </div>
  );
};
