import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { VisualConfig } from "../config/VisualConfig";

interface VisualContextType {
  isDarkMode: boolean;
  setMode: (isDarkMode: boolean) => void;
}

export const VisualContext = createContext<VisualContextType>({
  isDarkMode: VisualConfig.isDarkMode() === true,
  setMode: (isDarkMode: boolean) => {},
});

export const useVisualContext = () => {
  return useContext(VisualContext);
};

export function VisualProvider(props: any) {
  const [isDarkMode, changeMode] = useState(VisualConfig.isDarkMode());
  const previousMode = useRef(isDarkMode);

  const setMode = (isDarkMode: boolean) => {
    VisualConfig.changeMode(isDarkMode);
    changeMode(isDarkMode);
  };
  useEffect(() => {
    if (previousMode.current !== isDarkMode) {
      window.document.documentElement.classList.remove(
        getClassMode(previousMode.current)
      );
      previousMode.current = isDarkMode;
    }
    window.document.documentElement.classList.add(getClassMode(isDarkMode));
  }, [isDarkMode]);

  const getClassMode = (isDarkMode: boolean): string => {
    return isDarkMode ? "dark" : "light";
  };
  return (
    <VisualContext.Provider value={{ setMode, isDarkMode }}>
      {props.children}
    </VisualContext.Provider>
  );
}
