import { useForm } from "react-hook-form";
import { NxFilledButton } from "../../../../../common/components/Buttons/NxFilledButton";
import { NxModal } from "../../../../../common/components/NxModal/NxModal";
import { NxSVG } from "../../../../../common/components/NxSvg";
import { NxDropFormField } from "../../../../../common/components/TextFields/NxDropFormField";
import { IconClose } from "../../../../../common/fonts/icons/components/IconsClose";
import { MembersModals } from "../MembersModals/MembersModals";
import { useMembersUserModal } from "./MembersUserContext";
import { MembersEmailField } from "./components/MembersEmailField";
import { t } from "i18next";

type MemberAddInput = {
  email: string;
  role: string;
};

export const MembersAddUserModal = ({ isOpen }: { isOpen: boolean }) => {
  const { register, setValue } = useForm<MemberAddInput>();
  const { closeModal } = useMembersUserModal();
  return (
    <NxModal
      showModal={isOpen}
      onOutsideClick={() => {
        closeModal(new MembersModals().none());
      }}
    >
      <div className="w-96">
        <div className="mb-6 flex flex-row justify-between">
          <div>
            <h5 className="text-base text-nx-main-800 dark:text-nx-white ">
              {t('addMembers')}
            </h5>
            <h6 className="text-caption text-nx-gray-400 dark:text-nx-gray-200">
              {t('invitePeople')}
            </h6>
          </div>
          <NxSVG
            onClick={() => {
              closeModal(new MembersModals().none());
            }}
            className="w-4 h-4  cursor-pointer dark:stroke-nx-white stroke-nx-main-500"
          >
            <IconClose />
          </NxSVG>
        </div>
        <div className="flex flex-row gap-2 w-full  mb-6">
          <div className="w-3/5">
            <MembersEmailField
              register={register("email")}
              onSuggestionClicked={(email) => {
                setValue("email", email);
              }}
            />
          </div>
          <div className="w-2/5">
            <NxDropFormField
              labelText={t('role')}
              placeholder={t('select')}
              labelBuilder={(value) => value}
              valueBuilder={(value) => value}
              options={[
                "Desenvolvedor",
                "Designer",
                "Gerente de projetos asdsadas",
                "Analista de",
              ]}
            />
          </div>
        </div>
        <NxFilledButton
          onClick={() => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                closeModal(new MembersModals().none());
              }, 2000);
            });
          }}
        >
          {t('sendInvitation')}
        </NxFilledButton>
      </div>
    </NxModal>
  );
};
