import { useNxButton } from "web/common/components/Buttons/hooks/UseNxButton";
import { CSName } from "web/common/utils/ClassName";

interface MyProfileTileProps {
  title: string;
  titleClassName?: string;
  subtitle?: string;
  trailing?: React.ReactNode;
  onClick?: () => void;
}

export const MyProfileTile = ({
  title,
  subtitle,
  titleClassName,
  trailing,
  onClick,
}: MyProfileTileProps) => {
  const { clickWithLoading } = useNxButton(onClick);
  return (
    <div
      onClick={clickWithLoading}
      className={CSName("flex flex-row items-center p-2 gap-2 w-full rounded ")
        .concatIf(
          !!onClick,
          "hover:bg-nx-main-400/20 transition cursor-pointer "
        )
        .build()}
    >
      <div className="flex flex-col   gap-1 grow">
        <div
          className={
            titleClassName ??
            "text-xs text-nx-gray-700 dark:text-white font-normal"
          }
        >
          {title}
        </div>
        {subtitle && (
          <div className="text-caption text-nx-gray-400 dark:text-nx-gray-200 font-normal">
            {subtitle}
          </div>
        )}
      </div>
      {trailing}
    </div>
  );
};

