import { MemberEntity } from "web/features/members/domain/entities/MemberEntity";
import { PanelEntity } from "web/features/panels/domain/entities/PanelEntity";

export class ProjectEntity {
  id?: string;
  name?: string;
  description?: string;
  members?: MemberEntity[] | undefined;
  createdAt?: Date;
  widgets?: [];
  archived?: boolean;
  panels?:PanelEntity[];

  constructor({
    id,
    name,
    description,
    members,
    panels,
    widgets,
    createdAt,
    archived,
  }: Partial<ProjectEntity>) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdAt = createdAt;
    this.members = members;
    this.panels = panels;
    this.widgets = widgets;
    this.archived = archived;
  }

  static fromJSON(data: any): ProjectEntity {
    return new ProjectEntity({
      id: data.id,
      name: data.name,
      description: data.description,
      members: data.members.map((e: any) => MemberEntity.fromJSON(e)),
      panels: data.dashboards.map((e: any) => PanelEntity.fromJSON(e)),
      createdAt: new Date(data.created_at),
      widgets: data.widgets,
      archived: data.archived ?? false,
    });
  }
}
