import { useRef, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { NxFilledButton } from "../Buttons/NxFilledButton";
import { IconClose } from "web/common/fonts/icons/components/IconsClose";
import { NxSVG } from "../NxSvg";
import { cropImage } from "./utils/CropUtils";
import { useTranslation } from "react-i18next";

interface NxImageCropperProps {
  title: string;
  subtitle: string;
  initialImage: string;
  isRound?:boolean;
  onCropComplete: (croppedImage: string) => Promise<void> | void;
  onClosedTapped: () => void;
}

export const NxImageCropper = ({
  title,
  isRound = true,
  subtitle,
  initialImage,
  onCropComplete,
  onClosedTapped,
}: NxImageCropperProps) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const currentCroppedAreaPixels = useRef<Area>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="flex flex-col relative bg-nx-white dark:bg-nx-dark-1200 p-4  rounded-lg  h-[600px] w-[532px]">
        <div className="flex">
          <div className="flex flex-col grow">
            <h5 className="text-base font-semibold text-nx-dark-800 dark:text-nx-main-200 mb-1">
              {title}
            </h5>
            <h6 className="text-caption text-nx-gray-400 dark:text-nx-gray-200">
              {subtitle}
            </h6>
          </div>
          <NxSVG
            onClick={onClosedTapped}
            className="w-4 h-4  cursor-pointer stroke-nx-main-600"
          >
            <IconClose />
          </NxSVG>
        </div>
        <div className="absolute top-20 left-4  h-[408px] w-[500px] rounded overflow-hidden">
          {initialImage && (
            <Cropper
              image={initialImage}
              crop={crop}
              aspect={4 / 4}
              zoom={zoom}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={(_, croppedAreaPixels) => {
                currentCroppedAreaPixels.current = croppedAreaPixels;
              }}
            />
          )}
        </div>
        <div className="absolute flex flex-col  items-center bottom-4 w-full left-0 px-4 gap-6">
          <div className="flex flex-col w-1/2  gap-3">
            <span className="text-nx-gray-200 text-caption">Zoom</span>
            <input
              id="default-range"
              type="range"
              value={zoom}
              min={1}
              max={5}
              step={0.1}
              onChange={(e) => {
                setZoom(Number.parseFloat(e.target.value));
              }}
              className="h-0.5 bg-gray-200 rounded-lg  cursor-pointer dark:bg-gray-700 "
            />
          </div>
          <NxFilledButton
            onClick={async () => {
              if (isLoading) return;
              setIsLoading(true);
              if (currentCroppedAreaPixels.current) {
                const imageCropped = await cropImage(
                  initialImage,
                  currentCroppedAreaPixels.current
                );
                if (!imageCropped) return;

                await onCropComplete(imageCropped!);
              }
              setIsLoading(false);
            }}
          >
            {t("save")}
          </NxFilledButton>
        </div>
      </div>
      {isLoading && (
        <div className="absolute left-0 top-0 w-screen h-screen z-[60] bg-transparent"></div>
      )}
    </>
  );
};
