import { WidgetType } from "web/features/widgets/domain/entities/WidgetType";
import { QueryEntity } from "./QueryEntity";

export class WidgetEntity {
  id?: string;
  name?: string;
  kind?: string;
  type?: WidgetType;
  settings?: string;
  queries?: QueryEntity[];
  archivedAt?: Date;
  restoredAt?: Date;
  deletedAt?: Date;
  createdAt?: Date;
  constructor({ id, name, kind, type, settings, queries, archivedAt, restoredAt, deletedAt, createdAt }: Partial<WidgetEntity>) {
    this.id = id;
    this.name = name;
    this.kind = kind;
    this.type = type;
    this.settings = settings;
    this.queries = queries;
    this.archivedAt = archivedAt;
    this.restoredAt = restoredAt;
    this.deletedAt = deletedAt;
    this.createdAt = createdAt;
  }

  static fromJSON(
    data: any
  ): WidgetEntity {

    return new WidgetEntity({
      id: data.id,
      name: data.name,
      kind: data.kind,
      createdAt: data.createdAt,
    });
  }
}