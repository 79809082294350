let size = 0;

interface FormatterPropreties {
  value: any;
  regex: RegExp;
  groupExpression: string;
  specialChars: string[];
  stringMaxLength: number;
}

export function NumberFormatter({
  value,
  regex,
  groupExpression,
  specialChars,
  stringMaxLength,
}: FormatterPropreties) {
  if(value[value.length-1] !== undefined){
    if (value[value.length-1].match(/[0-9]/) !== null) {
      if (value.length === stringMaxLength) {
        value = value.replace(regex, groupExpression);
        size = value.length;
      } else if (value.length < size) {
          specialChars.forEach((e)=>{
              value = value.replaceAll(e, "");
          });
      }
    } else {
      value = value.substring(0, value.length-1);
    }
  }

  return value.substring(0, stringMaxLength+specialChars.length);
}
