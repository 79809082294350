import { useState } from "react";
import { LoginParams } from "web/features/auth/domain/params/LoginParams";
import { loginUsecase } from "web/features/auth/domain/usecase/LoginUsecase";

abstract class LoginState {}

class LoginLoading extends LoginState {}

class LoginSuccess extends LoginState {}

class LoginError extends LoginState {}

export const useLogin = () => {
  const [loginState, setState] = useState<LoginState>();
  return {
    loginState: {
      isLoading: loginState instanceof LoginLoading,
      isSuccess: loginState instanceof LoginSuccess,
      isError: loginState instanceof LoginError,
    },
    login: async (params: LoginParams) => {
      setState(new LoginLoading());
      const result = await loginUsecase(params);
      if (!result) {
        return setState(new LoginError());
      }
      setState(new LoginSuccess());
    },
  };
};
