export const IconCopy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="6" y="2" width="10" height="14" rx="1" fill="#2998FF" />
      <path
        d="M4.50009 5L4.50001 16.5C4.5 17.0523 4.94772 17.5 5.50001 17.5H13"
        stroke="#2998FF"
        strokeLinecap="round"
      />
    </svg>
  );
};
