import { t } from "i18next";

export const MembersTableHeaderRow = () => {
  return (
    <tr className="text-left text-caption text-nx-gray-200 mb-4 tracking-[1.5px]">
      <th className="py-4 pl-6">{t('firstName').toUpperCase()}</th>
      <th>{t('projects').toUpperCase()}</th>
      <th>{t('role').toUpperCase()}</th>
      <th></th>
    </tr>
  );
};
