import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import PageView from "web/common/components/PageView";
import { useCallback, useMemo, useRef, useState } from "react";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { WidgetCategoryEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { StepOnePage } from "./components/Steps/StepOnePage";
import { StepThreePage } from "./components/Steps/StepThreePage";
import { StepTwoPage } from "./components/Steps/StepTwoPage";

type Params = {
  widgets: WidgetEntity[];
  widgetCategoriesEntity: WidgetCategoryEntity[] ;
  onClick: () => void;
  onCreated: () => void;
};

export function CreateWidgetModal({ widgets, widgetCategoriesEntity, onClick, onCreated }: Params) {
  const [category, setCategory] = useState<WidgetCategoryEntity>();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemId, setItemId] = useState(0);
  const previousRef = useRef(0);

  const memoCurrentPageIndex = useMemo(
    () => currentPageIndex,
    [currentPageIndex]
  );

  const handlePageChange = useCallback(
    (index: number) => {
      if (index === currentPageIndex) return;
      previousRef.current = currentPageIndex;
      setCurrentPageIndex(index);
    },
    [currentPageIndex]
  );

  return (
    <NxModal showModal={true} onOutsideClick={onClick}>
        <div className="w-[440px] h-fit px-4 py-4 flex flex-col z-50 m-auto dark:bg-nx-dark-1100 rounded-lg">
          <PageView
            previousPageIndex={previousRef.current}
            currentPageIndex={memoCurrentPageIndex}
            pages={[
              <StepOnePage
                onPrevious={onClick}
                onNext={() => handlePageChange(currentPageIndex + 1)}
              />,
              <StepTwoPage
                categories={widgetCategoriesEntity}
                onClose={onClick}
                onPrevious={() => {
                  handlePageChange(0);
                }}
                onSelect={(c, i) => {
                  setCategory(c);
                  setItemId(i);
                  handlePageChange(currentPageIndex + 1);
                }}
              />,
              <StepThreePage
                category={category!}
                itemId={itemId}
                onPrevious={() => {
                  handlePageChange(currentPageIndex - 1);
                }}
                onClose={onClick}
                onCreated={onCreated}
              />,
            ]}
          />
        </div>
    </NxModal>
  );
}
