export const IconAddUser = () => {
  return (
    <svg viewBox="0 0 18 18" fill="fill" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 9.9917C11.098 9.9917 12.0392 9.60184 12.8235 8.82211C13.6078 8.04238 14 7.10671 14 6.01509C14 4.92347 13.6078 3.9878 12.8235 3.20807C12.0392 2.39716 11.098 1.9917 10 1.9917C8.90196 1.9917 7.96078 2.39716 7.17647 3.20807C6.39216 3.9878 6 4.92347 6 6.01509C6 7.10671 6.39216 8.04238 7.17647 8.82211C7.96078 9.60184 8.90196 9.9917 10 9.9917Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 12.5055C12.3048 12.163 11.1382 11.9917 10 11.9917C8.31579 11.9917 6.55361 12.3667 4.71345 13.1167C2.90448 13.8354 2 14.7886 2 15.9761V16.9917C2 17.544 2.44772 17.9917 3 17.9917H13.5V16.4917H12C11.1716 16.4917 10.5 15.8201 10.5 14.9917C10.5 14.1633 11.1716 13.4917 12 13.4917H13.5V12.5055Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11.9917C15.5 11.7156 15.2761 11.4917 15 11.4917C14.7239 11.4917 14.5 11.7156 14.5 11.9917V14.4917H12C11.7239 14.4917 11.5 14.7156 11.5 14.9917C11.5 15.2678 11.7239 15.4917 12 15.4917H14.5V17.9917C14.5 18.2678 14.7239 18.4917 15 18.4917C15.2761 18.4917 15.5 18.2678 15.5 17.9917V15.4917H18C18.2761 15.4917 18.5 15.2678 18.5 14.9917C18.5 14.7156 18.2761 14.4917 18 14.4917H15.5V11.9917Z"
      />
    </svg>
  );
};
