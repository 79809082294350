import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { MyProfileContenteCard } from "./MyProfileContentCard";
import { MyProfileTile } from "./MyProfileTile";
import { IconDropButton } from "web/common/fonts/icons/components/IconDropButton";
import { NxSVG } from "web/common/components/NxSvg";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { useState } from "react";
import { useVisualContext } from "web/core/context/VisualContext";
import { useBrowserLanguage } from "web/core/presentation/hooks/UseBrowserLanguage";
import { useTranslation } from "react-i18next";

export const MyProfileLanguageAndTheme = () => {
  const { isDarkMode, setMode } = useVisualContext();
  const { t } = useTranslation();
  const { browserLanguage, allLanguages, changeLanguage } =
    useBrowserLanguage();

  return (
    <MyProfileContenteCard title={t("preferences")}>
      <MyProfileTile
        title={t("theme")}
        subtitle={t("changeNexusAppereance")}
        trailing={
          <MyProfileDropButton
            options={[true, false]}
            value={isDarkMode}
            labelBuilder={(opt) => (opt ? t("dark") : t("light"))}
            onValueChange={(value) => {
              setMode(value);
            }}
          />
        }
      />
      <MyProfileTile
        title={t("language")}
        trailing={
          <MyProfileDropButton
            options={allLanguages}
            value={browserLanguage}
            labelBuilder={(opt) => t(opt)}
            onValueChange={(value) => {
              changeLanguage(value);
            }}
          />
        }
      />
    </MyProfileContenteCard>
  );
};

interface MyProfileDropButtonProps<T> {
  value: T;
  options: T[];
  labelBuilder: (opt: T) => string;

  onValueChange: (value: T) => void;
}

export function MyProfileDropButton<T>({
  options,
  value,
  labelBuilder,
  onValueChange,
}: MyProfileDropButtonProps<T>) {
  const [stateValue, setValue] = useState<T>(value);

  return (
    <NxDropElement
      options={options.map((opt) => {
        return {
          label: labelBuilder(opt),
          onClick: () => {
            onValueChange(opt);
            setValue(opt);
          },
        };
      })}
      dimension={{
        x: 60, y: 10
      }}
    >
      <div className="w-24 h-8 flex flex-row items-center place-content-end">
        <label
          className={CSName(FontStyles.bodyBold)
            .combine(
              "text-nx-main-500 dark:text-nx-main-600 cursor-pointer mr-3.5"
            )
            .build()}
        >
          {labelBuilder(stateValue)}
        </label>
        <NxSVG className="fill-nx-main-500 dark:fill-nx-main-600 w-2 h-2 cursor-pointer">
          <IconDropButton />
        </NxSVG>
      </div>
    </NxDropElement>
  );
}
