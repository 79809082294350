export const IconOrg = () => {
  return (
    <svg viewBox="0 0 20 20" fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4748 4.92197C13.4325 5.27436 13.3378 5.61064 13.1987 5.92282C14.882 6.98552 16 8.86219 16 11L15.9999 11.0354C16.3501 11.0855 16.6835 11.1874 16.9922 11.3331C16.9974 11.2227 17 11.1117 17 11C17 8.39862 15.581 6.12867 13.4748 4.92197ZM10 17C10.9213 17 11.794 16.7924 12.5741 16.4213C12.7652 16.7118 12.9983 16.9721 13.2649 17.1935C12.2901 17.7085 11.1791 18 10 18C8.82088 18 7.70984 17.7085 6.73506 17.1935C7.00165 16.9721 7.23483 16.7118 7.42594 16.4213C8.20597 16.7924 9.07874 17 10 17ZM4.0001 11.0354C4.00003 11.0236 4 11.0118 4 11C4 8.8622 5.11804 6.98554 6.80129 5.92283C6.66222 5.61066 6.56751 5.27437 6.52516 4.92198C4.41899 6.12869 3 8.39863 3 11C3 11.1117 3.00261 11.2227 3.00779 11.3331C3.31649 11.1874 3.64994 11.0855 4.0001 11.0354Z"
      />
      <circle cx="10" cy="4.5" r="2.5" />
      <circle cx="15.5" cy="14.5" r="2.5" />
      <circle cx="4.5" cy="14.5" r="2.5" />
    </svg>
  );
};
