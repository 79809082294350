import { useRef, useState } from "react";
import {
  NxSnackbar,
  NxSnackbarType,
} from "../../../../../common/components/NxSnackbar/NxSnackbar";
import { NxSVG } from "../../../../../common/components/NxSvg";
import CircularProgressBar from "../../../../../common/components/ProgressBar/CircularProgressBar";
import { FontStyles } from "../../../../../common/fonts/FontStyles";
import { IconClose } from "../../../../../common/fonts/icons/components/IconClose";
import { OptionsList } from "./OptionList/OptionsList";
import { OptionsEntity } from "../../../domain/entities/OptionsEntity";
import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { CSName } from "web/common/utils/ClassName";
import { useMembersUserModal } from "../MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../MembersModals/MembersModals";
import { t } from "i18next";

type Parameters = {
  isOpen?: boolean;
};

interface ProjectStateElements {
  isSuccess?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

class ProjectState implements ProjectStateElements {
  isSuccess?: boolean;
  isLoading?: boolean;
  isError?: boolean;

  constructor({ isSuccess, isLoading, isError }: ProjectStateElements) {
    this.isSuccess = isSuccess;
    this.isLoading = isLoading;
    this.isError = isError;
  }
}

export function MembersProjectOptionsModal({ isOpen }: Parameters) {
  const [state, setState] = useState<ProjectState>(new ProjectState({}));
  const { closeModal } = useMembersUserModal();

  const selectedOptionsRef = useRef<OptionsEntity[]>([]);

  const handleState: any = () => {
    setState(
      new ProjectState({
        isLoading: true,
        isSuccess: false,
        isError: false,
      })
    );
  };

  return (
    <div>
      <NxModal
        showModal={isOpen}
        onOutsideClick={() => {
          closeModal(new MembersModals().none());
        }}
      >
        <div className="flex pb-7">
          <div className="grid grid-rows-1 pr-24">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-nx-dark-800 dark:text-nx-main-200 text-start")
                .build()}
            >
              {t("accessProjects")}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
                .build()}
            >
              {t("selectTheProjectText")}{" "}
              <label
                className={CSName(FontStyles.caption)
                  .combine("text-start text-nx-main-500 dark:text-nx-main-200")
                  .build()}
              >
                Vinicius Pereira
              </label>{" "}
              {t("accessToText")}
            </label>
          </div>
          <NxSVG
            className="cursor-pointer"
            width={20}
            height={20}
            onClick={() => {
              closeModal(new MembersModals().none());
            }}
          >
            <IconClose />
          </NxSVG>
        </div>
        <OptionsList
          onUpdate={(options) => {
            selectedOptionsRef.current = options;
          }}
        />
        <PrimaryButton
          className="w-full px-2 grid grid-rows-0.5 place-content-center py-2 bg-nx-main-600 rounded"
          onClick={() => {
            handleState();
          }}
        >
          {state.isLoading ? (
            <CircularProgressBar
              className="stroke-nx-white"
              width={20}
              height={20}
            />
          ) : (
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("text-nx-white cursor-pointer")
                .build()}
            >
              {t("save")}
            </label>
          )}
        </PrimaryButton>
      </NxModal>
      <NxSnackbar
        type={NxSnackbarType.success}
        label="Salvo com sucesso!"
        showIcon={true}
        showSnackbar={state.isSuccess === true}
        onSnackbarClose={() => {
          closeModal(new MembersModals().none());
        }}
      />
      <NxSnackbar
        type={NxSnackbarType.error}
        label="Ops! Erro ao salvar. Tente novamente mais tarde"
        showIcon={true}
        showSnackbar={state.isError === true}
        onSnackbarClose={() => {}}
      />
    </div>
  );
}
