import { FC, ReactNode } from "react";

interface MultiContextProviderProps {
  providers: FC<{ children: ReactNode }>[];
  children: ReactNode;
}

export const MultiContextProvider: React.FC<MultiContextProviderProps> = ({
  providers,
  children,
}: MultiContextProviderProps) => {
  return (
    <>
      {providers.reduce(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children
      )}
    </>
  );
};
