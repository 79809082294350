import { useState } from "react";
import { LeaveOrganizationParams } from "../../domain/params/LeaveOrganizationParams";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { leaveOrganizationUsecase } from "../../domain/usecase/LeaveOrganizationUsecase";

abstract class LeaveOrganizationState {}

class LeaveOrganizationLoading extends LeaveOrganizationState {}

class LeaveOrganizationSuccess extends LeaveOrganizationState {}

class LeaveOrganizationFailure extends LeaveOrganizationState {}

export const useLeaveOrganizationHook = () => {
  const [organizationState, setState] = useState<LeaveOrganizationState>();
  return {
    organizationState: {
      isLoading: organizationState instanceof LeaveOrganizationLoading,
      isSuccess: organizationState instanceof LeaveOrganizationSuccess,
      isError: organizationState instanceof LeaveOrganizationFailure,
    },
    leaveOrganization: async (params: LeaveOrganizationParams) => {
      setState(new LeaveOrganizationLoading());
      const result = await leaveOrganizationUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new LeaveOrganizationFailure());
      }
      setState(new LeaveOrganizationSuccess());
    },
  };
};
