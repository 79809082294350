import { t } from "i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCheck } from "web/common/fonts/icons/components/IconCheck";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { IconRefresh } from "web/common/fonts/icons/components/IconRefresh";
import { IconWarning } from "web/common/fonts/icons/components/IconWarning";
import { CSName } from "web/common/utils/ClassName";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import { ShowRemoveWidgetModal, HideWidgetModal, ShowWidgetDetailModal } from "../../../presentation/context/abstraction/WidgetsModal";
import { useWidgetModal } from "../../../presentation/context/WidgetsModalProvider";
import { format } from "date-fns";

type Params = {
    widget: WidgetEntity;
}

export function SlaveWidget({widget}: Params) {
  const { setModal } = useWidgetModal();
  
  return (
    <div
      id={widget.id}
      className="dark:bg-nx-dark-1200 bg-nx-gray-50 p-4 w-fit rounded"
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <label
            className={CSName(FontStyles.bodyLargeBold)
              .combine("dark:text-nx-main-200 text-start")
              .build()}
          >
            {widget.name}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
              .build()}
          >
            {t("lastUpdate") + format(widget.createdAt!, " MM/yyyy")}
          </label>
        </div>
        <div className="ml-24 flex">
          <NxSVG className="w-5 h-5 stroke-nx-main-500 mr-4">
            <IconRefresh />
          </NxSVG>
          <NxSVG
            className="w-5 h-5 fill-nx-danger-600 cursor-pointer"
            onClick={() => {
              setModal(
                new ShowRemoveWidgetModal({
                  widget: widget,
                  onKeep: () => {
                    setModal(new HideWidgetModal({}));
                  },
                  onRemove: () => {
                    setModal(new HideWidgetModal({}));
                  },
                })
              );
            }}
          >
            <IconDelete />
          </NxSVG>
        </div>
      </div>
      <div className="mt-2 dark:bg-nx-dark-1300 bg-nx-gray-100 w-full flex flex-col p-4">
        <div className="flex flex-row justify-between w-full dark:border-b-nx-gray-500 border-b pb-2">
          <div className="w-[100px]">
            <label
              className={CSName(FontStyles.category)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("firstName").toUpperCase()}
            </label>
          </div>
          <div className="w-[100px]">
            <label
              className={CSName(FontStyles.category)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("status").toUpperCase()}
            </label>
          </div>
        </div>
        {widget.queries?.map((q, index) => {
          if (index > 2) return <div id={q.id}></div>;
          else
            return (
              <div
                id={q.id}
                className="mt-4 flex flex-row justify-between w-full"
              >
                <div className="flex flex-col">
                  <label
                    className={CSName(FontStyles.bodyRegular)
                      .combine("dark:text-nx-white text-nx-gray-700 text-start")
                      .build()}
                  >
                    {q.name}
                  </label>
                  <label
                    className={CSName(FontStyles.caption)
                      .combine(
                        "dark:text-nx-gray-200 text-nx-gray-400 text-start"
                      )
                      .build()}
                  >
                    {q.description}
                  </label>
                </div>
                {(q.queryResults === undefined ||
                  q.queryResults.length === 0) && (
                  <div className="flex flex-row">
                    <label
                      className={CSName(FontStyles.caption)
                        .combine("dark:text-nx-gray-200 text-nx-gray-400")
                        .build()}
                    >
                      {t("noRegisters")}
                    </label>
                    <NxSVG className="w-5 h-5 fill-nx-success-600 ml-1.5 -mt-0.5">
                      <IconCheck />
                    </NxSVG>
                  </div>
                )}
                {q.queryResults !== undefined && q.queryResults.length > 0 && (
                  <div className="flex flex-row">
                    <label
                      className={CSName(FontStyles.caption)
                        .combine("dark:text-nx-white text-nx-gray-700")
                        .build()}
                    >
                      {q.queryResults?.length} {t("registers")}
                    </label>
                    <NxSVG className="w-5 h-5 fill-nx-danger-600 ml-1.5 -mt-0.5">
                      <IconWarning />
                    </NxSVG>
                  </div>
                )}
              </div>
            );
        })}
        {widget.queries && widget.queries?.length > 3 && (
          <div className="flex flex-row m-auto mt-4 py-1">
            <label
              className={CSName(FontStyles.bodyBold)
                .combine(
                  "dark:text-nx-main-500 text-nx-main-600 cursor-pointer"
                )
                .build()}
              onClick={() => {
                setModal(
                  new ShowWidgetDetailModal({
                    widget: widget,
                    onClose: () => {
                      setModal(new HideWidgetModal({}));
                    },
                  })
                );
              }}
            >
              {t("seeAll")} (+{widget.queries?.length - 3})
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
