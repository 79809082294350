import { t } from "i18next";
import { NxAddActionButton } from "web/common/components/Buttons/NxAddActionButton";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { useOrgModal } from "../../context/OrgModalProvider";
import { ShowCreateOrEditOrganizationModal } from "../../context/abstraction/OrganizationModal";

export function OrganizationHeader() {
  const { setModal } = useOrgModal();

  return (
    <div className="flex flex-row pb-6 w-full">
      <div className="w-full">
        <label
          className={CSName(FontStyles.title)
            .combine("dark:text-nx-main-500 text-nx-dark-800")
            .build()}
        >
          {t("manageOrganizations")}
        </label>
      </div>
      <NxAddActionButton
        label={t("newOrganization")}
        onClick={() => {
          setModal(new ShowCreateOrEditOrganizationModal({}));
        }}
      />
    </div>
  );
}
