interface MyProfileContenteCardProops {
  title: string;
  children: React.ReactNode;
}

export const MyProfileContenteCard = ({
  title,
  children,
}: MyProfileContenteCardProops) => {
  return (
    <div className="flex flex-col ">
      <h3 className="mb-2 text-caption text-nx-gray-400 dark:text-nx-gray-200 font-semibold">
        {title.toLocaleUpperCase()}
      </h3>
      <div className="flex flex-col gap-2 w-full p-4 bg-nx-gray-50 dark:bg-nx-dark-1200  rounded">
        {children}
      </div>
    </div>
  );
};
