import { format } from "date-fns";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import {
  WidgetEntity,
} from "web/core/domain/entities/WidgetEntity";
import { MapComponent } from "web/common/components/Maps/MapComponent";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { ShowCreateLocationModal } from "web/features/locations/presentation/context/abstraction/LocationModal";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapComponentController } from "web/common/components/Maps/controller/MapController";
import { MapDrawModes } from "web/common/components/Maps/types/MapDrawModes";
import { useLocationsModal } from "web/features/locations/presentation/context/LocationsModalProvider";
import { IconDownload } from "web/common/fonts/icons/components/IconDownload";

type Params = {
  widget: WidgetEntity;
  onClose: () => void;
  onClickOutside?: () => void;
};

export function WidgetLocationDetailModal({
  widget,
  onClose,
  onClickOutside,
}: Params) {
  const { t } = useTranslation();
  const mapController = useRef<MapComponentController>();
  const { modal } = useLocationsModal();

  const [eventDraw] = useState<{
    type: "created" | "edited";
    draw: DrawLayer | EmptyLayer;
  }>();

  const [draws, setDraws] = useState<Array<DrawLayer | EmptyLayer>>([]);
  const drawsRef = useRef(draws);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const currentIndexRef = useRef(currentIndex);

  const [mapModeState] = useState<MapDrawModes>(
    MapDrawModes.circlemarker
  );
  const changeDraws = (draws: Array<DrawLayer | EmptyLayer>) => {
    drawsRef.current = draws;
    setDraws(draws);
  };

  const changeCurrentIndex = (index: number) => {
    currentIndexRef.current = index;
    setCurrentIndex(index);
  };
  useEffect(() => {
    const content = modal as ShowCreateLocationModal;
    if (
      modal instanceof ShowCreateLocationModal &&
      content.content !== undefined
    ) {
      const options = [
        {
          type: MapDrawModes.circle,
          value: "Point",
        },
        {
          type: MapDrawModes.circlemarker,
          value: "Point",
        },
        {
          type: MapDrawModes.polygon,
          value: "Polygon",
        },
      ];
      const locations = content.content!;

      const draw = new DrawLayer({
        points: DrawLayer.converGeoJsonToLatLngs(locations),
        radius: locations.properties.radius,
        drawMode: options.filter((o) => o.value === locations.geometry.type)[0]
          .type,
      });
      setDraws([draw]);
    }
  }, [modal]);

  useEffect(() => {
    const index = currentIndexRef.current;

    if (eventDraw?.type === "created") {
      eventDraw.draw.id = uuidv4();
      if (index === -1) {
        changeCurrentIndex(0);
        changeDraws([eventDraw.draw]);
      } else {
        drawsRef.current[index] = eventDraw.draw;
        changeDraws([...drawsRef.current]);
      }
    }
    if (eventDraw?.type === "edited") {
      eventDraw.draw.id = uuidv4();
      drawsRef.current[index] = eventDraw.draw;
      changeDraws([...drawsRef.current]);
    }
  }, [eventDraw]);

  useEffect(() => {
    mapController.current?.clearEdit();
    mapController.current?.enableMode(mapModeState);
  }, [mapModeState]);

  return (
    <NxModal showModal={true} onOutsideClick={onClose}>
      <NxModalTitle
        title={widget.name!}
        subtitle={t("lastUpdate") + format(widget.createdAt!, " MM/yyyy")}
        onClosedTapped={onClose}
      />
      <div onClick={onClickOutside} className="flex flex-row gap-4">
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col rounded-lg h-fit w-[37.5rem] bg-nx-white dark:bg-nx-dark-1100"
        >
          <header className="flex flex-col items-center my-4">
            <div className="flex flex-row">
              <h5 className="text-caption text-nx-gray-200">
                {t("clickUnderPivot")}
              </h5>
            </div>
          </header>

          <MapComponent
            className="w-full h-96 rounded-lg overflow-hidden"
            onMonted={(controller) => {
              mapController.current = controller;
              mapController.current.enableMode(mapModeState);
            }}
            onEditCreated={(e)=>{}}
            onDrawCreated={(e) => {
              
            }}
          />
        </div>
      </div>
      <div className="w-full h-fit dark:bg-nx-dark-1300 bg-nx-gray-50 flex flex-col gap-4 my-4 p-2 rounded">
        <h1
          className={CSName(FontStyles.bodyLargeBold)
            .combine("dark:text-nx-main-200 text-start")
            .build()}
        >
          {t("individuals")}
        </h1>
        <div className="flex flex-row gap-8">
          {widget.queries![0].queryResults!.map((q) => (
            <div className="flex flex-col">
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-white text-start")
                  .build()}
              >
                {q.organization}
              </label>
              <label
                className={CSName(FontStyles.caption)
                  .combine("dark:text-nx-gray-200 text-start")
                  .build()}
              >
                {q.responsefile}
              </label>
            </div>
          ))}
        </div>
        <h1
          className={CSName(FontStyles.bodyLargeBold)
            .combine("dark:text-nx-main-200 text-start")
            .build()}
        >
          {t("summary")}
        </h1>
        <div className="flex flex-row gap-10">
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("dark:text-nx-white text-start")
                .build()}
            >
              {t("identifiedPivots")}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-start")
                .build()}
            >
              {widget.queries![0].description}
            </label>
          </div>
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("dark:text-nx-white text-start")
                .build()}
            >
              {t("addedArea")}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-start")
                .build()}
            >
              {widget.queries![1].description}
            </label>
          </div>
        </div>
      </div>
      <div className="relative mb-12">
        <NxOutlinedButton className="h-8 grid grid-flow-col gap-3.5 px-4 place-content-center absolute right-0">
          <label
            className={CSName(FontStyles.bodyBold)
              .combine("text-nx-main-500 cursor-pointer")
              .build()}
          >
            Download
          </label>
          <NxSVG
            className={CSName(`w-5 h-5`)
              .combine("fill-nx-main-500 stroke-nx-main-500 m-auto")
              .build()}
          >
            <IconDownload />
          </NxSVG>
        </NxOutlinedButton>
      </div>
    </NxModal>
  );
}
function uuidv4(): string | undefined {
  throw new Error("Function not implemented.");
}
