import { useEffect } from "react";
import { PivotLocationMiniArea } from "./PivotLocationMiniArea";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { NxSVG } from "web/common/components/NxSvg";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { HideLocationModal } from "web/features/locations/presentation/context/abstraction/LocationModal";
import { useLocationsModal } from "web/features/locations/presentation/context/LocationsModalProvider";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";

interface PivotLocationsSideMenuProps {
  draws: Array<DrawLayer | EmptyLayer>;
  onAddMoreClick: () => void;
  currentIndex: number;
  onDeleted: (index: number) => void;
  onEdit: (index: number) => void;
  onFinish: (name: string, draw: DrawLayer) => void;
  onCreated: () => void;
  state: any;
  name?: string;
}

export interface ILocationInputs {
  name: string;
}

export const PivotLocationSideMenu = ({
  draws,
  name = "",
  onAddMoreClick,
  onDeleted,
  onCreated,
  onEdit,
  onFinish,
  state,
}: PivotLocationsSideMenuProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    getValues,
    setValue,
  } = useForm<ILocationInputs>();
  const { modal } = useLocationsModal();
  const setName = () => {
    if (
      name.length > 0 &&
      (getValues("name") === undefined || getValues("name").length === 0)
    ) {
      setValue("name", name);
      name = "";
    }
  };

  useEffect(() => {
    if (
      modal instanceof HideLocationModal &&
      getValues("name") !== undefined &&
      getValues("name").length > 0
    ) {
      setValue("name", "");
    }
  }, [modal, setValue, getValues]);

  const onSubmit = (data: ILocationInputs) => {
    if (!(draws instanceof EmptyLayer)) {
      onFinish(data.name, (draws as DrawLayer[])[0]);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-lg flex flex-col w-72 p-6 bg-nx-white dark:bg-nx-dark-1100 self-start max-h-[47.25rem] overflow-auto "
    >
      <div className="flex flex-col">
        <div
          className={CSName(FontStyles.bodyLargeBold)
            .combine(
              "text-sm text-nx-dark-800 dark:text-nx-main-200 mb-1 text-start"
            )
            .build()}
        >
          {t("definedParameters")}
        </div>
        <div className="text-caption  text-nx-gray-200 mb-6">
          {t("dataToBeDisplayed")}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-sm text-nx-dark-800 dark:text-nx-white font-medium mb-1">
          {t("createdLocations")}
        </div>
        <div className="text-caption  text-nx-gray-200">
          {t("consultationAreaOfActivity")}
        </div>
        {draws.length === 0 && (
          <div className="mt-2 border-dotted px-3 pt-0.5 pb-1.5 border-nx-gray-300 bg-nx-black/20 w-fit h-fit rounded-full border-2">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-300")
                .build()}
            >
              {t("select")}
            </label>
          </div>
        )}
        {draws.map((draw, index) => {
          setName();
          return (
            <PivotLocationMiniArea
              key={draw.id}
              draw={draw}
            />
          );
        })}
        <div className="mt-4 mb-4 bg-nx-gray-500 h-[1px] rounded" />
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("appointmentCost")}
            </label>
            <div className="flex flex-row">
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-main-200 text-nx-dark-600 mr-1")
                  .build()}
              >
                2000
              </label>
              <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
                <IconCoin />
              </NxSVG>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("availableCredits")}
            </label>
            <div className="flex flex-row">
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-main-200 text-nx-dark-600 mr-1")
                  .build()}
              >
                2000
              </label>
              <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
                <IconCoin />
              </NxSVG>
            </div>
          </div>
        </div>
        <NxFilledButton className="mt-3 py-4" onClick={onCreated}> {t("appointment")}</NxFilledButton>
      </form>
    </div>
  );
};
