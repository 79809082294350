import { createContext, useContext, useState } from "react";
import { MyProfilePoliciesModal } from "./MyProfilePoliciesModal";

interface MyProfilePoliciesModalContextProps {
  showModal: () => void;
}

export const MyProfilePoliciesModalContext =
  createContext<MyProfilePoliciesModalContextProps>(
    {} as MyProfilePoliciesModalContextProps
  );

export function useMyProfilePoliciesModal() {
  return useContext(MyProfilePoliciesModalContext);
}

export const MyProfilePoliciesModalProvider = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <MyProfilePoliciesModalContext.Provider
        value={{
          showModal: () => setIsOpen(true)
        }}
      >
        <div className="w-full h-full">
          {children}
          <MyProfilePoliciesModal isOpen={isOpen} onClosedTapped={() => setIsOpen(false)}/>
        </div>
      </MyProfilePoliciesModalContext.Provider>
    );
  };