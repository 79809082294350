import { t } from "i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { CheckBoxRounded } from "../CheckboxRounded/CheckboxRouded";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { useCallback, useEffect, useState } from "react";
import { ApiKeyEntity } from "web/features/organizations/domain/entities/ApiKeyEntity";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { useOrgModal } from "../../context/OrgModalProvider";
import {
  HideOrganizationModal,
  ShowCreateApiKeyModal,
  ShowCreateOrEditOrganizationModal,
} from "../../context/abstraction/OrganizationModal";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { PermissionsApiKeyEnum } from "web/features/organizations/domain/params/enums/PermissionsApiKeyEnum";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { useCreateApiKeyHook } from "../../hooks/UseCreateApiKeyHook";

interface CreateApiKeyModalProps {
  org: Partial<CreateOrgParams>;
}

const CreateApiKeyModal = ({ org }: CreateApiKeyModalProps) => {
  const {
    trigger,
    register,
    formState: { errors },
    getValues,
    reset,
  } = useForm<Partial<ApiKeyEntity>>();

  const { createApiKey, createApiKeyState } = useCreateApiKeyHook();

  const { showSnackbar } = useNxSnackBarContext();

  const [checkboxError, setCheckboxError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setModal, modal } = useOrgModal();

  const closeModal = useCallback(
    (reloadOrgs: boolean) => {
      setModal(new HideOrganizationModal({ reloadOrgs }));
    },
    [setModal]
  );

  const [onlyReadCheck, setOnlyReadCheck] = useState(false);
  const [writeCheck, setWriteCheck] = useState(false);

  const generateApiKey: SubmitHandler<Partial<ApiKeyEntity>> = (data) => {
    if (data.name !== undefined) {
      setIsLoading(true);
      createApiKey({
        name: data.name,
        org: org.id as string,
        permission: writeCheck
          ? PermissionsApiKeyEnum.write
          : PermissionsApiKeyEnum.read,
      });
    }
  };

  useEffect(() => {
    if (createApiKeyState.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: t('messageErrorCreateApiKey'),
      });
    }
  }, [closeModal, showSnackbar, createApiKeyState.isError]);

  useEffect(() => {
    if (createApiKeyState.isSuccess) {
      setTimeout(()=> {
        showSnackbar({
          type: NxSnackbarType.success,
          label: t('messageSuccessCreateApiKey'),
        });
        setTimeout(() => {
          setIsLoading(false);
          reset();
          setOnlyReadCheck(false);
          setWriteCheck(false);
    
          setModal(
            new ShowCreateOrEditOrganizationModal({
              createOrgParams: {
                id: org.id,
                name: org.name,
                category: org.category,
                s3Key: org.s3Key,
              },
            })
          );
        }, 3000)
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, showSnackbar, createApiKeyState.isSuccess]);

  return (
    <NxModal
      showModal={modal instanceof ShowCreateApiKeyModal}
      onOutsideClick={() => closeModal(false)}
    >
      <div className="flex flex-col items-center bg-nx-white dark:bg-nx-dark-1100 p-4 rounded-lg w-[500px] space-y-4">
        <NxModalTitle
          title={t("generateApiKey")}
          subtitle="Dados de cadastro"
          onClosedTapped={() => {
            closeModal(false);
          }}
        />
        <div className="w-full">
          <NxFormField
            register={register("name", {
              required: t("enterNameForKey"),
            })}
            labelText={t("keyName")}
            error={errors.name}
          />
        </div>
        <div className="w-full mb-4 space-y-1 flex flex-col">
          <span className="text-caption tracking-[1.5px] font-bold text-nx-gray-200">
            {t("permissions").toUpperCase()}
          </span>
          <div className="w-full py-5 space-y-4 rounded-lg dark:bg-nx-dark-1200 bg-nx-gray-50">
            <div className="flex flex-row gap-2 px-4">
              <CheckBoxRounded
                isChecked={onlyReadCheck}
                onClick={(c) => {
                  setCheckboxError(false);
                  setOnlyReadCheck(c);
                  setWriteCheck(false);
                }}
              />
              <div className="flex flex-col">
                <label className="text-captionMedium dark:text-nx-white text-nx-gray-700">
                  {t("readOnly")}{" "}
                </label>
              </div>
            </div>

            <div className="flex flex-row gap-2 px-4">
              <CheckBoxRounded
                isChecked={writeCheck}
                onClick={(c) => {
                  setCheckboxError(false);
                  setWriteCheck(c);
                  setOnlyReadCheck(false);
                }}
              />
              <div className="flex flex-col">
                <label className="text-captionMedium dark:text-nx-white text-nx-gray-700">
                  {t("writing")}
                </label>
              </div>
            </div>
          </div>
          {checkboxError && (
            <p className="text-caption text-nx-danger-600">
              {t("selectPermissionOption")}
            </p>
          )}
        </div>
        <NxFilledButton
          isLoading={isLoading}
          onClick={async () => {
            const isCheckboxInvalid = !onlyReadCheck && !writeCheck;
            setCheckboxError(isCheckboxInvalid);

            const isFormValid = await trigger();
            if (isFormValid && !isCheckboxInvalid) {
              generateApiKey(getValues());
            }
          }}
        >
          {t("generateKey")}
        </NxFilledButton>
      </div>
    </NxModal>
  );
};

export default CreateApiKeyModal;
