import { AgentType } from "./AgentType";

export class AgentEntity {
  id: string;
  name: string;
  category: string;
  type: AgentType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null = null;
  restoredAt: Date | null = null;
  organization: string;
  archived: boolean;
  company: {
    cnpj: string;
  } | null = null;
  person: {
    cpf: string;
    nis: string;
  } | null = null;

  constructor({
    id,
    name,
    category,
    type,
    createdAt,
    updatedAt,
    organization,
    company,
    person,
    archived,
  }: Required<AgentEntity>) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.organization = organization;
    this.company = company;
    this.person = person;
    this.archived = archived;
  }

  static fromJSON(data: any, archived: boolean): AgentEntity {
    return new AgentEntity({
      id: data.id,
      name: data.name,
      category: data.category,
      type: data.type,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      organization: data.organization,
      archived,
      company: data.company
        ? {
            cnpj: data.company?.cnpj,
          }
        : null,
      person: data.person
        ? { cpf: data.person.cpf, nis: data.person.nis }
        : null,

      deletedAt: data.deleted_at ? new Date(data.deleted_at) : null,
      restoredAt: data.restored_at ? new Date(data.restored_at) : null,
    });
  }
}
