import { t } from "i18next";

export const validateName = (value: string): string | boolean => {
  if (!value) {
    return t("requiredField");
  } else if (value.length <= 3) {
    return t("minimalCharactersForName");
  } else if (/\s/.test(value)) {
    return t("onlyOneWord");
  } else if (/\d/.test(value)) {
    return t("onlyLettersValidation");
  }
  return true;
};
