import React from "react";
import { NxTextProps } from "./interfaces/NxTextProps";
import { CSName } from "../../utils/ClassName";

export const NxPageText = ({ className, children }: NxTextProps) => {
  return (
    <h1
      className={CSName(
        "text-xl flex-1 font-semibold text-nx-dark-800 dark:text-nx-white"
      )
        .combine(className)
        .build()}
    >
      {children}
    </h1>
  );
};
