import styled from "styled-components";
import { Color } from "../fonts/Colors";

export enum Align {
    topCenter = "margin: auto",
    left = "margin-left: 0px",
    right = "margin-right: 0px;  float: right",
    center = "align-items: center; justify-content: center;",
    bottomCenter = "margin-top: 50%; margin-left: auto; margin-right: auto"
}

export enum Display {
    row = "display: flex; flex-direction: row; float: right",
    column = "display: flex; flex-direction: column"
}

export const Container = styled.div<{ 
    $background?: Color; $background_url?: string; 
    $width?: number; $widthPx?: number; $height?: number;
    $heightPx?: number;
    $maring_horizontal?: number; $maring_vertical?: number; 
    $border_left?: number; $border_right?: number; 
    $border?: number; $border_color?: Color,
    $align?: Align, $display?: Display}> `
    border: ${(props)=> `${props.$border ? props.$border : '0'}`}px solid ${(props)=> `${props.$border_color ? props.$border_color : ''}`};
    
    width: ${(props) => props.$width}%;
    ${(props) => (props.$width && `width: ${props.$width}%`) || (props.$widthPx && `width: ${props.$widthPx}px`)};
    ${(props) =>( props.$height && `height: ${props.$height}vh`) ||( props.$heightPx && `height: ${props.$heightPx}px`)};

    ${(props) => props.$align};
    ${(props) => props.$display};

    ${(props) => props.$maring_vertical && `
    margin-top: ${props.$maring_vertical}px; 
    margin-bottom: ${props.$maring_vertical}px`};

    ${(props) => props.$maring_horizontal && `
    margin-left: ${props.$maring_horizontal}px; 
    margin-right: ${props.$maring_horizontal}px`};
    

    ${(props) => props.$background && `background: ${props.$background}`};
    ${(props) => props.$background_url && `
    background: url(${props.$background_url}); 
    ${props.$background ? `background-color: ${props.$background}`: ''};
    background-blend-mode: overlay;
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat`};

    ${(props) => props.$border_left && `
    border-top-left-radius: ${props.$border_left}px; 
    border-bottom-left-radius: ${props.$border_left}px`};

    ${(props) => props.$border_right && `
    border-top-right-radius: ${props.$border_right}px; 
    border-bottom-right-radius: ${props.$border_right}px`};
    position: relative;
`

export const Rectangle = styled(Container)`
    border-radius: 24px;
    border: 0px solid;
`