import { NxSVG } from "../../../../../common/components/NxSvg";
import { IconDropButton } from "../../../../../common/fonts/icons/components/IconDropButton";

export const MemberUserRuleDropdown = () => {
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full items-center gap-x-2 py-1.5 px-4 bg-nx-main-400/20 rounded-[50px]
        text-caption font-medium text-nx-main-700 dark:text-nx-main-200"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          Options
          <NxSVG className="fill-nx-main-700 dark:fill-nx-main-200 w-2 h-2 ">
            <IconDropButton />
          </NxSVG>
        </button>
      </div>
    </div>
  );
};
