import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { editProjectUsecase } from "../../domain/usecase/EditProjectUsecase";
import { EditProjectParams } from "../../domain/params/EditProjectParams";

abstract class EditProjectState {}

class EditProjectLoading extends EditProjectState {}

class EditProjectSuccess extends EditProjectState {}

class EditProjectFailure extends EditProjectState {}

export const useEditProjectHook = () => {
  const [projectState, setState] = useState<EditProjectState>();
  return {
    projectState: {
      isLoading: projectState instanceof EditProjectLoading,
      isSuccess: projectState instanceof EditProjectSuccess,
      isError: projectState instanceof EditProjectFailure,
    },
    editProject: async (params: EditProjectParams) => {
      setState(new EditProjectLoading());
      const result = await editProjectUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new EditProjectFailure());
      }
      setState(new EditProjectSuccess());
    },
  };
};
