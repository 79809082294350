import { t } from "i18next";

export const validateEmail = (value: string): string | boolean => {
  if (!value) {
    return t('requiredEmail');
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
    return t('invalidEmail');
  }
  return true;
};
