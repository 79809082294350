import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { ApiKeyEntity } from "web/features/organizations/domain/entities/ApiKeyEntity";

export abstract class OrganizationsModal {}

export class HideOrganizationModal extends OrganizationsModal {
  reloadOrgs: boolean;

  constructor(params: Partial<HideOrganizationModal> | undefined) {
    super();
    this.reloadOrgs = params?.reloadOrgs ?? false;
  }
}

export class ShowNewAdminModal extends OrganizationsModal {}

export class ShowCreateOrEditOrganizationModal extends OrganizationsModal {
  createOrgParams?: Partial<CreateOrgParams>;
  onSuccess?: () => void;

  constructor({
    createOrgParams,
    onSuccess,
  }: Partial<ShowCreateOrEditOrganizationModal>) {
    super();
    this.createOrgParams = createOrgParams;
    this.onSuccess = onSuccess;
  }
}

export class ShowLeaveOrganizationModal extends OrganizationsModal {}
export class ShowRemoveApiKeyModal extends OrganizationsModal {
  apiKey: ApiKeyEntity;
  org: Partial<CreateOrgParams>;
  constructor(apiKey: ApiKeyEntity, org: Partial<CreateOrgParams>) {
    super();
    this.apiKey = apiKey;
    this.org = org;
  }
}

export class ShowCreateApiKeyModal extends OrganizationsModal {
  org: Partial<CreateOrgParams>;
  constructor(org: Partial<CreateOrgParams>) {
    super();
    this.org = org;
  }
}
