import { useState } from "react";
import { archiveAgentUsecase } from "../../domain/usecase/ArchiveAgentUsecase";
import { ArchiveAgentError } from "../../domain/errors/ArchiveAgentError";

abstract class ArchiveAgentState {}

export class ArchiveAgentLoading extends ArchiveAgentState {}

export class ArchiveAgentSuccess extends ArchiveAgentState {}

export class ArchiveAgentFailure extends ArchiveAgentState {}

export const useArchiveAgentHook = () => {
  const [archiveAgentState, setState] = useState<ArchiveAgentState>();

  return {
    archiveAgentState,
    archiveAgent: async (id: string) => {
      setState(new ArchiveAgentLoading());
      const result = await archiveAgentUsecase(id);

      if (result instanceof ArchiveAgentError) {
        return setState(new ArchiveAgentFailure());
      }

      return setState(new ArchiveAgentSuccess());
    },
  };
};
