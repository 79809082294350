import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";

import en from "./languages/en.json";
import es from "./languages/es.json";
import pt from "./languages/pt.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  pt: {
    translation: pt,
  },
};

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    resources,
    lng: localStorage.getItem("language") ?? "pt",
    fallbackLng: ["pt", "en", "es"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
