export const MemberInvetedUserCell = () => {
  return (
    <div className="flex flex-row gap-2 py-4  ml-6 cursor-pointer  items-center">
      <div className="inline-flex items-center justify-center rounded-full w-6 h-6 overflow-hidden bg-nx-main-400/20 text-sm font-normal text-nx-main-500">
        G
      </div>
      <div className="flex flex-col items-center">
        <h6 className="text-xs text-nx-gray-700 dark:text-nx-white">
          teste@gmail.com
        </h6>
      </div>
    </div>
  );
};
