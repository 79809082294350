import React, { useContext, useState } from "react";
import { Outlet } from "react-router";
import styled from "styled-components";
import { Color } from "../../../../common/fonts/Colors";
import { Align, Container } from "../../../../common/components/shapes";
import { VisualContext } from "../../../../core/context/VisualContext";
import { DropdownField } from "../../../../common/components/TextFields/DropdownField";
import ManagerImages from "../../../../common/fonts/ManagerImages";
import { DropdownStateContext } from "../../../../core/context/DropdownStateContext";
import { useTranslation } from "react-i18next";

const Body = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const FormArea = styled.div`
  width: 460px;
  max-width: 460px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ImageBackground = styled.div`
  flex-grow: 1;
  height: auto;
  background-size: cover;
  background-color: ${Color.blueOverlay};
  background-blend-mode: overlay;

  @media screen and (max-width: 460px) {
    display: none;
  }
`;

export const AuthPageComponent = () => {
  const { isDarkMode, setMode } = useContext(VisualContext);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <DropdownStateContext.Provider value={{ isOpen, setIsOpen }}>
      <Body
        style={{
          backgroundColor: isDarkMode ? Color.dark1100 : Color.white,
        }}
        onClick={() => {
          if (isOpen === true) setIsOpen(false);
        }}
      >
        <ImageBackground
          style={{
            backgroundImage: `url(${ManagerImages.background})`,
          }}
        >
          <Container
            $maring_vertical={24}
            $align={Align.center}
            style={{ float: "right", marginRight: 20 }}
          >
            <DropdownField
                label={t('changeAppearanceLabel')}
                background={Color.main100}
                dropdownColor={isDarkMode ? Color.dark1200 : Color.white}
                elementsColor={isDarkMode ? Color.white : Color.black}
                list={(t('changeAppearanceOptions', {returnObjects: true}) as string[]).map((e, index)=>{
                  return {
                    label: e,
                    action: () => {
                      setMode(index === 0 ? false : true);
                    },
                  };
                })}
              /> 
          </Container>
        </ImageBackground>
        <FormArea>
          <Container
            $background={isDarkMode ? Color.dark1100 : Color.white}
            $width={100}
            $align={Align.topCenter}
          >
            <Outlet />
          </Container>
        </FormArea>
      </Body>
    </DropdownStateContext.Provider>
  );
};
