import { useState } from "react";
import { getProjectsUsecase } from "../../domain/usecase/GetProjectsUsecase";
import { ProjectEntity } from "../../domain/entity/ProjectEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";

abstract class GetProjectsState {}

export class GetProjectsLoading extends GetProjectsState {}

export class GetProjectsSuccess extends GetProjectsState {
  projects: ProjectEntity[];
  projectsArchived: ProjectEntity[];

  constructor({ projects, projectsArchived }: Required<GetProjectsSuccess>) {
    super();
    this.projects = projects;
    this.projectsArchived = projectsArchived;
  }
}

export class GetProjectsFailure extends GetProjectsState {}

export const useGetProjectsHook = () => {
  const [getProjectState, setState] = useState<GetProjectsState>();

  return {
    getProjectState,
    getProjects: async () => {
      setState(new GetProjectsLoading());

      const [resultPagination, resultPaginationArchived] = await Promise.all([
        getProjectsUsecase({ deleted: false }),
        getProjectsUsecase({ deleted: true }),
      ]);

      if (
        resultPagination instanceof PaginationEntity &&
        resultPaginationArchived instanceof PaginationEntity
      ) {
        return setState(
          new GetProjectsSuccess({
            projects: resultPagination.results,
            projectsArchived: resultPaginationArchived.results,
          })
        );
      }

      return setState(new GetProjectsFailure());
    },
  };
};
