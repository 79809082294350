export class Color {
    static black = "#000000"

    static white = "#FFFFFF"

    static gray700 = "#272E34"

    static gray600 = "#384551"

    static gray500 = "#4F6577"

    static gray400 = "#667C8E"

    static gray300 = "#7E93A4"

    static gray200 = "#7E93A4"

    static gray100 = "#D9E3EB"

    static gray50 = "#F0F3F6"

    ///Dark mode colors
    static dark1300 = "#061522"

    static dark1200 = "#0F202D"

    static dark1100 = "#192C3A"

    static dark1000 = "#163552"

    static dark900 = "#174B7D"

    static dark800 = "#13599A"

    static dark600 = "#0167C5"

    ///Main colors
    static main700 = "#0167C5"

    static main600 = "#0084FF"

    static main500 = "#2998FF"

    static main400 = "#51ACFF"

    static main300 = "#79C0FF"

    static main200 = "#A2D3FF"

    static main100 = "#CAE6FF"

    static main50 = "#F6FBFF"

    ///Success colors
    static success700 = "#119D5A"

    static success600 = "#0DAD5F"

    static success500 = "#25C577"

    static success400 = "#4ED890"

    static success300 = "#73E8A8"

    static success200 = "#99F4C0"

    static success100 = "#BFFBD7"

    static success50 = "#E6FFEF"

    ///Warning colors
    static warning700 = "#F39303"

    static warning600 = "#F0AD00"

    static warning500 = "#FFC027"

    static warning400 = "#FFD350"

    static warning300 = "#FFE279"

    static warning200 = "#FFEEA2"

    static warning100 = "#FFF7CA"

    static warning50 = "#FFFEF2"

    ///Danger colors
    static danger700 = "#D03A3D"

    static danger600 = "#F64C4C"

    static danger500 = "#FF5C5C"

    static danger400 = "#FF7070"

    static danger300 = "#FF8B8B"

    static danger200 = "#FFA9A9"

    static danger100 = "#FFCBCB"

    static danger50 = "#FFF2F2"

    ///Others colors
    static clicked = "#CAE6FF"

    static lightField = "#CAE6FF1A"

    static lightFocus = "#0084FF1A"

    static lightDisabled = "#98AAB9"

    static darkField = "rgba(202, 230, 255, 0.1)"

    static darkFocus = "#51ACFF33"

    static darkDisabled = "#D9E3EB"

    static blueOverlay = "#336A91"

    static box = "#244561"

    static transparent = "transparent"
}