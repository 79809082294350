import { useEffect, useRef, useState } from "react";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { NxDropParams } from "./params/NxDropParams";
import { NxSVG } from "../NxSvg";

type Dimension = {
  x: number;
  y: number;
};

export function NxDropElement(params: NxDropParams) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
  const [visibility, setVisibility] = useState(false);
  const [dimensions, setDimensions] = useState<Dimension>();

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      setDimensions({
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
      });
    }
    window.addEventListener("resize", () => {
      setZoomLevel(zoomLevel);
      setVisibility(false);
    });
  }, [setZoomLevel, zoomLevel]);

  return (
    <div>
      <div
        ref={elementRef}
        className="relative"
        onClick={() => {
          setVisibility(!visibility);
        }}
      >
        <div className="flex flex-row">
          {visibility && (
            <InvisibleComponent
              onFinish={(rect) => {
                setDimensions({
                  x: rect.left + window.scrollX,
                  y: rect.top + window.scrollY,
                });
              }}
            />
          )}
          {params.children}
        </div>
      </div>
      {visibility && dimensions !== undefined && (
        <div>
          <div
            className="absolute w-full h-lvh z-10 inset-x-0 inset-y-0 flex justify-center"
            onClick={() => {
              setVisibility(false);
            }}
          >
            {dimensions.x !== 0 && (
              <div
                className={CSName(
                  "absolute rounded bg-nx-gray-50  dark:bg-nx-dark-1200  border-nx-gray-400 border-[0.5px] px-2 py-2  z-10"
                ).build()}
                style={{
                  left: dimensions.x - params.dimension!.x,
                  top: dimensions.y + 20,
                }}
                onClick={() => {
                  setVisibility(false);
                }}
              >
                {params.options.map((e, index) => {
                  ///TODO: Replace key with the unique ID
                  return (
                    <div
                      className={CSName(
                  "flex flex-row px-2 py-2 hover:bg-nx-main-600 rounded gap-2 group cursor-pointer"
                      )
                  .combine(e.className)
                  .build()}
                onClick={e.onClick}
                      key={index}
                    >
                      {e.icon && (
                        <NxSVG className="fill-nx-gray-400 group-hover:fill-nx-white w-5 h-5  cursor-pointer">
                          {e.icon}
                        </NxSVG>
                      )}
                      <label
                        className={CSName(FontStyles.bodyRegular)
                          .combine(
                            "cursor-pointer text-black text-start dark:text-white group-hover:text-white place-content-center"
                          )
                          .build()}
                      >
                        {e.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

type Params = {
  onFinish: (e: DOMRect) => void;
};

function InvisibleComponent({ onFinish }: Params) {
  const elementRef = useRef<HTMLDivElement>(null);
  let finished = useRef<boolean>(false);

  useEffect(() => {
    const element = elementRef.current;
    if (element && !finished.current) {
      const rect = element.getBoundingClientRect();
      onFinish(rect);
      finished.current = true;
    }
  }, [onFinish]);

  return (
    <div
      ref={elementRef}
      onChange={() => {
      }}
    >
    </div>
  );
}
