export const IconSetting = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5516 12.9466C15.7618 12.5676 15.9302 12.147 16.0569 11.7263L18 11.0947V8.82065L16.0569 8.18898C15.9302 7.76833 15.7618 7.34768 15.5516 6.96868L16.48 5.11531L14.8752 3.51601L13.0184 4.442C12.6384 4.23098 12.2166 4.06299 11.7935 3.93666L11.1602 2H8.8816L8.20513 3.97831C7.78338 4.10464 7.36164 4.27401 6.98164 4.48364L5.12484 3.55766L3.51997 5.15835L4.44837 7.01033C4.23819 7.38933 4.06838 7.80998 3.94171 8.23202L2 8.8623V11.1363L3.98486 11.8096C4.11153 12.2317 4.27995 12.6523 4.49152 13.0313L3.56172 14.8833L5.16659 16.484L7.0234 15.558C7.40339 15.7676 7.82653 15.937 8.24828 16.0633L8.8816 18H11.1602L11.7935 15.9787C12.2166 15.8523 12.6384 15.6843 13.0184 15.4733L14.8752 16.3993L16.48 14.8L15.5516 12.9466ZM10 13.5556C11.9637 13.5556 13.5556 11.9637 13.5556 10C13.5556 8.03632 11.9637 6.44444 10 6.44444C8.03632 6.44444 6.44444 8.03632 6.44444 10C6.44444 11.9637 8.03632 13.5556 10 13.5556Z"
      />
    </svg>
  );
};
