import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { t } from "i18next";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { usePanelModal } from "../../context/PanelsModalProvider";
import {
  HidePanelModal,
  ShowCreateOrEditPanelModal,
} from "../../context/abstraction/PanelsModal";
import { NxSVG } from "web/common/components/NxSvg";
import { IconImage } from "web/common/fonts/icons/components/IconImage";
import { validateEmptyField } from "web/common/forms/validators/ValidateEmptyField";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import ImageUploading from "react-images-uploading";
import { NxImageCropper } from "web/common/components/NxImageCropper/NxImageCropper";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import {
  CreatingPanel,
  PanelCreated,
  useCreatePanelHook,
} from "../../hook/UseCreatePanelHook";
import { UploadEntity } from "web/core/domain/entities/UploadEntity";
import { CreatePanelParams } from "web/features/panels/domain/params/CreatePanelParams ";
import {
  PanelUpdated,
  UpdatingPanel,
  useUpdatePanelHook,
} from "../../hook/UseUpdatePaneHook";
import ManagerImages from "web/common/fonts/ManagerImages";

type ModalParams = {
  panel?: CreatePanelParams;
  onSuccess?: () => void;
};

interface IPanelInputs {
  name: string;
}

interface Controller {
  inputs?: IPanelInputs;
  showSnackbar?: boolean;
  submit?: boolean;
}

export function CreateOrEditPanelModal({ panel, onSuccess }: ModalParams) {
  const { panelCreationState, createPanel } = useCreatePanelHook();
  const { panelUpdationState, updatePanel } = useUpdatePanelHook();
  const [uploadEntity, setUploadData] =
    useState<Partial<UploadEntity> | null>();
  const [imageToEdit, setImageToEdit] = useState<string | null>(null);
  const [newImage, setNewImage] = useState<string | null>();
  const [controller] = useState<Controller>();
  const { modal, setModal } = usePanelModal();

  const [defaultImageBuffer, setDefaultImageBuffer] =
    useState<ArrayBufferLike | null>(null);

  useEffect(() => {
    (async () => {
      const buffer = await getDefaultImageAsArrayBuffer();
      setDefaultImageBuffer(buffer);
    })();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IPanelInputs>();

  const onSubmit = async (data: IPanelInputs) => {
    if (data.name !== undefined) {
      const imageToUpload = uploadEntity
        ? uploadEntity
        : {
            fileName: "default-image-panel.png",
            folder: "panels",
            file: defaultImageBuffer!,
          };
      if (panel?.id !== undefined) {
        updatePanel({
          upload: uploadEntity!,
          panel: {
            id: panel.id,
            name: data.name,
            s3Key: panel.s3Key,
          },
        });
      } else {
        if (uploadEntity !== undefined)
          createPanel({
            upload: uploadEntity!,
            panel: { name: data.name, project: panel?.project },
          });
        else {
          createPanel({
            upload: imageToUpload!,
            panel: { name: data.name, project: panel?.project },
          });
        }
      }
    }
  };

  const closeModal = useCallback(() => {
    setModal(new HidePanelModal({ reload: true }));
  }, [setModal]);

  useEffect(() => {
    if (panel?.name !== undefined && getValues("name").length === 0) {
      setValue("name", panel!.name!);
    }
    if (
      panelCreationState instanceof PanelCreated ||
      panelUpdationState instanceof PanelUpdated
    ) {
      closeModal();
    }
  }, [
    setValue,
    getValues,
    panel,
    onSuccess,
    panelCreationState,
    panelUpdationState,
    closeModal,
  ]);

  async function getDefaultImageAsArrayBuffer(): Promise<ArrayBufferLike> {
    const response = await fetch(ManagerImages.defaultImagePanel);
    const blob = await response.blob();
    return blob.arrayBuffer();
  }

  const convertToUint8 = (base64: string) => {
    var binaryString = atob(base64.split(",")[1]);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  return (
    <div>
      <NxModal
        showModal={modal instanceof ShowCreateOrEditPanelModal}
        onOutsideClick={closeModal}
      >
        {imageToEdit && (
          <NxImageCropper
            title={t("coverImage")}
            subtitle={t("coverDescription")}
            initialImage={imageToEdit}
            onClosedTapped={() => setImageToEdit("")}
            isRound={false}
            onCropComplete={(croppedImage) => {
              setNewImage(croppedImage);
              setUploadData({
                fileName: uploadEntity?.fileName,
                folder: uploadEntity?.folder,
                file: convertToUint8(croppedImage),
              });
              setImageToEdit("");
            }}
          />
        )}
        {!imageToEdit && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[432px] flex flex-col justify-center"
          >
            <NxModalTitle
              title={panel?.name ? t("editPanel") : t("newPanel")}
              subtitle={t("savedData")}
              onClosedTapped={closeModal}
            />

            <ImageUploading
              value={[]}
              onChange={(imageList) => {
                setUploadData({
                  fileName: imageList[0].file!.name,
                  folder: "panels",
                  file: convertToUint8(imageList[0].dataURL!),
                });
                setImageToEdit(imageList[0].dataURL ?? "");
              }}
              maxNumber={1}
            >
              {({ onImageUpload }) => {
                return newImage ? (
                  <div className="w-[224px] h-[129px] m-auto">
                    <div className="relative w-full h-full rounded overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={newImage ?? imageToEdit ?? ""}
                        alt=""
                      />
                      <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black to-transparent"></div>
                    </div>
                    <NxDropElement
                      options={[
                        {
                          label: t("edit"),
                          onClick: () => {
                            onImageUpload();
                          },
                        },
                        {
                          label: t("remove"),
                          onClick: () => {
                            setNewImage("");
                          },
                        },
                      ]}
                      dimension={{
                        x: 0,
                        y: 0,
                      }}
                    >
                      <div className="w-[259px] h-full flex flex-cols z-50 ml-2 -mt-5">
                        <label
                          className={CSName(FontStyles.caption)
                            .combine("w-20 text-nx-white mr-2")
                            .build()}
                        >
                          {t("coverImage")}
                        </label>
                        <div className="ml-28 mt-0.5 w-7 h-7 flex items-center justify-center rounded-full bg-nx-main-500 cursor-pointer">
                          <NxSVG className="w-4 h-4 mb-1 mr-0.5 fill-nx-white">
                            <IconEdit />
                          </NxSVG>
                        </div>
                      </div>
                    </NxDropElement>
                  </div>
                ) : (
                  <div
                    className="my-6 dark:bg-nx-black/20 bg-nx-status-disabled/30 border-nx-gray-400 border-[0.5px] rounded border-solid self-center py-5 px-14 cursor-pointer"
                    onClick={() => {
                      onImageUpload();
                    }}
                  >
                    <NxSVG className="w-8 h-8 fill-nx-main-500 dark:fill-nx-main-500 m-auto mb-2">
                      <IconImage />
                    </NxSVG>
                    <h5
                      className={CSName(FontStyles.bodyBold)
                        .combine("dark:text-nx-main-500 text-nx-main-600")
                        .build()}
                    >
                      {t("addPhoto")}
                    </h5>
                    <div className="w-24 flex justify-center">
                      <label
                        className={CSName(FontStyles.caption)
                          .combine("dark:text-nx-gray-200 text-nx-gray-400")
                          .build()}
                      >
                        {t("addPhotoDimensions")}
                      </label>
                    </div>
                  </div>
                );
              }}
            </ImageUploading>
            <NxFormField
              register={register("name", { validate: validateEmptyField })}
              labelText={t("panelName")}
              error={errors.name}
            />

            <NxFilledButton
              isLoading={
                panelCreationState instanceof CreatingPanel ||
                panelUpdationState instanceof UpdatingPanel
              }
              className="mt-6 py-3"
              onClick={() => {
                handleSubmit(onSubmit);
              }}
            >
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("cursor-pointer")
                  .build()}
              >
                {controller?.submit === true ? (
                  <CircularProgressBar
                    className="stroke-nx-white"
                    width={20}
                    height={20}
                  />
                ) : panel?.name ? (
                  t("save")
                ) : (
                  t("createPanel")
                )}
              </label>
            </NxFilledButton>
          </form>
        )}
      </NxModal>
      <NxSnackbar
        type={NxSnackbarType.success}
        label={t("createdWithSuccess") + "!!"}
        showIcon={true}
        showSnackbar={
          panelCreationState instanceof PanelCreated ||
          panelUpdationState instanceof PanelUpdated
        }
        onSnackbarClose={() => {
          closeModal();
        }}
      />
      <NxSnackbar
        type={NxSnackbarType.success}
        label={t("removedSuccessfull")}
        showIcon={true}
        showSnackbar={newImage === ""}
        onSnackbarClose={() => {}}
      />
    </div>
  );
}
