import { IconClose } from "../fonts/icons/components/IconsClose";
import { NxSVG } from "./NxSvg";

type NxModalTitleProps = {
  title: string;
  subtitle: string;
  onClosedTapped: () => void;
};

export const NxModalTitle = ({
  title,
  subtitle,
  onClosedTapped,
}: NxModalTitleProps) => {
  return (
    <div className="flex w-full">
      <div className="flex flex-col grow">
        <h5 className="text-base font-semibold text-nx-dark-800 dark:text-nx-main-200 mb-1">
          {title}
        </h5>
        <h6 className="text-caption text-nx-gray-400 dark:text-nx-gray-200">
          {subtitle}
        </h6>
      </div>
      <NxSVG
        onClick={onClosedTapped}
        className="w-4 h-4  cursor-pointer stroke-nx-main-600"
      >
        <IconClose />
      </NxSVG>
    </div>
  );
};
