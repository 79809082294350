import Slider from "react-slick";
import "./HomeCarrousel.css";
import { useRef, useState, useEffect } from "react";
import { StepIndicator } from "../../../../auth/features/sign-up/presentation/page/SignUpPage";

export interface HomeCarrouselItem {
  title: string;
  font: string;
  date: string;
  image: string;
}

interface HomeCarrouselProps {
  items: HomeCarrouselItem[];
}
export const HomeCarrousel = ({ items }: HomeCarrouselProps) => {
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const slidesPerView = useRef<number>(3);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 800) {
      slidesPerView.current = 1;
    } else if (window.innerWidth < 1030) {
      slidesPerView.current = 2;
    } else {
      slidesPerView.current = 3;
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    customPaging: (i: number) => {
      return <StepIndicator active={i === currentSlideNumber} />;
    },
    beforeChange: (current: number, next: number) => {
      setCurrentSlideNumber(Math.floor(next / slidesPerView.current));
    },
  };
  return (
    <div className="flex flex-col">
      <div className="w-full overflow-hidden">
        <Slider {...settings}>
          {items.map(({ title, font, date, image }, index) => (
            <div
              key={index}
              className="aspect-[336/121] bg-nx-main-500 text-center rounded-lg relative overflow-hidden"
            >
              <img
                className="absolute w-full h-full object-cover"
                src={image}
                alt={image}
              />
              <div className="absolute flex flex-col items-start justify-end w-full h-[80%] bottom-0 p-4 bg-gradient-to-t from-black">
                <h4 className="text-nx-white text-xs ">{title}</h4>
                <h5 className="text-caption text-nx-gray-200">
                  {font} · {date}
                </h5>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="flex flex-row gap-2 self-center">
        {[
          ...Array.from(
            {
              length: Math.ceil(items.length / slidesPerView.current),
            },
            (_, i) => i
          ),
        ].map((i) => {
          return <StepIndicator key={i} active={i === currentSlideNumber} />;
        })}
      </div>
    </div>
  );
};
