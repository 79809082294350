export const IconWeather = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="fill"
    >
      <path d="M16 10.5H18" stroke='stroke' stroke-linecap="round" />
      <path d="M2 10.5H4" stroke='stroke' stroke-linecap="round" />
      <path
        d="M14.2427 6.25735L15.6569 4.84314"
        stroke='stroke'
        stroke-linecap="round"
      />
      <path
        d="M4.34326 16.1569L5.75748 14.7426"
        stroke='stroke'
        stroke-linecap="round"
      />
      <path
        d="M5.75732 6.25735L4.34311 4.84314"
        stroke='stroke'
        stroke-linecap="round"
      />
      <path d="M10 4L10 2.50001" stroke='stroke' stroke-linecap="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9986 10.6069C13.9995 10.5714 14 10.5357 14 10.5C14 8.29086 12.2091 6.5 10 6.5C7.79086 6.5 6 8.29086 6 10.5C6 12.0702 6.90478 13.4291 8.22144 14.0838C7.94142 14.2903 7.69858 14.5443 7.50482 14.8339C6.00766 13.97 5 12.3527 5 10.5C5 7.73858 7.23858 5.5 10 5.5C12.7614 5.5 15 7.73858 15 10.5C15 10.6669 14.9918 10.8318 14.9759 10.9945C14.6736 10.8226 14.3454 10.691 13.9986 10.6069Z"
        fill="fill"
      />
      <mask id="path-8-inside-1_117_684" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.1 11.5C11.5536 11.5 10.3 12.7536 10.3 14.3C10.3 14.3672 10.3024 14.4339 10.307 14.5H10C8.89543 14.5 8 15.3954 8 16.5C8 17.6046 8.89543 18.5 10 18.5H16C17.1046 18.5 18 17.6046 18 16.5C18 15.3954 17.1046 14.5 16 14.5H15.893C15.8976 14.4339 15.9 14.3672 15.9 14.3C15.9 12.7536 14.6464 11.5 13.1 11.5Z"
        />
      </mask>
      <path
        d="M10.307 14.5V15.5H11.3801L11.3046 14.4296L10.307 14.5ZM15.893 14.5L14.8954 14.4296L14.8199 15.5H15.893V14.5ZM11.3 14.3C11.3 13.3059 12.1059 12.5 13.1 12.5V10.5C11.0013 10.5 9.3 12.2013 9.3 14.3H11.3ZM11.3046 14.4296C11.3015 14.3869 11.3 14.3437 11.3 14.3H9.3C9.3 14.3908 9.3032 14.4809 9.30951 14.5704L11.3046 14.4296ZM10 15.5H10.307V13.5H10V15.5ZM9 16.5C9 15.9477 9.44772 15.5 10 15.5V13.5C8.34315 13.5 7 14.8431 7 16.5H9ZM10 17.5C9.44772 17.5 9 17.0523 9 16.5H7C7 18.1569 8.34315 19.5 10 19.5V17.5ZM16 17.5H10V19.5H16V17.5ZM17 16.5C17 17.0523 16.5523 17.5 16 17.5V19.5C17.6569 19.5 19 18.1569 19 16.5H17ZM16 15.5C16.5523 15.5 17 15.9477 17 16.5H19C19 14.8431 17.6569 13.5 16 13.5V15.5ZM15.893 15.5H16V13.5H15.893V15.5ZM14.9 14.3C14.9 14.3437 14.8985 14.3869 14.8954 14.4296L16.8905 14.5704C16.8968 14.4809 16.9 14.3908 16.9 14.3H14.9ZM13.1 12.5C14.0941 12.5 14.9 13.3059 14.9 14.3H16.9C16.9 12.2013 15.1987 10.5 13.1 10.5V12.5Z"
        mask="url(#path-8-inside-1_117_684)"
      />
    </svg>
  );
};
