import { useState } from "react";
import { CreateLocationParams } from "../../domain/params/CreateLocationParams";
import { CreateLocationFailure } from "../../domain/errors/CreateLocationFailure";
import { UpdateLocationUsecase } from "../../domain/usecase/UpdateLocationUsecase";

abstract class LocationState {}

export class UpdatingLocation extends LocationState {}

export class LocationUpdated extends LocationState {}

export class LocationUpdateFailure extends LocationState {}

type Params = {
  location: CreateLocationParams;
};

export const useUpdateLocationHook = () => {
  const [updateLocationState, setUpdateState] = useState<LocationState>();

  return {
    updateLocationState,
    setUpdateState,
    updateLocation: async (params: Params) => {
        setUpdateState(new UpdatingLocation());

      const result = await UpdateLocationUsecase(params.location);

      if (result instanceof CreateLocationFailure) {
        return setUpdateState(new LocationUpdateFailure());
      }
      return setUpdateState(new LocationUpdated());
    },
  };
};