import { useState } from "react";
import { WidgetCategoryEntity } from "../../domain/entities/WidgetCategoryEntity";
import { GetWidgetCategoriesUsecase } from "../../domain/usecases/GetWidgetCategoriesUsecase";

abstract class WidgetCategoriesState {}

export class FetchingWidgetCategories extends WidgetCategoriesState {}

export class WidgetCategoriesFetched extends WidgetCategoriesState {
  categories: WidgetCategoryEntity[];
  constructor({ categories }: Required<WidgetCategoriesFetched>) {
    super();
    this.categories = categories;
  }
}

export class WidgetCategoriesFetchingFailure extends WidgetCategoriesState {}

export function useGetWidgetCategoriesHook() {
  const usecase = GetWidgetCategoriesUsecase();
  const [state, setState] = useState<WidgetCategoriesState>();

  return {
    categoriesState: state,
    getWidgetCategories: async () => {
      setState(new FetchingWidgetCategories());
      setState(
        new WidgetCategoriesFetched({
          categories: usecase,
        })
      );
    },
  };
}
