import { MemberUserCell } from "./MemberUserCell";
import { MemberProjectCell } from "./MemberProjectCell";
import { MemberUserRuleDropdown } from "../MemberUserRuleDropdown";
import { useMembersUserModal } from "../../MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../../MembersModals/MembersModals";
import { NxSVG } from "web/common/components/NxSvg";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { IconRemove } from "web/common/fonts/icons/components/IconRemove";
import { useTranslation } from "react-i18next";

export const MemberTableRow = () => {
  const { t } = useTranslation();
  const { showModal } = useMembersUserModal();

  return (
    <tr className="border-b-[0.031rem]  border-nx-gray-100 dark:border-nx-gray-500 relative">
      <td>
        <MemberUserCell />
      </td>
      <td>
        <div className="flex flex-row gap-2 py-4 items-center">
          <MemberProjectCell />
          <div
            className="text-caption font-medium cursor-pointer text-nx-main-600 dark:text-nx-main-500"
            onClick={() => {
              showModal(new MembersModals().projectOptions());
            }}
          >
            {t("seeAll")}...
          </div>
        </div>
      </td>
      <td>
        <div>
          <MemberUserRuleDropdown />
        </div>
      </td>
      <td>
        <NxDropElement
          options={[
            {
              icon: <IconRemove />,
              label: t("removeMember"),
              className: "w-[150px] ",
              onClick: () => {
                showModal(new MembersModals().removeUser());
              },
            },
          ]}
          dimension={{
            x: 0,
            y: 0,
          }}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
            <IconMore />
          </NxSVG>
        </NxDropElement>
      </td>
    </tr>
  );
};
