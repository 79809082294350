import { ReactNode } from "react";
import { NxSVG } from "web/common/components/NxSvg";
import { CSName } from "web/common/utils/ClassName";

interface LocationsCreateModeButtonProps {
  className?: string;
  icon: ReactNode;
  label: string;
  isSelect?: boolean;
  onClick: () => void;
}
export const LocationsCreateModeButton = ({
  className,
  icon,
  label,
  onClick,
  isSelect,
}: LocationsCreateModeButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={CSName(
        `h-full grow flex flex-row justify-center items-center gap-2  border-[0.5px] transition hover:bg-nx-main-600/10 cursor-pointer`
      )
        .combine(className)
        .concatIf(
          !!isSelect,
          "bg-nx-main-600/10 border-nx-main-600",
          "border-nx-gray-200"
        )
        .build()}
    >
      <span className="text-sm text-nx-main-600">{label}</span>
      <NxSVG className="w-5 h-5 stroke-nx-main-600 fill-nx-main-600">
        {icon}
      </NxSVG>
    </button>
  );
};
