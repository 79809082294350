import { useState } from "react";
import { UploadEntity } from "web/core/domain/entities/UploadEntity";
import { uploadImageUsecase } from "web/core/domain/usecase/UploadImageUsecase";
import { CreatePanelParams } from "../../domain/params/CreatePanelParams ";
import { updatePanelUsecase } from "../../domain/usecases/UpdatePanelUsecase";
import { updatePanelFailure } from "../../domain/errors/UpdatePanelFailure";
import { UploadFailed } from "web/core/domain/errors/UploadFailed";

abstract class PanelState {}

export class UpdatingPanel extends PanelState {}

export class PanelUpdated extends PanelState {}

export class PanelUploadFailure extends PanelState {}

export class PanelUpdationFailure extends PanelState {}

type Params = {
  upload: Partial<UploadEntity>;
  panel: Partial<CreatePanelParams>;
};

export const useUpdatePanelHook = () => {
  const [panelUpdationState, setState] = useState<PanelState>();

  return {
    panelUpdationState,
    updatePanel: async (params: Params) => {
      setState(new UpdatingPanel());
      let image = "";

      if (params.upload !== undefined) {
        const upload = await uploadImageUsecase(params.upload);

        if (upload instanceof UploadFailed) {
          setState(new PanelUploadFailure());
        } else {
          image = upload as string;
        }
      } else {
        image = params.panel.s3Key ?? "";
      }

      const result = await updatePanelUsecase({
        id: params.panel.id!,
        name: params.panel.name!,
        project: params.panel.project!,
        s3Key: image,
      });
      if (result instanceof updatePanelFailure) {
        return setState(new PanelUpdationFailure());
      }
      return setState(new PanelUpdated());
    },
  };
};
