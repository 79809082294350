import { t } from "i18next";
import { MapComponent } from "web/common/components/Maps/MapComponent";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { MapDrawModes } from "web/common/components/Maps/types/MapDrawModes";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";

interface PivotLocationMiniAreaProps {
  draw: DrawLayer | EmptyLayer;
}

export const PivotLocationMiniArea = ({ draw }: PivotLocationMiniAreaProps) => {

  const getDrawMode = () =>{
    if((draw as DrawLayer).drawMode === MapDrawModes.circle)
      return t('circleMarkerMap');
    if((draw as DrawLayer).drawMode === MapDrawModes.polygon)
      return t('polygonMarkerMap');
    else
      return t('pointMarkerMap');
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mt-2">
      <div className="rounded-full bg-nx-main-400/20 mr-2 pb-1.5 px-3">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-main-200")
              .build()}
          >
            {getDrawMode()}
          </label>
        </div>
        <div className="rounded-full bg-nx-main-400/20 mr-2 pb-1.5 px-3">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-main-200")
              .build()}
          >
            {(draw as DrawLayer).radius} Km
          </label>
        </div>
      </div>
      <MapComponent
        initialLayers={draw instanceof EmptyLayer ? undefined : [draw]}
        className={"h-24 w-full my-2 rounded-lg overflow-hidden"}
        isReadOnly={true}
        zoomControl={false}
      />
    </div>
  );
};
