import { t } from "i18next";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxSVG } from "web/common/components/NxSvg";
import { IconFolder } from "web/common/fonts/icons/components/IconFolder";
import { IconRoundedAddFilled } from "web/common/fonts/icons/components/IconRoundedAddFilled";
import { useProjectModal } from "../context/ProjectModalProvider";
import { ShowCreateOrEditProjectModal } from "../context/abstraction/ProjectsModal";

export const NoProjectsFound = () => {
  const { setModal } = useProjectModal();

  return (
    <div
      className="pl-6 pr-8 pt-48 w-full h-full flex flex-col items-center justify-center
      text-nx-white
      "
    >
      <div className="w-[260px] flex flex-col justify-center items-center gap-2">
        <IconFolder />
        <p className="text-xs text-nx-gray-200">{t('noProjectCreated')}</p>
        <NxFilledButton
          className="mt-2 h-10 flex justify-center items-center gap-2"
          backgroundColor="bg-nx-main-600"
          onClick={() => {
            setModal(new ShowCreateOrEditProjectModal({}));
          }}
        >
          {t("newProject")}
          <NxSVG className="fill-nx-white w-4 h-4">
            <IconRoundedAddFilled />
          </NxSVG>
        </NxFilledButton>
      </div>
    </div>
  );
};
