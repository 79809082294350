import React from "react";

import HomeNavBar from "../components/HomeNavBar";
import { Outlet } from "react-router-dom";

export function HomeOutlet() {
  return (
    <div className="flex flex-row w-screen h-screen overflow-hidden">
      <HomeNavBar />
      <div className="w-full bg-nx-white dark:bg-nx-dark-1100">
        <Outlet />
      </div>
    </div>
  );
}
