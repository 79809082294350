import { t } from "i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import { CSName } from "web/common/utils/ClassName";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { WidgetCategoryEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import {
  GetAllAgentsLoading,
  GetAllAgentsSuccess,
  useGetAllAgentsHook,
} from "web/features/agents/presentation/hooks/UseGetAllAgentsHook";
import { useEffect, useState } from "react";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CheckBoxField } from "web/common/components/TextFields/CheckField";
import { NxChipField } from "web/common/components/TextFields/NxChipField";
import { CpfFormatter } from "web/common/forms/formatters/CpfFormatter";

type Params = {
  category: WidgetCategoryEntity;
  onCreated: () => void;
  onPrevious: () => void;
  onClose: () => void;
  itemId: number;
};

interface SelectedItem {
  id: string;
  value: string;
}

export function SlaveForm({
  category,
  onPrevious,
  onCreated,
  onClose,
  itemId,
}: Params) {
  const [selectedItems, setItems] = useState<SelectedItem[]>([]);
  const { getAllAgents, getAllAgentsState } = useGetAllAgentsHook();

  useEffect(() => {
    getAllAgents();
  }, []);

  const getEntitiesList = () => {
    const { unarchived } = getAllAgentsState as GetAllAgentsSuccess;

    if (unarchived !== undefined && unarchived.length > 0)
      return unarchived.map((u) => (
        <div
          className="grid grid-flow-col place-content-between mx-4 mb-2 mt-2"
          key={u.id}
        >
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("text-nx-gray-700 dark:text-nx-white text-start")
                .build()}
            >
              {u.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-nx-gray-400 dark:text-nx-gray-200 text-start")
                .build()}
            >
              {u.category}
            </label>
          </div>
          <CheckBoxField
            className={"bg-nx-focus-light dark:bg-nx-main-400/20"}
            onClick={(c) => {
              if (c) {
                selectedItems.push({
                  id: u.id,
                  value: u.name,
                });
                setItems([...selectedItems]);
              } else {
                setItems([...selectedItems.filter((s) => s.id !== u.id)]);
              }
            }}
          />
        </div>
      ));
    else
      return (
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 my-4")
            .build()}
        >
          {t("noEntities")}
        </label>
      );
  };

  return (
    <div>
      <div className="flex flex-row justify-between mb-4">
        <div className="flex flex-row ">
          <NxSVG
            className="w-5 h-5 stroke-nx-main-600 dark:stroke-nx-main-500 mr-4 mt-1 cursor-pointer"
            onClick={onPrevious}
          >
            <IconArrowLeft />
          </NxSVG>
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-start dark:text-nx-main-200 text-nx-dark-600")
                .build()}
            >
              {category.items.filter((i) => i.id === itemId)[0].name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {category.name}
            </label>
          </div>
        </div>
        <NxSVG
          onClick={onClose}
          className="w-5 h-5 cursor-pointer stroke-nx-main-600"
        >
          <IconClose />
        </NxSVG>
      </div>
      <NxChipField
        chips={selectedItems}
        labelText={"CPF / CNPJ"}
        placeholder={t("onlyNumbers")}
        formatter={CpfFormatter}
        onEnter={(s) => {
          if (selectedItems.filter((i) => i.id === s).length === 0) {
            selectedItems.push({
              id: s,
              value: s,
            });
            setItems([...selectedItems]);
          }
        }}
        onRemoved={(i) => {
          setItems([...selectedItems.filter((s) => s.id !== i.id)]);
        }}
      />
      <label
        className={CSName(FontStyles.caption)
          .combine("dark:text-nx-gray-200 text-nx-gray-400")
          .build()}
      >
        {t("documentFieldTip")}
      </label>
      <div className="mt-6">
        <label
          className={CSName(FontStyles.category)
            .combine("dark:text-nx-gray-200 text-nx-gray-400")
            .build()}
        >
          {t("registeredEntities").toUpperCase()}
        </label>
        <div className="bg-nx-gray-50 dark:bg-nx-dark-1200 w-full h-fit pt-1 pb-1 flex flex-col rounded mt-2 mb-6">
          {getAllAgentsState instanceof GetAllAgentsLoading && (
            <div className="flex flex-col m-auto my-4">
              <CircularProgressBar
                className="stroke-nx-main-600"
                width={20}
                height={20}
              />
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("text-nx-gray-200 text-nx-gray-400")
                  .build()}
              >
                {t("loadingData")}
              </label>
            </div>
          )}
          {getAllAgentsState instanceof GetAllAgentsSuccess &&
            getEntitiesList()}
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("appointmentCost")}
            </label>
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("dark:text-nx-main-200 text-nx-dark-600 ml-2 mr-1")
                .build()}
            >
              2000
            </label>
            <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
              <IconCoin />
            </NxSVG>
          </div>
          <div className="flex flex-row">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("availableCredits")}
            </label>
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("dark:text-nx-main-200 text-nx-dark-600 ml-2 mr-1")
                .build()}
            >
              2000
            </label>
            <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
              <IconCoin />
            </NxSVG>
          </div>
        </div>
        <NxFilledButton
          className="mt-2 py-3"
          onClick={() => {
            onClose();
            onCreated();
          }}
        >
          <label
            className={CSName(FontStyles.bodyBold)
              .combine("cursor-pointer")
              .build()}
          >
            {t("appointment")}
          </label>
        </NxFilledButton>
      </div>
    </div>
  );
}