import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { EditMemberOrganizationParams } from "../../domain/params/EditMemberOrganizationParams";
import { EditMemberOrganizationUsecase } from "../../domain/usecase/EditMemberOrganizationUsecase";

abstract class EditMemberOrganizationState {}

class EditMemberOrganizationLoading extends EditMemberOrganizationState {}

class EditMemberOrganizationSuccess extends EditMemberOrganizationState {}

class EditMemberOrganizationFailure extends EditMemberOrganizationState {}

export const useEditMemberOrganizationHook = () => {
  const [ memberState, setState] = useState<EditMemberOrganizationState>();
  return {
    memberState: {
      isLoading: memberState instanceof EditMemberOrganizationLoading,
      isSuccess: memberState instanceof EditMemberOrganizationSuccess,
      isError: memberState instanceof EditMemberOrganizationFailure,
    },
    editMember: async (params: EditMemberOrganizationParams) => {
      setState(new EditMemberOrganizationLoading());
      const result = await EditMemberOrganizationUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new EditMemberOrganizationFailure());
      }
      setState(new EditMemberOrganizationSuccess());
    },
  };
};
