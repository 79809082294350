import { useState } from "react";
import { UploadEntity } from "web/core/domain/entities/UploadEntity";
import { uploadImageUsecase } from "web/core/domain/usecase/UploadImageUsecase";
import { createOrganizationUsecase } from "../../domain/usecase/CreateOrganizationUsecase";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { createOrUpdateOrganizationFailure } from "../../domain/errors/CreateOrUpdateOrganizationFailure";
import { useHomeUsecase } from "web/features/home/presentation/hooks/UseHomeUsecase";
import { updateOrganizationUsecase } from "../../domain/usecase/UpdateOrganizationUsecase";
import { UploadFailed } from "web/core/domain/errors/UploadFailed";

abstract class OrgCreationState {}

export class CreationInProgress extends OrgCreationState {}

export class CreationSucceed extends OrgCreationState {}

export class CreationOrgUploadFailure extends OrgCreationState {}

export class CreationFailure extends OrgCreationState {}

type Params = {
  upload: Partial<UploadEntity> | undefined;
  org: Partial<CreateOrgParams>;
};

export const useCreateOrUpdateOrganizationHook = () => {
  const [orgCreationState, setState] = useState<OrgCreationState>();
  const usecase = useHomeUsecase();

  return {
    orgCreationState,
    createOrg: async (params: Params) => {
      setState(new CreationInProgress());
      let uploadImageKey: string | undefined;
      if (params.upload !== undefined) {
        const uploadResult = await uploadImageUsecase(params.upload);
        if (uploadResult instanceof UploadFailed) {
          return setState(new CreationOrgUploadFailure());
        }
        uploadImageKey = uploadResult as string;
      }

      const user = await usecase.getUser();
      const result = await createOrganizationUsecase({
        name: params.org.name!,
        owner: user.sub,
        category: params.org.category!,
        s3Key: uploadImageKey,
      });
      if (result instanceof createOrUpdateOrganizationFailure) {
        return setState(new CreationFailure());
      }
      return setState(new CreationSucceed());
    },
    updateOrg: async (params: Params) => {
      setState(new CreationInProgress());
      let image = "";

      if (params.upload !== undefined) {
        const upload = await uploadImageUsecase(params.upload);

        if (upload instanceof UploadFailed) {
          setState(new CreationOrgUploadFailure());
        } else {
          image = upload as string;
        }
      } else {
        image = params.org.s3Key ?? "";
      }

      const user = await usecase.getUser();

      const result = await updateOrganizationUsecase({
        id: params.org.id,
        name: params.org.name!,
        owner: user.sub,
        category: params.org.category!,
        s3Key: image,
      });
      if (result instanceof createOrUpdateOrganizationFailure) {
        return setState(new CreationFailure());
      }
      return setState(new CreationSucceed());
    },
  };
};
