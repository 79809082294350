import { MembersTableHeaderRow } from "./MemberUser/MembersTableHeaderRow";
import React from "react";
import { MemberTableRow } from "./MemberUser/MemberTableRow";
import { NxBodyLargeText } from "../../../../../common/components/Texts/NxBodyLargeText";
import { MemberInvitedRow } from "./MemberUserInvited/MemberInvitedRow";
import { t } from "i18next";

export const MembersTable = () => {
  return (
    <div className="flex flex-col">
      <table className="table w-full border-collapse">
        <colgroup>
          <col className="w-[35%]" />
          <col className="w-[50%]" />
          <col className="w-[10%]" />
          <col className="w-[5%]" />
        </colgroup>
        <thead className="mb-4 border-spacing-y-4">
          <MembersTableHeaderRow />
        </thead>
        <tbody>
          <MemberTableRow />
          <MemberTableRow />
          <MemberTableRow />
          <MemberTableRow />
          <MemberTableRow />
          <MemberTableRow />
        </tbody>
      </table>
      <table className="table-fixed border-collapse">
        <colgroup>
          <col className="w-[35%]" />
          <col className="w-[50%]" />
          <col className="w-[10%]" />
          <col className="w-[5%]" />
        </colgroup>
        <thead>
          <tr>
            <td>
              <NxBodyLargeText className="mt-8 mb-4 ml-6">
                {t('pendingInvitations')}
              </NxBodyLargeText>
            </td>
          </tr>
        </thead>

        <tbody>
          <MemberInvitedRow />
        </tbody>
      </table>
    </div>
  );
};
