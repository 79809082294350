import { IconHelpFilled } from "../../../../common/fonts/icons/components/IconHelpFilled";
import { useLocation, useNavigate } from "react-router-dom";
import { IconGroup } from "web/common/fonts/icons/components/IconGroups";
import { withTranslation, WithTranslation } from "react-i18next";
import { HomeNavBarOption } from "web/features/home/presentation/components/HomeNavBarOption";
import { ChangeOrgModalProvider } from "web/features/home/presentation/context/ChangeOrgProvider";
import { HomeNavFooter } from "web/features/home/presentation/components/HomeNavFooter";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { NxSVG } from "web/common/components/NxSvg";
import { IconSetting } from "web/common/fonts/icons/components/IconSetting";
import { t } from "i18next";

const ManagementNavBar: React.FC<WithTranslation> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSelected = (route: string) => {
    return location.pathname.includes(route);
  };

  return (
    <div className="flex flex-col  items-stretch  min-w-64  h-screen bg-nx-gray-50 dark:bg-nx-dark-1200">
      <div className="h-full overflow-y-auto">
        <div
          className="mt-8 ml-6 mb-6 flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate("-1");
          }}
        >
          <NxSVG className="w-5 h-5 stroke-nx-main-600 dark:stroke-nx-main-500">
            <IconArrowLeft />
          </NxSVG>
          <div className=" text-base font-semibold text-nx-dark-800 dark:text-nx-main-200">
            Conta
          </div>
        </div>

        <HomeNavBarOption
          title={t('profile')}
          route="/my-profile"
          icon={<IconSetting />}
          isSelected={isSelected("/my-profile")}
        />

        <HomeNavBarOption
          title={t("organizations")}
          route="/organizations"
          icon={<IconGroup />}
          isSelected={isSelected("/organizations")}
        />

        <HomeNavBarOption
          title={t("help")}
          icon={<IconHelpFilled />}
          stroke={false}
          route="/help"
          isSelected={isSelected("/help")}
        />
      </div>

      <ChangeOrgModalProvider>
        <HomeNavFooter />
      </ChangeOrgModalProvider>
    </div>
  );
};
export default withTranslation()(ManagementNavBar);