import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { CSName } from "web/common/utils/ClassName";
import { useEffect } from "react";
import { useSortBy } from "web/core/presentation/hooks/UseSortBy";
import { IconSort } from "web/common/fonts/icons/components/IconSort";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import {
  GetAllAgentsLoading,
  GetAllAgentsSuccess,
  useGetAllAgentsHook,
} from "../../hooks/UseGetAllAgentsHook";
import { ExtendableComponent } from "../Extendable/ExtendableComponent";
import { useAgentsModal } from "../../context/AgentsModalProvider";
import {
  HideAgentModal,
  ShowAgentArchiveModal,
} from "../../context/abstraction/AgentsModal";
import { ArchiveAgentModal } from "../ArchiveAgentModal/ArchiveAgentModal";

export function AgentTable() {
  const { t } = useTranslation();
  const { modal, setModal } = useAgentsModal();

  const { sortState, sortedBy } = useSortBy();
  const { getAllAgents, getAllAgentsState } = useGetAllAgentsHook();

  useEffect(() => {
    getAllAgents();
  }, []);

  useEffect(() => {
    if (modal instanceof HideAgentModal && modal.needReloadAgents) {
      setModal(new HideAgentModal(false));
      getAllAgents();
    }
  }, [modal, getAllAgents, setModal]);

  if (getAllAgentsState instanceof GetAllAgentsLoading) {
    return (
      <div className="flex flex-col mt-[15%] m-auto">
        <CircularProgressBar
          className="stroke-nx-main-600"
          width={20}
          height={20}
        />
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("text-nx-gray-200")
            .build()}
        >
          {t("loadingData")}
        </label>
      </div>
    );
  }
  if (getAllAgentsState instanceof GetAllAgentsSuccess) {
    const { archived, unarchived } = getAllAgentsState;
    return (
      <div className="overflow-y-auto h-full">
        <div
          className={
            "pl-6 flex flex-row text-[10px] tracking-[1.5px]  font-semibold text-nx-gray-200 uppercase "
          }
        >
          <div
            className="w-[24%] flex flex-row"
            onClick={() => {
              // setContent(
              //   tableEntity({
              //     entity: tableContent,
              //     instance: new SortByName({
              //       orderByAsc: !sortedBy.name || !sortState?.orderByAsc,
              //     }),
              //   })
              // );
            }}
          >
            <label className="cursor-pointer">{t("firstName")}</label>
            {sortedBy.name && (
              <NxSVG
                className={CSName(
                  "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                )
                  .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                  .build()}
              >
                <IconSort />
              </NxSVG>
            )}
          </div>
          <div
            className="w-[34.5%] flex flex-row"
            onClick={() => {
              // setContent(
              //   tableEntity({
              //     entity: tableContent,
              //     instance: new SortByWidgets({
              //       orderByAsc: !sortedBy.widgets || !sortState?.orderByAsc,
              //     }),
              //   })
              // );
            }}
          >
            <label className="cursor-pointer">{t("associatedWidgets")}</label>
            {sortedBy.widgets && (
              <NxSVG
                className={CSName(
                  "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                )
                  .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                  .build()}
              >
                <IconSort />
              </NxSVG>
            )}
          </div>
          <div
            className="w-[19%] flex flex-row"
            onClick={() => {
              // setContent(
              //   tableEntity({
              //     entity: tableContent,
              //     instance: new SortByCategory({
              //       orderByAsc: !sortedBy.category || !sortState?.orderByAsc,
              //     }),
              //   })
              // );
            }}
          >
            <label className="cursor-pointer">{t("category")}</label>
            {sortedBy.category && (
              <NxSVG
                className={CSName(
                  "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                )
                  .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                  .build()}
              >
                <IconSort />
              </NxSVG>
            )}
          </div>
          <div
            className="w-[18%] flex flex-row"
            onClick={() => {
              // setContent(
              //   tableEntity({
              //     entity: tableContent,
              //     instance: new SortByDate({
              //       orderByAsc: !sortedBy.date || !sortState?.orderByAsc,
              //     }),
              //   })
              // );
            }}
          >
            <label className="cursor-pointer">{t("creationDate")}</label>
            {sortedBy.date && (
              <NxSVG
                className={CSName(
                  "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                )
                  .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                  .build()}
              >
                <IconSort />
              </NxSVG>
            )}
          </div>
          <div className="mr-3 flex flex-row"></div>
        </div>

        <div className="flex flex-col">
          {unarchived.map((group, index) => {
            return (
              <ExtendableComponent
                key={group.id}
                group={group}
                showBorder={index + 1 < unarchived.length}
              />
            );
          })}
        </div>

        {archived.length > 0 && (
          <div>
            <div className="flex flex-row mt-8 ml-6">
              <NxSVG className="fill-nx-gray-400 w-5 h-5">
                <IconArchive />
              </NxSVG>
              <label
                className={CSName(FontStyles.bodyLargeBold)
                  .combine("text-nx-gray-600 dark:text-nx-white ml-2")
                  .build()}
              >
                {t("archived")}
              </label>
            </div>

            <div className="flex flex-col">
              {archived.map((group, index) => {
                return (
                  <ExtendableComponent
                    key={group.id}
                    group={group}
                    showBorder={index + 1 < archived.length}
                  />
                );
              })}
            </div>
          </div>
        )}
        {modal instanceof ShowAgentArchiveModal && (
          <ArchiveAgentModal isOpen={true} agentId={modal.agentId} />
        )}
      </div>
    );
  }
  return <></>;
}
