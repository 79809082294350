import { useState } from "react";
import { getAllAgentsUsecase } from "../../domain/usecase/GetAllAgentsUsecase";
import { GetAllAgentsError } from "../../domain/errors/GetAllAgentsError";
import { AgentEntity } from "../../domain/entities/AgentEntity";

abstract class GetAllAgentsState {}

export class GetAllAgentsLoading extends GetAllAgentsState {}

export class GetAllAgentsSuccess extends GetAllAgentsState {
  archived: AgentEntity[];
  unarchived: AgentEntity[];

  constructor({ archived, unarchived }: Required<GetAllAgentsSuccess>) {
    super();
    this.archived = archived;
    this.unarchived = unarchived;
  }
}

export class GetAllAgentsFailure extends GetAllAgentsState {}

export const useGetAllAgentsHook = () => {
  const [getAllAgentsState, setState] = useState<GetAllAgentsState>();

  return {
    getAllAgentsState,
    getAllAgents: async () => {
      setState(new GetAllAgentsLoading());

      const [unarchivedResult, archivedResult] = await Promise.all([
        getAllAgentsUsecase({ deleted: false }),
        getAllAgentsUsecase({ deleted: true }),
      ]);

      if (
        unarchivedResult instanceof GetAllAgentsError ||
        archivedResult instanceof GetAllAgentsError
      ) {
        return setState(new GetAllAgentsFailure());
      }

      return setState(
        new GetAllAgentsSuccess({
          archived: archivedResult.results,
          unarchived: unarchivedResult.results,
        })
      );
    },
  };
};
