import { GeometryEntity } from "web/features/locations/domain/entities/GeometryEntity";
import { CreateLocationParams } from "web/features/locations/domain/params/CreateLocationParams";

export interface LocationAdpater {
  buildbody(location: CreateLocationParams): CreateLocationParams;
}

export class LocationAdpaterImpl implements LocationAdpater {
  buildbody(location: CreateLocationParams) {
    const options = [
      {
        type: "circle",
        value: "Point",
      },
      {
        type: "circlemarker",
        value: "Point",
      },
      {
        type: "polygon",
        value: "Polygon",
      },
    ];
    let coordinates: any;
    const type = options.filter((o) => o.type === location.geometry!.type)[0];

    if(location.geometry!.coordinates.length >1){
      coordinates = [location.geometry!.coordinates];
    }else{
      coordinates = location.geometry!.coordinates[0];
    }
    
    const geometry: GeometryEntity = {
        type: type.value,
        coordinates: coordinates,
    };
    return {
      type: location.type,
      geometry: geometry,
      properties: location.properties!,
    };
  }
}
