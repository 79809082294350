import { FontStyles, LabelStyles } from "../../fonts/FontStyles";
import { Color } from "../../fonts/Colors";
import React, { ReactNode, useState } from "react";
import { GlobalError, UseFormRegisterReturn } from "react-hook-form/dist/types";
import { useVisualContext } from "../../../core/context/VisualContext";
import { CSName } from "web/common/utils/ClassName";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { NxSVG } from "../NxSvg";

export interface INxChipFieldProps {
  chips: any[];
  labelText?: string;
  register?: UseFormRegisterReturn;
  type?: string;
  error?: GlobalError;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (s: string) => void;
  onRemoved?: (i:any) => void;
  formatter?: (value: any) => any;
  value?: string;
  disabled?: boolean;
  className?: string;
  icon?: ReactNode;
}

export const NxChipField = ({
  chips,
  labelText,
  register,
  type = "text",
  placeholder,
  error,
  onChange,
  onEnter,
  onRemoved,
  formatter,
  value,
  disabled = false,
  className,
  icon,
}: INxChipFieldProps) => {
  const { isDarkMode } = useVisualContext();
  const [isFocused, setFocus] = useState(false);
  const [currentValue, setValue] = useState("");

  const hasError = (): boolean => {
    return error !== undefined;
  };

  const getLabelColor = () => {
    if (hasError()) {
      return Color.danger600;
    }
    return isDarkMode ? Color.white : Color.gray600;
  };

  const getBorderColor = () => {
    if (hasError()) {
      return Color.danger600;
    }

    return isFocused ? Color.main600 : Color.gray400;
  };

  const getBackgroundColor = () => {
    if (isFocused) {
      return isDarkMode ? Color.darkFocus : Color.lightFocus;
    }
    return "rgba(202, 230, 255, 0.1)";
  };

  return (
    <div className={CSName(`flex flex-col`).combine(className).build()}>
      <label
        className="transition"
        style={{
          ...LabelStyles.caption,
          textAlign: "left",
          marginBottom: 4,
          color: isFocused ? Color.main600 : getLabelColor(),
        }}
      >
        {labelText}
      </label>
      <div
        className="flex flex-row items-center gap-2 py-3 px-4 h-10 rounded transition"
        style={{
          backgroundColor: getBackgroundColor(),
          borderWidth: hasError() ? 1 : 0.5,
          borderColor: getBorderColor(),
          borderStyle: "solid",
        }}
      >
        {chips.map((s) => (
          <div className="rounded-full px-3 bg-nx-main-400/20 my-2 flex flex-row gap-1 w-fit">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-main-200 my-1.5 ml-1")
                .build()}
            >
              {s.value}
            </label>
            <div className="rounded-[54px] border m-auto p-0.5">
              <NxSVG
                onClick={() => {
                    if(onRemoved) onRemoved(s);
                }}
                className="w-2.5 h-2.5 cursor-pointer stroke-nx-main-200"
              >
                <IconClose />
              </NxSVG>
            </div>
          </div>
        ))}

        <input
          className="w-full outline-none bg-transparent text-xs dark:text-nx-white placeholder-nx-gray-200 dark:placeholder-nx-gray-500"
          {...register}
          onChange={(e) => {
            if (formatter !== undefined)
              e.currentTarget.value = formatter!(e.currentTarget.value);
            if (onChange) {
              onChange(e);
            }
            register?.onChange(e);
            setValue(e.currentTarget.value);
          }}
          onKeyUp={(k) => {
            if (k.key === "Enter" && onEnter) onEnter(currentValue);
          }}
          value={value}
          type={type}
          autoComplete="off "
          placeholder={placeholder}
          onFocus={(e) => {
            setFocus(true);
          }}
          onBlur={(e) => {
            setFocus(false);
          }}
          disabled={disabled}
        />
        {icon}
      </div>
      {error && (
        <span
          style={{
            ...LabelStyles.caption,
            marginTop: 4,
            color: Color.danger600,
            textAlign: "left",
          }}
        >
          {error.message}
        </span>
      )}
    </div>
  );
};
