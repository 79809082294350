import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";

export class PanelEntity {
  id?: string;
  name?: string;
  s3Key?: string;
  project?: string;
  imageUrl: string;
  widgets?: WidgetEntity[];
  description?: string;
  archivedAt?: Date;
  restoredAt?: Date;
  createdAt?: Date;
  constructor({
    id,
    name,
    s3Key,
    project,
    imageUrl,
    widgets,
    createdAt,
    archivedAt,
    restoredAt,
    description,
  }: Required<PanelEntity>) {
    this.id = id;
    this.name = name;
    this.s3Key = s3Key;
    this.project = project;
    this.imageUrl = imageUrl;
    this.widgets = widgets;
    this.createdAt = createdAt;
    this.archivedAt = archivedAt;
    this.restoredAt = restoredAt;
    this.description = description;
  }

  static fromJSON(data: any): PanelEntity {
    return new PanelEntity({
      id: data.id,
      name: data.name,
      s3Key: data.image_s3_key,
      project: data.project,
      imageUrl: data.image_url,
      widgets: data.widgets,
      createdAt: new Date(data.created_at),
      archivedAt: new Date(data.deleted_at),
      restoredAt: new Date(data.restored_at),
      description: data.description,
    });
  }
}
