import { NxSVG } from "web/common/components/NxSvg";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { MyProfileTile } from "./MyProfileTile";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { useMyProfileEditNameModal } from "./MyProfileEditNameModal/MyProfileEditNameModalContext";
import { useMyProfileEditPasswordModal } from "./MyProfileEditPasswordModal/MyProfileEditPasswordModalContext";
import { MyProfileContenteCard } from "./MyProfileContentCard";
import { useTranslation } from "react-i18next";
import { MyProfileUserAvatar } from "./MyProfileUserAvatar/MyProfileUserAvatar";
import { UserEntity } from "web/features/auth/domain/entities/UserEntity";

export type ProfileProps = {
  account: UserEntity;
};

export const MyProfileHeader = ({ account }: ProfileProps) => {
  const { showModalEditName } = useMyProfileEditNameModal();
  const { showModalEditPassword } = useMyProfileEditPasswordModal();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full">
      <MyProfileContenteCard title={t("personalData")}>
        <div className="flex flex-row gap-2">
          <MyProfileUserAvatar
            firstName={account.first_name}
            lastName={account.last_name}
            imageUrl={account.image_url}
          />
          <div className="grow flex flex-col gap-2">
            <MyProfileTile
              title={`${account.first_name} ${account.last_name}`}
              onClick={() =>
                showModalEditName(account.first_name, account.last_name)
              }
              subtitle={t("fullName")}
              trailing={
                <NxSVG className="w-5 h-5 mb-0.5 fill-nx-main-500">
                  <IconEdit />
                </NxSVG>
              }
            />
            <MyProfileTile
              title={account.email}
              subtitle={t("email")}
              trailing={
                <NxSVG className="w-5 h-5 mb-0.5 fill-nx-gray-300">
                  <IconEdit />
                </NxSVG>
              }
            />
            <MyProfileTile
              title={t("editPassword")}
              onClick={() => showModalEditPassword(account.email)}
              trailing={
                <NxSVG className="w-5 h-5  stroke-nx-main-500">
                  <IconArrowRight />
                </NxSVG>
              }
            />
          </div>
        </div>
      </MyProfileContenteCard>
    </div>
  );
};
