import { useState } from "react";
import { ArchiveLocationUsecase } from "../../domain/usecase/ArchiveLocationUsecase";
import { ArchiveLocationFailure } from "../../domain/errors/ArchiveLocationFailure";

abstract class ArchiveLocationState {}

export class ArchivingLocation extends ArchiveLocationState {}

export class LocationArchived extends ArchiveLocationState {}

export class LocationArchivingFailure extends ArchiveLocationState {}

export const useArchiveLocationHook = () => {
  const [archiveLocationState, setState] = useState<ArchiveLocationState>();

  return {
    archiveLocationState,
    archiveLocation: async (id: string) => {
      setState(new ArchivingLocation());
      
      const result = await ArchiveLocationUsecase(id);

      if (result instanceof ArchiveLocationFailure) {
        return setState(new LocationArchivingFailure());
      }
      return setState(new LocationArchived());
    }
  };
};