import { CategoryEntity } from "web/features/auth/domain/entities/CategoryEntity";
import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { OrganizationEntity } from "../entities/OrganizationEntity";
import { getOrganizationsFailure } from "../errors/GetOrganizationFailure";
import { leaveOrganizationFailure } from "../errors/LeaveOrganizationFailure";
import { LeaveOrganizationParams } from "../params/LeaveOrganizationParams";
import { createOrUpdateOrganizationFailure } from "../errors/CreateOrUpdateOrganizationFailure";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { SelectCurrentOrgParams } from "../params/SelectCurrentOrgParams";
import { ApiKeyEntity } from "../entities/ApiKeyEntity";
import { getApiKeysFailure } from "../errors/GetApiKeysFailure";
import { CreateApiKeyParams } from "../params/CreateApiKeyParams";
import { CreateApiKeysFailure } from "../errors/CreateApiKeysFailure";
import { deleteApiKeysFailure } from "../errors/DeleteApiKeysFailure";
import { DeleteApiKeyParams } from "../params/DeleteApiKeyParams";

export const OrganizationRepository = {
  async getCategories(): Promise<CategoryEntity[]> {
    const response = await networkAdapter.get<PaginationEntity<CategoryEntity>>(
      {
        path: "/core/organization_categories/",
      }
    );
    if (response instanceof NetworkError) {
      return Promise.resolve([]);
    } else {
      return response.data.results;
    }
  },

  async createOrganization(
    params: CreateOrgParams
  ): Promise<OrganizationEntity | createOrUpdateOrganizationFailure> {
    const result = await networkAdapter.post({
      path: "/core/organizations/",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: {
        name: params.name,
        owner: params.owner,
        category: params.category,
        image_s3_key: params.s3Key,
      },
    });
    if (result instanceof NetworkError) {
      return new createOrUpdateOrganizationFailure();
    }
    return OrganizationEntity.fromJSON(result.data);
  },

  async updateOrganization(
    params: CreateOrgParams
  ): Promise<OrganizationEntity | createOrUpdateOrganizationFailure> {
    const result = await networkAdapter.put({
      path: `/core/organizations/${params.id}/`,
      headers: {
        "content-type": "application/json",
      },
      body: {
        name: params.name,
        owner: params.owner,
        category: params.category,
        image_s3_key: params.s3Key,
      },
    });
    if (result instanceof NetworkError) {
      return new createOrUpdateOrganizationFailure();
    }
    return OrganizationEntity.fromJSON(result.data);
  },

  async getOrganizations(): Promise<
    PaginationEntity<OrganizationEntity> | getOrganizationsFailure
  > {
    const categories = await this.getCategories();
    localStorage.setItem("categories", JSON.stringify(categories));

    const result = await networkAdapter.get({
      path: "/core/organizations/",
    });
    if (result instanceof NetworkError) {
      return new getOrganizationsFailure();
    }
    return PaginationEntity.fromJSON(result.data, (e) =>
      OrganizationEntity.fromJSON(e)
    );
  },

  async leaveOrganization({ organizationId }: LeaveOrganizationParams) {
    return networkAdapter
      .delete({
        path: `/core/organization_members/leave/`,
        headers: {
          "x-organization-id": organizationId,
        },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new leaveOrganizationFailure();
        }
        return new EmptyEntity();
      });
  },

  async createApiKey({ name, permission, org }: CreateApiKeyParams) {
    const result = networkAdapter.post({
      path: `/core/organization_api_keys/`,
      headers: {
        "content-type": "application/json",
        "x-organization-id": org,
      },
      body: {
        name,
        permission,
      },
    });
    if (result instanceof NetworkError) {
      return new CreateApiKeysFailure();
    }
    return new EmptyEntity();
  },

  async deleteApiKey({org, id}: DeleteApiKeyParams) {
    const result = networkAdapter.delete({
      path: `/core/organization_api_keys/${id}/`,
      headers: {
        "content-type": "application/json",
        "x-organization-id": org,
      },
    });

    if (result instanceof NetworkError) {
      return new deleteApiKeysFailure();
    }

    return new EmptyEntity();
  },

  async getApiKeys(
    org: string
  ): Promise<PaginationEntity<ApiKeyEntity> | getApiKeysFailure> {
    const result = await networkAdapter.get<PaginationEntity<ApiKeyEntity>>({
      path: `/core/organization_api_keys/`,
      headers: {
        "content-type": "application/json",
        "x-organization-id": org,
      },
    });
    if (result instanceof NetworkError) {
      return new getApiKeysFailure();
    }
    return PaginationEntity.fromJSON(result.data, (e) =>
      ApiKeyEntity.fromJSON(e)
    );
  },

  selectCurrentOrg(org: SelectCurrentOrgParams) {
    localStorage.setItem("currentOrganization", JSON.stringify(org));

    localStorage.setItem("currentOrganization", JSON.stringify(org));
  },

  getCurrentOrgId() {
    const currentOrgId =  localStorage.getItem("currentOrganization");
    return JSON.parse(currentOrgId as string);
  },
};
