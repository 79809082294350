export class PropertyEntity {
  entityIds: string[];
  widgetIds: string[];
  name: string;
  radius?: number;
  createdAt?: Date;

  constructor({ entityIds, widgetIds, name, radius, createdAt }: PropertyEntity) {
    this.entityIds = entityIds;
    this.widgetIds = widgetIds;
    this.name = name;
    this.radius = radius;
    this.createdAt = createdAt;
  }

  static fromJSON(data: any): PropertyEntity {
    return new PropertyEntity({
      entityIds: data.entities,
      widgetIds: data.widgets,
      name: data.name,
      radius: data.radius,
      createdAt: new Date(data.created_at),
    });
  }
}
