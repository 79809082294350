import { t } from "i18next";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { NxTile } from "web/common/components/NxTile/NxTile";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { CSName } from "web/common/utils/ClassName";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";

type Params = {
  widgets?: WidgetEntity[];
  onPrevious: () => void;
  onNext: () => void;
};

export function StepOnePage({ widgets, onPrevious, onNext }: Params) {
  return (
    <div className="flex flex-col">
      <NxModalTitle
        title={t("newWidget")}
        subtitle={t("lastMadeAppointment")}
        onClosedTapped={onPrevious}
      />
      <label
        className={CSName(FontStyles.caption)
          .combine("dark:text-nx-gray-200 text-nx-gray-400 font-semibold mt-6 text-start mb-2")
          .build()}
      >
        {t("lastFifteenDays").toUpperCase()}
      </label>
      <div className="dark:bg-nx-dark-1200 bg-nx-gray-50 text-center rounded">
        {(widgets === undefined || widgets?.length === 0) && (
          <div className="m-4">
            <label
              className={CSName(FontStyles.caption)
                .combine("dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {t("noRecentsWidgets")}
            </label>
          </div>
        )}
        {widgets !== undefined &&
          widgets!.map((w) => (
            <div className="m-4">
              <NxTile
                title={w.name ?? ""}
                subtitle="09/02/24 • 11:34"
                titleClassName={CSName(FontStyles.bodyRegular)
                  .combine("text-start dark:text-nx-white")
                  .build()}
                subtitleClassName={CSName(FontStyles.caption)
                  .combine("text-start dark:text-nx-gray-200 text-nx-gray-400")
                  .build()}
                onClick={() => {}}
                trailing={
                  <NxSVG className="w-5 h-5  stroke-nx-main-500">
                    <IconArrowRight />
                  </NxSVG>
                }
              />
            </div>
          ))}
      </div>
      <NxFilledButton className="mt-6 py-3" onClick={onNext}>
        <label
          className={CSName(FontStyles.bodyBold)
            .combine("cursor-pointer")
            .build()}
        >
          {t("newAppointment")}
        </label>
      </NxFilledButton>
    </div>
  );
}
