export const WidgetsCategoryMock = {
  status: 200,
  data: {
    count: 6,
    next: null,
    previous: null,
    results: [
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "ESG",
        description: "Sustentabilidade, Social e Governança",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "Clima",
        description: "Chuva, Umidade, Massas de ar, secas, histórico e previsão",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "Agricultura",
        description: "Produtividade, mercado, análise de solo e logística",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "Pecuária",
        description: "Mercado, logística",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "Seguro",
        description: "Desastres naturais, queimadas, pragas e doenças",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        category_widget: "Produtos",
        description: "Mercado, taxa de utilização",
      },
    ],
  },
};
