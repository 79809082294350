const convertToUint8 = (base64: string) => {
  var binaryString = atob(base64.split(",")[1]);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const ImageUtils = {
  convertToUint8,
};
