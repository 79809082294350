import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { deleteApiKeyUsecase } from "../../domain/usecase/DeleteApiKeyUsecase";
import { DeleteApiKeyParams } from "../../domain/params/DeleteApiKeyParams";

abstract class DeleteApiKeyState {}

export class DeleteApiKeySuccess extends DeleteApiKeyState {}

export class DeleteApiKeyFailure extends DeleteApiKeyState {}

export class DeleteApiKeyLoading extends DeleteApiKeyState {}

export const useDeleteApiKeyHook = () => {
    const [deleteApiKeyState, setState] = useState<DeleteApiKeyState>();
    return {
        deleteApiKeyState: {
            isLoading: deleteApiKeyState instanceof DeleteApiKeyLoading,
            isSuccess: deleteApiKeyState instanceof DeleteApiKeySuccess,
            isError: deleteApiKeyState instanceof DeleteApiKeyFailure,
          },
        deleteApiKey: async ({id, org}: DeleteApiKeyParams) => {
            setState(new DeleteApiKeyLoading())
            const result = await deleteApiKeyUsecase({id, org});
            if(result instanceof NexusFailure){
                return setState(new DeleteApiKeyFailure());
            }
            setState(new DeleteApiKeySuccess())
        }
    }
};
