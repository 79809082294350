export const IconUndo = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5H11C14.3137 5 17 7.68629 17 11V11C17 14.3137 14.3137 17 11 17H5M1 5L5 9M1 5L5 1"
        stroke="#0167C5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
