import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { OptionsEntity } from "../entities/OptionsEntity";
import { MemberEntity } from "../entities/MemberEntity";
import { getMembersOrganizationFailure } from "../errors/GetMembersOrganizationFailure";
import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { GetMembersOrganizationParams } from "../params/GetMembersOrganizationParams";
import { EditMemberOrganizationParams } from "../params/EditMemberOrganizationParams";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { editMemberOrganizationFailure } from "../errors/EditMemberOrganizationFailure";

export class MembersRepository {
  getProjectOptions() {
    const options = [
      "Safra Milho Brasil",
      "Safra Milho Brasil",
      "Planejamento de logística",
      "Planejamento de logística",
      "Produção MT",
      "Produção MT",
      "Safra Milho Brasil",
      "Safra Milho Brasil",
      "Planejamento de logística",
      "Planejamento de logística",
      "Produção MT",
      "Produção MT",
    ];

    return options.map((element) => {
      return new OptionsEntity({ isSelected: false, name: element });
    });
  }
}
export const MemberRepositoryImpl = {
  async getMembersOrganization({
    organizationId,
  }: GetMembersOrganizationParams): Promise<
    PaginationEntity<MemberEntity> | getMembersOrganizationFailure
  > {
    const result = await networkAdapter.get({
      path: "/core/organization_members",
      headers: {
        "x-organization-id": organizationId,
      },
    });
    if (result instanceof NetworkError) {
      return new getMembersOrganizationFailure();
    }
    return PaginationEntity.fromJSON(result.data, (e) =>
      MemberEntity.fromJSON(e)
    );
  },
  async editMemberOrganization({
    organizationId,
    role,
    userId,
    userEmail,
  }: EditMemberOrganizationParams): Promise<
    EmptyEntity | editMemberOrganizationFailure
  > {
    return networkAdapter
      .patch({
        path: `/core/organization_members/${userId}/`,
        headers: {
          "x-organization-id": organizationId,
        },
        body: { role: role, user_email: userEmail },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new editMemberOrganizationFailure();
        }
        return new EmptyEntity();
      });
  },
};
