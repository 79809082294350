import { useEffect, useRef, useState } from "react";
import { PanelsHeader } from "../components/PanelsHeader/PanelsHeader";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";
import { IconDashboard } from "web/common/fonts/icons/components/IconDashboard";
import { t } from "i18next";
import { GroupDivider } from "../components/GroupDivider/GroupDrivider";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { NxDashboardCard } from "web/common/components/NxDashboardCard";
import { NxTHeader } from "web/common/components/NxTable/NxTHeader/NxTHeader";
import { PanelsTbody } from "../components/PanelsTbody/PanelsTbody";
import { usePanelModal } from "../context/PanelsModalProvider";
import {
  HidePanelModal,
  ShowArchivePanelModal,
  ShowCreateOrEditPanelModal,
} from "../context/abstraction/PanelsModal";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconShare } from "web/common/fonts/icons/components/IconShare";
import { ArchivePanelModal } from "../components/ArchivePaneltModal/ArchivePanelModal";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  FetchingPanels,
  PanelsFetched,
  useGetPanelsHook,
} from "../hook/UseGetPanelsHook";
import { format } from "date-fns";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";
import { PanelEntity } from "../../domain/entities/PanelEntity";
import {
  SortByDate,
  SortByName,
  useSortPanelsBy,
} from "../hook/UseSortPanelsBy";
import { SortEntity } from "web/core/domain/entities/SortEntity";

type Controller = {
  id?: string;
  state?: SortEntity;
  archived?: PanelEntity[];
  restored?: PanelEntity[];
  project?: ProjectEntity;
  isCopied: boolean;
};

export function PanelsMenu() {
  const navigate = useNavigate();
  const { sortState, sortedBy, setState, filterPanels } = useSortPanelsBy();
  const { fetchPanelState, getPanels } = useGetPanelsHook();
  const [controller, setController] = useState<Controller>();
  const { modal, setModal } = usePanelModal();
  const { appState } = useAppContext();
  const projectName = useRef("");
  let { id } = useParams();

  useEffect(() => {
    if (appState instanceof AppSuccess && projectName.current !== id) {
      let project = appState.projects.filter((p) => p.id === id)[0];
      setController({
        project: project,
        restored: project.panels,
        isCopied: false,
      });
      getPanels(id ?? "");
      projectName.current = id ?? "";
    }
  }, [id, appState, getPanels]);

  useEffect(() => {
    if (fetchPanelState instanceof PanelsFetched) {
      let project = controller?.project;
      setController({
        project: project,
        restored: (fetchPanelState as PanelsFetched).restoreds,
        archived: (fetchPanelState as PanelsFetched).archiveds,
        isCopied: controller?.isCopied ?? false,
      });
    }
  }, [fetchPanelState, controller?.isCopied, controller?.project]);

  useEffect(() => {
    if (modal instanceof HidePanelModal && modal.reload) {
      if (modal.reload) {
        getPanels(id ?? "");
        setModal(new HidePanelModal({}));
      }
    }
  }, [modal, getPanels, id, setModal]);

  useEffect(() => {
    if (sortState !== undefined && sortState !== controller?.state) {
      let lists = filterPanels({
        instance: sortState,
        panels: {
          archived: controller?.archived,
          restored: controller?.restored,
        },
      });

      setController({
        state: sortState,
        project: controller?.project,
        restored: lists.restored,
        archived: lists.archived,
        isCopied: controller?.isCopied ?? false,
      });
    }
  }, [
    sortState,
    filterPanels,
    controller?.archived,
    controller?.restored,
    controller?.isCopied,
    controller?.state,
    controller?.project,
  ]);

  const copyLink = (link: string) => {
    setController({
      project: controller!.project,
      archived: controller?.archived,
      restored: controller?.restored,
      isCopied: true,
    });
  };

  return (
    <div className="w-full h-full overflow-y-auto pr-8 pt-8">
      <div className="pl-8">
        <PanelsHeader project={controller?.project} />
      </div>
      {fetchPanelState instanceof FetchingPanels && (
        <div className="flex flex-col mt-[15%] m-auto">
          <CircularProgressBar
            className="stroke-nx-main-600"
            width={20}
            height={20}
          />
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("text-nx-gray-200")
              .build()}
          >
            {t("loadingData")}
          </label>
        </div>
      )}
      {fetchPanelState instanceof PanelsFetched && (
        <div>
          <div className="pl-6">
            <GroupDivider title={t("activePanels")} icon={<IconDashboard />} />
          </div>
          <div className="grid grid-cols-4 gap-4 pl-6 mb-8">
            {controller?.restored?.map((e, index) => {
              return (
                <NxDashboardCard
                  key={index}
                  dashboard={{
                    image: e.imageUrl,
                    title: e.name!,
                    description:
                      t("createdAt") + format(e.createdAt!, "dd/MM/yyyy"),
                  }}
                  onProjectClick={() => {
                    navigate('/widgets', {state: e});
                  }}
                  newButtonLabel={t("newPanel")}
                  options={[
                    {
                      icon: <IconEdit />,
                      label: t("edit"),
                      onClick: () => {
                        setModal(
                          new ShowCreateOrEditPanelModal({
                            panel: {
                              id: e.id,
                              name: e.name,
                              project: id,
                              s3Key: e.s3Key,
                              widgets: e.widgets?.map((e) => e.name ?? ""),
                            },
                          })
                        );
                      },
                    },
                    {
                      icon: <IconShare/>,
                      label: t("share"),
                      onClick: () => {
                        copyLink(document.location.href);
                      },
                    },
                    {
                      icon: <IconArchive />,
                      label: t("archive"),
                      onClick: () => {
                        setController({
                          id: e.id,
                          project: controller?.project,
                          archived: controller?.archived,
                          restored: controller?.restored,
                          isCopied: controller?.isCopied ?? false,
                        });
                        setModal(new ShowArchivePanelModal());
                      },
                    },
                  ]}
                />
              );
            })}
            <NxDashboardCard
              onProjectClick={() => {
                setModal(
                  new ShowCreateOrEditPanelModal({
                    panel: {
                      project: id,
                    },
                  })
                );
              }}
              newButtonLabel={t("newPanel")}
            />
          </div>
          {controller?.archived && controller.archived.length > 0 && (
            <>
              <div className="pl-6">
                <GroupDivider
                  title={t("archivedPanels")}
                  icon={<IconArchive />}
                />
              </div>
              <div className="w-full mt-4">
                <NxTHeader
                  className="pl-6"
                  params={[
                    {
                      label: t("firstName"),
                      onClick: function (): void {
                        setState(
                          new SortByName({
                            orderByAsc:
                              !sortedBy.name || !sortState?.orderByAsc,
                          })
                        );
                      },
                      className: " w-[62%]",
                      isSorting: sortedBy.name,
                      isOrderByAsc: sortState?.orderByAsc,
                    },
                    {
                      label: t("archivedAt"),
                      onClick: function (): void {
                        setState(
                          new SortByDate({
                            orderByAsc:
                              !sortedBy.date || !sortState?.orderByAsc,
                          })
                        );
                      },
                      className: "w-[23.5%]",
                      isSorting: sortedBy.date,
                      isOrderByAsc: sortState?.orderByAsc,
                    },
                    {
                      label: "",
                      onClick: function (): void {},
                      className: "w-[14.5%]",
                      isSorting: false,
                    },
                  ]}
                />
                {controller?.archived?.map((e, index) => {
                  return (
                    <PanelsTbody
                      key={index}
                      id={id ?? ""}
                      data={e}
                      showBorder={index + 1 < controller.archived!.length}
                    />
                  );
                })}
              </div>
            </>
          )}
          <ArchivePanelModal
            isOpen={modal instanceof ShowArchivePanelModal}
            id={controller?.id!}
          />
        </div>
      )}
      <NxSnackbar
        showIcon={true}
        showSnackbar={controller?.isCopied === true}
        type={NxSnackbarType.success}
        label={t("linkCopied")}
      />
    </div>
  );
}
