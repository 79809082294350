import { t } from "i18next";

export const validateLocationName = (value: string): string | boolean => {
  if (!value) {
    return t("requiredField");
  } else if (value.length <= 3) {
    return t("minimalCharactersForName");
  }
  return true;
};
