import { NxBodyLargeText } from "../../../../common/components/Texts/NxBodyLargeText";
import { NxAddActionButton } from "../../../../common/components/Buttons/NxAddActionButton";
import { MembersTable } from "../components/MembersTable/MembersTable";
import {
  MemberUserModelProvider,
  useMembersUserModal,
} from "../components/MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../components/MembersModals/MembersModals";
import { t } from "i18next";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";

export const MemberListPage = () => {
  return (
    <MemberUserModelProvider>
      <MemberListBody />
    </MemberUserModelProvider>
  );
};
const MemberListBody = () => {
  const { showModal } = useMembersUserModal();
  return (
    <div className="flex flex-col">
      <div className="flex flex-col px-6 pt-8">
        <div className="flex flex-row w-full">
          <div className="w-full">
            <label
              className={CSName(FontStyles.title)
                .combine("dark:text-nx-main-500 text-nx-dark-800")
                .build()}
            >
              {t("membersManage")}
            </label>
          </div>
          <NxAddActionButton
            label={t("addMembers")}
            onClick={() => {
              showModal(new MembersModals().addUser());
            }}
          />
        </div>
        <NxBodyLargeText className="mt-8">
          {t("organizationMembers")}
        </NxBodyLargeText>
      </div>
      <MembersTable />
    </div>
  );
};
