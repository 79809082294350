import { useState } from "react";
import { FontStyles } from "../../../../../../common/fonts/FontStyles";
import { OptionsEntity } from "../../../../domain/entities/OptionsEntity";
import { MembersRepository } from "../../../../domain/repositories/MembersRepository";
import { MembersUsecase } from "../../../../domain/usecase/MembersUsecase";
import { CheckBoxField } from "web/common/components/TextFields/CheckField";
import { CSName } from "web/common/utils/ClassName";
import { t } from "i18next";

interface IOptionsListElements {
  options?: OptionsEntity[];
  isAllSelected?: boolean;
}

class OptionsListElements implements IOptionsListElements {
  options?: OptionsEntity[];
  isAllSelected?: boolean;

  constructor({ options, isAllSelected = false }: IOptionsListElements) {
    this.isAllSelected = isAllSelected;
    this.options = options;
  }
}

type OptionsListParameters = {
  onUpdate: (options: OptionsEntity[]) => void;
};

export function OptionsList({ onUpdate }: OptionsListParameters) {
  const [state, setState] = useState<IOptionsListElements>(
    new OptionsListElements({
      isAllSelected: false,
      options: new MembersUsecase(new MembersRepository()).getProjectOptions(),
    })
  );

  return (
    <div className="pb-7">
      <div className="flex pb-7">
        <CheckBoxField
          className="bg-nx-focus-light dark:bg-nx-focus-dark"
          isChecked={state.isAllSelected}
          onClick={(isClicked) => {
            setState(
              new OptionsListElements({
                options: state.options?.map((e) => {
                  e.isSelected = isClicked;
                  return e;
                }),
                isAllSelected: isClicked,
              })
            );
          }}
        />
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("pl-2 text-nx-gray-700 dark:text-nx-white text-center")
            .build()}
        >
          {state.isAllSelected ? t("deselectAll") : t("selectAll")}
        </label>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {state.options?.map((e, index) => {
          return (
            <div className="flex" key={index}>
              <CheckBoxField
                key={index}
                className="bg-nx-focus-light dark:bg-nx-focus-dark"
                isChecked={e.isSelected}
                onClick={(c) => {
                  state.options![index].isSelected = !e.isSelected;
                  setState(
                    new OptionsListElements({
                      options: state.options,
                      isAllSelected: state.isAllSelected,
                    })
                  );
                }}
              />
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine(
                    "pl-2 text-nx-gray-700 dark:text-nx-white text-center"
                  )
                  .build()}
              >
                {e.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
