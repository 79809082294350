import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NexusBackButton } from "web/common/components/Buttons/NexusBackButton";
import { NxAddActionButton } from "web/common/components/Buttons/NxAddActionButton";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { PanelEntity } from "web/features/panels/domain/entities/PanelEntity";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { useWidgetModal } from "../context/WidgetsModalProvider";
import {
  HideWidgetModal,
  ShowCreateWidgetModal,
} from "../context/abstraction/WidgetsModal";
import {
  useGetWidgetCategoriesHook,
  WidgetCategoriesFetched,
} from "../hook/UseGetWidgetCategoriesHook";
import {
  useGetWidgetsHook,
  WidgetsFetched,
} from "../hook/UseGetWidgetsHook";
import { WidgetType } from "../../domain/entities/WidgetType";
import { PivotWidget } from "../../features/PivotWidget/presentation/PivotWidget";
import { SlaveWidget } from "../../features/SlaveWidget/presentation/SlaveWidget";

export function WidgetsPage() {
  const { categoriesState, getWidgetCategories } = useGetWidgetCategoriesHook();
  const { widgetsState, getWidgets } = useGetWidgetsHook();
  const [isCreated, setCreated] = useState<boolean>(false);
  const [panel, setPanel] = useState<PanelEntity>();
  const { setModal } = useWidgetModal();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getWidgetCategories();
    getWidgets();
  }, []);

  useEffect(() => {
    setPanel(location.state as PanelEntity);
  }, [location.state]);

  const showCreateModal = () => {
    setModal(
      new ShowCreateWidgetModal({
        widgetCategoriesEntity: (categoriesState as WidgetCategoriesFetched)
          .categories,
        widgets: panel?.widgets!,
        onClick: () => {
          setModal(new HideWidgetModal({}));
        },
        onCreated: () => {
          setCreated(true);
        },
      })
    );
  };

  return (
    <div>
      <div className="w-lvh h-lvh bg-nx-white dark:bg-nx-dark-1100 min-w-full fixed z-50 inset-x-0 inset-y-0 flex flex-col">
        <div className="w-ful mx-8 mt-8 flex flex-row justify-between">
          <div className="flex flex-row w-fit">
            <NexusBackButton
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="flex flex-col ml-3 -mt-1">
              <h3
                className={CSName(FontStyles.title)
                  .combine("text-nx-main-600 dark:text-nx-main-500 text-start")
                  .build()}
              >
                {panel?.name}
              </h3>
              <h5
                className={CSName(FontStyles.bodyRegular)
                  .combine(
                    "dark:text-nx-gray-200 text-nx-gray-400 text-nx-gray-400 text-start"
                  )
                  .build()}
              >
                Nome do projeto
              </h5>
            </div>
          </div>
          <NxAddActionButton
            label={t("newWidget")}
            onClick={() => {
              showCreateModal();
            }}
          />
        </div>
        {widgetsState instanceof WidgetsFetched &&
          (widgetsState as WidgetsFetched).widgets !== undefined && (
            <div className="w-full px-8 pt-5 grid grid-cols-3 gap-4">
              {(widgetsState as WidgetsFetched).widgets?.map((w) =>
                w.type === WidgetType.slave ? (
                  <SlaveWidget widget={w} />
                ) : (
                  <PivotWidget widget={w} />
                )
              )}
            </div>
          )}
      </div>
      <NxSnackbar
        type={NxSnackbarType.success}
        label={t("createdWithSuccess") + "!"}
        showIcon={true}
        showSnackbar={isCreated}
        onSnackbarClose={() => {
          setCreated(false);
        }}
      />
    </div>
  );
}
