import { createContext, useContext, useState } from "react";
import {
  HideWidgetModal,
  ShowCreateWidgetModal,
  ShowRemoveWidgetModal,
  ShowWidgetLocationModal,
  ShowWidgetDetailModal,
  WidgetsModal,
  ShowWidgetLocationDetailModal,
} from "./abstraction/WidgetsModal";
import { CreateWidgetModal } from "../components/CreateWidgetModal/CreateWidgetModal";
import { RemoveWidgetModal } from "../components/RemoveWidgetModal/RemoveWidgetModal";
import { WidgetDetailModal } from "../components/WidgetDetailModal/WidgetDetailModal";
import { WidgetLocationDetailModal } from "../components/WidgetLocationDetailModal/WidgetLocationDetailModal";
import { PivotLocationModal } from "../../features/PivotWidget/presentation/components/PivotLocationModal/PivotLocationModal";

type WidgetModalType = {
  modal: WidgetsModal;
  setModal: (modal: WidgetsModal) => void;
};

const WidgetModalContext = createContext<WidgetModalType>(
  {} as WidgetModalType
);

export function useWidgetModal() {
  return useContext(WidgetModalContext);
}

export function WidgetsModalProvider(props: any) {
  const [modal, changeModal] = useState(new HideWidgetModal({}));

  const setModal = (newModal: WidgetsModal) => {
    changeModal(newModal);
  };

  return (
    <WidgetModalContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      <div className="w-full h-full">
        {props.children}
        {modal instanceof ShowCreateWidgetModal && (
          <CreateWidgetModal
            widgetCategoriesEntity={
              (modal as ShowCreateWidgetModal).widgetCategoriesEntity
            }
            widgets={(modal as ShowCreateWidgetModal).widgets}
            onClick={(modal as ShowCreateWidgetModal).onClick}
            onCreated={(modal as ShowCreateWidgetModal).onCreated}
          />
        )}
        {modal instanceof ShowRemoveWidgetModal && (
          <RemoveWidgetModal
            widget={(modal as ShowRemoveWidgetModal).widget}
            onRemove={(modal as ShowRemoveWidgetModal).onRemove}
            onKeep={(modal as ShowRemoveWidgetModal).onKeep}
          />
        )}
        {modal instanceof ShowWidgetDetailModal && (
          <WidgetDetailModal
            widget={(modal as ShowWidgetDetailModal).widget}
            onClose={(modal as ShowWidgetDetailModal).onClose}
          />
        )}
        {modal instanceof ShowWidgetLocationModal && (
          <PivotLocationModal
            item={(modal as ShowWidgetLocationModal).item}
            onClickOutside={(modal as ShowWidgetLocationModal).onClose}
            onCreated={(modal as ShowWidgetLocationModal).onCreated}
          />
        )}
        {modal instanceof ShowWidgetLocationDetailModal && (
          <WidgetLocationDetailModal
            widget={(modal as ShowWidgetLocationDetailModal).widget}
            onClose={(modal as ShowWidgetLocationDetailModal).onClose}
            onClickOutside={(modal as ShowWidgetLocationDetailModal).onClose}
          />
        )}
      </div>
    </WidgetModalContext.Provider>
  );
}
