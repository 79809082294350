import { t } from "i18next";
import { NxAddActionButton } from "web/common/components/Buttons/NxAddActionButton";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { useAgentsModal } from "../../context/AgentsModalProvider";
import { ShowAgentCreateModal } from "../../context/abstraction/AgentsModal";
import { CreateAgentModal } from "../CreateAgentModal/CreateAgentModal";

export function AgentHeader() {
  const content = useAgentsModal();
  return (
    <div className="flex flex-row pb-6 w-full">
      <div className="w-full">
        <label
          className={CSName(FontStyles.title)
            .combine("dark:text-nx-main-500 text-nx-dark-800")
            .build()}
        >
          {t("manageEntities")}
        </label>
      </div>
      <NxAddActionButton
        label={t("newEntity")}
        onClick={() => {
          content.setModal(new ShowAgentCreateModal());
        }}
      />
      <CreateAgentModal />
    </div>
  );
}
