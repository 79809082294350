import { useState } from "react";
import { RestoreLocationUsecase } from "../../domain/usecase/RestoreLocationUsecase";
import { RestoreLocationFailure } from "../../domain/errors/RestoreLocationFailure";

abstract class RestoreLocationState {}

export class RestoringLocation extends RestoreLocationState {}

export class LocationRestored extends RestoreLocationState {}

export class LocationRestaurationFailure extends RestoreLocationState {}

export const useRestoreLocationHook = () => {
  const [resotreLocationState, setState] = useState<RestoreLocationState>();

  return {
    resotreLocationState,
    restoreLocation: async (id: string) => {
      setState(new RestoringLocation());
      
      const result = await RestoreLocationUsecase(id);

      if (result instanceof RestoreLocationFailure) {
        return setState(new LocationRestaurationFailure());
      }
      return setState(new LocationRestored());
    },
  };
};