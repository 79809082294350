import ManagerImages from "../../../../common/fonts/ManagerImages";
import { IconHomeFilled } from "../../../../common/fonts/icons/components/IconHomeFilled";
import { useVisualContext } from "../../../../core/context/VisualContext";
import { IconCreditCardFilled } from "../../../../common/fonts/icons/components/IconCreditCardFilled";
import { IconUsers } from "../../../../common/fonts/icons/components/IconUsers";
import { HomeNavBarOption, HomeNavSectionTitle } from "./HomeNavBarOption";
import { HomeNavFooter } from "./HomeNavFooter";
import { IconAdd } from "../../../../common/fonts/icons/components/IconAdd";
import { useLocation } from "react-router-dom";
import { IconLocation } from "web/common/fonts/icons/components/IconLocation";
import { IconGroup } from "web/common/fonts/icons/components/IconGroups";
import { ChangeOrgModalProvider } from "../context/ChangeOrgProvider";
import { IconDashboard } from "web/common/fonts/icons/components/IconDashboard";
import { withTranslation, WithTranslation } from "react-i18next";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";
import { NxSVG } from "web/common/components/NxSvg";

const HomeNavBar: React.FC<WithTranslation> = (props) => {
  const { t } = props;

  const { isDarkMode } = useVisualContext();
  const { appState } = useAppContext();
  const location = useLocation();

  const projectLabelColor = [
    "bg-nx-labelColorProject-green",
    "bg-nx-labelColorProject-cyan",
    "bg-nx-labelColorProject-blue",
    "bg-nx-labelColorProject-darkBlue",
    "bg-nx-labelColorProject-purple",
    "bg-nx-labelColorProject-pink",
    "bg-nx-labelColorProject-red",
    "bg-nx-labelColorProject-brown",
    "bg-nx-labelColorProject-orange",
    "bg-nx-labelColorProject-yellow",
  ];

  const isSelected = (route: string) => {
    const parts = location.pathname.split("/").slice(3);
    return "/" + parts.join("/") === route;
  };

  return (
    <div className="flex flex-col  items-stretch min-w-64 h-screen bg-nx-gray-50 dark:bg-nx-dark-1200">
      <img
        src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
        className="w-24 mx-6 my-8"
        alt=""
      />
      <div className="h-full overflow-y-auto">
        <HomeNavBarOption
          title={t("home")}
          route="/"
          icon={<IconHomeFilled />}
          isSelected={isSelected("/")}
        />
        <HomeNavSectionTitle>{t("projects").toUpperCase()}</HomeNavSectionTitle>
        <HomeNavBarOption
          title={t("manage")}
          stroke={false}
          icon={<IconDashboard />}
          route="/projects"
          isSelected={isSelected("/projects")}
        />
        {appState instanceof AppSuccess &&
          appState.projects.map((project, index) => {
            return (
              <HomeNavBarOption
                key={project.id}
                title={project.name!}
                isSvg={false}
                icon={
                  <div
                    className={`w-3 h-3 rounded-[2.5px] ${
                      projectLabelColor[
                        index % projectLabelColor.length
                      ]
                    }`}
                  />
                }
                route={`/projects/${project.id}`}
                isSelected={isSelected(`/projects/${project.id}`)}
              />
            );
          })}

        <HomeNavBarOption
          title={t("new")}
          icon={
            <NxSVG className="stroke-nx-main-500 w-5 h-5">
              <IconAdd />
            </NxSVG>
          }
          isSvg={false}
          menu={true}
          isSelected={false}
        />
        <div className="my-2 h-[0.5px] w-full bg-nx-gray-100  dark:bg-nx-dark-1000" />
        <HomeNavSectionTitle>{t("general").toUpperCase()}</HomeNavSectionTitle>
        <HomeNavBarOption
          title={t("entities")}
          route="/agents"
          icon={<IconGroup />}
          isSelected={isSelected("/agents")}
        />
        <HomeNavBarOption
          title={t("location")}
          route="/locations"
          icon={<IconLocation />}
          isSelected={isSelected("/locations")}
        />
        <HomeNavBarOption
          title={t("members")}
          route="/members"
          icon={<IconUsers />}
          isSelected={isSelected("/members")}
        />
        <HomeNavBarOption
          title={t("signature")}
          route="/signatures"
          icon={<IconCreditCardFilled />}
          isSelected={isSelected("/signatures")}
        />
      </div>

      <ChangeOrgModalProvider>
        <HomeNavFooter />
      </ChangeOrgModalProvider>
    </div>
  );
};
export default withTranslation()(HomeNavBar);
