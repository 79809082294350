import { createContext, useContext, useState } from "react";
import { MyProfileEditPasswordModal } from "./MyProfileEditPasswordModal";

interface MyProfileEditPasswordModalContextProps {
  showModalEditPassword: (email: string) => void;
}

export const MyProfileEditPasswordModalContext =
  createContext<MyProfileEditPasswordModalContextProps>(
    {} as MyProfileEditPasswordModalContextProps
  );

export const MyProfileEditPasswordModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  const showModalEditPassword = (email: string) => {
    setEmail(email);
    setIsOpen(true);
  };

  return (
    <MyProfileEditPasswordModalContext.Provider
      value={{ showModalEditPassword }}
    >
      {children}
      <MyProfileEditPasswordModal
        email={email}
        isOpen={isOpen}
        onClosedTapped={() => setIsOpen(false)}
      />
    </MyProfileEditPasswordModalContext.Provider>
  );
};

export function useMyProfileEditPasswordModal() {
  return useContext(MyProfileEditPasswordModalContext);
}
