import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { LoginParams } from "web/features/auth/domain/params/LoginParams";
import { CheckCredentialPasswordUsecase } from "web/features/auth/domain/usecase/CheckCredentialPasswordUsecase";

abstract class CheckCredentialPasswordState {}

class CheckCredentialPasswordLoading extends CheckCredentialPasswordState {}

class CheckCredentialPasswordSuccess extends CheckCredentialPasswordState {}

class CheckCredentialPasswordError extends CheckCredentialPasswordState {}

export const useCheckCredentialPassword = () => {
  const [checkCredentialPasswordState, setState] = useState<CheckCredentialPasswordState>();
  return {
    checkCredentialPasswordState: {
      isLoading: checkCredentialPasswordState instanceof CheckCredentialPasswordLoading,
      isSuccess: checkCredentialPasswordState instanceof CheckCredentialPasswordSuccess,
      isError: checkCredentialPasswordState instanceof CheckCredentialPasswordError,
    },
    checkCredentialPassword: async (params: LoginParams) => {
      setState(new CheckCredentialPasswordLoading());
      const result = await CheckCredentialPasswordUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new CheckCredentialPasswordError());
      }
      setState(new CheckCredentialPasswordSuccess());
    },
  };
};
