import React from "react";
import { IconRoundedAddOutlined } from "../../../../../common/fonts/icons/components/IconRoundedAddOutlined";
import { NxSVG } from "../../../../../common/components/NxSvg";
import { t } from "i18next";
import { useMembersUserModal } from "../MembersAddUserModal/MembersUserContext";
import { MembersModals } from "../MembersModals/MembersModals";

export const MemberAddProjectCell = () => {
  const { showModal } = useMembersUserModal();
  return (
    <div
      className="flex flex-row gap-2 bg-nx-main-400/20 rounded-[50px] py-1.5 px-4 text-caption font-normal cursor-pointer
      text-nx-main-700 dark:text-nx-main-200"
      onClick={() => {
        showModal(new MembersModals().projectOptions());
      }}
    >
      {t('addProjects')}
      <NxSVG className="w-3.5 h-3.5   fill-nx-main-700 dark:fill-nx-main-200">
        <IconRoundedAddOutlined />
      </NxSVG>
    </div>
  );
};
