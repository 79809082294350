import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { LocationHeader } from "../components/LocationHeader/LocationHeader";
import { useTranslation } from "react-i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { LocationTable } from "../components/LocationTable/LocationTable";
import { CSName } from "web/common/utils/ClassName";
import { LocationsCreateModal } from "../components/LocationsCreateModal/LocationsCreateModal";
import { useLocationsModal } from "../context/LocationsModalProvider";
import { HideLocationModal, ShowCreateLocationModal } from '../context/abstraction/LocationModal';

export function LocationsPage() {
  const { modal, setModal } = useLocationsModal();
    const { t } = useTranslation();
    
  return (
    <div className="w-full h-full overflow-y-auto ">
      <div className="pl-6 pr-8 pt-8">
        <LocationHeader />
        <div className="pb-4 w-72">
          <NxFormField
            placeholder={t("locationsSearchPlaceholder")}
            prefix={
              <NxSVG className="stroke-nx-gray-400">
                <IconSearch />
              </NxSVG>
            }
          />
        </div>
      </div>
      <LocationTable />
      <LocationsCreateModal
        onBackClick={() => {
          setModal(new HideLocationModal({}));
        }}
        onClickOutside={() => {
          setModal(new HideLocationModal({}));
        }}
        className={CSName("transition")
          .concatIf(modal instanceof ShowCreateLocationModal, "visible opacity-1", "invisible opacity-0")
          .build()}
      />
    </div>
  );
}
