import { NxModal } from "web/common/components/NxModal/NxModal";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { t } from "i18next";
import { useChangeOrgModal } from "../../context/ChangeOrgProvider";
import { NxSVG } from "web/common/components/NxSvg";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { useNavigate } from "react-router-dom";

import { OrganizationRouteNames } from "web/features/organizations/routes/OrganizationRoutes";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";

export function ChangeOrgModal() {
  const { showModal, setVisibility } = useChangeOrgModal();
  const navigate = useNavigate();
  const { changeOrg, appState } = useAppContext();

  if (!(appState instanceof AppSuccess)) {
    return <></>;
  }
  const organizations = appState.organizations;
  return (
    <div>
      <NxModal
        showModal={showModal}
        onOutsideClick={() => {
          setVisibility(false);
        }}
      >
        <div className="grid grid-flow-col place-content-between">
          <div className="grid grid-rows-1">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-nx-gray-700 dark:text-nx-main-200 text-start mb-1")
                .build()}
            >
              {t("changeOrg")}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start text-nx-gray-200 dark:text-nx-gray-200")
                .build()}
            >
              {t("changeOrgDescription")}
            </label>
          </div>
          <NxSVG
            className="cursor-pointer dark:stroke-nx-main-500"
            width={20}
            height={20}
            onClick={() => {
              setVisibility(false);
            }}
          >
            <IconClose />
          </NxSVG>
        </div>

        <div className="my-6">
          <div className="mt-2 bg-nx-gray-50 dark:bg-nx-dark-1200 w-fit h-fit flex flex-col pt-2 rounded">
            {organizations.map((org, index) => (
              <div
                className="grid grid-flow-col gap-[267px] place-content-between mx-2 mb-2 p-2 cursor-pointer hover:bg-nx-focus-light"
                key={index}
                onClick={() => {
                  setVisibility(false);
                  changeOrg({
                    orgId: org.id,
                    orgName: org.name
                  });
                }}
              >
                <div className="flex flex-col">
                  <label
                    className={CSName(FontStyles.bodyRegular)
                      .combine("text-nx-gray-700 dark:text-nx-white text-start")
                      .build()}
                  >
                    {org.name}
                  </label>
                  <label
                    className={CSName(FontStyles.caption)
                      .combine(
                        "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                      )
                      .build()}
                  >
                    {org.categoryName}
                  </label>
                </div>
                <NxSVG
                  className={CSName(
                    "stroke-nx-main-500 w-5 h-5 mt-1 active:transition duration-100"
                  ).build()}
                >
                  <IconArrowRight />
                </NxSVG>
              </div>
            ))}
          </div>
        </div>
        <SecondaryButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            setVisibility(false);
            navigate(OrganizationRouteNames.organizations);
          }}
        >
          <div className="flex justify-center">
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("text-nx-main-500 cursor-pointer pr-2")
                .build()}
            >
              {t("manageOrganizations")}
            </label>
          </div>
        </SecondaryButton>
      </NxModal>
    </div>
  );
}
