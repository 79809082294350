import { ExtendableParameters } from "./params/ExtendableParameters";
import { useState } from "react";
import { AgentTbody } from "../AgentTbody/AgentTbody";

export const ExtendableComponent = (params: ExtendableParameters) => {
  const [extended, setExtension] = useState(false);

  return (
    <div
      className={params.showBorder ? "border-b-[0.5px] border-nx-gray-100 dark:border-nx-gray-500" : ""}
    >
      <AgentTbody
        data={params.group}
        extended={extended}
        onClick={() => {
          setExtension(!extended);
        }}
      />
      {/* {extended &&
        params.group!.locations!.map((location, index) => {
          ///TODO: Replace key with the unique ID
          return <AgentTbody key={index} data={location} />;
        })} */}
    </div>
  );
};
