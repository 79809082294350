import { t } from "i18next";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { IconErrorReload } from "web/common/fonts/icons/components/IconErrorReload";
import { IconRefresh } from "web/common/fonts/icons/components/IconRefresh";

interface ReloadableErrorProps {
  onRefresh: () => void;
}
export const ReloadableError = ({ onRefresh }: ReloadableErrorProps) => {
  return (
    <div
      className="pl-6 pr-8 pt-8 w-full h-full flex flex-col items-center justify-center
    text-nx-white
    "
    >
      <IconErrorReload/>
      <div className="w-[260px] mt-5 flex flex-col justify-center items-center gap-2">
        <div className="w-full flex gap-2 justify-center">
          <h1 className="text-nx-gray-200 text-xs">{t("errorToReloadData")}</h1>
        </div>
        <NxFilledButton
          className="h-10 justify-center items-center gap-2 flex"
          onClick={onRefresh}
          backgroundColor="bg-nx-main-600"
        >
          {t("tryAgain")}
          <IconRefresh />
        </NxFilledButton>
      </div>
    </div>
  );
};
