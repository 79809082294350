export const IconClose = () => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 18L2 2M2 18L18 2" stroke="stroke" strokeWidth="2" />
    </svg>
  );
};
