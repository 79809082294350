import { createContext, useContext, useState } from "react";
import {
  HidePanelModal,
  PanelsModal,
  ShowCreateOrEditPanelModal,
} from "./abstraction/PanelsModal";
import { CreateOrEditPanelModal } from "../components/CreateOrEditPanelModal/CreateOrEditPanelModal";

type PanelModalType = {
  modal: PanelsModal;
  setModal: (modal: PanelsModal) => void;
};

const PanelModalContext = createContext<PanelModalType>({} as PanelModalType);

export function usePanelModal() {
  return useContext(PanelModalContext);
}

export function PanelsModalProvider(props: any) {
  const [modal, changeModal] = useState(new HidePanelModal({}));

  const setModal = (newModal: PanelsModal) => {
    changeModal(newModal);
  };

  return (
    <PanelModalContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      <div className="w-full h-full">
        {props.children}
        {modal instanceof ShowCreateOrEditPanelModal && (
          <CreateOrEditPanelModal
            panel={(modal as ShowCreateOrEditPanelModal).panel}
          />
        )}
      </div>
    </PanelModalContext.Provider>
  );
}
