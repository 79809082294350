import { useEffect } from "react";
import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { useAgentsModal } from "../../context/AgentsModalProvider";
import { HideAgentModal } from "../../context/abstraction/AgentsModal";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { t } from "i18next";
import {
  ArchiveAgentFailure,
  ArchiveAgentLoading,
  ArchiveAgentSuccess,
  useArchiveAgentHook,
} from "../../hooks/ArchiveAgentHook";

type ArchiveParams = {
  isOpen: boolean;
  agentId: string;
};

export function ArchiveAgentModal({ isOpen, agentId }: ArchiveParams) {
  const { setModal } = useAgentsModal();
  const { archiveAgent, archiveAgentState } = useArchiveAgentHook();

  useEffect(() => {
    if (archiveAgentState instanceof ArchiveAgentSuccess) {
      setModal(new HideAgentModal(true));
    }
  }, [archiveAgentState, setModal]);

  return (
    <div>
      <NxModal
        showModal={isOpen}
        onOutsideClick={() => {
          setModal(new HideAgentModal());
        }}
      >
        <div className="grid grid-rows-1 pr-24 pb-6">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-main-200 text-start")
              .build()}
          >
            {t("archivedEntity")}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {t("archivedEntityDescription")}
          </label>
        </div>
        <div className="pb-2">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => {
              setModal(new HideAgentModal());
            }}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              {t("maintain")}
            </label>
          </PrimaryButton>
        </div>
        <SecondaryButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            archiveAgent(agentId);
          }}
        >
          {archiveAgentState instanceof ArchiveAgentLoading ? (
            <CircularProgressBar
              className="stroke-nx-danger-500"
              width={20}
              height={20}
            />
          ) : (
            <div className="flex justify-center">
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("text-nx-danger-600 cursor-pointer pr-2")
                  .build()}
              >
                {t("archive")}
              </label>
              <NxSVG className="w-5 h-5 fill-nx-danger-600">
                <IconArchive />
              </NxSVG>
            </div>
          )}
        </SecondaryButton>
      </NxModal>
      {archiveAgentState instanceof ArchiveAgentSuccess && (
        <NxSnackbar
          type={NxSnackbarType.success}
          label={t("archivedSuccessfull")}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            
          }}
        />
      )}
      {archiveAgentState instanceof ArchiveAgentFailure && (
        <NxSnackbar
          type={NxSnackbarType.success}
          label={t("archivedSuccessfull")}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            
          }}
        />
      )}
    </div>
  );
}
