import React from "react";
import { NxSVG } from "../NxSvg";
import { CSName } from "../../utils/ClassName";

type CircularProgressBarElements = {
  width?: number;
  height?: number;
  className?: string;
};

const CircularProgressBar = ({
  width,
  height,
  className ="stroke-nx-main-500 dark:stroke-nx-white",
}: CircularProgressBarElements) => {
  return (
    <NxSVG
      width={width ?? 39}
      height={height ?? 39}
      className={CSName("m-auto").combine(className).build()}
    >
      <svg className="place-self-center" viewBox="0 0 100 100">
        <circle
          className="fill-none stroke-transparent stroke-4"
          cx="50"
          cy="50"
          r="48"
          strokeLinecap="round"
        />
        <circle
          className="fill-none stroke-4"
          cx="50"
          cy="50"
          r="46"
          strokeWidth="8"
        >
          <animate
            attributeName="stroke-dasharray"
            from="0 301.592"
            to="301.592 0"
            dur="3s"
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </NxSVG>
  );
};

export default CircularProgressBar;
