import { forwardRef, useEffect, useRef } from "react";
import { NxSVG } from "../../../../common/components/NxSvg";
import { IconDropSelect } from "../../../../common/fonts/icons/components/IconDropSelect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHomeUsecase } from "../hooks/UseHomeUsecase";
import { useChangeOrgModal } from "../context/ChangeOrgProvider";

interface HomeNavFooterMenuProps {
  onNeedCloseModal: () => void;
}
export const HomeNavFooterMenu = forwardRef<
  HTMLUListElement,
  HomeNavFooterMenuProps
>(({ onNeedCloseModal }, ref) => {
  const refSubmenu = useRef<HTMLDivElement>(null);
  const { setVisibility } = useChangeOrgModal();
  const home = useHomeUsecase();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === refSubmenu.current) return;
      }
      refSubmenu.current?.classList.remove("visible");
      refSubmenu.current?.classList.add("invisible");
      refSubmenu.current?.classList.remove("opacity-100");
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ul
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={ref}
      className="invisible opacity-0 flex flex-col gap-2 absolute bottom-[80%] w-32 py-2  rounded transition bg-nx-white dark:bg-nx-dark-1200 border-nx-gray-400 border-[0.5px]"
    >
      <div
        className="pl-2 pr-2 mb-2"
        onClick={() => {
          if (refSubmenu.current) {
            refSubmenu.current.classList.remove("invisible");
            refSubmenu.current.classList.add("visible");
            refSubmenu.current.classList.add("opacity-100");
          }
        }}
        onMouseEnter={() => {
          if (refSubmenu.current) {
            refSubmenu.current.classList.remove("invisible");
            refSubmenu.current.classList.add("visible");
            refSubmenu.current.classList.add("opacity-100");
          }
        }}
        onMouseLeave={() => {
          if (refSubmenu.current) {
            refSubmenu.current.classList.remove("visible");
            refSubmenu.current.classList.add("invisible");
            refSubmenu.current.classList.remove("opacity-100");
          }
        }}
      >
        <li
          className="group/change-theme relative flex flex-row items-center justify-between text-xs w-full h-8 rounded text-start px-2  
          text-nx-gray-600 dark:nx-text-white 
          hover:bg-nx-main-600 hover:text-nx-white focus:bg-nx-main-600 focus:text-nx-white transition dark:text-nx-white
          "
        >
          {t("organizations")}
          <NxSVG
            className="fill-nx-gray-600 group-hover/change-theme:fill-nx-white dark:fill-nx-white transition"
            width={16}
            height={16}
          >
            <IconDropSelect />
          </NxSVG>
          <div
            ref={refSubmenu}
            className="opacity-0 invisible flex flex-col gap-2 absolute  ml-2 left-full -bottom-2 w-[9.02rem] p-2 rounded transition bg-nx-white dark:bg-nx-dark-1200  border-nx-gray-400 border-[0.5px]"
          >
            <div
              className={`flex flex-col justify-center text-xs w-full h-8 rounded text-start px-2 transitionhover:bg-nx-main-600 hover:bg-nx-main-600
                text-nx-gray-600 dark:text-nx-white hover:text-nx-white`}
              onClick={() => {
                onNeedCloseModal();
                setVisibility(true);
              }}
            >
              {t("changeOrg")}
            </div>
            <div
              className={`flex flex-col justify-center text-xs w-full h-8 rounded text-start px-2 transition hover:bg-nx-main-600 text-nx-gray-600 dark:text-nx-white hover:text-nx-white`}
              onClick={() => {
                navigate("/management/organizations");
                onNeedCloseModal();
              }}
            >
              {t("manage")}
            </div>
          </div>
        </li>
      </div>
      <div className="pl-2 pr-2">
        <li
          className="w-full h-8 px-2 text-start text-xs flex flex-col justify-center    text-nx-gray-600 dark:text-nx-white  rounded transition
           hover:bg-nx-main-600 hover:text-nx-white
        "
          onClick={() => {
            onNeedCloseModal();
            navigate("/management/my-profile");
          }}
        >
          {t("manageAccount")}
        </li>
      </div>
      <div className="pl-2 pr-2">
        <li
          className="w-full h-8 px-2 text-start text-xs flex flex-col justify-center text-nx-danger-700 rounded transition
           hover:bg-nx-main-600 hover:text-nx-white
        "
          onClick={() => {
            home.logout(navigate);
          }}
        >
          {t("logOut")}
        </li>
      </div>
    </ul>
  );
});
