import { useState } from "react";
import { OrganizationEntity } from "../../domain/entities/OrganizationEntity";
import { getOrganizationsUsecase } from "../../domain/usecase/GetOrganizationsUsecase";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { getLocalCategoriesUsecase } from "../../domain/usecase/GetLocalCategoriesUsecase";

abstract class GetOrganizationState {}

export class GetOrganizationsLoading extends GetOrganizationState {}

export class GetOrganizationsSuccess extends GetOrganizationState {
  organizations: OrganizationEntity[];

  constructor({ organizations }: Required<GetOrganizationsSuccess>) {
    super();
    this.organizations = organizations;
  }
}

export class GetOrganizationsFailure extends GetOrganizationState {}

export const useGetOrganizationsHook = () => {
  const [getOrganizationState, setState] = useState<GetOrganizationState>();

  return {
    getOrganizationState,
    getOrganizations: async () => {
      setState(new GetOrganizationsLoading());

      const resultPagination = await getOrganizationsUsecase();

      if (resultPagination instanceof PaginationEntity) {
        return setState(
          new GetOrganizationsSuccess({
            organizations: resultPagination.results,
          })
        );
      }

      return setState(new GetOrganizationsFailure());
    },
    getCategories: () => {
      return getLocalCategoriesUsecase();
    }
  };
};
