import {
  WidgetCategoryEntity,
} from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { WidgetType } from "web/features/widgets/domain/entities/WidgetType";
import { PivotForm } from "web/features/widgets/features/PivotWidget/presentation/components/PivotForm";
import { SlaveForm } from "web/features/widgets/features/SlaveWidget/presentation/components/SlaveForm";

type Params = {
  category: WidgetCategoryEntity;
  onCreated: () => void;
  onPrevious: () => void;
  onClose: () => void;
  itemId: number;
};

export function StepThreePage({
  category,
  onPrevious,
  onCreated,
  onClose,
  itemId,
}: Params) {
  return (
    <div>
      {category.items.filter((i) => i.id === itemId)[0].type ===
        WidgetType.slave && (
        <SlaveForm
          category={category!}
          itemId={itemId}
          onPrevious={onPrevious}
          onClose={onClose}
          onCreated={onCreated}
        />
      )}
      {category.items.filter((i) => i.id === itemId)[0].type ===
        WidgetType.pivot && (
        <PivotForm
          onPrevious={onPrevious}
          onClose={onClose}
          onCreated={onCreated}
          item={category.items.filter((i) => i.id === itemId)[0]}
        />
      )}
    </div>
  );
}
