import { t } from "i18next";

export const validatePassword = (value: string): string | boolean => {
  if (!value) {
    return t('requiredPassword');
  } else if (value.length < 4) {
    return t('minimalCharactersForPassword');
  }
  return true;
};
