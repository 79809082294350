import { PanelEntity } from "web/features/panels/domain/entities/PanelEntity";
import { CreatePanelParams } from "web/features/panels/domain/params/CreatePanelParams ";

export abstract class PanelsModal {}

export class HidePanelModal extends PanelsModal {
  reload?: boolean;
  constructor({ reload = false }: Partial<HidePanelModal>) {
    super();
    this.reload = reload;
  }
}

export class ShowCreateOrEditPanelModal extends PanelsModal {
  panel?: CreatePanelParams;
  constructor({ panel }: Partial<ShowCreateOrEditPanelModal>) {
    super();
    this.panel = panel;
  }
}

export class ShowPanelModal extends PanelsModal {
  panel: PanelEntity;
  constructor(panel: PanelEntity) {
    super();
    this.panel = panel;
  }
}

export class ShowArchivePanelModal extends PanelsModal {}
