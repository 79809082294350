import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { IconArrowLogout } from "web/common/fonts/icons/components/IconArrowLogout";
import { useHomeUsecase } from "web/features/home/presentation/hooks/UseHomeUsecase";

interface MyProfileLogoutModalProps {
  isOpen: boolean;
  onClosedTapped: () => void;
}

export const MyProfileLogoutModal = ({
  isOpen,
  onClosedTapped,
}: MyProfileLogoutModalProps) => {
  const navigate = useNavigate();
  const home = useHomeUsecase();

  return (
    <div>
      <NxModal showModal={isOpen} onOutsideClick={onClosedTapped}>
        <div className="w-[25rem]">
          <NxModalTitle
            title={t("logOut")}
            subtitle={t("confirmLogOut")}
            onClosedTapped={onClosedTapped}
          />

          <NxFilledButton
            onClick={() => {
              home.logout(navigate);
            }}
            className="mt-6"
            backgroundColor="bg-nx-danger-600"
          >
            <div className="flex flex-row items-center justify-center gap-4">
              {t("logOutNexus")}
              <NxSVG className="w-5 h-5">
                <IconArrowLogout />
              </NxSVG>
            </div>
          </NxFilledButton>

          <NxOutlinedButton className=" w-full mt-4" onClick={onClosedTapped}>
            <div className="text-xs font-bold text-nx-main-500">
              {t("back")}
            </div>
          </NxOutlinedButton>
        </div>
      </NxModal>
    </div>
  );
};
