import { useState } from "react";
import { OrganizationEntity } from "../../domain/entities/OrganizationEntity";

abstract class Sort {
  orderByAsc: boolean = true;
  constructor(orderByAsc: boolean) {
    this.orderByAsc = orderByAsc;
  }
}

export class SortByName extends Sort {
  constructor({ orderByAsc }: Partial<SortByName>) {
    super(orderByAsc!);
  }
}

export class SortByCategory extends Sort {
  constructor({ orderByAsc }: Partial<SortByCategory>) {
    super(orderByAsc!);
  }
}

export class SortByRole extends Sort {
  constructor({ orderByAsc }: Partial<SortByRole>) {
    super(orderByAsc!);
  }
}

export class SortByDate extends Sort {
  constructor({ orderByAsc }: Partial<SortByDate>) {
    super(orderByAsc!);
  }
}

export interface SortByParameters {
  instance: Sort;
  entity: OrganizationEntity[];
}

export const useSortOrganizationBy = () => {
  const [sortState, setState] = useState<Sort>();

  return {
    sortState: sortState,
    sortedBy: {
      name: sortState instanceof SortByName,
      category: sortState instanceof SortByCategory,
      role: sortState instanceof SortByRole,
      date: sortState instanceof SortByDate,
    },
    organizations: (param: SortByParameters) => {
      setState(param.instance);
      if (param.instance instanceof SortByName) {
        const organizations = param.entity.sort((a, b) => {
          if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
          else return b.name! < a.name! ? -1 : 1;
        });

        return organizations;
      }

      if (param.instance instanceof SortByCategory) {
        const organizations = param.entity.sort((a, b) => {
          if (param.instance.orderByAsc) 
            return a.categoryName! < b.categoryName! ? -1 : 1;
          else return b.categoryName! < a.categoryName! ? -1 : 1;
        });

        return organizations;
      }

      // if (param.instance instanceof SortByRole) {

        // const organizations = param.entity.sort((a, b) => {
        //   if (param.instance.orderByAsc) 
        //     return a.memberRole! < b.memberRole! ? -1 : 1;
        //   else return b.memberRole! < a.memberRole! ? -1 : 1;
        // });

      //   return organizations;
      // }

      if (param.instance instanceof SortByDate) {
        const organizations = param.entity.sort((a, b) => {
          if (param.instance.orderByAsc)
            return a.createdAt! < b.createdAt! ? -1 : 1;
          else return b.createdAt! < a.createdAt! ? -1 : 1;
        });

        return organizations;
      }
      return param.entity;
    },
  };
};
