import { useState } from "react";
import { ArchivePanelUsecase } from "../../domain/usecases/ArchivePanelUsecase";
import { ArchivePanelFailure } from "../../domain/errors/ArchivePanelFailure";

abstract class ArchivePanelState {}

export class ArchivingPanel extends ArchivePanelState {}

export class PanelArchived extends ArchivePanelState {}

export class PanelArchivingFailure extends ArchivePanelState {}

export const useArchivePanelHook = () => {
  const [archivePanelState, setState] = useState<ArchivePanelState>();

  return {
    archivePanelState,
    archivePanel: async (id: string) => {
      setState(new ArchivingPanel());
      
      const result = await ArchivePanelUsecase(id);

      if (result instanceof ArchivePanelFailure) {
        return setState(new PanelArchivingFailure());
      }
      return setState(new PanelArchived());
    }
  };
};
