export class PaginationEntity<T> {
  count: number;
  next: string | undefined;
  previous: string | undefined;
  results: T[];

  constructor({ count, next, previous, results }: PaginationEntity<T>) {
    this.count = count;
    this.next = next;
    this.previous = previous;
    this.results = results;
  }

  static fromJSON<T>(
    data: any,
    resultCallback: (results: any) => T
  ): PaginationEntity<T> {
    let results: any[] = [data.results];
    if (Array.isArray(data.results)) results = data.results;

    return new PaginationEntity<T>({
      count: data.count,
      next: data.next,
      previous: data.previous,
      results: results?.map((e: any) => resultCallback(e)),
    });
  }
}
