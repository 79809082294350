import { createContext, useContext, useState } from "react";
import { MyProfileTermsModal } from "./MyProfileTermsModal";

interface MyProfileTermsModalContextProps {
  showModal: () => void;
}

export const MyProfileTermsModalContext =
  createContext<MyProfileTermsModalContextProps>(
    {} as MyProfileTermsModalContextProps
  );

export function useMyProfileTermsModal() {
  return useContext(MyProfileTermsModalContext);
}

export const MyProfileTermsModalProvider = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <MyProfileTermsModalContext.Provider
        value={{
          showModal: () => setIsOpen(true)
        }}
      >
        <div className="w-full h-full">
          {children}
          <MyProfileTermsModal isOpen={isOpen} onClosedTapped={() => setIsOpen(false)}/>
        </div>
      </MyProfileTermsModalContext.Provider>
    );
  };