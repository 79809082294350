export class UserEntity {
  id: string;
  first_name: string;
  last_name: string;
  image_s3_key: string | undefined;
  image_url: string | undefined;
  email: string;
  created_at: string;
  updated_at: string;
  last_used_organization: string;

  constructor({
    id,
    first_name,
    last_name,
    image_s3_key,
    email,
    created_at,
    updated_at,
    image_url,
    last_used_organization,
  }: Required<UserEntity>) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.image_s3_key = image_s3_key;
    this.email = email;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.image_url = image_url;
    this.id = id;
    this.last_used_organization = last_used_organization;
  }

  static fromJSON(data: any): UserEntity {
    return new UserEntity({
      id: data.id,
      first_name: data.first_name,
      email: data.email,
      last_name: data.last_name,
      image_s3_key: data.image_s3_key,
      created_at: data.created_at,
      updated_at: data.updated_at,
      image_url: data.image_url,
      last_used_organization: data.last_used_organization,
    });
  }
}
