import { useState } from "react";
import { UploadEntity } from "web/core/domain/entities/UploadEntity";
import { UploadFailed } from "web/core/domain/errors/UploadFailed";
import { uploadImageUsecase } from "web/core/domain/usecase/UploadImageUsecase";
import { CreatePanelParams } from "../../domain/params/CreatePanelParams ";
import { CreatePanelUsecase } from "../../domain/usecases/CreatePanelUsecase";
import { createPanelFailure } from "../../domain/errors/CreatePanelFailure";

abstract class PanelState {}

export class CreatingPanel extends PanelState {}

export class PanelCreated extends PanelState {}

export class PanelUploadFailure extends PanelState {}

export class PanelCreationFailure extends PanelState {}

type Params = {
  upload: Partial<UploadEntity>;
  panel: Partial<CreatePanelParams>;
};

export const useCreatePanelHook = () => {
  const [panelCreationState, setState] = useState<PanelState>();

  return {
    panelCreationState,
    createPanel: async (params: Params) => {
      setState(new CreatingPanel());

      const upload = await uploadImageUsecase(params.upload);

      if (upload instanceof UploadFailed) {
        setState(new PanelUploadFailure());
      }
      const result = await CreatePanelUsecase({
        name: params.panel.name!,
        project: params.panel.project!,
        s3Key: upload as string,
      });

      if (result instanceof createPanelFailure) {
        return setState(new PanelCreationFailure());
      }
      return setState(new PanelCreated());
    },
  };
};
