import { useState } from "react";
import { SortEntity } from "web/core/domain/entities/SortEntity";
import { LocationEntity } from "../../domain/entities/LocationEntity";

export class SortByName extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByName>) {
    super(orderByAsc!);
  }
}

export class SortByWidgets extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByWidgets>) {
    super(orderByAsc!);
  }
}

export class SortByMembers extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByMembers>) {
    super(orderByAsc!);
  }
}

export class SortByDate extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByDate>) {
    super(orderByAsc!);
  }
}

export interface SortByParameters {
  instance?: SortEntity;
  locations: {
    archived?: LocationEntity[];
    restored?: LocationEntity[];
  };
}

export const useSortLocationBy = () => {
  const [sortState, setState] = useState<SortEntity>();

  return {
    setState: setState,
    sortState: sortState,
    sortedBy: {
      name: sortState instanceof SortByName,
      widgets: sortState instanceof SortByWidgets,
      members: sortState instanceof SortByMembers,
      date: sortState instanceof SortByDate,
    },
    filterLocations: (param: SortByParameters) => {
      setState(param.instance);
      if (param.instance instanceof SortByName) {
        return {
          restored: param.locations.restored?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.name! < b.properties.name! ? -1 : 1;
            else return b.properties.name! < a.properties.name! ? -1 : 1;
          }),
          archived: param.locations.archived?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.name! < b.properties.name! ? -1 : 1;
            else return b.properties.name! < a.properties.name! ? -1 : 1;
          }),
        };
      }

      if (param.instance instanceof SortByWidgets) {
        return {
          restored: param.locations.restored?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.widgetIds?.sort()! <
                b.properties.widgetIds?.sort()!
                ? -1
                : 1;
            else
              return b.properties.widgetIds?.sort()! <
                a.properties.widgetIds?.sort()!
                ? -1
                : 1;
          }),
          archived: param.locations.archived?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.widgetIds?.sort()! <
                b.properties.widgetIds?.sort()!
                ? -1
                : 1;
            else
              return b.properties.widgetIds?.sort()! <
                a.properties.widgetIds?.sort()!
                ? -1
                : 1;
          }),
        };
      }

      if (param.instance instanceof SortByDate) {
        return {
          restored: param.locations.restored?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.createdAt! < b.properties.createdAt! ? -1 : 1;
            else
              return b.properties.createdAt! < a.properties.createdAt! ? -1 : 1;
          }),
          archived: param.locations.archived?.sort((a, b) => {
            if (param.instance?.orderByAsc)
              return a.properties.createdAt! < b.properties.createdAt! ? -1 : 1;
            else
              return b.properties.createdAt! < a.properties.createdAt! ? -1 : 1;
          }),
        };
      }
      return param.locations;
    },
  };
};
