import { t } from "i18next";
import { NxAddActionButton } from "web/common/components/Buttons/NxAddActionButton";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { useLocationsModal } from "../../context/LocationsModalProvider";
import { ShowCreateLocationModal } from '../../context/abstraction/LocationModal';

export function LocationHeader() {
  const content = useLocationsModal();
  return (
    <div className="flex flex-row pb-6 w-full">
      <div className="w-full">
        <label
          className={CSName(FontStyles.title)
            .combine("dark:text-nx-main-500 text-nx-dark-800")
            .build()}
        >
          {t("manageLocations")}
        </label>
      </div>
      <NxAddActionButton
        label={t("newLocations")}
        onClick={() => {
            content.setModal(new ShowCreateLocationModal({}));
        }}
      />
    </div>
  );
}
