import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { validateName } from "web/common/forms/validators/ValidateName";
import { useEditAccountHook } from "../../hooks/useEditAccountHook";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";

interface MyProfileEditNameModalProps {
  isOpen: boolean;
  onClosedTapped: () => void;
  firstName: string;
  lastName: string;
}

type IEditNameInput = {
  firstName: string;
  lastName: string;
};

export const MyProfileEditNameModal = ({
  isOpen,
  onClosedTapped,
  firstName,
  lastName,
}: MyProfileEditNameModalProps) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditNameInput>();
  const { editAccount, accountState } = useEditAccountHook();
  const state = accountState;

  const { showSnackbar } = useNxSnackBarContext();

  useEffect(() => {
    if (isOpen) {
      reset({
        firstName,
        lastName,
      });
    }
  }, [isOpen, firstName, lastName, reset]);

  const handleSubmitData = (data: IEditNameInput) => {
    const updatedData = {
      ...(data.firstName && { firstName: data.firstName }),
      ...(data.lastName && { lastName: data.lastName }),
    };
    editAccount(updatedData);
  };

  useEffect(() => {
    if (state.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        showIcon: true,
        label: t("errorSnackbar"),
      });
      setLoading(false);
    }
  }, [showSnackbar, state.isError, onClosedTapped]);

  useEffect(() => {
    if (state.isSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        showIcon: true,
        label: t("successSnackbar"),
      });
      setLoading(false);
      onClosedTapped();
    }
  }, [onClosedTapped, showSnackbar, state.isSuccess]);

  useEffect(() => {
    if (state.isLoading) {
      setLoading(true);
    }
  }, [accountState, showSnackbar, state.isLoading]);

  return (
    <div>
      <NxModal showModal={isOpen} onOutsideClick={onClosedTapped}>
        <div className="max-w-[37.5rem]">
          <NxModalTitle
            title={t("editName")}
            subtitle={t("personalData")}
            onClosedTapped={onClosedTapped}
          />

          <form className="flex-col" onSubmit={handleSubmit(handleSubmitData)}>
            <div className="flex gap-4 my-3">
              <NxFormField
                labelText={t("firstName")}
                register={register("firstName", { validate: validateName })}
                error={errors.firstName}
                className="w-52"
              />

              <NxFormField
                labelText={t("lastName")}
                className="w-52"
                register={register("lastName", { validate: validateName })}
                error={errors.lastName}
              />
            </div>
            <NxFilledButton>
              {loading ? (
                <CircularProgressBar
                  width={16}
                  height={16}
                  className="stroke-nx-white"
                />
              ) : (
                t("save")
              )}
            </NxFilledButton>
          </form>
        </div>
      </NxModal>
    </div>
  );
};
