import { createContext, useContext, useState } from "react";
import { MyProfileLogoutModal } from "./MyProfileLogoutModal";

interface MyProfileLogoutModalContextProps {
  showModal: () => void;
}

export const MyProfileLogoutModalContext =
  createContext<MyProfileLogoutModalContextProps>(
    {} as MyProfileLogoutModalContextProps
  );

export function useMyProfileLogoutModal() {
  return useContext(MyProfileLogoutModalContext);
}

export const MyProfileLogoutModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MyProfileLogoutModalContext.Provider
      value={{ showModal: () => setIsOpen(true) }}
    >
      {children}
      <MyProfileLogoutModal
        isOpen={isOpen}
        onClosedTapped={() => setIsOpen(false)}
      />
    </MyProfileLogoutModalContext.Provider>
  );
};
