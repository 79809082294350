import { t } from "i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { IconGroup } from "web/common/fonts/icons/components/IconGroups";
import { IconLocation } from "web/common/fonts/icons/components/IconLocation";
import { CSName } from "web/common/utils/ClassName";
import { ItemEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { ShowWidgetLocationModal } from "../../../../presentation/context/abstraction/WidgetsModal";
import { useWidgetModal } from "../../../../presentation/context/WidgetsModalProvider";

type Params = {
  item: ItemEntity;
  onPrevious: () => void;
  onNext: () => void;
  onClose: () => void;
  onCreated: () => void;
};

export function PivotOptionsPage({
  item,
  onPrevious,
  onNext,
  onClose,
  onCreated,
}: Params) {
  const { setModal } = useWidgetModal();

  return (
    <div>
      <div className="flex flex-row justify-between mb-4">
        <div className="flex flex-row ">
          <NxSVG
            className="w-5 h-5 stroke-nx-main-600 dark:stroke-nx-main-500 mr-4 mt-1 cursor-pointer"
            onClick={onPrevious}
          >
            <IconArrowLeft />
          </NxSVG>
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-start dark:text-nx-main-200 text-nx-dark-600")
                .build()}
            >
              {item.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start dark:text-nx-gray-200 text-nx-gray-400")
                .build()}
            >
              {item.description}
            </label>
          </div>
        </div>
        <NxSVG
          onClick={onClose}
          className="w-5 h-5 cursor-pointer stroke-nx-main-600"
        >
          <IconClose />
        </NxSVG>
      </div>
      <div className="grid grid-auto-cols gap-2">
        <div className="bg-nx-gray-50 dark:bg-nx-dark-1200 w-full h-fit pt-1 flex flex-col rounded">
          <div
            className="cursor-pointer flex flex-row justify-between place-content-between m-2 p-2 hover:dark:bg-nx-focus-dark/20 hover:rounded"
            key={0}
            onClick={() => {
              setModal(
                new ShowWidgetLocationModal({
                  item: item,
                  onClose: onClose,
                  onCreated: onCreated,
                })
              );
            }}
          >
            <div className="flex flex-row">
              <NxSVG
                className={CSName(
                  "cursor-pointer dark:stroke-nx-gray-400 dark:fill-nx-gray-400 stroke-nx-focus-light mt-0.5 ml-2"
                ).build()}
                width={20}
                height={21}
              >
                <IconLocation />
              </NxSVG>
              <div className="flex flex-col ml-2">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine(
                      "cursor-pointer text-nx-gray-700 dark:text-nx-white text-start"
                    )
                    .build()}
                >
                  {t("drawOnMap")}
                </label>
                <label
                  className={CSName(FontStyles.caption)
                    .combine(
                      "cursor-pointer text-nx-gray-400 dark:text-nx-gray-200 text-start"
                    )
                    .build()}
                >
                  {t("drawOnMapDescription")}
                </label>
              </div>
            </div>
            <NxSVG
              className={CSName(
                "cursor-pointer dark:stroke-nx-main-500 stroke-nx-focus-light mt-1.5 mr-2"
              )
                .combine("")
                .build()}
              width={20}
              height={20}
            >
              <IconArrowRight />
            </NxSVG>
          </div>
        </div>
        <div className="bg-nx-gray-50 dark:bg-nx-dark-1200 w-full h-fit pt-1 flex flex-col rounded">
          <div
            className="cursor-pointer flex flex-row justify-between place-content-between m-2 p-2 hover:dark:bg-nx-focus-dark/20 hover:rounded"
            key={1}
            onClick={onNext}
          >
            <div className="flex flex-row">
              <NxSVG
                className={CSName(
                  "cursor-pointer dark:stroke-nx-gray-400 dark:fill-nx-gray-400 stroke-nx-focus-light mt-0.5 ml-2"
                ).build()}
                width={20}
                height={21}
              >
                <IconGroup />
              </NxSVG>
              <div className="flex flex-col ml-2">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine(
                      "cursor-pointer text-nx-gray-700 dark:text-nx-white text-start"
                    )
                    .build()}
                >
                  {t("selectEntityAndLocation")}
                </label>
                <label
                  className={CSName(FontStyles.caption)
                    .combine(
                      "cursor-pointer text-nx-gray-400 dark:text-nx-gray-200 text-start"
                    )
                    .build()}
                >
                  {t("selectEntityAndLocationDescription")}
                </label>
              </div>
            </div>
            <NxSVG
              className={CSName(
                "cursor-pointer dark:stroke-nx-main-500 stroke-nx-focus-light mt-1.5 mr-2"
              )
                .combine("")
                .build()}
              width={20}
              height={20}
            >
              <IconArrowRight />
            </NxSVG>
          </div>
        </div>
      </div>
    </div>
  );
}
