import { useState } from "react";
import { editAgentUsecase } from "../../domain/usecase/EditAgentUsecase";
import { EditAgentParams } from "../../domain/params/EditAgentParams";
import { EditAgentError } from "../../domain/errors/EditAgentError";

abstract class EditAgentState {}

export class EditAgentsLoading extends EditAgentState {}

export class EditAgentsSuccess extends EditAgentState {}

export class EditAgentsFailure extends EditAgentState {}

export const useEditAgentsHook = () => {
  const [editAgentState, setState] = useState<EditAgentState>();

  return {
    editAgentState,
    editAgent: async (params: EditAgentParams) => {
      setState(new EditAgentsLoading());
      const result = await editAgentUsecase(params);

      if (result instanceof EditAgentError) {
        return setState(new EditAgentsFailure());
      }

      return setState(new EditAgentsSuccess());
    },
  };
};
