// HomePage.tsx
import { NxSVG } from "../../../../common/components/NxSvg";
import ManagerImages from "../../../../common/fonts/ManagerImages";
import { HomeCarrousel } from "../components/HomeCarrousel/HomeCarrousel";
import { NxFormField } from "../../../../common/components/TextFields/NxFormField";
import { IconSearch } from "../../../../common/fonts/icons/components/IconSearch";
import { NxAddActionButton } from "../../../../common/components/Buttons/NxAddActionButton";
import { NxPageText } from "../../../../common/components/Texts/NxPageText";
import { NxDashboardCard } from "web/common/components/NxDashboardCard";
import { t } from "i18next";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SelectProjectModal } from "../components/SelectProjectModal/SelectProjectModal";

export const HomePage = () => {
  const { appState } = useAppContext();
  const [isVisible, setVisibility] = useState(false);
  if (!(appState instanceof AppSuccess)) return <></>;
  const account = appState.user;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigator = useNavigate();
  
  return (
    <div className="px-6 pt-8 w-full min-h-screen overflow-none">
      <div className="flex flex-col w-full min-h-full overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <NxPageText>
            {t("welcomeTitle")} {account?.first_name}
          </NxPageText>
          <div className="mr-[15.5rem]">
            <NxAddActionButton
              label={t("newProject")}
              onClick={() => {
                navigator("projects");
              }}
            />
          </div>
        </div>

        <h3 className="text-base font-semibold my-2 text-nx-gray-600 dark:text-nx-white">
          {t("relevantNews")}
        </h3>
        <div className="max-w-full">
          <HomeCarrousel
            items={[
              {
                title: "Danos de geada na região Centro Oeste",
                font: "G1",
                date: "09/02/2024",
                image: ManagerImages.carrousel1,
              },
              {
                title: "Anomalias climáticas em Março",
                font: "Weather Channel",
                date: "09/02/2024",
                image: ManagerImages.carrousel2,
              },
              {
                title: "Aumento de produtividade em 2024",
                font: "G1",
                date: "09/02/2024",
                image: ManagerImages.carrousel3,
              },
              {
                title: "Danos de geada na região Centro Oeste",
                font: "G1",
                date: "09/02/2024",
                image: ManagerImages.carrousel1,
              },
              {
                title: "Anomalias climáticas em Março",
                font: "Weather Channel",
                date: "09/02/2024",
                image: ManagerImages.carrousel2,
              },
              {
                title: "Aumento de produtividade em 2024",
                font: "G1",
                date: "09/02/2024",
                image: ManagerImages.carrousel3,
              },
            ]}
          />
        </div>

        <h3 className="text-base font-semibold mt-6 text-nx-gray-600 dark:text-nx-white">
          {t("recentlyAccessed")}
        </h3>
        <div className="w-80 mb-4">
          <NxFormField
            placeholder={t("findDashboard")}
            prefix={
              <NxSVG className="stroke-nx-gray-400">
                <IconSearch />
              </NxSVG>
            }
          />
        </div>

        <div className="flex flex-row gap-6 flex-wrap w-full">
          {appState.projects.length > 0
            ? appState.projects.map((p, idx) => (
                <NxDashboardCard
                  key={idx}
                  dashboard={{
                    image:
                      "https://i.pinimg.com/550x/d6/99/00/d69900c385273d6b431a6bedcf7d94f3.jpg",
                    title: p.name!,
                    description: p.description ?? "",
                  }}
                  onProjectClick={() => {}}
                  newButtonLabel={t("createNew")}
                />
              ))
            : [...Array(1)].map((_, idx) => (
                <NxDashboardCard
                  key={idx}
                  onProjectClick={() => {}}
                  newButtonLabel={t("createNew")}
                />
              ))}
              <NxDashboardCard
                  onProjectClick={() => {}}
                  newButtonLabel={t("createNew")}
                />
        </div>
        <SelectProjectModal
          showModal={isVisible}
          projects={[]}
          onClose={() => {
            setVisibility(false);
          }}
        />
      </div>
    </div>
  );
};
