import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { IconEdit } from "../../../../../common/fonts/icons/components/IconEdit";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { NxDropFormField } from "web/common/components/TextFields/NxDropFormField";
import { useTranslation } from "react-i18next";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { useCallback, useEffect, useState } from "react";
import { NxImageCropper } from "web/common/components/NxImageCropper/NxImageCropper";
import ImageUploading from "react-images-uploading";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IconImage } from "web/common/fonts/icons/components/IconImage";
import { useOrgModal } from "../../context/OrgModalProvider";
import {
  HideOrganizationModal,
  ShowCreateApiKeyModal,
  ShowCreateOrEditOrganizationModal,
} from "../../context/abstraction/OrganizationModal";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { useGetOrganizationsHook } from "../../hooks/UseGetOrganizationsHook";
import { CategoryEntity } from "web/features/auth/domain/entities/CategoryEntity";
import { UploadEntity } from "web/core/domain/entities/UploadEntity";
import {
  CreationFailure,
  CreationInProgress,
  CreationOrgUploadFailure,
  CreationSucceed,
  useCreateOrUpdateOrganizationHook,
} from "../../hooks/UseCreateOrUpdateOrganizationHook";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import { ImageUtils } from "web/common/utils/ImageUtils";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { ApiKeyEntity } from "web/features/organizations/domain/entities/ApiKeyEntity";
import ApiKeyTbody from "../ApiKeyTbody/ApiKeyTbody";
import { IconAdd } from "web/common/fonts/icons/components/IconAdd";
import {
  GetApiKeysFailure,
  GetApiKeySuccess,
  useGetApiKeysHook,
} from "../../hooks/UseGetApiKeysHook";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { validateCategory } from "web/common/forms/validators/ValidateCategory";

type ICreateOrgInput = {
  name: string;
  category: string;
  nameApiKey?: string;
};

type CreateOrUpdateOrgProps = {
  org?: Partial<CreateOrgParams>;
  onSuccess?: () => void;
  apiKeys?: Partial<ApiKeyEntity[]>;
};

export const CreateOrUpdateOrg = ({
  org,
  onSuccess,
  apiKeys,
}: CreateOrUpdateOrgProps) => {
  const { orgCreationState, createOrg, updateOrg } =
    useCreateOrUpdateOrganizationHook();
  const { getCategories } = useGetOrganizationsHook();
  const { modal, setModal } = useOrgModal();

  const {
    trigger,
    register,
    formState: { errors },
    control,
    getValues,
  } = useForm<ICreateOrgInput>({
    values: {
      name: org?.name ?? "",
      category: org?.category ?? "",
      nameApiKey: "",
    },
  });
  const { t } = useTranslation();
  const [uploadEntity, setUploadData] = useState<
    Partial<UploadEntity> | undefined
  >();
  const [imageToEdit, setImageToEdit] = useState<string | null>(null);
  const [categories, setCategory] = useState<CategoryEntity[]>([]);

  const { getApiKeys, getApiKeysState } = useGetApiKeysHook();
  const [contentApiKeys, setContentApiKeys] = useState<ApiKeyEntity[] | null>();

  const [loadingApiKeys, setLoadingApikeys] = useState(true)

  const [newImage, setNewImage] = useState<string | null>(null);

  const closeModal = useCallback(
    (reloadOrgs: boolean) => {
      setModal(new HideOrganizationModal({ reloadOrgs }));
    },
    [setModal]
  );

  useEffect(() => {
    org && getApiKeys(org.id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contentApiKeys === undefined) {
        if (getApiKeysState instanceof GetApiKeySuccess) {
          // console.log(getApiKeysState.apiKeys)
          setContentApiKeys(getApiKeysState.apiKeys);
          setLoadingApikeys(false)
        }
        if (getApiKeysState instanceof GetApiKeysFailure) {
          setLoadingApikeys(false)
          setContentApiKeys(null);
        }
    }
  }, [getApiKeysState, contentApiKeys]);

  useEffect(() => {
    if (categories.length === 0) setCategory(getCategories());

    if (orgCreationState instanceof CreationSucceed) {
      closeModal(true);
      if (onSuccess !== undefined) onSuccess();
    }
  }, [
    orgCreationState,
    categories.length,
    closeModal,
    getCategories,
    onSuccess,
  ]);

  const createOrgSubimit: SubmitHandler<ICreateOrgInput> = (data) => {
    if (data.name !== undefined && data.category !== undefined) {
      if (org?.id !== undefined) {
        updateOrg({
          upload: uploadEntity,
          org: {
            id: org.id,
            name: data.name,
            category: data.category,
            s3Key: org.s3Key,
          },
        });
      } else {
        createOrg({
          upload: uploadEntity,
          org: { name: data.name, category: data.category },
        });
      }
    }
  };

  return (
    <div>
      <NxModal
        showModal={modal instanceof ShowCreateOrEditOrganizationModal}
        onOutsideClick={() => closeModal(false)}
      >
        <div>
          <h1>
            {imageToEdit && (
              <NxImageCropper
                title={t("profilePicture")}
                subtitle={t("orgData")}
                initialImage={imageToEdit}
                onClosedTapped={() => setImageToEdit("")}
                onCropComplete={(croppedImage) => {
                  setNewImage(croppedImage);
                  setUploadData({
                    fileName: uploadEntity?.fileName,
                    folder: uploadEntity?.folder,
                    file: ImageUtils.convertToUint8(croppedImage),
                  });
                  setImageToEdit("");
                }}
              />
            )}
            {!imageToEdit && (
              <div className="flex flex-col items-center bg-nx-white dark:bg-nx-dark-1100 p-4 rounded-lg w-[500px]">
                <NxModalTitle
                  title={org ? t("orgData") : t("createOrg")}
                  subtitle={t("exibingSavedInformation")}
                  onClosedTapped={() => {
                    closeModal(false);
                  }}
                />
                <div className="relative w-24 h-24 rounded-full  border-2 border-nx-main-600 bg-nx-main-600/10">
                  <div className="absolute flex items-center justify-center w-full  h-full rounded-full overflow-hidden">
                    {newImage ?? imageToEdit ? (
                      <img
                        className="w-full h-full object-cover"
                        src={newImage ?? imageToEdit ?? ""}
                        alt=""
                      />
                    ) : (
                      <NxSVG className="w-7 h-7 fill-nx-main-600">
                        <IconImage />
                      </NxSVG>
                    )}
                  </div>
                  <ImageUploading
                    value={[]}
                    onChange={(imageList) => {
                      setUploadData({
                        fileName: imageList[0].file!.name,
                        folder: "orgs",
                        file: ImageUtils.convertToUint8(imageList[0].dataURL!),
                      });
                      setImageToEdit(imageList[0].dataURL ?? "");
                    }}
                    maxNumber={1}
                  >
                    {({ onImageUpload }) => (
                      <div
                        className="absolute bottom-0 right-0 w-7 h-7  flex items-center justify-center rounded-full bg-nx-main-500 cursor-pointer"
                        onClick={() => {
                          onImageUpload();
                        }}
                      >
                        <NxSVG className="w-4 h-4 mb-1 mr-0.5 fill-nx-white">
                          <IconEdit />
                        </NxSVG>
                      </div>
                    )}
                  </ImageUploading>
                </div>

                <div className="flex my-4  gap-4 w-full">
                  <div className="w-1/2">
                    <NxFormField
                      register={register("name", {
                        required: t("requiredField"),
                      })}
                      labelText={t("nameOrg")}
                      error={errors.name}
                    />
                  </div>
                  <div className="w-1/2 z-10">
                  <Controller
                      name="category"
                      control={control}
                      rules={{ validate: validateCategory }}
                      render={({ field: { value } }) => (
                        <NxDropFormField
                          labelText={t("category")}
                          defaultValue={value}
                          options={categories}
                          register={register("category", {
                            validate: validateCategory,
                          })}
                          placeholder={t("select")}
                          labelBuilder={(category) => category.name}
                          valueBuilder={(category) => category.id}
                        ></NxDropFormField>
                      )}
                    />
                  </div>
                </div>

                {org && (
                  <div className="w-full mb-4 space-y-1 flex flex-col">
                    <div className="flex justify-between items-center">
                      <span className="text-captionMedium font-bold text-nx-white">
                        {t("apiKeys").toUpperCase()}
                      </span>
                      {contentApiKeys && contentApiKeys.length > 0 && (
                        <button
                          onClick={() =>
                            setModal(new ShowCreateApiKeyModal(org))
                          }
                          className="flex items-center gap-2 text-captionMedium text-nx-main-500"
                        >
                          {t("newKey")}
                          <NxSVG
                            className="fill-white stroke-nx-main-500"
                            width={20}
                            height={20}
                          >
                            <IconAdd />
                          </NxSVG>
                        </button>
                      )}
                    </div>

                    <span className="text-captionMedium text-nx-gray-200">
                      Descrição se necessário
                    </span>
                    <div
                      className={`w-full max-h-[20.75rem] ${
                        apiKeys ? "space-y-5" : "space-y-4"
                      } py-5 rounded-lg text-center dark:bg-nx-dark-1200 bg-nx-gray-50 overflow-y-scroll no-scrollbar`}
                    >
                      {loadingApiKeys ? (
                        <div className="flex flex-col m-auto">
                          <CircularProgressBar
                            className="stroke-nx-main-600"
                            width={20}
                            height={20}
                          />
                          <label
                            className={CSName(FontStyles.bodyRegular)
                              .combine("text-nx-gray-200")
                              .build()}
                          >
                            {t("loadingData")}
                          </label>
                        </div>
                      ) : contentApiKeys && contentApiKeys.length > 0 ? (
                        contentApiKeys.map((api, index) => {
                          return (
                            <div
                              key={api?.id}
                              className="w-full text-start px-4 flex flex-col space-y-2"
                            >
                              <ApiKeyTbody
                                indexKey={index + 1}
                                apiKey={api as ApiKeyEntity}
                                organization={org}
                              />

                              {index < contentApiKeys.length - 1 && (
                                <div>
                                  <hr className="border-t border-nx-gray-500" />
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <p className="text-caption text-nx-gray-200">
                            {t("noKeyToDisplay")}
                          </p>
                          <NxOutlinedButton
                            className="px-6 text-captionMedium text-nx-main-500"
                            onClick={() =>
                              setModal(new ShowCreateApiKeyModal(org))
                            }
                          >
                            {t("generateKey")}
                          </NxOutlinedButton>
                        </>
                      )
                      }
                    </div>
                  </div>
                )}

                <NxFilledButton
                  className="h-10"
                  isLoading={orgCreationState instanceof CreationInProgress}
                  onClick={async () => {
                    const valid = await trigger();
                    if (valid) {
                      createOrgSubimit(getValues());
                    }
                  }}
                >
                  {t("save")}
                </NxFilledButton>
              </div>
            )}
          </h1>
        </div>
      </NxModal>
      <NxSnackbar
        showIcon={true}
        showSnackbar={orgCreationState instanceof CreationSucceed}
        type={NxSnackbarType.success}
        label={t("CreateOrgSuccessMessage")}
      />
      <NxSnackbar
        showIcon={true}
        showSnackbar={orgCreationState instanceof CreationFailure}
        type={NxSnackbarType.error}
        label={t("CreateOrgFailureMessage")}
      />
      <NxSnackbar
        showIcon={true}
        showSnackbar={orgCreationState instanceof CreationOrgUploadFailure}
        type={NxSnackbarType.error}
        label={t("uploadImageError")}
      />
    </div>
  );
};
