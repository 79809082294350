export class PoliciesTermsEntity {
  version: number;
  text: string;
  created_at: string;
  constructor({ version, text, created_at }: Required<PoliciesTermsEntity>) {
    this.version = version;
    this.text = text;
    this.created_at = created_at;
  }

  static fromJSON(data: any): PoliciesTermsEntity {
    return new PoliciesTermsEntity({
      version: data.version,
      text: data.text,
      created_at: data.created_at,
    });
  }
}
