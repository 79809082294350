import { AuthLocalRepositoryImpl } from "../../../auth/domain/repositories/AuthLocalRepository";
import { HomeUsecase } from "../../domain/usecase/HomeUsecase";

export function useHomeUsecase() {
  const authLocalRepository = new AuthLocalRepositoryImpl();
  const usecase = new HomeUsecase({
    authLocalRepository: authLocalRepository,
  });

  return usecase;
}
