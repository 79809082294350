export const IconSecurity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10.1886 17.8319C10.07 17.8985 9.93004 17.8985 9.81138 17.8319C9.10286 17.4341 7.63053 16.5291 6.34162 15.2406C5.04535 13.9447 4 12.3275 4 10.5V5.59676C4 5.38741 4.13042 5.20022 4.32681 5.12771L9.82681 3.09694C9.93858 3.05567 10.0614 3.05567 10.1732 3.09694L15.6732 5.12771C15.8696 5.20022 16 5.38741 16 5.59676V10.5C16 12.3275 14.9547 13.9447 13.6584 15.2406C12.3695 16.5291 10.8971 17.4341 10.1886 17.8319Z"
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
