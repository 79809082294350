import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { CreateAccountParams } from "../params/CreateAccountParams";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { CredentialEntity } from "../entities/CredentialEntity";
import { CreateAccountFailure } from "../errors/CreateAccountFailure";
import { CategoryEntity } from "../entities/CategoryEntity";
import { CreateOrgParams } from "../params/CreateOrgParams";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { CreateOrgFailure } from "../errors/CreateOrgFailure";
import { LoginParams } from "../params/LoginParams";
import { jwtDecode } from "jwt-decode";
import { LoginDTO } from "../dto/LoginDTO";
import { LoginWithOAuthFailure } from "../errors/LoginWithOAuthFailure";
import { NxJTWDecoded } from "../entities/NxJWTDecoded";
import { EditAccountParams } from "../params/EditAccountParams";
import { EditAccountFailure } from "../errors/EditAccountFailure";
import { GetAccountFailure } from "../errors/GetAccountFailure";
import { UserEntity } from "../entities/UserEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { CheckCredentialPasswordFailure } from "../errors/CheckCredentialPasswordFailure";
import { GetTermsUserFailure } from "../errors/GetTermsUserFailure";
import { GetPoliciesUserFailure } from "../errors/GetPoliciesUserFailure";
import { AcceptTermsUserFailure } from "../errors/AcceptTermsUserFailure";
import { AcceptPoliciesUserFailure } from "../errors/AcceptPoliciesUserFailure";
import { PoliciesTermsEntity } from "../entities/PoliciesTermsEntity";

export const AuthRepositoryImpl = {
  async loginOAuht(code: string) {
    const redirectUri = `${window.location.origin}/loading-oauth`;

    const response = await networkAdapter.post<LoginDTO>({
      path: "/auth/protocol/openid-connect/token",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      needToken: false,
      body: [
        "grant_type=authorization_code",
        `redirect_uri=${redirectUri}`,
        `code=${code.trim()}`,
      ].join("&"),
    });

    if (response instanceof NetworkError) {
      return new LoginWithOAuthFailure();
    } else {
      const data = response.data;
      const credential = this.decodeTokenByLogin(data);
      localStorage.setItem("credential", JSON.stringify(credential));
      return new EmptyEntity();
    }
  },
  async login({
    email,
    password,
    maintainLogin,
  }: LoginParams): Promise<boolean> {
    const response = await networkAdapter.post<LoginDTO>({
      path: "/auth/protocol/openid-connect/token",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      needToken: false,
      body: {
        username: email,
        password: password,
        grant_type: "password",
      },
    });

    if (response instanceof NetworkError) {
      return false;
    } else {
      const data = response.data;
      const credential = this.decodeTokenByLogin(data);
      if (maintainLogin)
        localStorage.setItem("credential", JSON.stringify(credential));
      else sessionStorage.setItem("credential", JSON.stringify(credential));

      return true;
    }
  },

  async checkCredentialPassword({
    email,
    password,
  }: LoginParams): Promise<boolean | CheckCredentialPasswordFailure> {
    const response = await networkAdapter.post<LoginDTO>({
      path: "/auth/protocol/openid-connect/token",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      needToken: false,
      checkCredential: true,
      body: {
        username: email,
        password: password,
        grant_type: "password",
      },
    });

    if (response instanceof NetworkError) {
      return new CheckCredentialPasswordFailure();
    }
    return true;
  },

  async createUser({
    firstName,
    lastName,
    email,
    password,
  }: CreateAccountParams): Promise<CreateAccountFailure | CategoryEntity[]> {
    const result = await networkAdapter.post({
      path: "/users/",
      needToken: false,
      body: {
        username: email,
        email: email,
        enabled: true,
        firstName,
        lastName,
        credentials: [
          {
            type: "password",
            value: password,
            temporary: false,
          },
        ],
      },
    });

    if (result instanceof NetworkError) {
      return new CreateAccountFailure();
    }
    const isSuccess = await this.login({
      email,
      password,
      maintainLogin: true,
    });
    if (!isSuccess) {
      return new CreateAccountFailure();
    }
    const resultOrgs = await networkAdapter.get({
      path: "/core/organization_categories/",
    });

    if (resultOrgs instanceof NetworkError) {
      return new CreateAccountFailure();
    }
    const orgs = (resultOrgs.data as any).results as Array<object>;

    return orgs.map((org: object) => CategoryEntity.fromJSON(org));
  },

  async createOrg({ name, category }: Partial<CreateOrgParams>) {
    return networkAdapter
      .post({
        path: "/core/organizations/",
        body: {
          name,
          description: "",
          category: category,
        },
      })
      .then((result) => {
        if (result instanceof NetworkError) {
          return new CreateOrgFailure();
        }
        return new EmptyEntity();
      });
  },

  decodeTokenByLogin({
    access_token,
    refresh_token,
  }: LoginDTO): CredentialEntity {
    const decodeToken = jwtDecode<NxJTWDecoded>(access_token);
    return new CredentialEntity({
      access_token,
      refresh_token,
      name: decodeToken.name,
      sub: decodeToken.sub,
    });
  },

  async editUser({
    firstName,
    lastName,
    password,
    imageS3Key,
  }: EditAccountParams): Promise<EditAccountFailure | UserEntity> {
    const result = await networkAdapter.patch<any>({
      path: "/users/profile/",
      body: {
        first_name: firstName,
        last_name: lastName,
        password,
        image_s3_key: imageS3Key,
      },
    });

    if (result instanceof NetworkError) {
      return new EditAccountFailure();
    }
    return UserEntity.fromJSON(result.data);
  },

  async getUser(): Promise<UserEntity | GetAccountFailure> {
    const result = await networkAdapter.get({
      path: "/users/profile",
    });
    if (result instanceof NetworkError) {
      return new GetAccountFailure();
    }

    return UserEntity.fromJSON(result.data);
  },

  async getTermsUser(): Promise<
    PaginationEntity<PoliciesTermsEntity> | GetPoliciesUserFailure
  > {
    const result = await networkAdapter.get({
      path: "/users/terms/",
      needToken: false,
    });
    if (result instanceof NetworkError) {
      return new GetTermsUserFailure();
    }
    const dataTerms = {
      count: 1,
      next: null,
      previous: null,
      results: [result.data],
    };

    return PaginationEntity.fromJSON(dataTerms, (e) =>
      PoliciesTermsEntity.fromJSON(e)
    );
  },

  async acceptTermsUser(version: number) {
    const result = await networkAdapter.patch({
      path: `/users/terms/${version}/accept`,
    });
    if (result instanceof NetworkError) {
      return new AcceptTermsUserFailure();
    }
    return new EmptyEntity();
  },

  async getPoliciesUser(): Promise<
    PaginationEntity<PoliciesTermsEntity> | GetPoliciesUserFailure
  > {
    const result = await networkAdapter.get({
      path: "/users/policies/",
      needToken: false,
    });
    if (result instanceof NetworkError) {
      return new GetPoliciesUserFailure();
    }
    const dataPolices = {
      count: 1,
      next: null,
      previous: null,
      results: [result.data],
    };

    return PaginationEntity.fromJSON(dataPolices, (e) =>
      PoliciesTermsEntity.fromJSON(e)
    );
  },

  async acceptPoliciesUser(version: number) {
    const result = await networkAdapter.patch({
      path: `/users/policies/${version}/accept`,
    });
    if (result instanceof NetworkError) {
      return new AcceptPoliciesUserFailure();
    }
    return new EmptyEntity();
  },
};