import { createContext, useContext, useState } from "react";
import {
  HideProjectModal,
  ProjectsModal,
  ShowCreateOrEditProjectModal,
  ShowProjectsPanelsModal,
} from "./abstraction/ProjectsModal";
import { CreateOrEditProjectModal } from "../components/CreateOrEditProjectModal/CreateOrEditProjectModal";
import { PanelsProjecModal } from "../components/PanelsProjectModal/PanelsProjectModal";

type ProjectModalType = {
  modal: ProjectsModal;
  setModal: (modal: ProjectsModal) => void;
};

const ProjectModalContext = createContext<ProjectModalType>(
  {} as ProjectModalType
);

export function useProjectModal() {
  return useContext(ProjectModalContext);
}

export function ProjectModalProvider(props: any) {
  const [modal, changeModal] = useState<ProjectsModal>(
    new HideProjectModal({ reloadProjects: false })
  );

  const setModal = (newModal: ProjectsModal) => {
    changeModal(newModal);
  };

  return (
    <ProjectModalContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      <div className="w-full h-full">
        {props.children}
        {modal instanceof ShowCreateOrEditProjectModal && (
          <CreateOrEditProjectModal
            project={(modal as ShowCreateOrEditProjectModal).project}
          />
        )}
        {modal instanceof ShowProjectsPanelsModal && (
          <PanelsProjecModal
            panelsList={(modal as ShowProjectsPanelsModal).panelsList}
          />
        )}
        {/* {(modal instanceof ShowShareProjectModal) && <ShareForecastModal members={(modal as ShowShareProjectModal).members}/>} */}
      </div>
    </ProjectModalContext.Provider>
  );
}
