import { format } from "date-fns";
import { useState } from "react";
import { NxSVG } from "web/common/components/NxSvg";
import { IconCopy } from "web/common/fonts/icons/components/IconCopy";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { ApiKeyEntity } from "web/features/organizations/domain/entities/ApiKeyEntity";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { t } from "i18next";
import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { useOrgModal } from "../../context/OrgModalProvider";
import { ShowRemoveApiKeyModal } from "../../context/abstraction/OrganizationModal";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";

export interface ApiKeyDetailsProps {
  apiKey: ApiKeyEntity;
  indexKey: number;
  organization: Partial<CreateOrgParams>
}

const ApiKeyTbody = ({ apiKey, organization, indexKey }: ApiKeyDetailsProps) => {
  const [snackbarCopyClipboard, setSnackbarCopyClipboard] =
    useState<boolean>(false);

  const { setModal } = useOrgModal();

  const handleRemove = () => {
    setModal(new ShowRemoveApiKeyModal(apiKey, organization));
  };

  return (
    <>
      <div className="flex justify-between ">
        <div className="flex flex-col">
          <label className="text-captionMedium dark:text-nx-gray-200 text-nx-gray-700">
            {t('key')} #{indexKey.toString().length < 2 && 0}{indexKey}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {apiKey.name}
          </span>
        </div>
        <NxDropElement
          options={[
            {
              icon: <IconDelete />,
              label: t("delete"),
              className: "w-[90px] ",
              onClick: () => {
                handleRemove();
              },
            },
          ]}
          dimension={{
            x: 0,
            y: 0,
          }}
        >
          <NxSVG className="w-5 h-5 cursor-pointer fill-nx-main-500">
            <IconMore />
          </NxSVG>
        </NxDropElement>
      </div>

      <div className="space-x-8 flex">
        <div className="flex flex-col">
          <label className="text-captionMedium text-nx-gray-200">
            {t('createdAt')}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {format(apiKey.createdAt, "dd/MM/yyyy")}
          </span>
        </div>

        <div className="flex flex-col">
          <label className="text-captionMedium text-nx-gray-200">
            {t('lastUse')}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {format(apiKey.usedAt, "dd/MM/yyyy")}
          </span>
        </div>

        <div className="flex flex-col">
          <label className="text-captionMedium text-nx-gray-200">
            {t('category')}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {t(apiKey.permission)}
          </span>
        </div>
      </div>

      <div className="space-x-4 flex items-center">
        <div className="flex flex-col">
          <label className="text-captionMedium text-nx-gray-200">
            {t('key')}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {apiKey.id}
          </span>
        </div>
        <button
          className="p-3 mt-4 rounded flex gap-2 text-captionLarge text-nx-main-500
                                  hover:bg-[#51ACFF33]
                                  "
          onClick={() => {
            navigator.clipboard.writeText(apiKey.id);
            setSnackbarCopyClipboard(true);
            setTimeout(() => {
              setSnackbarCopyClipboard(false);
            }, 3000);
          }}
        >
          {t('copy')} <IconCopy />
        </button>
      </div>


      <div className="space-x-4 flex items-center">
        <div className="flex flex-col">
          <label className="text-captionMedium text-nx-gray-200">
            {t('clientId')}
          </label>
          <span className="text-captionLarge dark:text-nx-white text-nx-gray-700">
            {apiKey.clientId}
          </span>
        </div>
        <button
          className="p-3 mt-4 rounded flex gap-2 text-captionLarge text-nx-main-500
                                  hover:bg-[#51ACFF33]
                                  "
          onClick={() => {
            navigator.clipboard.writeText(apiKey.clientId);
            setSnackbarCopyClipboard(true);
            setTimeout(() => {
              setSnackbarCopyClipboard(false);
            }, 3000);
          }}
        >
          {t('copy')} <IconCopy />
        </button>
      </div>

      <NxSnackbar
        showIcon={true}
        showSnackbar={snackbarCopyClipboard}
        type={NxSnackbarType.success}
        label={t('copiedKeySuccess')}
      />
    </>
  );
};

export default ApiKeyTbody;
