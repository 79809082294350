import { useCallback, useState } from "react";
import { LoginWithOAuthFailure } from "web/features/auth/domain/errors/LoginWithOAuthFailure";
import getUrlGoogleLoginUsecase from "web/features/auth/domain/usecase/GetUrlGoogleLoginUsecase";
import { loginOAuthUsecase } from "web/features/auth/domain/usecase/LoginOAuthUsecase";
export abstract class LoginOAuthState {}

export class LoginOAuthLoading extends LoginOAuthState {}

export class LoginOAuthSuccess extends LoginOAuthState {}

export class LoginOAuthError extends LoginOAuthState {}

export const useGoogleLogin = () => {
  const [loginOAuthState, setState] = useState<LoginOAuthState>();
  return {
    loginOAuthState,
    openGoogleLogin: () => {
      const url = getUrlGoogleLoginUsecase();
      window.open(url, "_self");
    },
    loginOAuth: useCallback(async () => {
      setState(new LoginOAuthLoading());
      const url = new URL(window.location.href);
      const code = url.searchParams.get("code");
      if (!code) return setState(new LoginOAuthError());
      const result = await loginOAuthUsecase(code);
      if (result instanceof LoginWithOAuthFailure) {
        return setState(new LoginOAuthError());
      }
      setState(new LoginOAuthSuccess());
    }, []),
  };
};
