import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { useEffect, useState } from "react";
import { IconSort } from "web/common/fonts/icons/components/IconSort";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { OrganizationEntity } from "../../../domain/entities/OrganizationEntity";
import {
  SortByName,
  SortByRole,
  SortByCategory,
  SortByDate,
  useSortOrganizationBy,
} from "../../hooks/UseSortOrganizationBy";
import { OrganizationTbody } from "../OrganizationTbody/OrganizationTbody";
import {
  GetOrganizationsFailure,
  GetOrganizationsLoading,
  GetOrganizationsSuccess,
  useGetOrganizationsHook,
} from "../../hooks/UseGetOrganizationsHook";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { ReloadableError } from "web/features/projects/presentation/components/ReloadableError";
import { useOrgModal } from "../../context/OrgModalProvider";
import { HideOrganizationModal } from "../../context/abstraction/OrganizationModal";
import { NoOrganizationsFound } from "../NoOrganizationsFound/NoOrganizationsFound";

export function OrganizationTable() {
  const { t } = useTranslation();
  const [content, setContent] = useState<OrganizationEntity[] | null>();
  const { sortState, sortedBy, organizations } = useSortOrganizationBy();
  const { getOrganizationState, getOrganizations } = useGetOrganizationsHook();
  const [searchText, setSearchText] = useState<string>("");
  const { modal } = useOrgModal();

  useEffect(() => {
    if (content === undefined) {
      setTimeout(() => {
        if (getOrganizationState instanceof GetOrganizationsSuccess) {
          setContent(getOrganizationState.organizations);
        }
        if (getOrganizationState instanceof GetOrganizationsFailure) {
          setContent(null);
        }
      }, 3000);
    }
  }, [getOrganizationState, content]);

  useEffect(() => {
    if (modal instanceof HideOrganizationModal) {
      if (modal.reloadOrgs) {
        setContent(undefined);
        getOrganizations();
        setSearchText("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    getOrganizationState instanceof GetOrganizationsLoading ||
    content === undefined
  ) {
    return (
      <div className="flex flex-col mt-[15%] m-auto">
        <CircularProgressBar
          className="stroke-nx-main-600"
          width={20}
          height={20}
        />
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("text-nx-gray-200")
            .build()}
        >
          {t("loadingData")}
        </label>
      </div>
    );
  }

  if (getOrganizationState instanceof GetOrganizationsFailure) {
    return (
      <div className="flex flex-col mt-[15%] m-auto">
        <ReloadableError
          onRefresh={() => {
            getOrganizations();
          }}
        />
      </div>
    );
  }

  const organizationsContent = content?.filter((organization) => {
    return organization.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <div className="w-full h-full">
      <div className="pl-6 mb-6 w-72">
        <NxFormField
          placeholder={t("organizationsSearchPlaceholder")}
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value)
          }
          prefix={
            <NxSVG className="stroke-nx-gray-400">
              <IconSearch />
            </NxSVG>
          }
        />
      </div>
      {content !== undefined && (
        <div>
          <div
            className={
              "pl-6 flex flex-row text-[10px] font-semibold text-nx-gray-200 uppercase"
            }
          >
            <div
              className="w-[24%] flex flex-row"
              onClick={() => {
                setContent(
                  organizations({
                    entity: content as OrganizationEntity[],
                    instance: new SortByName({
                      orderByAsc: !sortedBy.name || !sortState?.orderByAsc,
                    }),
                  })
                );
              }}
            >
              <label className="cursor-pointer">{t("firstName")}</label>
              {sortedBy.name && (
                <NxSVG
                  className={CSName(
                    "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                  )
                    .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                    .build()}
                >
                  <IconSort />
                </NxSVG>
              )}
            </div>
            <div
              className="w-[34.5%] flex flex-row"
              onClick={() => {
                setContent(
                  organizations({
                    entity: content as OrganizationEntity[],
                    instance: new SortByCategory({
                      orderByAsc: !sortedBy.category || !sortState?.orderByAsc,
                    }),
                  })
                );
              }}
            >
              <label className="cursor-pointer">{t("category")}</label>
              {sortedBy.category && (
                <NxSVG
                  className={CSName(
                    "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                  )
                    .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                    .build()}
                >
                  <IconSort />
                </NxSVG>
              )}
            </div>
            <div
              className="w-[19%] flex flex-row"
              onClick={() => {
                setContent(
                  organizations({
                    entity: content as OrganizationEntity[],
                    instance: new SortByRole({
                      orderByAsc: !sortedBy.role || !sortState?.orderByAsc,
                    }),
                  })
                );
              }}
            >
              <label className="cursor-pointer">{t("role")}</label>
              {sortedBy.role && (
                <NxSVG
                  className={CSName(
                    "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                  )
                    .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                    .build()}
                >
                  <IconSort />
                </NxSVG>
              )}
            </div>
            <div
              className="w-[18%] flex flex-row"
              onClick={() => {
                setContent(
                  organizations({
                    entity: content as OrganizationEntity[],
                    instance: new SortByDate({
                      orderByAsc: !sortedBy.date || !sortState?.orderByAsc,
                    }),
                  })
                );
              }}
            >
              <label className="cursor-pointer">{t("memberSince")}</label>
              {sortedBy.date && (
                <NxSVG
                  className={CSName(
                    "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                  )
                    .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                    .build()}
                >
                  <IconSort />
                </NxSVG>
              )}
            </div>
            <div className="mr-3 flex flex-row"></div>
          </div>
          {organizationsContent?.map((e, index) => {
            return (
              <OrganizationTbody
                key={index}
                data={e}
                showBorder={index + 1 < (content?.length as number)}
              />
            );
          })}
          {organizationsContent?.length === 0 && <NoOrganizationsFound />}
        </div>
      )}
    </div>
  );
}
