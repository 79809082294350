import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { AgentEntity } from "../entities/AgentEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { GetAllAgentsError } from "../errors/GetAllAgentsError";
import { GetAllAgentsParams } from "../params/GetAllAgentsParams";
import { CreateAgentParams } from "../params/CreateAgentParams";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { CreateAgentError } from "../errors/CreateAgentError";
import { EditAgentParams } from "../params/EditAgentParams";
import { EditAgentError } from "../errors/EditAgentError";
import { ArchiveAgentError } from "../errors/ArchiveAgentError";
import { RestoreAgentError } from "../errors/RestoreAgentError";
const AgentRepositoryImpl = {
  async getAll({ deleted }: GetAllAgentsParams) {
    const result = await networkAdapter.get<PaginationEntity<AgentEntity>>({
      path: "/core/entities/",
      params: {
        deleted,
      },
    });
    if (result instanceof NetworkError) {
      return new GetAllAgentsError();
    }
    const { data } = result;
    return PaginationEntity.fromJSON(data, (e) =>
      AgentEntity.fromJSON(e, deleted ?? false)
    );
  },

  async create(params: CreateAgentParams) {
    const result = await networkAdapter.post({
      path: "/core/entities/",
      body: params,
    });
    if (result instanceof NetworkError) {
      return new CreateAgentError();
    }
    return new EmptyEntity();
  },

  async edit(params: EditAgentParams) {
    const result = await networkAdapter.patch({
      path: `/core/entities/${params.id}/`,
      body: params,
    });
    if (result instanceof NetworkError) {
      return new EditAgentError();
    }
    return new EmptyEntity();
  },

  async archive(id: string) {
    const result = await networkAdapter.delete({
      path: `/core/entities/${id}/`,
    });
    if (result instanceof NetworkError) {
      return new ArchiveAgentError();
    }
    return new EmptyEntity();
  },

  async restore(id: string) {
    const result = await networkAdapter.post({
      path: `/core/entities/${id}/restore/`,
    });
    if (result instanceof NetworkError) {
      return new RestoreAgentError();
    }
    return new EmptyEntity();
  },
};

export default AgentRepositoryImpl;
