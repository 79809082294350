import { redirect } from "react-router-dom";
import { userIsLoggedUsecase } from "web/core/domain/usecase/UserIsLoggedUsecase";

export const authGuardLoader = () => {
  return () => {
    const userIsLogged = userIsLoggedUsecase();
    if (!userIsLogged) return redirect("/auth/login");
    return null;
  };
};
