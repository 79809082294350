export default class ManagerImages {
  static background = require("../../../assets/fundo.jpg");
  static logoGoogle = require("../../../assets/gmail_logo.png");
  static darkNexus = require("../../../assets/logo_dark.png");
  static lightNexus = require("../../../assets/logo_light.png");
  static carrousel1 = require("../../../assets/carrousel-1.png");
  static carrousel2 = require("../../../assets/carrousel-2.png");
  static carrousel3 = require("../../../assets/carrousel-3.png");
  static dive = require("../../../assets/dive.png");
  static defaultImagePanel = require("../../../assets/default-image-panel.png");
}
