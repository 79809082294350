import { createContext, useContext } from "react";

import { AppState, AppSuccess, useAppHook } from "../hooks/UseAppHook";
import { SelectCurrentOrgParams } from "web/features/organizations/domain/params/SelectCurrentOrgParams";

type AppContextType = {
  appState: AppState;
  fetchInitialData: () => void;
  updateState: (state: Partial<AppSuccess>) => void;
  changeOrg: (org: SelectCurrentOrgParams) => Promise<void>;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export function useAppContext() {
  return useContext(AppContext);
}

export function AppProvider(props: any) {
  const { appState, fetchInitialData, updateState, changeOrg } = useAppHook();

  return (
    <AppContext.Provider
      value={{
        appState,
        fetchInitialData,
        updateState,
        changeOrg,
      }}
    >
      <div className="w-full h-full">{props.children}</div>
    </AppContext.Provider>
  );
}
