import { createContext, useContext, useState } from "react";
import { HomeNavQuickSearchModal } from "./HomeNavQuickSearchModal";

interface HomeNavQuickSearchModalModalContextProps {
  showModal: () => void;
}

export const HomeNavQuickSearchModalModalContext =
  createContext<HomeNavQuickSearchModalModalContextProps>(
    {} as HomeNavQuickSearchModalModalContextProps
  );

export function useHomeNavQuickSearchModal() {
  return useContext(HomeNavQuickSearchModalModalContext);
}

export const QuickSearchModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HomeNavQuickSearchModalModalContext.Provider
      value={{ showModal: () => setIsOpen(true) }}
    >
      {children}
      <HomeNavQuickSearchModal
        isOpen={isOpen}
        onClosedTapped={() => setIsOpen(false)}
      />
    </HomeNavQuickSearchModalModalContext.Provider>
  );
};
