import { IconDrawCircle } from "web/common/fonts/icons/components/IconDrawCirlce";
import { LocationsCreateModeButton } from "./LocationsCreateModeButton";
import { MapDrawModes } from "web/common/components/Maps/types/MapDrawModes";
import { IconDrawGPS } from "web/common/fonts/icons/components/IconDrawGPS";
import { IconDrawArea } from "web/common/fonts/icons/components/IconDrawArea";
import { t } from "i18next";

interface LocationsCreateFooterProps {
  mode: MapDrawModes;
  onModeChange: (mode: MapDrawModes) => void;
}

export const LocationsCreateFooter = ({
  mode,
  onModeChange,
}: LocationsCreateFooterProps) => {
  return (
    <footer className="flex flex-row rounded h-10 w-full">
      <LocationsCreateModeButton
        className="rounded-l"
        isSelect={mode === MapDrawModes.circlemarker}
        label={t('pointMarkerMap')}
        icon={<IconDrawGPS />}
        onClick={() => {
          if (mode !== MapDrawModes.circlemarker)
            onModeChange(MapDrawModes.circlemarker);
        }}
      />
      <LocationsCreateModeButton
        isSelect={mode === MapDrawModes.polygon}
        label={t('polygonMarkerMap')}
        icon={<IconDrawArea />}
        onClick={() => {
          if (mode !== MapDrawModes.polygon) onModeChange(MapDrawModes.polygon);
        }}
      />
      <LocationsCreateModeButton
        className="rounded-r"
        isSelect={mode === MapDrawModes.circle}
        label={t('circleMarkerMap')}
        icon={<IconDrawCircle />}
        onClick={() => {
          if (mode !== MapDrawModes.circle) onModeChange(MapDrawModes.circle);
        }}
      />
    </footer>
  );
};
