export const IconLocation = () => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="fill"
      stroke="stroke"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 15C17.0809 15.2147 18 15.7875 18 16.4561C18 17.3088 14.4183 18 10 18C5.58172 18 2 17.3088 2 16.4561C2 15.7838 2.89282 15.2119 6 15"
        stroke="stroke"
        fill="none"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.23858 2 5 4.19381 5 6.9C5 10.05 7.14286 13.9 10 16C12.8571 13.9 15 10.05 15 6.9C15 4.19381 12.7614 2 10 2ZM9.94118 8.3C10.7209 8.3 11.3529 7.6732 11.3529 6.9C11.3529 6.1268 10.7209 5.5 9.94118 5.5C9.16148 5.5 8.52941 6.1268 8.52941 6.9C8.52941 7.6732 9.16148 8.3 9.94118 8.3Z"
        fill="fill"
        stroke="none"
      />
    </svg>
  );
};
