import { useState } from "react";
import { createAgentUsecase } from "../../domain/usecase/CreateAgentUsecase";
import { CreateAgentParams } from "../../domain/params/CreateAgentParams";
import { CreateAgentError } from "../../domain/errors/CreateAgentError";

abstract class CreateAgentState {}

export class CreateAgentLoading extends CreateAgentState {}

export class CreateAgentSuccess extends CreateAgentState {}

export class CreateAgentFailure extends CreateAgentState {}

export const useCreateAgentHook = () => {
  const [createAgentState, setState] = useState<CreateAgentState>();

  return {
    createAgentState,
    createAgent: async (params: CreateAgentParams) => {
      setState(new CreateAgentLoading());
      const result = await createAgentUsecase(params);

      if (result instanceof CreateAgentError) {
        return setState(new CreateAgentFailure());
      }

      return setState(new CreateAgentSuccess());
    },
  };
};
