import i18n from "locale/index.locale";

export const useBrowserLanguage = () => {
  return {
    browserLanguage: i18n.language,
    changeLanguage: (language: string) => {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language);
    },
    allLanguages: ["pt", "en", "es"],
  };
};
