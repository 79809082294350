import { useState } from "react";
import { CreateApiKeyParams } from "../../domain/params/CreateApiKeyParams";
import { createApiKeyUsecase } from "../../domain/usecase/CreateApiKeyUsecase";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";

abstract class CreateApiKeyState {}

export class CreateApiKeySuccess extends CreateApiKeyState {}

export class CreateApiKeyFailure extends CreateApiKeyState {}

export class CreateApiKeyLoading extends CreateApiKeyState {}

export const useCreateApiKeyHook = () => {
    const [createApiKeyState, setState] = useState<CreateApiKeyState>();
    return {
        createApiKeyState: {
            isLoading: createApiKeyState instanceof CreateApiKeyLoading,
            isSuccess: createApiKeyState instanceof CreateApiKeySuccess,
            isError: createApiKeyState instanceof CreateApiKeyFailure,
          },
        createApiKey: async ({name, permission, org}: CreateApiKeyParams) => {
            setState(new CreateApiKeyLoading())
            const result = await createApiKeyUsecase({name, permission, org});
            if(result instanceof NexusFailure){
                return setState(new CreateApiKeyFailure());
            }
            setState(new CreateApiKeySuccess())
        }
    }
};
