import { t } from "i18next";
import { NxTHeader } from "web/common/components/NxTable/NxTHeader/NxTHeader";
import { useEffect, useState } from "react";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { NxSVG } from "web/common/components/NxSvg";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import {
  GetProjectsLoading,
  GetProjectsSuccess,
  useGetProjectsHook,
} from "../../hooks/UseGetProjectsHook";
import {
  ProjectsTbody,
  ProjectTbodyController,
} from "../ProjectsTbody/ProjectsTbody";
import { useProjectModal } from "../../context/ProjectModalProvider";
import { HideProjectModal } from "../../context/abstraction/ProjectsModal";
import { useAppContext } from "web/features/app/context/AppContext";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { NoProjectsFound } from "../NoProjectFound";
import { ReloadableError } from "../ReloadableError";
import {
  RestoreProjectLoading,
  RestoreProjectSuccess,
  useRestoreProjectHook,
} from "../../hooks/UseRestoreProjectHook";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { NxSnackbar } from "../../../../../common/components/NxSnackbar/NxSnackbar";
import {
  SortByDate,
  SortByMembers,
  SortByName,
  SortByPanels,
  useSortProjectBy,
} from "../../hooks/UseSortProjectBy";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";
import { SortEntity } from "web/core/domain/entities/SortEntity";

export type ProjectsTypes = {
  state?: SortEntity;
  archived: ProjectEntity[];
  restored: ProjectEntity[];
};

export function ProjectsTable() {
  const { getProjectState, getProjects } = useGetProjectsHook();
  const { restoreProjectState, restoreProject } = useRestoreProjectHook();

  const { sortState, sortedBy, setState, projectsParams } = useSortProjectBy();
  const [controller, setController] = useState<ProjectTbodyController>({
    isRestoring: undefined,
    project: undefined,
    searchText: "",
  });
  const [projects, setProjects] = useState<ProjectsTypes>({
    archived: [],
    restored: [],
  });
  const { modal, setModal } = useProjectModal();
  const { updateState } = useAppContext();

  useEffect(() => {
    if (modal instanceof HideProjectModal) {
      setController({
        isRestoring: undefined,
        project: undefined,
        searchText: "",
      });
      if (modal.reloadProjects) {
        getProjects();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getProjectState instanceof GetProjectsSuccess) {
      updateState({
        projects: getProjectState.projects,
      });

      setProjects({
        archived: getProjectState.projectsArchived.filter((project) => {
          return project
            .name!.toLowerCase()
            .includes(controller!.searchText.toLowerCase());
        }),
        restored: getProjectState.projects.filter((project) => {
          return project
            .name!.toLowerCase()
            .includes(controller!.searchText.toLowerCase());
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectState]);

  useEffect(() => {
    if (restoreProjectState instanceof RestoreProjectSuccess) {
      getProjects();
    }
    if (restoreProjectState instanceof RestoreProjectLoading) {
    }
    if (restoreProjectState instanceof RestoreProjectSuccess) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreProjectState]);

  useEffect(() => {
    if (sortState !== undefined && sortState !== projects.state) {
      let lists = projectsParams({
        instance: sortState,
        projects: {
          archived: projects?.archived,
          restored: projects?.restored,
        },
      });
      setProjects({
        state: sortState,
        archived: lists.archived!,
        restored: lists.restored!,
      });
    }
  }, [
    sortState,
    projectsParams,
    projects.state,
    projects?.archived,
    projects?.restored,
  ]);

  if (getProjectState instanceof GetProjectsLoading) {
    return (
      <div className="flex flex-col mt-[15%] m-auto">
        <CircularProgressBar
          className="stroke-nx-main-600"
          width={20}
          height={20}
        />
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("text-nx-gray-200")
            .build()}
        >
          {t("loadingData")}
        </label>
      </div>
    );
  }
  if (getProjectState instanceof GetProjectsSuccess) {
    return (
      <div className="flex flex-col  max-h-full">
        <div className="pl-6 mb-6 w-72">
          <NxFormField
            placeholder={t("projectsSearchPlaceholder")}
            value={controller.searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setController({
                isRestoring: controller.isRestoring,
                project: controller.project,
                searchText: e.target.value,
              })
            }
            prefix={
              <NxSVG className="stroke-nx-gray-400">
                <IconSearch />
              </NxSVG>
            }
          />
        </div>
        <div className="w-full">
          <NxTHeader
            params={[
              {
                label: t("firstName"),
                onClick: function (): void {
                  setState(
                    new SortByName({
                      orderByAsc: !sortedBy.name || !sortState?.orderByAsc,
                    })
                  );
                },
                className: "w-[24%]",
                isSorting: false,
                isOrderByAsc: false,
              },
              {
                label: t("membersWithAccess"),
                onClick: function (): void {
                  setState(
                    new SortByMembers({
                      orderByAsc: !sortedBy.members || !sortState?.orderByAsc,
                    })
                  );
                },
                className: "w-[23%]",
                isSorting: false,
                isOrderByAsc: false,
              },
              {
                label: t("associatedPanels"),
                onClick: function (): void {
                  setState(
                    new SortByPanels({
                      orderByAsc: !sortedBy.panels || !sortState?.orderByAsc,
                    })
                  );
                },
                className: "w-[30%]",
                isSorting: sortedBy.panels,
                isOrderByAsc: sortState?.orderByAsc,
              },
              {
                label: t("creationDate"),
                onClick: function (): void {
                  setState(
                    new SortByDate({
                      orderByAsc: !sortedBy.date || !sortState?.orderByAsc,
                    })
                  );
                },
                className: "w-[18%] text-start",
                isSorting: false,
                isOrderByAsc: false,
              },
              {
                label: "",
                onClick: function (): void {},
                className: "",
                isSorting: false,
              },
            ]}
          />
        </div>
        <div className="w-full max-h-full overflow-y-auto">
          {(projects!.restored.length > 0 || projects!.archived.length > 0) && (
            <>
              <>
                {projects!.restored.map((project, index) => {
                  return (
                    <ProjectsTbody
                      key={project.id}
                      project={project}
                      showBorder={index + 1 < projects!.restored.length}
                      onClick={() => {}}
                      controller={controller}
                      setController={setController}
                      modal={modal}
                      setModal={setModal}
                    />
                  );
                })}
              </>

              <>
                {projects!.archived.length > 0 && (
                  <>
                    <div className="flex flex-row mt-8 ml-6">
                      <NxSVG className="fill-nx-gray-400 w-5 h-5">
                        <IconArchive />
                      </NxSVG>
                      <label
                        className={CSName(FontStyles.bodyLargeBold)
                          .combine("text-nx-gray-600 dark:text-nx-white ml-2")
                          .build()}
                      >
                        {t("archived")}
                      </label>
                    </div>
                    {projects!.archived.map((project, index) => {
                      return (
                        <ProjectsTbody
                          key={index}
                          project={project}
                          showBorder={index + 1 < projects!.archived.length}
                          onClick={() => {}}
                          controller={controller}
                          setController={setController}
                          modal={modal}
                          setModal={setModal}
                          onRestore={() => {
                            restoreProject(project.id!);
                          }}
                        />
                      );
                    })}
                  </>
                )}
              </>
            </>
          )}

          {projects!.restored.length === 0 &&
            projects!.archived.length === 0 && <NoProjectsFound />}
          <div className="h-40"></div>
        </div>
        <NxSnackbar
          label={t("restoring")}
          showIcon={true}
          time={60000}
          showSnackbar={restoreProjectState instanceof RestoreProjectLoading}
          type={NxSnackbarType.loading}
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col mt-[15%] m-auto">
      <ReloadableError
        onRefresh={() => {
          getProjects();
        }}
      />
    </div>
  );
}
