import { useContext } from "react";
import { Color } from "../../../../common/fonts/Colors";
import { VisualContext } from "../../../../core/context/VisualContext";
import { LabelStyles } from "../../../../common/fonts/FontStyles";
import CircularProgressBar from "../../../../common/components/ProgressBar/CircularProgressBar";
import { t } from "i18next";

export function LoadingAppPage() {
  const { isDarkMode } = useContext(VisualContext);

  const currentCategory = JSON.parse(
    localStorage.getItem("currentOrganization") as string
  );

  return (
    <div
      className={`w-screen h-lvh grid grid-cols-1 gap-4 place-content-center ${
        isDarkMode ? "bg-nx-dark-1100" : "bg-nx-white"
      }`}
    >
      <CircularProgressBar className="stroke-nx-main-600" />
      <label className={`pb-${currentCategory === null ? '16': '0'} pt-6 text-xs dark:text-nx-gray-300 text-nx-gray-600 text-center`}>
        {t("loadingDataPlatform")}
      </label>
      {currentCategory !== null && (
        <label className="pb-16 text-base font-semibold text-nx-main-200 text-center">
          {currentCategory.orgName}
        </label>
      )}

      <div
        className={`place-self-center w-96 rounded grid grid-rows-0 place-content-center p-4 ${
          isDarkMode ? "bg-nx-dark-1200" : "bg-nx-gray-50"
        } `}
      >
        <label
          className="w-full"
          style={{
            ...LabelStyles.caption,
            color: isDarkMode ? Color.gray400 : Color.gray500,
          }}
        >
          {t("describeLoadingAppPage")}
        </label>
      </div>
    </div>
  );
}
