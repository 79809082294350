export const WidgetMock = {
  status: 200,
  data: {
    count: 10,
    next: null,
    previous: null,
    results: [
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        used_at: "2024-02-09T18:11:34.083605Z",
        name: "Trabalho análogo a escravidão",
      },
      {
        id: "48694476-8420-4bdb-8929-6ea7efc9d6f1",
        used_at: "2024-02-09T18:03:03.083605Z",
        name: "Produtividade",
      },
      {
        id: "1be6ab53-1913-4925-8143-fc9a1a69bbf9",
        used_at: "2024-02-08T14:14:09.083605Z",
        name: "Acumulado de chuva",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        used_at: "2024-02-09T18:11:34.083605Z",
        name: "Trabalho análogo a escravidão",
      },
      {
        id: "48694476-8420-4bdb-8929-6ea7efc9d6f1",
        used_at: "2024-02-09T18:03:03.083605Z",
        name: "Produtividade",
      },
      {
        id: "1be6ab53-1913-4925-8143-fc9a1a69bbf9",
        used_at: "2024-02-08T14:14:09.083605Z",
        name: "Acumulado de chuva",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        used_at: "2024-02-09T18:11:34.083605Z",
        name: "Trabalho análogo a escravidão",
      },
      {
        id: "48694476-8420-4bdb-8929-6ea7efc9d6f1",
        used_at: "2024-02-09T18:03:03.083605Z",
        name: "Produtividade",
      },
      {
        id: "1be6ab53-1913-4925-8143-fc9a1a69bbf9",
        used_at: "2024-02-08T14:14:09.083605Z",
        name: "Acumulado de chuva",
      },
      {
        id: "2846a6a2-390b-47be-84a2-156f89fd98f7",
        used_at: "2024-02-09T18:11:34.083605Z",
        name: "Trabalho análogo a escravidão",
      }
    ],
  },
};
