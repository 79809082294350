import { createContext, useContext, useState } from "react";

interface ChangeOrgType {
  showModal: boolean;
  setVisibility: (showModal: boolean) => void;
}

export const ChangeOrgModalContext = createContext<ChangeOrgType>({
    showModal: false,
    setVisibility: (showModal: boolean) => {},
});

export function useChangeOrgModal(){
  return useContext(ChangeOrgModalContext);
};

export function ChangeOrgModalProvider(props: any) {
  const [showModal, setVisibility] = useState(false);

  return (
    <ChangeOrgModalContext.Provider value={{ showModal, setVisibility }}>
      {props.children}
    </ChangeOrgModalContext.Provider>
  );
}
