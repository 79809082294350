import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { UploadEntity } from "../entities/UploadEntity";
import { UploadFailed } from "../errors/UploadFailed";

export class UploadRepository {
  async getUploadUrl({
    fileName,
    folder,
    file,
  }: Partial<UploadEntity>): Promise<string | UploadFailed> {
    const response = await networkAdapter.post({
      path: "/s3uploads/generate-presigned-url/",
      body: {
        file_name: fileName,
        file_type: "image/jpeg",
        folder: folder,
      },
    });
    if (response instanceof NetworkError) {
      return new UploadFailed();
    }
    const result = await this.uploadImage({
      fileUrl: (response.data as any).url,
      file: file,
    });

    if (!result) {
      return new UploadFailed();
    }
    return (response.data as any).key;
  }

  private async uploadImage({
    fileUrl,
    file,
  }: Partial<UploadEntity>): Promise<boolean> {
    const response = await networkAdapter.put({
      baseUrl: fileUrl,
      path: "",
      headers: {"Content-Type": "image/jpeg"},
      body: file,
      needToken: false,
    });
    if (response instanceof NetworkError) return false;
    return response.status === 200;
  }
}