import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { CreatePanelParams } from "../params/CreatePanelParams ";
import { createPanelFailure } from "../errors/CreatePanelFailure";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { updatePanelFailure } from "../errors/UpdatePanelFailure";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { PanelEntity } from "../entities/PanelEntity";
import { getPanelsFailure } from "../errors/GetPanelsFailure";
import { ArchivePanelFailure } from "../errors/ArchivePanelFailure";

export const PanelRepository = {
  async getPanels(deleted: boolean) {
    const result = await networkAdapter.get<PaginationEntity<PanelEntity>>({
      path: "/core/dashboards/",
      params: {
        deleted,
      },
    });

    if (result instanceof NetworkError) {
      return new getPanelsFailure();
    }
    return PaginationEntity.fromJSON(result.data, (e) => {
      return PanelEntity.fromJSON(e);
    });
  },
  async createPanel(params: Partial<CreatePanelParams>) {
    return networkAdapter
      .post({
        path: "/core/dashboards/",
        body: {
          name: params.name,
          project: params.project,
          widget_ids: params.widgets ?? [],
          image_s3_key: params.s3Key,
        },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new createPanelFailure();
        }
        return new EmptyEntity();
      });
  },
  async archivePanel(id: string): Promise<ArchivePanelFailure | EmptyEntity> {
    const result = await networkAdapter.delete({
      path: `/core/dashboards/${id}/`,
    });
    if (result instanceof NetworkError) {
      return new ArchivePanelFailure();
    }
    return new EmptyEntity();
  },
  async restorePanel(id: string): Promise<ArchivePanelFailure | EmptyEntity> {
    const result = await networkAdapter.post({
      path: `/core/dashboards/${id}/restore/`,
    });
    if (result instanceof NetworkError) {
      return new ArchivePanelFailure();
    }
    return new EmptyEntity();
  },
  async updatePanel(
    params: Partial<CreatePanelParams>
  ): Promise<updatePanelFailure | EmptyEntity> {
    return networkAdapter
      .patch({
        path: `/core/dashboards/${params.id}/`,
        body: {
          name: params.name,
          image_s3_key: params.s3Key,
          description: params.description,
          widgets: params.widgets,
        },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new updatePanelFailure();
        }
        return new EmptyEntity();
      });
  },
};
