import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxSVG } from "web/common/components/NxSvg";
import ManagerImages from "web/common/fonts/ManagerImages";
import { IconSetting } from "web/common/fonts/icons/components/IconSetting";
import { VisualContext } from "web/core/context/VisualContext";
import {
  useOrgModal,
} from "web/features/organizations/presentation/context/OrgModalProvider";
import { ShowCreateOrEditOrganizationModal } from "web/features/organizations/presentation/context/abstraction/OrganizationModal";

type Params = {
  onSuccess: ()=>void;
}

export const OrganizationActioRequiredPage = ({onSuccess}: Partial<Params>) => {
  const { setModal } = useOrgModal();
  const { isDarkMode } = useContext(VisualContext);
  const { t } = useTranslation();

  return (
    
      <div className="fixed flex flex-col justify-center items-center top-0 left-0 h-full w-full bg-nx-white dark:bg-nx-dark-1100 z-[1]">
        <img
          src={isDarkMode ? ManagerImages.darkNexus : ManagerImages.lightNexus}
          className="w-28 mb-2"
          alt=""
        />
        <div className="w-96 p-4 rounded-lg bg-nx-gray-50 dark:bg-nx-dark-1200">
          <h4 className="text-base font-semibold text-nx-dark-800 dark:text-nx-main-200">
            {t("actionRequired")}
          </h4>
          <h5
            mt-2
            className="text-caption font-semibold text-nx-gray-400 dark:text-nx-gray-200 "
          >
            {t("actionRequiredDesc")}
          </h5>
          <div className="flex flex-col w-full gap-4 mt-6">
            <NxFilledButton
              className="h-10"
              onClick={() => {
                setModal(new ShowCreateOrEditOrganizationModal({onSuccess: onSuccess}));
              }}
            >
              {t("createOrg")}
            </NxFilledButton>
            <NxOutlinedButton className="w-full text-center text-nx-main-500 text-xs cursor-pointer ">
              <div className="flex flex-row w-full items-center justify-center gap-2 ">
                <span>{t("manageAccount")}</span>
                <NxSVG className="w-5 h-5 fill-nx-main-500">
                  <IconSetting />
                </NxSVG>
              </div>
            </NxOutlinedButton>
          </div>
        </div>
      </div>
  );
};
