import { createContext, useContext, useState } from "react";
import { AgentsModal, HideAgentModal } from "./abstraction/AgentsModal";

interface AgentsModalType {
  modal: AgentsModal;
  setModal: (modal: AgentsModal) => void;
}

export const AgentsModalContext = createContext<AgentsModalType>({
  modal: new HideAgentModal(),
  setModal: (modal: AgentsModal) => {},
});

export function useAgentsModal() {
  return useContext(AgentsModalContext);
}

export function AgentsModalProvider(props: any) {
  const [modal, changeModal] = useState<AgentsModal>(new HideAgentModal());

  const setModal = (newModal: AgentsModal) => {
    changeModal(newModal);
  };

  return (
    <AgentsModalContext.Provider value={{ modal, setModal }}>
      {props.children}
    </AgentsModalContext.Provider>
  );
}
