import { useCallback, useEffect, useState } from "react";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { CSName } from "web/common/utils/ClassName";
import { useOrgModal } from "../../context/OrgModalProvider";
import {
  HideOrganizationModal,
  ShowCreateOrEditOrganizationModal,
  ShowRemoveApiKeyModal,
} from "../../context/abstraction/OrganizationModal";
import { ApiKeyEntity } from "web/features/organizations/domain/entities/ApiKeyEntity";
import { CreateOrgParams } from "web/features/auth/domain/params/CreateOrgParams";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { t } from "i18next";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { useDeleteApiKeyHook } from "../../hooks/UseDeleteApiKeyHook";
interface RemoveApiKeyModalProps {
  org?: Partial<CreateOrgParams>;
  apiKey: ApiKeyEntity;
}

const RemoveApiKeyModal = ({ apiKey, org }: RemoveApiKeyModalProps) => {
  const { setModal, modal } = useOrgModal();

  const [showRemoveSnackbar, setShowRemoveSnackbar] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { showSnackbar } = useNxSnackBarContext();

  const { deleteApiKey, deleteApiKeyState } = useDeleteApiKeyHook();

  const openModalOrg = useCallback(() => {
    setModal(
      new ShowCreateOrEditOrganizationModal({
        createOrgParams: {
          id: org?.id,
          name: org?.name,
          category: org?.category,
          s3Key: org?.s3Key,
        },
      })
    );
  }, [org?.category, org?.id, org?.name, org?.s3Key, setModal]);

  const handleRemoveApiKey = () => {
    deleteApiKey({ id: apiKey.id, org: org?.id as string });
    setIsLoading(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = (reloadOrgs: boolean) => {
    setModal(new HideOrganizationModal({ reloadOrgs }));
  };

  useEffect(() => {
    if (deleteApiKeyState.isSuccess) {
      setTimeout(() => {
        showSnackbar({
          type: NxSnackbarType.success,
          label: t('deleteKeySuccess'),
        });
        setTimeout(() => {
          setIsLoading(false);
          openModalOrg();
          setShowRemoveSnackbar(false);
        }, 1000);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, showSnackbar, deleteApiKeyState.isSuccess]);

  useEffect(() => {
    if (deleteApiKeyState.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: t('messageErrorDeleteApiKey'),
      });
    }
  }, [closeModal, showSnackbar, deleteApiKeyState.isError]);

  return (
    <div>
      <NxModal
        showModal={modal instanceof ShowRemoveApiKeyModal}
        onOutsideClick={() => {
          closeModal(false);
        }}
      >
        <div className="grid grid-rows-1 pb-6 w-96">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-main-200 text-start pb-1")
              .build()}
          >
            {t("deleteKeyAPI")}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {t("wantToDelete")} {apiKey.name}? <br />
            {t("irreversibleAction")}
          </label>
        </div>
        <div className="pb-4">
          <NxOutlinedButton
            className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center rounded"
            onClick={() => {
              closeModal(false);
            }}
          >
            <button className="cursor-pointer text-captionMedium text-nx-main-500">
              {t("cancel")}
            </button>
          </NxOutlinedButton>
        </div>

        <div className="pb-4">
          <NxFilledButton
            backgroundColor="bg-nx-danger-600"
            isLoading={isLoading}
            className="w-full px-2 py-3 flex gap-2 place-content-center items-center rounded"
            onClick={() => handleRemoveApiKey()}
          >
            <label className="cursor-pointer text-captionMedium">
              {t("delete")}
            </label>

            <NxSVG className=" group-hover:fill-nx-white fill-nx-white w-4 h-4 ">
              <IconDelete />
            </NxSVG>
          </NxFilledButton>
        </div>
      </NxModal>

      <NxSnackbar
        showIcon={true}
        showSnackbar={showRemoveSnackbar}
        type={NxSnackbarType.success}
        label={t("deleteKeySuccess")}
      />
    </div>
  );
};

export default RemoveApiKeyModal;
