export const IconArrowLogout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
      <path
        d="M7.47982 18.5H3.92426C3.45277 18.5 3.00058 18.3127 2.66718 17.9793C2.33379 17.6459 2.14648 17.1937 2.14648 16.7222V4.27778C2.14648 3.80628 2.33379 3.3541 2.66718 3.0207C3.00058 2.6873 3.45277 2.5 3.92426 2.5H7.47982"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7021 14.9446L18.1466 10.5001L13.7021 6.05566"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1466 10.5H7.47998"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
