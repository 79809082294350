export const IconDropSelect = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3999 7.99727L6.3999 11.1973L6.3999 4.79727L10.3999 7.99727Z" />
    </svg>
  );
};
