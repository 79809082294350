import styled from "styled-components";

export const PrimaryButton = styled.button<{
  $width?: number;
  $height?: number | string;
}>`
  background: #0084ff;
  border-radius: 4px;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 12px;
  padding: 12px 8px 12px, 8px;
  ${(props) => props.$width && `width: ${props.$width}%`};
  ${(props) => props.$height && `height: ${props.$height}`};
  color: white;
`;
