import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { ApiKeyEntity } from "../../domain/entities/ApiKeyEntity";
import { getApiKeysUsecase } from "../../domain/usecase/GetApiKeysUsecase";

abstract class GetApiKeysState {}

export class GetApiKeysLoading extends GetApiKeysState {}

export class GetApiKeySuccess extends GetApiKeysState {
  apiKeys: ApiKeyEntity[];

  constructor({ apiKeys }: Required<GetApiKeySuccess>) {
    super();
    this.apiKeys = apiKeys;
  }
}

export class GetApiKeysFailure extends GetApiKeysState {}

export const useGetApiKeysHook = () => {
  const [getApiKeysState, setState] = useState<GetApiKeysState>();

  return {
    getApiKeysState,
    getApiKeys: async (org: string) => {
      setState(new GetApiKeysLoading());

      const resultPagination = await getApiKeysUsecase(org);

      if (resultPagination instanceof PaginationEntity) {
        return setState(
          new GetApiKeySuccess({
            apiKeys: resultPagination.results,
          })
        );
      }

      return setState(new GetApiKeysFailure());
    },
  };
};
