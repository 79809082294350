export const IconOpenedEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <mask id="path-1-inside-1_472_21909" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1465 7.5C6.72804 7.5 3.74335 9.30993 2.14648 12.0001C3.74336 14.6902 6.72802 16.5001 10.1465 16.5001C13.565 16.5001 16.5496 14.6901 18.1465 12C16.5496 9.30989 13.565 7.5 10.1465 7.5ZM10.1465 15C11.8296 15 13.1941 13.6569 13.1941 12C13.1941 10.3432 11.8296 9.00001 10.1465 9.00001C8.46334 9.00001 7.09888 10.3432 7.09888 12C7.09888 13.6569 8.46334 15 10.1465 15Z"
        />
      </mask>
      <path
        d="M2.14648 12.0001L1.28657 11.4896L0.983572 12.0001L1.28658 12.5105L2.14648 12.0001ZM18.1465 12L19.0064 12.5104L19.3094 12L19.0064 11.4895L18.1465 12ZM3.0064 12.5105C4.42613 10.1188 7.08688 8.5 10.1465 8.5V6.5C6.36919 6.5 3.06056 8.50109 1.28657 11.4896L3.0064 12.5105ZM10.1465 15.5001C7.08687 15.5001 4.42614 13.8813 3.00639 11.4896L1.28658 12.5105C3.06058 15.499 6.36918 17.5001 10.1465 17.5001V15.5001ZM17.2866 11.4896C15.8669 13.8813 13.2061 15.5001 10.1465 15.5001V17.5001C13.9238 17.5001 17.2324 15.499 19.0064 12.5104L17.2866 11.4896ZM10.1465 8.5C13.2061 8.5 15.8668 10.1187 17.2866 12.5104L19.0064 11.4895C17.2324 8.50106 13.9238 6.5 10.1465 6.5V8.5ZM12.1941 12C12.1941 13.0898 11.2922 14 10.1465 14V16C12.367 16 14.1941 14.2239 14.1941 12H12.1941ZM10.1465 10C11.2922 10 12.1941 10.9102 12.1941 12H14.1941C14.1941 9.77609 12.367 8.00001 10.1465 8.00001V10ZM8.09888 12C8.09888 10.9102 9.00072 10 10.1465 10V8.00001C7.92595 8.00001 6.09888 9.77609 6.09888 12H8.09888ZM10.1465 14C9.00072 14 8.09888 13.0898 8.09888 12H6.09888C6.09888 14.2239 7.92595 16 10.1465 16V14Z"
        fill="#667C8E"
        mask="url(#path-1-inside-1_472_21909)"
      />
      <path
        d="M11.6465 12C11.6465 12.8284 10.9749 13.5 10.1465 13.5C9.31806 13.5 8.64648 12.8284 8.64648 12C8.64648 11.1716 9.31806 10.5 10.1465 10.5C10.9749 10.5 11.6465 11.1716 11.6465 12Z"
        fill="#667C8E"
      />
      <path
        d="M3.14648 6L4.14648 7.99998"
        stroke="#667C8E"
        strokeLinecap="round"
      />
      <path
        d="M17.1465 6L16.1465 7.99998"
        stroke="#667C8E"
        strokeLinecap="round"
      />
      <path d="M10.1465 6.5V4.5" stroke="#667C8E" strokeLinecap="round" />
    </svg>
  );
};
