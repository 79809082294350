import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { GetAllAgentsError } from "../errors/GetAllAgentsError";
import AgentRepositoryImpl from "../repository/AgentRepository";
import { AgentEntity } from "../entities/AgentEntity";
import { GetAllAgentsParams } from "../params/GetAllAgentsParams";

export function getAllAgentsUsecase(
  params: GetAllAgentsParams
): Promise<GetAllAgentsError | PaginationEntity<AgentEntity>> {
  return AgentRepositoryImpl.getAll(params);
}
