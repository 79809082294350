import { useEffect, useState } from "react";
import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { t } from "i18next";
import { useLocationsModal } from "../../context/LocationsModalProvider";
import { HideLocationModal } from "../../context/abstraction/LocationModal";
import { ArchivingLocation, LocationArchived, LocationArchivingFailure, useArchiveLocationHook } from "../../hooks/UseArchiveLocationHook";

type ArchiveParams = {
  id: string;
  isOpen: boolean
}

export function ArchiveLocationModal({ id, isOpen }: ArchiveParams) {
  const [showSnackBar, setSnackbar] = useState<boolean>();
  const { archiveLocationState, archiveLocation } = useArchiveLocationHook();
  const { setModal } = useLocationsModal();

  useEffect(() => {
    if(showSnackBar !== undefined){
      if(archiveLocationState instanceof LocationArchived){
        setTimeout(() => {
          setModal(new HideLocationModal({reload: true}));
        }, 3000);
      }
      if(archiveLocationState instanceof LocationArchivingFailure){
        setModal(new HideLocationModal({}));
        setSnackbar(true);
        setTimeout(() => {
          setSnackbar(undefined);
        }, 3000);
      }
    }
  }, [showSnackBar, setModal, archiveLocationState]);

  return (
    <div>
      {" "}
      <NxModal
        showModal={isOpen}
        onOutsideClick={() => {
          setModal(new HideLocationModal({}));
        }}
      >
        <div className="grid grid-rows-1 pb-6 w-96">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-main-200 text-start pb-1")
              .build()}
          >
            {t('archivedLocation')}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {t('archivedLocationDescription')}
          </label>
        </div>
        <div className="pb-2">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => {
              setModal(new HideLocationModal({}));
            }}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              {t("maintain")}
            </label>
          </PrimaryButton>
        </div>
        <SecondaryButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            setSnackbar(true);
            archiveLocation(id ?? "");
          }}
        >
          {!(archiveLocationState instanceof ArchivingLocation) ? (
            <div className="flex justify-center">
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("text-nx-danger-600 cursor-pointer pr-2")
                  .build()}
              >
                {t("archive")}
              </label>
              <NxSVG className="w-5 h-5 fill-nx-danger-600">
                <IconArchive />
              </NxSVG>
            </div>
          ) : (
            <CircularProgressBar
              className="stroke-nx-danger-500"
              width={20}
              height={20}
            />
          )}
        </SecondaryButton>
      </NxModal>
      <NxSnackbar
        type={NxSnackbarType.success}
        label={t('archivedSuccessfull')}
        showIcon={true}
        showSnackbar={archiveLocationState instanceof LocationArchived}
        onSnackbarClose={() => {
          setSnackbar(undefined);
        }}
      />
      <NxSnackbar
        type={NxSnackbarType.error}
        label={t("archivedSuccessfull")}
        showIcon={true}
        showSnackbar={(showSnackBar ?? false) && archiveLocationState instanceof LocationArchivingFailure}
        onSnackbarClose={() => {
          setSnackbar(undefined);
        }}
      />
    </div>
  );
}
