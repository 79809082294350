import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { AcceptPoliciesUserUsecase } from "web/features/auth/domain/usecase/AcceptPoliciesUserUsecase";

abstract class AcceptPoliciesUserState {}

class AcceptPoliciesUserLoading extends AcceptPoliciesUserState {}

class AcceptPoliciesUserSuccess extends AcceptPoliciesUserState {}

class AcceptPoliciesUserFailure extends AcceptPoliciesUserState {}

export const useAcceptPoliciesUserHook = () => {
  const [acceptPoliciesUserState, setState] = useState<AcceptPoliciesUserState>();

  return {
    acceptPoliciesUserState: {
      isLoading: acceptPoliciesUserState instanceof AcceptPoliciesUserLoading,
      isSuccess: acceptPoliciesUserState instanceof AcceptPoliciesUserSuccess,
      isError: acceptPoliciesUserState instanceof AcceptPoliciesUserFailure,
    },
    acceptPoliciesUser: async (version: number) => {
      setState(new AcceptPoliciesUserLoading());
      const result = await AcceptPoliciesUserUsecase(version);
      if (result instanceof NexusFailure) {
        return setState(new AcceptPoliciesUserFailure());
      } else {
        setState(new AcceptPoliciesUserSuccess());
      }
    },
  };
};
