import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { UserEntity } from "web/features/auth/domain/entities/UserEntity";
import { GetAccountFailure } from "web/features/auth/domain/errors/GetAccountFailure";
import { getAccountUsecase } from "web/features/auth/domain/usecase/GetAccountUsecase";
import { OrganizationEntity } from "web/features/organizations/domain/entities/OrganizationEntity";
import { SelectCurrentOrgParams } from "web/features/organizations/domain/params/SelectCurrentOrgParams";
import { getCurrentOrgIdUsecase } from "web/features/organizations/domain/usecase/GetCurrentOrgIdUsecase";
import { getOrganizationsUsecase } from "web/features/organizations/domain/usecase/GetOrganizationsUsecase";
import { selectCurrentOrgUsecase } from "web/features/organizations/domain/usecase/SelectOrgUsecase";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";
import { GetProjectsFailure } from "web/features/projects/domain/errors/GetProjectFailure";
import { getProjectsUsecase } from "web/features/projects/domain/usecase/GetProjectsUsecase";

export abstract class AppState {}

export class AppInitial extends AppState {}

export class AppLoading extends AppState {}

export class AppSuccess extends AppState {
  projects: ProjectEntity[];
  organizations: OrganizationEntity[];
  currentOrgId: SelectCurrentOrgParams | null;
  user: UserEntity | null;

  constructor({
    projects,
    organizations,
    currentOrgId,
    user,
  }: Required<AppSuccess>) {
    super();
    this.projects = projects;
    this.organizations = organizations;
    this.currentOrgId = currentOrgId;
    this.user = user;
  }
}

export class AppFailure extends AppState {}

export const useAppHook = () => {
  const [appState, setState] = useState<AppState>(new AppInitial());

  const fetchInitialData = async () => {
    setState(new AppLoading());
    let hasError = false;
    const projects = [];
    const organizations = [];
    let currentOrgId: SelectCurrentOrgParams | null = null;

    const [userResult, organizationResult] = await Promise.all([
      getAccountUsecase(),
      getOrganizationsUsecase(),
    ]);

    hasError = userResult instanceof GetAccountFailure;

    if (hasError) {
      return setState(new AppFailure());
    }

    if (organizationResult instanceof PaginationEntity) {
      const currentUser = userResult as UserEntity;
      const orgs = organizationResult.results;
      const lastUserOrg = currentUser.last_used_organization;

      currentOrgId = await getCurrentOrgIdUsecase();
      if (!currentOrgId) {
        if (lastUserOrg) {
          const lastOrgExists = orgs.find((org) => org.id === lastUserOrg);
          if (lastOrgExists) {
            await selectCurrentOrgUsecase({
              orgId: lastUserOrg,
              orgName: lastOrgExists.name,
            });
            currentOrgId = {
              orgId: lastUserOrg,
              orgName: lastOrgExists.name,
            };
          }
        } else if (orgs.length > 0) {
          await selectCurrentOrgUsecase({
            orgId: orgs[0].id,
            orgName: orgs[0].name,
          });
          currentOrgId = {
            orgId: orgs[0].id,
            orgName: orgs[0].name,
          };
        }
      }

      organizations.push(...organizationResult.results);
    } else {
      hasError = true;
      return setState(new AppFailure());
    }

    const paginationResult = await getProjectsUsecase({ deleted: false });
    hasError = paginationResult instanceof GetProjectsFailure;

    if (paginationResult instanceof PaginationEntity) {
      projects.push(...paginationResult.results);
    }

    if (!hasError) {
      return setState(
        new AppSuccess({
          projects,
          organizations,
          currentOrgId,
          user: userResult as UserEntity,
        })
      );
    }

    return setState(new AppFailure());
  };

  return {
    appState,
    fetchInitialData,
    updateState: (state: Partial<AppSuccess>) => {
      if (appState instanceof AppSuccess) {
        setState(
          new AppSuccess({
            projects: state.projects ?? appState.projects,
            organizations: state.organizations ?? appState.organizations,
            currentOrgId: state.currentOrgId ?? appState.currentOrgId,
            user: state.user ?? appState.user,
          })
        );
      }
    },

    async changeOrg(org: SelectCurrentOrgParams) {
      selectCurrentOrgUsecase(org).then(() => {
        fetchInitialData();
      });
    },
  };
};
