import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import { WidgetType } from "../entities/WidgetType";

export function GetWidgetsUsecase(){
    return [
        new WidgetEntity({
          id: "1",
          name: "Trabalho análogo a escravidão",
          createdAt: new Date(),
          type: WidgetType.slave,
          queries: [
            {
              id: "0",
              name: "Rodolfo Moraes",
              description: "125.928.847-31",
              method: "Ministério do Trabalho e Emprego",
              requestBody: "5",
              parameters: "2491-5/01",
              createdAt: new Date(),
            },
            {
              id: "1",
              name: "Manuel Rodrigues",
              description: "125.928.847-31",
              method: "Ministério do Trabalho e Emprego",
              requestBody: "5",
              parameters: "2491-5/01",
              createdAt: new Date(),
              queryResults: [
                {
                  id: "1",
                  organization: "Ministério do Trabalho e Emprego",
                  statusCode: 5,
                  responsefile: "2491-5/01",
                },
              ],
            },
            {
              id: "2",
              name: "Luciano Pereira",
              description: "125.928.847-31",
              method: "Ministério do Trabalho e Emprego",
              requestBody: "5",
              parameters: "2491-5/01",
              createdAt: new Date(),
            },
            {
              id: "3",
              name: "Morais Pereira",
              description: "125.928.847-31",
              method: "Ministério do Trabalho e Emprego",
              requestBody: "5",
              parameters: "2491-5/01",
              createdAt: new Date(),
            },
          ],
        }),
        new WidgetEntity({
          id: "1",
          name: "Identificação de Pivôs",
          createdAt: new Date(),
          type: WidgetType.pivot,
          queries: [
            {
              id: "0",
              name: "Quantidade de pivôs identificados",
              description: "3 Pivôs",
              queryResults: [
                {
                  id: '1',
                  organization: 'Pivô 1',
                  responsefile: '200 ha'
                },
                {
                  id: '2',
                  organization: 'Pivô 2',
                  responsefile: '200 ha'
                },
                {
                  id: '3',
                  organization: 'Pivô 3',
                  responsefile: '200 ha'
                }
              ]
            },
            {
              id: "1",
              name: "Área total",
              description: "500 ha",
            },
          ],
        }),
      ];
}