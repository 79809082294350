import { redirect } from "react-router-dom";
import { userIsLoggedUsecase } from "web/core/domain/usecase/UserIsLoggedUsecase";

export const loginGuardLoader = () => {
  return () => {
    const userIsLogged = userIsLoggedUsecase();
    if (userIsLogged) return redirect("/org");
    return null;
  };
};
