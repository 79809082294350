import { t } from "i18next";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import { CSName } from "web/common/utils/ClassName";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { WidgetCategoryEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { ExtendableCategory } from "../ExtendableCategory/ExtendableCategory";

type Params = {
  categories: WidgetCategoryEntity[];
  onClose: () => void;
  onPrevious: () => void;
  onSelect: (category: WidgetCategoryEntity, itemId: number) => void;
};

export function StepTwoPage({ categories, onClose, onPrevious, onSelect }: Params) {
  return (
    <div>
      <div className="flex flex-row">
        <NxSVG
          className="w-5 h-5 stroke-nx-main-600 dark:stroke-nx-main-500 mr-4 mt-1 cursor-pointer"
          onClick={onPrevious}
        >
          <IconArrowLeft />
        </NxSVG>
        <NxModalTitle
          title={t("newWidget")}
          subtitle={t("lastUpdated")}
          onClosedTapped={onClose}
        />
      </div>
      <div className="mt-6 flex flex-row justify-between">
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 uppercase font-semibold")
            .build()}
        >
          {t("category") + "s"}
        </label>
        <div className="grid grid-flow-col gap-0.5">
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("dark:text-nx-gray-200 text-nx-gray-400")
              .build()}
          >
            {t("availableCredits")}
          </label>
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("dark:text-nx-main-200 text-nx-dark-600")
              .build()}
          >
            2000
          </label>
          <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5">
            <IconCoin />
          </NxSVG>
        </div>
      </div>

      <div className="bg-nx-gray-50 dark:bg-nx-dark-1200 w-full h-fit pt-1 flex flex-col rounded">
        {categories.map((category, index) => (
          <ExtendableCategory key={index} category={category} onSelect={onSelect}/>
        ))}
      </div>
    </div>
  );
}
