import { NxSVG } from "../../../../../../common/components/NxSvg";
import { IconMore } from "../../../../../../common/fonts/icons/components/IconMore";
import { MemberInvetedUserCell } from "./MemberInvetedUserCell";
import { MemberUserRuleDropdown } from "../MemberUserRuleDropdown";
import { MemberAddProjectCell } from "../MemberAddProjectCell";

export const MemberInvitedRow = () => {
  return (
    <tr className="border-b-[0.031rem] border-nx-gray-100 dark:border-nx-gray-500 ">
      <td>
        <MemberInvetedUserCell />
      </td>
      <td>
        <div className="flex flex-row gap-2 py-4 items-center">
          <MemberAddProjectCell />
        </div>
      </td>
      <td>
        <div>
          <MemberUserRuleDropdown />
        </div>
      </td>
      <td>
        <div className="cursor-pointer">
          <NxSVG className="fill-nx-main-500 w-5 h-5">
            <IconMore />
          </NxSVG>
        </div>
      </td>
    </tr>
  );
};
