

export class OptionsEntity {
    isSelected: boolean;
    name: string;
    
    constructor({isSelected, name}: Partial<OptionsEntity>){
        this.isSelected = isSelected!;
        this.name = name!;
    }
}