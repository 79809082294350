import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { AcceptTermsUserUsecase } from "web/features/auth/domain/usecase/AcceptTermsUserUsecase";

abstract class AcceptTermsUserState {}

class AcceptTermsUserLoading extends AcceptTermsUserState {}

class AcceptTermsUserSuccess extends AcceptTermsUserState {}

class AcceptTermsUserFailure extends AcceptTermsUserState {}

export const useAcceptTermsUserHook = () => {
  const [acceptTermsUserState, setState] = useState<AcceptTermsUserState>();

  return {
    acceptTermsUserState: {
      isLoading: acceptTermsUserState instanceof AcceptTermsUserLoading,
      isSuccess: acceptTermsUserState instanceof AcceptTermsUserSuccess,
      isError: acceptTermsUserState instanceof AcceptTermsUserFailure,
    },
    acceptTermsUser: async (version: number) => {
      setState(new AcceptTermsUserLoading());
      const result = await AcceptTermsUserUsecase(version);
      if (result instanceof NexusFailure) {
        return setState(new AcceptTermsUserFailure());
      } else {
        setState(new AcceptTermsUserSuccess());
      }
    },
  };
};
