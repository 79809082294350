import { useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { format } from "date-fns";

import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { CheckBoxField } from "web/common/components/TextFields/CheckField";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";

import { useOrgModal } from "../../context/OrgModalProvider";
import { HideOrganizationModal } from "../../context/abstraction/OrganizationModal";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";

import { useLeaveOrganizationHook } from "../../hooks/UseLeaveOrganizationHook";
import { useEditMemberOrganizationHook } from "web/features/members/presentation/hooks/useEditMemberOrganizationHook";

import { MemberEntity } from "web/features/members/domain/entities/MemberEntity";
import { MemberRoles } from "web/features/members/domain/entities/ENUMS/MemberRoles";

import {
  GetMembersOrganizationFailure,
  GetMembersOrganizationLoading,
  GetMembersOrganizationSuccess,
  useGetMembersOrganizationHook,
} from "web/features/members/presentation/hooks/useGetMembersOrganizationHook";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";

export function SelectAdminModal() {
  const { setModal, organization } = useOrgModal();
  const [members, setMembers] = useState<MemberEntity[] | null>(null);
  const [selectedMemberIds, setSelectedMemberIds] = useState<MemberEntity[]>(
    []
  );

  const { editMember, memberState: editMemberState } =
    useEditMemberOrganizationHook();
  const { getMembersOrganizationState, getMembersOrganization } =
    useGetMembersOrganizationHook({
      organizationId: organization?.id as string,
    });

  const { showSnackbar } = useNxSnackBarContext();
  const { leaveOrganization, organizationState } = useLeaveOrganizationHook();
  const state = organizationState;
  const { appState } = useAppContext();
  const isLeavingRef = useRef(false);

  useEffect(() => {
    getMembersOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(
    (reloadOrgs: boolean) => {
      setModal(new HideOrganizationModal({ reloadOrgs }));
    },
    [setModal]
  );

  const handleCheckboxChange = (member: MemberEntity, isChecked: boolean) => {
    setSelectedMemberIds((prev) =>
      isChecked
        ? [...prev, member]
        : prev.filter((m) => m.user!.id !== member.user!.id)
    );
  };

  const handleChangeAdminAndExit = useCallback(
    (user: MemberEntity[]) => {
      if (user.length > 0) {
        user.forEach((u) => {
          editMember({
            organizationId: organization?.id as string,
            role: MemberRoles.ADMIN,
            userId: u.user!.id,
            userEmail: u.user!.email as string,
          });
        });
      }
    },
    [editMember, organization?.id]
  );

  useEffect(() => {
    if (
      state.isError ||
      getMembersOrganizationState instanceof GetMembersOrganizationFailure
    ) {
      showSnackbar({
        type: NxSnackbarType.error,
        showIcon: true,
        label: t("leaveOrgFailure"),
      });
      setTimeout(() => closeModal(false), 1000);
    }
  }, [closeModal, state.isError, showSnackbar, getMembersOrganizationState]);

  useEffect(() => {
    if (state.isSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        showIcon: true,
        label: t("leaveOrgSucess"),
      });
      setTimeout(() => closeModal(true), 1000);
    }
  }, [closeModal, state.isSuccess, showSnackbar]);

  useEffect(() => {
    if (editMemberState.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        showIcon: true,
        label: t("leaveOrgFailure"),
      });
      setTimeout(() => closeModal(false), 1000);
    }
  }, [closeModal, editMemberState.isError, showSnackbar]);

  useEffect(() => {
    if (editMemberState.isSuccess) {
      leaveOrganization({ organizationId: organization?.id as string });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMemberState.isSuccess, organization?.id]);

  useEffect(() => {
    if (isLeavingRef.current) {
      return;
    }
    if (
      getMembersOrganizationState instanceof GetMembersOrganizationSuccess &&
      members === null
    ) {
      isLeavingRef.current = true;
      const currentUser = (appState as AppSuccess).user;
      let currentUserIsAdmin = false;
      const adminMembers: MemberEntity[] = [];
      const viewerMembers: MemberEntity[] = [];
      const organizationId = organization?.id as string;

      if (getMembersOrganizationState.members.length === 1) {
        leaveOrganization({
          organizationId,
        });
        isLeavingRef.current = false;
        return;
      }

      getMembersOrganizationState.members.forEach((member) => {
        if (member.user?.id === currentUser?.id) {
          currentUserIsAdmin = member.role === MemberRoles.ADMIN;
        }
        if (member.role === MemberRoles.ADMIN) {
          adminMembers.push(member);
        }
        if (member.role === MemberRoles.VIEWER) {
          viewerMembers.push(member);
        }
      });

      if (!currentUserIsAdmin) {
        leaveOrganization({
          organizationId,
        });
        isLeavingRef.current = false;

        return;
      }

      if (adminMembers.length > 1) {
        leaveOrganization({
          organizationId,
        });
        isLeavingRef.current = false;
        return;
      }

      // Select new admin members
      return setMembers(viewerMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMembersOrganizationState]);

  if (
    getMembersOrganizationState instanceof GetMembersOrganizationLoading ||
    members === null
  ) {
    return (
      <div className="flex flex-col mt-[15%] m-auto">
        <NxModal showModal={true}>
          <CircularProgressBar
            className="stroke-nx-main-600"
            width={20}
            height={20}
          />
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("text-nx-gray-200")
              .build()}
          >
            {t("loadingData")}
          </label>
        </NxModal>
      </div>
    );
  }

  return (
    <div>
      <NxModal showModal={true} onOutsideClick={() => closeModal(false)}>
        <div className="grid grid-rows-1 w-96">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("dark:text-nx-main-200 text-nx-gray-700 text-start pb-1")
              .build()}
          >
            {t("newAdmin")}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-200 dark:text-nx-gray-200")
              .build()}
          >
            {t("newAdminDescription") + organization?.name}
          </label>
        </div>
        <div className="mt-6 mb-6 bg-nx-gray-50 dark:bg-nx-dark-1200 w-fit h-fit flex flex-col pt-4 rounded">
          {members?.map((u, index) => (
            
            <div
              className="grid grid-flow-col gap-[352px] place-content-between mx-4 mb-4"
              key={index}
            >
              <div className="flex flex-col">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine("text-nx-gray-700 dark:text-nx-white text-start")
                    .build()}
                >
                  {u.user!.first_name}
                </label>
                <label
                  className={CSName(FontStyles.caption)
                    .combine(
                      "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                    )
                    .build()}
                >
                  {t("memberSince")}: {format(u.created_at!, "dd/MM/yyyy")}
                </label>
              </div>
              <CheckBoxField
                className={"bg-nx-focus-light dark:bg-nx-focus-dark"}
                onClick={(isChecked) => handleCheckboxChange(u, isChecked)}
              />
            </div>
          ))}
        </div>
        <div className="pb-2">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => handleChangeAdminAndExit(selectedMemberIds)}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              {editMemberState.isLoading ? (
                <CircularProgressBar
                  className="stroke-nx-white"
                  width={20}
                  height={20}
                />
              ) : (
                t("newAdminButtonLabel")
              )}
            </label>
          </PrimaryButton>
        </div>
      </NxModal>
    </div>
  );
}
