import { useContext } from "react";
import styled from "styled-components";
import { Color } from "../../fonts/Colors";
import { Container } from "../shapes";
import { DropdownStateContext } from '../../../core/context/DropdownStateContext';

type listAction = {
  label: string;
  action: () => void;
};

interface DropdownElement {
  label: string;
  background: Color;
  dropdownColor: Color;
  elementsColor: Color;
  list: Array<listAction>;
}

const DropDownHeader = styled("div")`
  font-weight: 500;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 143px;
  color: ${Color.main600};
  background: ${Color.main100};
  border-radius: 4px;
  border: 0px solid transparent;
  cursor: pointer;
`;

const DropDownList = styled("ul")`
  width: 130px;
  padding: 0;
  margin: 0;
  margin-top: 4px;
  margin-left: 22px;
  border: 0.1px solid #667c8e;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  &:first-child {
    padding-top: 1.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  padding: 8px 8px 8px 8px;
  margin: 8px 8px 8px 8px;
  border-radius: 4px;
  &:hover {
    background: #0084ff;
    color: white;
  }
`;

const ArrowDown = styled.i`
  border: solid ${Color.main700};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

export function DropdownField({
  label,
  background,
  dropdownColor,
  elementsColor,
  list,
}: DropdownElement) {
  const {isOpen, setIsOpen} = useContext(DropdownStateContext);
  const toggling = () => setIsOpen(!isOpen);

  return (
    <Container>
      <DropDownHeader
        onClick={toggling}
        style={{ backgroundColor: `${background}`, marginLeft: 10 }}
      >
        {label} <ArrowDown style={{ marginLeft: 20 }} />
      </DropDownHeader>
      {isOpen && (
        <DropDownList
          style={{
            backgroundColor: `${dropdownColor}`,
            color: `${elementsColor}`,
          }}
        >
          {list.map((element, index) => (
            <ListItem
              onClick={() => {
                setIsOpen(!isOpen);
                element.action();
              }}
              key={index}
            >
              {element.label}
            </ListItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
}
