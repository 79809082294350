export const IconDrawGPS = () => {
  return (
    <svg
      id="svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M204.189 41.079 C 200.138 43.593,199.610 45.589,199.610 58.405 L 199.610 70.175 196.593 70.175 C 187.812 70.175,167.973 75.142,154.987 80.590 C 109.745 99.573,76.527 143.285,70.818 191.351 L 69.837 199.610 58.236 199.610 C 43.329 199.610,39.747 201.525,39.778 209.477 C 39.809 217.765,43.616 219.883,58.483 219.883 L 69.952 219.883 70.474 225.503 C 76.262 287.776,128.437 340.552,192.142 348.570 L 199.610 349.510 199.610 361.440 C 199.610 376.364,202.748 381.166,211.373 379.441 C 218.379 378.040,219.883 374.666,219.883 360.355 L 219.883 349.510 227.351 348.570 C 289.191 340.786,339.009 291.835,348.101 229.919 C 348.770 225.361,349.318 221.239,349.318 220.758 C 349.318 220.151,352.841 219.882,360.819 219.880 C 375.192 219.876,379.727 217.458,379.727 209.799 C 379.727 201.806,375.777 199.610,361.395 199.610 L 349.510 199.610 348.564 192.008 C 340.671 128.574,287.693 76.254,225.503 70.474 L 219.883 69.952 219.880 58.562 C 219.876 42.086,213.684 35.186,204.189 41.079 M225.815 91.289 C 312.661 103.368,357.672 203.366,308.839 275.736 C 253.225 358.158,127.835 341.197,96.311 246.990 C 68.562 164.063,139.121 79.231,225.815 91.289 M195.559 150.914 C 152.673 161.314,135.125 214.071,163.248 248.060 C 199.093 291.381,269.417 265.730,269.355 209.357 C 269.312 170.495,233.094 141.812,195.559 150.914 "
          stroke="none"
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};
