import { CSName } from "web/common/utils/ClassName";
import { NxSVG } from "../../NxSvg";
import { IconSort } from "web/common/fonts/icons/components/IconSort";
import { NxTHeaderParams } from "./interfaces/NxTHeaderParams";

export function NxTHeader({  params , className = "pl-6" }: NxTHeaderParams) {
  return (
    <div
     
      className={CSName(
          "flex flex-row text-[10px] font-semibold text-nx-gray-200 uppercase"
        )
        .combine(className)
        .build()}
    
    >
      {params.map((param, index) => {
        return (
          <div
            className={CSName("flex flex-row").combine(param.className).build()}
            onClick={param.onClick}
            key={index}
          >
            <label className="cursor-pointer">{param.label}</label>
            {param.isSorting && (
              <NxSVG
                className={CSName(
                  "fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer text-start"
                )
                  .combine(param.isOrderByAsc ? "rotate-180" : "")
                  .build()}
              >
                <IconSort />
              </NxSVG>
            )}
          </div>
        );
      })}
    </div>
  );
}
