import { WidgetType } from "./WidgetType";

export class WidgetCategoryEntity {
  id: string;
  icon: any;
  name: string;
  description: string;
  items: ItemEntity[];
  constructor({
    id,
    icon,
    name,
    description,
    items,
  }: Required<WidgetCategoryEntity>) {
    this.id = id;
    this.icon = icon;
    this.name = name;
    this.description = description;
    this.items = items;
  }
}

export class ItemEntity {
  id:number;
  name:string;
  value:number;
  type: WidgetType;
  description: string;
  constructor({id, name, value,
    type, description}: Required<ItemEntity>){
    this.id = id;
    this.name = name;
    this.value = value;
    this.type = type;
    this.description = description;
  }
}