import { useContext, useEffect } from "react";
import { Color } from "../../../../common/fonts/Colors";
import { VisualContext } from "../../../../core/context/VisualContext";
import { LabelStyles } from "../../../../common/fonts/FontStyles";
import CircularProgressBar from "../../../../common/components/ProgressBar/CircularProgressBar";
import { useNavigate } from "react-router-dom";
import {
  LoginOAuthError,
  LoginOAuthSuccess,
  useGoogleLogin,
} from "web/features/auth/features/login/presentation/hooks/UseGoogleLogin";
import { NxSnackbarProvider } from "web/common/providers/NxSnackBarProvider";
import { useNxSnackBarContext } from "../../../../common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { useTranslation } from "react-i18next";

export function LoadingOAuthPage() {
  return (
    <NxSnackbarProvider>
      <LoadingOAutBody />
    </NxSnackbarProvider>
  );
}

export function LoadingOAutBody() {
  const { isDarkMode } = useContext(VisualContext);
  const { loginOAuth, loginOAuthState } = useGoogleLogin();
  const navigate = useNavigate();
  const { showSnackbar } = useNxSnackBarContext();
  const { t } = useTranslation();

  useEffect(() => {
    loginOAuth();
  }, [loginOAuth]);

  useEffect(() => {
    if (loginOAuthState instanceof LoginOAuthSuccess) {
      navigate("/org");
    }
    if (loginOAuthState instanceof LoginOAuthError) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: t("loginGoogleError"),
      });
      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    }
  }, [loginOAuthState, navigate, showSnackbar, t]);

  return (
    <div
      className={`w-screen h-lvh grid grid-cols-1 gap-4 place-content-center ${
        isDarkMode ? "bg-nx-dark-1100" : "bg-nx-white"
      }`}
    >
      <CircularProgressBar className="stroke-nx-main-600" />
      <label className="pb-16 pt-6 text-xs dark:text-nx-gray-300 text-nx-gray-600 text-center">
        {t("loadingDataPlatform")}
      </label>
      <div
        className={`place-self-center w-96 rounded grid grid-rows-0 place-content-center p-4 ${
          isDarkMode ? "bg-nx-dark-1200" : "bg-nx-gray-50"
        } `}
      >
        <label
          className="w-full"
          style={{
            ...LabelStyles.caption,
            color: isDarkMode ? Color.gray400 : Color.gray500,
          }}
        >
          Analise dados climáticos na plataforma para identificar padrões
          correlacionados à produtividade da cultura, obtendo insights para
          otimizar estratégias agrícolas.
        </label>
      </div>
    </div>
  );
}
