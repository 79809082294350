import { useState } from "react";
import { CreateLocationParams } from "../../domain/params/CreateLocationParams";
import { CreateLocationUsecase } from "../../domain/usecase/CreateLocationUsecase";
import { CreateLocationFailure } from "../../domain/errors/CreateLocationFailure";

abstract class LocationState {}

export class CreatingLocation extends LocationState {}

export class LocationCreated extends LocationState {}

export class LocationCreationFailure extends LocationState {}

type Params = {
  location: CreateLocationParams;
};

export const useCreateLocationHook = () => {
  const [createLocationState, setState] = useState<LocationState>();

  return {
    createLocationState,
    setState,
    createLocation: async (params: Params) => {
      setState(new CreatingLocation());

      const result = await CreateLocationUsecase(params.location);

      if (result instanceof CreateLocationFailure) {
        return setState(new LocationCreationFailure());
      }
      return setState(new LocationCreated());
    },
  };
};