import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconInfo = ({ color }: NexusIconsProps) => (
    <svg
    viewBox="0 0 16 16"
    fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 8.00001C14.4 11.5346 11.5346 14.4 7.99998 14.4C4.46535 14.4 1.59998 11.5346 1.59998 8.00001C1.59998 4.46538 4.46535 1.60001 7.99998 1.60001C11.5346 1.60001 14.4 4.46538 14.4 8.00001ZM7.99999 11.975C8.42801 11.975 8.77499 11.628 8.77499 11.2V7.99999C8.77499 7.57197 8.42801 7.22499 7.99999 7.22499C7.57197 7.22499 7.22499 7.57197 7.22499 7.99999V11.2C7.22499 11.628 7.57197 11.975 7.99999 11.975ZM8.00002 4.79999C8.44184 4.79999 8.80002 5.15817 8.80002 5.59999C8.80002 6.04182 8.44184 6.39999 8.00002 6.39999C7.55819 6.39999 7.20002 6.04182 7.20002 5.59999C7.20002 5.15817 7.55819 4.79999 8.00002 4.79999Z"
      fill="white"
    />
  </svg>
);
