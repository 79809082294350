export const IconFolder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="117"
      viewBox="0 0 114 117"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4128 0.00106799C73.6888 -0.0925651 88.5035 5.9714 98.199 17.8593C108.066 29.9578 111.684 45.997 108.408 61.3013C105.081 76.8442 95.3945 90.4967 81.1726 97.4029C66.9418 104.313 50.514 102.987 36.1339 96.3972C21.3039 89.6016 7.87408 78.255 4.40221 62.2243C0.962513 46.3422 8.60576 30.5823 19.2293 18.3434C29.2023 6.85393 43.2593 0.09395 58.4128 0.00106799Z"
        fill="#76BDEA"
        fillOpacity="0.15"
      />
      <rect
        x="33.4863"
        y="27.0217"
        width="54.0553"
        height="13.3548"
        rx="4.76959"
        transform="rotate(-15 33.4863 27.0217)"
        fill="#53768E"
      />
      <rect
        x="31.1562"
        y="30.6079"
        width="54.0553"
        height="13.3548"
        rx="5.7235"
        transform="rotate(-15 31.1562 30.6079)"
        fill="#A8C6E0"
      />
      <rect
        x="29.1328"
        y="34.1145"
        width="54.0553"
        height="13.3548"
        rx="2.54378"
        transform="rotate(-15 29.1328 34.1145)"
        fill="#E2EFFA"
      />
      <path
        d="M10.1886 35.8988C9.50679 33.3544 11.0168 30.7391 13.5612 30.0573L37.1453 23.7379C38.3672 23.4105 39.669 23.5819 40.7645 24.2144L51.557 30.4455C52.6525 31.078 53.9544 31.2494 55.1763 30.922L85.9781 22.6686C88.5226 21.9869 91.1379 23.4968 91.8197 26.0412L103.547 69.8084C104.229 72.3528 102.719 74.9681 100.174 75.6499L30.7613 94.2491C28.2169 94.9308 25.6016 93.4209 24.9198 90.8765L10.1886 35.8988Z"
        fill="url(#paint0_linear_931_48442)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.4608 17.0719L90.1317 10.8396L92.8027 17.0719L99.035 19.7428L92.8027 22.4138L90.1317 28.6461L87.4608 22.4138L81.2285 19.7428L87.4608 17.0719Z"
        fill="#E3F3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1706 60.0195L25.862 53.7395L28.5534 60.0195L34.8334 62.7109L28.5534 65.4023L25.862 71.6822L23.1706 65.4023L16.8906 62.7109L23.1706 60.0195Z"
        fill="#E3F3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6352 74.2812L18.25 70.5132L19.8649 74.2812L23.6328 75.896L19.8649 77.5108L18.25 81.2788L16.6352 77.5108L12.8672 75.896L16.6352 74.2812Z"
        fill="#E3F3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0388 14.9211L74.6128 11.2485L76.1867 14.9211L79.8593 16.4951L76.1867 18.069L74.6128 21.7416L73.0388 18.069L69.3662 16.4951L73.0388 14.9211Z"
        fill="#E3F3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.1315 31.1748L82.7532 27.5022L84.3748 31.1748L88.1587 32.7487L84.3748 34.3227L82.7532 37.9953L81.1315 34.3227L77.3477 32.7487L81.1315 31.1748Z"
        fill="#E3F3FF"
      />
      <ellipse
        cx="56.6182"
        cy="113"
        rx="53"
        ry="4"
        fill="black"
        fillOpacity="0.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_931_48442"
          x1="48.2677"
          y1="20.7577"
          x2="48.2677"
          y2="77.5007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCD0DD" />
          <stop offset="1" stopColor="#6C96B3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
