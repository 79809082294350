export const IconHandEsg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10.0409 9.86395L12.1237 10.422C12.7072 10.5784 13.3069 10.2321 13.4633 9.64863V9.64863C13.6196 9.06515 13.2736 8.46549 12.6902 8.30915C11.5921 8.01492 10.1957 7.64075 9.62606 7.48812C7.58451 6.94109 6.16029 7.60963 5.57633 8.25945C5.34307 8.51901 5.39769 8.89273 5.57218 9.19495L6.82346 11.3622C6.97967 11.6328 7.23698 11.8302 7.53877 11.9111L11.8194 13.0581C12.2539 13.1745 12.7172 13.1048 13.0982 12.8656L17.6394 10.0146C18.1633 9.6857 18.3448 9.00839 18.0555 8.46163V8.46163C17.7311 7.84837 16.9506 7.64536 16.3685 8.02283L13.3795 9.96106"
        stroke="stroke"
        strokeLinecap="round"
      />
      <path
        d="M5.1465 8.45735L7.12296 11.8807C7.40325 12.3662 7.2539 12.9861 6.78328 13.2907L6.04803 13.7666C5.54235 14.0938 4.86567 13.9308 4.56449 13.4092L2.58803 9.98583C2.30774 9.50036 2.45709 8.88039 2.92771 8.57581L3.66296 8.09996C4.16864 7.77269 4.84532 7.9357 5.1465 8.45735Z"
        stroke="stroke"
      />
    </svg>
  );
};
