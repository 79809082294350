import { MyProfileHeader } from "../components/MyProfileHeader";
import { MyProfileContenteCard } from "../components/MyProfileContentCard";
import { MyProfileTile } from "../components/MyProfileTile";
import { NxSVG } from "web/common/components/NxSvg";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";

import { useMyProfileLogoutModal } from "../components/MyProfileLogoutModal/MyProfileLogoutModalContext";
import { useMyProfileTermsModal } from "../components/MyProfileTermsModal/MyProfileTermsModalContext";

import { MyProfileLanguageAndTheme } from "../components/MyProfileLanguageAndTheme";
import { useTranslation } from "react-i18next";

import { UserEntity } from "web/features/auth/domain/entities/UserEntity";

import { useMyProfilePoliciesModal } from "../components/MyProfilePoliciesModal/MyProfilePoliciesModalContext";
import { useAppContext } from "../../../app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";

export const MyProfilePage = () => {
  const { showModal: showLogoutModal } = useMyProfileLogoutModal();
  const { showModal: showMyTermsModal } = useMyProfileTermsModal();
  const { showModal: showMyPoliciesModal } = useMyProfilePoliciesModal();
  const { t } = useTranslation();
  const { appState } = useAppContext();

  if (!(appState instanceof AppSuccess)) return <></>;
  const account = appState.user;

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col mt-8 w-full max-w-lg">
        <h2 className="text-xl text-nx-main-800 dark:text-nx-main-500 font-semibold mb-5">
          {t("profile")}
        </h2>
        <div className="flex flex-col gap-5">
          <MyProfileHeader account={account as UserEntity} />
          <MyProfileLanguageAndTheme />
          <MyProfileContenteCard title={t("about")}>
            <MyProfileTile
              title={t("privacityPolicy")}
              onClick={showMyPoliciesModal}
              trailing={
                <NxSVG className="w-5 h-5  stroke-nx-main-500">
                  <IconArrowRight />
                </NxSVG>
              }
            />
            <MyProfileTile
              title={t("termsOfUse")}
              onClick={showMyTermsModal}
              trailing={
                <NxSVG className="w-5 h-5  stroke-nx-main-500">
                  <IconArrowRight />
                </NxSVG>
              }
            />
          </MyProfileContenteCard>
          <MyProfileContenteCard title="OUTROS">
            <MyProfileTile
              title={t("logOut")}
              titleClassName=" text-xs text-nx-danger-600 font-normal "
              subtitle={t("desconnectFromNexus")}
              onClick={() => {
                showLogoutModal();
              }}
              trailing={
                <NxSVG className="w-5 h-5  stroke-nx-main-500">
                  <IconArrowRight />
                </NxSVG>
              }
            />
          </MyProfileContenteCard>
        </div>
      </div>
    </div>
  );
};
