import { t } from "i18next";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { CSName } from "web/common/utils/ClassName";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { format } from "date-fns";
import { useState } from "react";
import { WidgetMock } from "web/features/widgets/domain/mocks/widgetsMock";
import { IconArrowLeft } from "web/common/fonts/icons/components/IconArrowLeft";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import HomeAccordionWidgetsDescription from "./HomeNavAccordionWidgetsDescription";

export interface HomeNavQuickSearchModalProps {
  isOpen: boolean;
  onClosedTapped: () => void;
} 


export const HomeNavQuickSearchModal = ({isOpen, onClosedTapped}: HomeNavQuickSearchModalProps) => {
  const [selectCategories, setSelectCategories] = useState(false);

  return (
    <div>
      <NxModal showModal={isOpen} onOutsideClick={onClosedTapped}>
        <div
          className={`w-[440px] max-h-[489px] ${
            selectCategories && "min-h-[429px]"
          } flex flex-col`}
        >
          <div className="flex gap-2">
            {selectCategories && (
              <NxSVG
                onClick={() => setSelectCategories(false)}
                className={CSName(
                  "stroke-nx-main-500 w-5 h-5 mt-1 cursor-pointer active:transition duration-100"
                ).build()}
              >
                <IconArrowLeft />
              </NxSVG>
            )}
            <NxModalTitle
              title={t("quickSearch")}
              subtitle={t("lastPerfomed")}
              onClosedTapped={onClosedTapped}
            />
          </div>

          <div className="flex items-center justify-between mt-5 mb-2">
            <h4 className="text-caption font-semibold text-nx-gray-200 tracking-[1.5px]">
              {selectCategories
                ? t("categories").toUpperCase()
                : t("lastFifteenDays")}
            </h4>
            {
              selectCategories && <div className="flex flex-row items-center">
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("dark:text-nx-gray-200 text-nx-gray-400 text-captionLarge")
                  .build()}
              >
                {t("availableCredits")}
              </label>
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("dark:text-nx-main-200 text-nx-dark-600 ml-2 mr-1 text-captionLarge")
                  .build()}
              >
                2000
              </label>
              <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-4 h-4">
                <IconCoin />
              </NxSVG>
            </div>
            }
          </div>

          <div className="dark:bg-nx-dark-1200 bg-nx-gray-50 rounded p-2 space-y-3 overflow-y-auto no-scrollbar flex-grow">
            {!selectCategories ? (
              WidgetMock.data.results.map((widget) => (
                <div
                  key={widget.id}
                  className="flex p-2 items-center justify-between hover:bg-nx-focus-dark rounded transition cursor-pointer"
                >
                  <div>
                    <p className="dark:text-nx-white text-gray-700 text-captionMedium">
                      {widget.name}
                    </p>
                    <p className="text-nx-gray-200 text-caption">
                      {format(widget.used_at, "dd/MM/yyyy")} •{" "}
                      {format(widget.used_at, "HH:mm")}
                    </p>
                  </div>
                  <div>
                    <NxSVG
                      className={CSName(
                        "stroke-nx-main-500 w-5 h-5 mt-1 active:transition duration-100"
                      ).build()}
                    >
                      <IconArrowRight />
                    </NxSVG>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <HomeAccordionWidgetsDescription />
              </div>
            )}
          </div>

          <div className="mt-4">
            {!selectCategories && (
              <NxFilledButton onClick={() => setSelectCategories(true)}>
                {t('newAppointment')}
              </NxFilledButton>
            )}
          </div>
        </div>
      </NxModal>
    </div>
  );
};
