import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { t } from "i18next";
import { useOrgModal } from "../../context/OrgModalProvider";
import {
  HideOrganizationModal,
  ShowNewAdminModal,
} from "../../context/abstraction/OrganizationModal";
import { IconRemove } from "web/common/fonts/icons/components/IconRemove";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { useLeaveOrganizationHook } from "../../hooks/UseLeaveOrganizationHook";
import { useCallback, useEffect } from "react";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { MemberRoles } from "web/features/members/domain/entities/ENUMS/MemberRoles";

export function LeaveOrgModal() {
  const { setModal, organization } = useOrgModal();
  const { leaveOrganization, organizationState } = useLeaveOrganizationHook();

  const closeModal = useCallback(
    (reloadOrgs: boolean) => {
      setModal(new HideOrganizationModal({ reloadOrgs }));
    },
    [setModal]
  );

  const { showSnackbar } = useNxSnackBarContext();

  const state = organizationState;

  useEffect(() => {
    if (state.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        showIcon: true,
        label: t("leaveOrgFailure"),
      });
      setTimeout(() => {
        closeModal(false);
      }, 1000);
    }
  }, [closeModal, showSnackbar, state.isError]);

  useEffect(() => {
    if (state.isSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        showIcon: true,
        label: t("leaveOrgSucess"),
      });
      setTimeout(() => {
        closeModal(true);
      }, 1000);
    }
  }, [showSnackbar, closeModal, state.isSuccess]);

  const confirmButtonLeaveOrg = () => {
    if (organization?.memberRole === MemberRoles.VIEWER) {
      leaveOrganization({ organizationId: organization?.id as string });
    } else {
      setModal(new ShowNewAdminModal());
    }
  };

  return (
    <div>
      <NxModal
        showModal={true}
        onOutsideClick={() => {
          closeModal(false);
        }}
      >
        <div className="grid grid-rows-1 pb-6 w-96">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-main-200 text-start pb-1")
              .build()}
          >
            {t("leaveOrgTitle")}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {t("leaveOrgDescription") + organization?.name + "?"}
          </label>
        </div>
        <div className="pb-4">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => {
              closeModal(false);
            }}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              {t("maintain")}
            </label>
          </PrimaryButton>
        </div>
        <NxOutlinedButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            confirmButtonLeaveOrg();
          }}
        >
          <div className="flex justify-center items-center">
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("text-nx-danger-600 cursor-pointer pr-2")
                .build()}
            >
              {t("leaveOrg")}
            </label>
            <NxSVG className="w-5 h-5 fill-nx-danger-600">
              <IconRemove />
            </NxSVG>
          </div>
        </NxOutlinedButton>
      </NxModal>
    </div>
  );
}
