export const IconShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="fill"
    >
      <path
        
        stroke="stroke"
        d="M15.5 6.9917C16.8807 6.9917 18 5.87241 18 4.4917C18 3.11099 16.8807 1.9917 15.5 1.9917C14.1193 1.9917 13 3.11099 13 4.4917C13 4.62639 13.0107 4.75859 13.0312 4.88751L6.29761 8.25428C5.84304 7.78407 5.20567 7.4917 4.5 7.4917C3.11929 7.4917 2 8.61099 2 9.9917C2 11.3724 3.11929 12.4917 4.5 12.4917C5.20566 12.4917 5.84303 12.1993 6.29759 11.7291L13.0312 15.0959C13.0106 15.2248 13 15.357 13 15.4917C13 16.8724 14.1193 17.9917 15.5 17.9917C16.8807 17.9917 18 16.8724 18 15.4917C18 14.111 16.8807 12.9917 15.5 12.9917C14.7943 12.9917 14.1568 13.2841 13.7023 13.7544L6.96881 10.3877C6.98934 10.2587 7 10.1265 7 9.9917C7 9.85695 6.98934 9.7247 6.96882 9.59573L13.7023 6.229C14.1569 6.69928 14.7943 6.9917 15.5 6.9917Z"
        fill="fill"
      />
    </svg>
  );
};