import { format } from "date-fns";
import { t } from "i18next";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconDropButton } from "web/common/fonts/icons/components/IconDropButton";
import { CSName } from "web/common/utils/ClassName";
import {
  WidgetEntity,
} from "web/core/domain/entities/WidgetEntity";
import { InlineCard } from "../InlineCard/InlineCard";
import { LongCard } from "../LongCard/LongCard";
import { QueryEntity } from "web/core/domain/entities/QueryEntity";

type Params = {
  widget: WidgetEntity;
  onClose: () => void;
};

export function WidgetDetailModal({ widget, onClose }: Params) {
  const getIems = () => {
    const skippedIndices: QueryEntity[] = [];
    let inlineCards: React.ReactNode[] = [];

    return widget.queries!.map((query, i) => {
      if (skippedIndices.includes(widget.queries![i])) {
        inlineCards.push(<InlineCard widget={widget} i={i} />);
      } else {
        skippedIndices.push(widget.queries![i + 1], widget.queries![i + 2]);
        inlineCards = [];
        return <LongCard widget={widget} i={i} />;
      }
      if (inlineCards.length > 1)
        return (
          <div className="grid grid-cols-2 gap-4">
            {inlineCards.map((e) => e)}
          </div>
        );
      else return <div id={i.toString()}></div>;
    });
  };

  return (
    <NxModal showModal={true} onOutsideClick={onClose}>
      <NxModalTitle
        title={widget.name!}
        subtitle={t("lastUpdate") + format(widget.createdAt!, " MM/yyyy")}
        onClosedTapped={onClose}
      />
      {getIems()}
      <div className="relative mb-12">
        <NxOutlinedButton className="h-8 grid grid-flow-col gap-3.5 px-4 place-content-center absolute right-0">
          <label
            className={CSName(FontStyles.bodyBold)
              .combine("text-nx-main-500 cursor-pointer")
              .build()}
          >
            {t("actions")}
          </label>
          <NxSVG
            className={CSName(`w-2 h-2`)
              .combine("fill-nx-main-500 m-auto")
              .build()}
          >
            <IconDropButton />
          </NxSVG>
        </NxOutlinedButton>
      </div>
    </NxModal>
  );
}
