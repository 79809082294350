import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { CSName } from "web/common/utils/ClassName";
import { useTranslation } from "react-i18next";
import { OrganizationTbodyParams } from "./params/OrganizationTbodyParams";
import { IconRemove } from "web/common/fonts/icons/components/IconRemove";
import { useOrgModal } from "../../context/OrgModalProvider";
import { format } from "date-fns";
import {
  ShowCreateOrEditOrganizationModal,
  ShowLeaveOrganizationModal,
} from "../../context/abstraction/OrganizationModal";
import { IconOrg } from "web/common/fonts/icons/components/IconOrg";

export function OrganizationTbody(params: OrganizationTbodyParams) {
  const { setModal, setOrganization } = useOrgModal();
  const { t } = useTranslation();

  const handleRemove = () => {
    setOrganization(params.data);
    setModal(new ShowLeaveOrganizationModal());
  };

  return (
    <div
      className={CSName("mt-4 pb-4 pl-6 flex flex-row text-nx-gray-200")
        .combine(
          params.showBorder
            ? "border-b-[0.5px] border-nx-gray-100 dark:border-nx-gray-500"
            : ""
        )
        .build()}
    >
      <div className={CSName("w-[24%]").combine().build()}>
        <div className="flex flex-row cursor-pointer" onClick={params.onClick}>
          <div className="flex flex-row mr-4 justify-center">
            <img
              className={CSName(
                "object-cover w-6 h-6 bg-nx-white mr-2 rounded-full"
              ).build()}
              src={params.data.thumbnail}
              alt=""
            />
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "text-start text-nx-gray-700 dark:text-nx-white cursor-pointer place-content-center"
                )
                .build()}
            >
              {params.data.name}
            </label>
          </div>
        </div>
      </div>
      <div className="w-[34.5%] flex items-center">
        <label
          className={CSName(FontStyles.caption)
            .combine("font-normal text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {params.data.categoryName}
        </label>
      </div>
      <div className="w-[19%] flex items-center">
        <label
          className={CSName(FontStyles.caption)
            .combine("font-normal text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {params.data.memberRole === "ADMIN"
            ? params.data.memberRole.charAt(0).toUpperCase() +
              params.data.memberRole.slice(1).toLowerCase()
            : t("viewer")}
        </label>
      </div>
      <div className="w-[18%] text-start  flex items-center">
        <div className="w-[18%] text-start  flex items-center">
          <label
            className={CSName(FontStyles.caption)
              .combine("font-normal text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {format(params.data.createdAt, "dd/MM/yyyy")}
          </label>
        </div>
      </div>
      <div className="mr-3 text-start  flex items-center">
        <NxDropElement
          options={[
            {
              icon: <IconOrg />,
              label: t("orgData"),
              onClick: () => {
                setModal(
                  new ShowCreateOrEditOrganizationModal({
                    createOrgParams: {
                      id: params.data.id,
                      name: params.data.name,
                      category: params.data.category,
                      s3Key: params.data.imageS3Key,
                    },
                  })
                );
              },
            },
            {
              icon: <IconRemove />,
              label: t("leaveOrganization"),
              onClick: () => handleRemove(),
            },
            
              ]}
              dimension={{
                x: 110,
                y: 10,
              }}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
            <IconMore />
          </NxSVG>
        </NxDropElement>
      </div>
    </div>
  );
}
