import { t } from "i18next";
import { IconFolder } from "web/common/fonts/icons/components/IconFolder";


export const NoOrganizationsFound = () => {
  return (
    <div
      className="pl-6 pr-8 pt-48 w-full h-full flex flex-col items-center justify-center
      text-nx-white
      "
    >
      <div className="w-[260px] flex flex-col justify-center items-center gap-2">
        <IconFolder />
        <p className="text-xs text-nx-gray-200">{t("noOrgCreated")}</p>
      </div>
    </div>
  );
};
