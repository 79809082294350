import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import {
  ItemEntity,
  WidgetCategoryEntity,
} from "web/features/widgets/domain/entities/WidgetCategoryEntity";

export abstract class WidgetsModal {}

export class HideWidgetModal extends WidgetsModal {
  reload?: boolean;
  constructor({ reload = false }: Partial<HideWidgetModal>) {
    super();
    this.reload = reload;
  }
}

export class ShowCreateWidgetModal extends WidgetsModal {
  widgetCategoriesEntity: WidgetCategoryEntity[];
  widgets: WidgetEntity[];
  onClick: () => void;
  onCreated: () => void;
  constructor({
    widgetCategoriesEntity,
    widgets,
    onClick,
    onCreated,
  }: Required<ShowCreateWidgetModal>) {
    super();
    this.widgetCategoriesEntity = widgetCategoriesEntity;
    this.widgets = widgets;
    this.onClick = onClick;
    this.onCreated = onCreated;
  }
}

export class ShowRemoveWidgetModal extends WidgetsModal {
  widget: WidgetEntity;
  onKeep: () => void;
  onRemove: () => void;
  constructor({ widget, onKeep, onRemove }: Required<ShowRemoveWidgetModal>) {
    super();
    this.widget = widget;
    this.onKeep = onKeep;
    this.onRemove = onRemove;
  }
}

export class ShowWidgetDetailModal extends WidgetsModal {
  widget: WidgetEntity;
  onClose: () => void;
  constructor({ widget, onClose }: Required<ShowWidgetDetailModal>) {
    super();
    this.widget = widget;
    this.onClose = onClose;
  }
}

export class ShowWidgetLocationDetailModal extends WidgetsModal {
  widget: WidgetEntity;
  onClose: () => void;
  constructor({ widget, onClose }: Required<ShowWidgetLocationDetailModal>) {
    super();
    this.widget = widget;
    this.onClose = onClose;
  }
}

export class ShowWidgetLocationModal extends WidgetsModal {
  item: ItemEntity;
  onClose: () => void;
  onCreated: () => void;
  constructor({ item, onClose, onCreated }: Required<ShowWidgetLocationModal>) {
    super();
    this.item = item;
    this.onClose = onClose;
    this.onCreated = onCreated;
  }
}
