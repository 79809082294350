import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";

interface NxShowSnackBarParams {
  type: NxSnackbarType;
  label: string;
  showIcon?: boolean;
}

interface NxSnackbarProviderProps {
  showSnackbar(modal: NxShowSnackBarParams): void;
}

const NxSnackBarContext = createContext<NxSnackbarProviderProps>(
  {} as NxSnackbarProviderProps
);

export const useNxSnackBarContext = () => useContext(NxSnackBarContext);

export const NxSnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const nxSnackbarPropsRef = useRef<NxShowSnackBarParams>();

  const showSnackbarCallback = useCallback(
    (props: NxShowSnackBarParams) => {
      nxSnackbarPropsRef.current = props;
      setShowSnackbar(true);
    },
    [setShowSnackbar]
  );

  return (
    <NxSnackBarContext.Provider
      value={{
        showSnackbar: showSnackbarCallback,
      }}
    >
      <div className="w-full h-full">{children}</div>
      {nxSnackbarPropsRef.current && (
        <NxSnackbar
          type={nxSnackbarPropsRef.current.type}
          label={nxSnackbarPropsRef.current.label}
          showIcon={nxSnackbarPropsRef.current.showIcon}
          showSnackbar={showSnackbar}
          onSnackbarClose={() => {
            nxSnackbarPropsRef.current = undefined;
            setShowSnackbar(false);
          }}
        />
      )}
    </NxSnackBarContext.Provider>
  );
};
