import { MapComponent } from "web/common/components/Maps/MapComponent";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { LocationCreateMiniAreaPopMenu } from "./LocationCreateMiniAreaPopMenu";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { t } from "i18next";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ILocationInputs } from "./LocationsCreateSideMenu";
import { validateLocationName } from "web/common/forms/validators/ValidateLocationName";

interface LocationCreateMiniAreaProps {
  draw: DrawLayer | EmptyLayer;
  onEditClick: () => void;
  onDeleteClick: () => void;
  register: UseFormRegister<ILocationInputs>;
  errors: FieldErrors<ILocationInputs>;
}

export const LocationCreateMiniArea = ({
  draw,
  onEditClick,
  onDeleteClick,
  register,
  errors,
}: LocationCreateMiniAreaProps) => {
  return (
    <div className="flex flex-col">
      <NxFormField
        register={register("name", { validate: validateLocationName })}
        labelText={t("locationName")}
        error={errors.name}
      />
      <MapComponent
        initialLayers={draw instanceof EmptyLayer ? undefined : [draw]}
        className={"h-24 w-full my-2 rounded-lg overflow-hidden"}
        isReadOnly={true}
        zoomControl={false}
      />
      {!(draw instanceof EmptyLayer) && (
        <div className=" flex items-center mb-4">
          <div className="text-caption text-nx-gray-200 grow">
            {draw.sizeHa && `Área: ${draw.sizeHa}`}
          </div>
          <LocationCreateMiniAreaPopMenu
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
          />
        </div>
      )}
    </div>
  );
};
