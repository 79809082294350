import { RouteObject } from "react-router-dom";
import { MyProfileRoutes } from "web/features/my-profile/routes/MyProfileRoutes";
import { OrganizationRoutes } from "web/features/organizations/routes/OrganizationRoutes";
import { ManagementOutlet } from "../presentation/pages/ManagementOutlet";

export const ManagementRoutes: RouteObject = {
  children: [
    {
      path: "",
      element: <ManagementOutlet />,
      children: [
        MyProfileRoutes,
        OrganizationRoutes,
        {
          path: "help",
          element: <div>Help</div>,
        },
      ],
    },
  ],
};