export class Icons {
  static message = (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="5" width="15" height="11" rx="0.5" stroke="#667C8E" />
      <path
        d="M3.5 5.5L9.88218 10.5146C10.2448 10.7995 10.7552 10.7995 11.1178 10.5146L17.5 5.5"
        stroke="#667C8E"
      />
    </svg>
  );

  static password = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 9C4.5 8.17157 5.17157 7.5 6 7.5H14C14.8284 7.5 15.5 8.17157 15.5 9V12C15.5 15.0376 13.0376 17.5 10 17.5C6.96243 17.5 4.5 15.0376 4.5 12V9Z"
        stroke="#667C8E"
      />
      <path
        d="M7 7.5V5C7 3.34315 8.34315 2 10 2V2C11.6569 2 13 3.34315 13 5V7.5"
        stroke="#667C8E"
      />
      <circle cx="10" cy="12" r="1" fill="#667C8E" />
      <path
        d="M9.5 14C9.5 14.2761 9.72386 14.5 10 14.5C10.2761 14.5 10.5 14.2761 10.5 14H9.5ZM9.5 12V14H10.5V12H9.5Z"
        fill="#667C8E"
      />
    </svg>
  );
}
