import { useState } from "react";
import { RestorePanelUsecase } from "../../domain/usecases/RestorePanelUsecase";
import { RestorePanelFailure } from "../../domain/errors/RestorePanelFailure";

abstract class RestorePanelState {}

export class RestoringPanel extends RestorePanelState {}

export class PanelRestored extends RestorePanelState {}

export class PanelRestaurationFailure extends RestorePanelState {}

export const useRestorePanelHook = () => {
  const [resotrePanelState, setState] = useState<RestorePanelState>();

  return {
    resotrePanelState,
    restorePanel: async (id: string) => {
      setState(new RestoringPanel());
      
      const result = await RestorePanelUsecase(id);

      if (result instanceof RestorePanelFailure) {
        return setState(new PanelRestaurationFailure());
      }
      return setState(new PanelRestored());
    },
  };
};
