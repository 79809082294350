import { useState } from "react";

// import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { restoreProjectUsecase } from "../../domain/usecase/RestoreProjectUsecase";

abstract class RestoreProjectState {}

export class RestoreProjectLoading extends RestoreProjectState {}

export class RestoreProjectSuccess extends RestoreProjectState {}

export class RestoreProjectFailure extends RestoreProjectState {}

export const useRestoreProjectHook = () => {
  const [restoreProjectState, setState] = useState<RestoreProjectState>();

  return {
    restoreProjectState,
    restoreProject: async (id: string) => {
      setState(new RestoreProjectLoading());

      await restoreProjectUsecase(id);

      setTimeout(() => {
        return setState(new RestoreProjectSuccess());
      }, 1000);
    },
  };
};