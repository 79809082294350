import { useState } from "react";

export const useNxButton = (onClick?: () => void | Promise<void>) => {
  const [loadingState, setLoading] = useState(false);

  const clickWithLoading = async () => {
    if (!onClick || loadingState) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return {
    loadingState,
    clickWithLoading,
    setLoading,
  };
};
