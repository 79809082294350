import { useState } from "react";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { IconCattle } from "web/common/fonts/icons/components/IconCattle";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import { IconHandEsg } from "web/common/fonts/icons/components/IconHandESG";
import { IconProduct } from "web/common/fonts/icons/components/IconProduct";
import { IconSecurity } from "web/common/fonts/icons/components/IconSecurity";
import { IconSheet } from "web/common/fonts/icons/components/IconSheet";
import { IconWeather } from "web/common/fonts/icons/components/IconWeather";
import { CSName } from "web/common/utils/ClassName";
import { WidgetsCategoryEnum } from "web/features/widgets/domain/enum/WidgetsCategoryEnum";
import { WidgetsCategoryMock } from "web/features/widgets/domain/mocks/widgetsCategoryMock";

const HomeNavAccordionWidgetsDescription = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const contentWidgets = [
    "Trabalho análogo a escravidão",
    "Sustentabilidade",
    "Governança",
  ];

  const contentWidgetIcon = {
    [WidgetsCategoryEnum.esg]: <IconHandEsg />,
    [WidgetsCategoryEnum.clima]: <IconWeather />,
    [WidgetsCategoryEnum.produtos]: <IconProduct />,
    [WidgetsCategoryEnum.seguro]: <IconSecurity />,
    [WidgetsCategoryEnum.agricultura]: <IconSheet />,
    [WidgetsCategoryEnum.pecuaria]: <IconCattle />,
  };

  return (
    <div className="space-y-1">
      {WidgetsCategoryMock.data.results.map((widget, index) => (
        <div key={index} className="group">
          <button
            className="flex justify-between items-center hover:bg-nx-focus-dark p-2 rounded w-full"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex gap-2 items-center text-start">
              <NxSVG
                className={CSName(
                  `w-6 h-6 mt-1 group-hover:stroke-nx-main-500 stroke-nx-gray-400 fill-nx-gray-400 group-hover:fill-nx-main-500 active:transition duration-100`
                ).build()}
              >
                {
                  contentWidgetIcon[
                    widget.category_widget.toUpperCase() as WidgetsCategoryEnum
                  ]
                }
              </NxSVG>
              <div>
                <h1 className="dark:text-nx-white text-captionLarge">
                  {widget.category_widget}
                </h1>
                <p className="text-nx-gray-200 text-captionMedium">
                  {widget.description}
                </p>
              </div>
            </div>
            <NxSVG
              className={CSName(
                `w-5 h-5 mt-1 stroke-nx-main-500 active:transition duration-100 ${
                  openIndex === index ? "rotate-90" : ""
                }`
              ).build()}
            >
              <IconArrowRight />
            </NxSVG>
          </button>
          <div
            className={`transition-all overflow-hidden ${
              openIndex === index ? "max-h-screen" : "max-h-0"
            }`}
          >
            <div className="space-y-1 my-2">
              {contentWidgets.map((contentWidgets, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-between w-full p-2 rounded cursor-pointer hover:bg-nx-focus-dark "
                  >
                    <p className="text-captionLarge dark:text-nx-white text-nx-black">
                      {contentWidgets}
                    </p>
                    <div className="flex flex-row items-center">
                      <label
                        className={CSName(FontStyles.bodyRegular)
                          .combine(
                            "dark:text-nx-main-200 text-nx-dark-600 text-start"
                          )
                          .build()}
                      >
                        20
                      </label>
                      <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5 ml-1.5">
                        <IconCoin />
                      </NxSVG>
                      <label
                        className={CSName(FontStyles.bodyRegular)
                          .combine(
                            "dark:text-nx-main-200 text-nx-dark-600 text-start"
                          )
                          .build()}
                      >
                        /Consulta
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeNavAccordionWidgetsDescription;
