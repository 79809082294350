export const IconErrorReload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="92"
      viewBox="0 0 58 92"
      fill="none"
    >
      <path
        d="M43.0481 7.66377C43.0693 10.1196 41.8849 22.6174 41.5229 26.3893C40.7709 34.2245 39.6711 42.0315 38.9402 49.8691C38.5924 51.0747 35.6242 52.3696 34.2447 53.3919C34.2306 53.7115 39.3279 55.9065 39.6452 56.7384C38.9566 56.8653 28.6209 62.6254 27.319 63.4291C27.3096 63.4338 27.3542 63.2764 27.1427 63.3116C26.5575 64.3151 26.5528 63.8521 26.4964 63.8991L23.3262 61.4339C23.6434 60.4821 27.514 58.4211 28.5504 57.6761C29.058 57.3118 32.6114 54.6891 32.7171 54.4471C33.1026 53.5822 30.1673 55.7678 28.2567 56.3835C27.2485 47.0584 26.527 37.6815 25.7914 28.3258C25.4859 24.4317 25.1945 20.5305 24.8514 16.6435L25.3214 16.3497C27.9041 15.1911 41.0365 7.61207 41.9342 7.30891C42.1293 7.24311 42.5264 7.41937 42.8155 7.19141C42.8507 7.30656 42.9377 7.57682 43.0505 7.66142L43.0481 7.66377Z"
        fill="url(#paint0_linear_139_2951)"
      />
      <path
        d="M14.7557 10.7155C16.1023 11.2678 23.122 15.6531 23.6202 15.7635C23.8881 15.8223 24.0338 15.6625 24.2665 15.646C25.27 15.5779 25.4204 15.1361 26.3792 14.941C26.0056 15.1549 24.8023 15.7118 24.619 15.881C24.5414 15.9515 24.2994 16.0667 24.2665 16.1748C24.5344 20.2593 24.8799 24.3555 25.2065 28.4423C25.787 35.7229 26.257 43.0693 26.9667 50.3381C27.056 51.2593 27.606 56.0324 27.6718 56.3261C27.6812 56.3637 27.8339 56.5094 27.9068 56.5024C27.8927 56.5047 27.9138 56.6034 27.7305 56.7374C27.6718 56.5259 27.4955 56.4624 27.3193 56.3849C27.4697 56.4906 27.4462 55.1111 27.4368 55.0359C27.425 54.9419 27.2135 55.0124 27.2017 54.9184C27.1383 54.3967 27.3287 54.1805 27.3193 54.0959C27.3075 53.9878 26.8492 53.3744 27.0842 52.2182C26.2476 50.787 26.5602 49.21 26.4967 48.6954C26.1372 45.746 25.6859 42.8037 25.4979 39.8308C25.183 41.3772 25.0303 42.7097 24.2641 44.115C24.062 44.4887 19.4535 51.0267 18.8636 50.3968L14.2856 10.833C14.5794 10.3842 14.6405 10.7461 14.7557 10.7155Z"
        fill="url(#paint1_linear_139_2951)"
      />
      <path
        d="M43.0482 7.66308C42.9354 7.57848 42.8484 7.30822 42.8132 7.19306C42.792 7.12491 43.0811 6.47863 41.6969 6.72304C36.7076 9.66301 31.4035 12.0813 26.3766 14.9413C25.4178 15.134 25.265 15.5782 24.2639 15.6463C26.997 13.4819 32.4116 11.3363 34.1836 9.18829C30.3154 8.93683 26.3649 10.4174 23.8526 10.596C21.3897 10.7723 16.8094 10.2082 14.8118 10.4785C14.7131 10.4926 14.7648 10.7112 14.7531 10.7135C14.6379 10.7441 14.5768 10.3821 14.283 10.831L14.1655 10.5372C19.9538 7.07556 25.9959 3.92408 31.8335 0.5C33.3681 1.33898 42.2914 6.06737 42.752 6.48803C42.9307 6.65254 43.0364 6.81235 43.1633 7.01681C43.0576 7.25181 43.0435 7.40457 43.0458 7.66308H43.0482Z"
        fill="url(#paint2_linear_139_2951)"
      />
      <path
        d="M27.3169 56.3857C26.7529 56.1366 24.1725 54.4281 23.9704 54.3905C23.0515 54.5667 22.1702 55.0673 21.3289 55.448L23.4416 54.099C22.0222 52.9475 18.4994 51.7842 18.8637 50.4C19.4535 51.0322 24.0597 44.4919 24.2642 44.1182C25.0303 42.7128 25.1807 41.378 25.498 39.834C25.686 42.8069 26.1348 45.7468 26.4968 48.6985C26.5602 49.2109 26.2476 50.7878 27.0843 52.2213C26.8493 53.3799 27.3075 53.9909 27.3193 54.099C27.3287 54.1837 27.1383 54.3975 27.2018 54.9216C27.2135 55.0156 27.425 54.9451 27.4368 55.0391C27.4462 55.1143 27.4697 56.4938 27.3193 56.388L27.3169 56.3857Z"
        fill="#77A9BA"
      />
      <path
        d="M25.7892 28.3256C26.5271 37.6837 27.2462 47.0582 28.2544 56.3833C28.2356 56.3881 28.3602 56.4186 27.9019 56.5009C27.8291 56.5079 27.6763 56.3622 27.6669 56.3246C27.6011 56.0308 27.0535 51.2578 26.9619 50.3366C26.2522 43.0701 25.7821 35.7213 25.2017 28.4407L25.7892 28.3232V28.3256Z"
        fill="#ECF5F8"
      />
      <path
        d="M42.8134 7.19372C42.5243 7.42167 42.1271 7.24542 41.9321 7.31122C41.0367 7.61438 27.9044 15.1934 25.3193 16.352C25.0796 16.1029 24.5061 16.7539 24.6142 15.882C24.7976 15.7152 26.0008 15.1558 26.3745 14.942C31.4013 12.0843 36.7055 9.66366 41.6947 6.7237C43.0789 6.47694 42.7899 7.12556 42.811 7.19372H42.8134Z"
        fill="#ECF5F8"
      />
      <path
        d="M25.3217 16.3515L24.8517 16.6452C25.1948 20.5299 25.4839 24.4334 25.7917 28.3275L25.2042 28.445C24.8775 24.3582 24.5344 20.262 24.2642 16.1776C24.2947 16.0694 24.5391 15.9543 24.6167 15.8838C24.5062 16.7557 25.0796 16.1047 25.3217 16.3538V16.3515Z"
        fill="#D9E8ED"
      />
      <path
        d="M24.2639 15.6465C24.0312 15.6629 23.8855 15.8227 23.6176 15.764C23.1194 15.6535 16.0996 11.2659 14.753 10.716C14.7648 10.7136 14.7131 10.4951 14.8118 10.481C16.8094 10.2107 21.3897 10.7747 23.8526 10.5985C26.3648 10.4199 30.3154 8.93932 34.1836 9.19078C32.4116 11.3364 26.9947 13.482 24.2639 15.6488V15.6465Z"
        fill="#B9D4DC"
      />
      <path
        d="M23.3237 61.4335C22.7785 63.0692 25.3236 63.3347 26.2002 64.3688C26.5833 64.3758 26.4728 63.9176 26.494 63.8988C26.548 63.8518 26.5551 64.3147 27.1403 63.3112C27.3518 63.276 27.3071 63.4334 27.3165 63.4287C27.3353 63.6003 27.1027 63.5086 27.0815 63.605C26.9922 64.0045 27.3471 70.7046 27.434 71.7645C27.1661 72.3121 26.7078 71.5342 26.3765 71.1182C25.514 70.0301 25.0416 68.888 24.9688 68.7705C24.5128 68.0537 23.93 67.7858 23.9112 67.713C23.8924 67.6401 24.6703 66.8693 23.6175 66.5967C22.1111 66.2065 19.2862 68.0067 17.9232 68.7094C17.9161 68.6718 17.9302 68.6295 17.9232 68.5919C17.8268 67.9832 17.7634 67.3275 17.6882 66.7142C17.6177 66.1384 17.5143 65.518 17.4532 64.954C17.4414 64.8552 17.4038 63.7765 17.3357 62.9587C17.2675 62.1362 17.1289 61.2878 17.1007 60.846C17.0372 59.8566 17.0584 58.8437 16.9832 57.852C17.0678 57.6029 20.7997 55.6828 21.3261 55.4455C22.1675 55.0648 23.0487 54.5642 23.9676 54.3879C24.1674 54.4279 26.7501 56.1341 27.3142 56.3832C27.4904 56.4607 27.6667 56.5242 27.7254 56.7357C27.9087 56.6017 27.8876 56.503 27.9017 56.5007C28.36 56.4184 28.2354 56.3879 28.2542 56.3832C30.1672 55.7674 33.1001 53.5795 32.7147 54.4467C32.6066 54.6864 29.0556 57.3091 28.548 57.6757C27.5116 58.4207 23.641 60.4817 23.3237 61.4335Z"
        fill="#77A9BA"
      />
      <path
        d="M40.7004 60.6101C40.7192 61.2611 40.5829 62.039 40.5829 62.7228C40.5829 63.4443 40.7192 64.2645 40.7004 64.9531C40.6863 65.4207 40.4983 66.1046 40.4654 66.7133C40.4278 67.4348 40.5312 68.2408 40.4654 68.9435C38.0918 68.3443 36.8368 66.725 36.6488 66.5958C36.5595 66.5347 35.1988 65.8391 34.7711 65.5382C34.6583 65.4583 33.6078 64.3844 32.6584 63.778C31.8899 63.2869 30.9757 63.0565 30.3694 62.603C33.6619 60.7065 37.0131 58.9039 40.3479 57.085C40.7098 57.7077 40.6769 59.7335 40.7004 60.6077V60.6101Z"
        fill="#B9D4DC"
      />
      <path
        d="M40.348 57.0883C37.0132 58.9073 33.6619 60.7098 30.3695 62.6063C29.9605 62.8413 27.6245 63.9083 27.5517 64.1903L28.4917 76.1053C28.3601 76.1241 28.1886 76.4108 27.9042 76.0465C27.9771 75.459 27.8008 75.2428 27.7867 75.1652C27.5775 74.0983 27.5235 72.8504 27.4342 71.7599C27.3472 70.7 26.9924 63.9999 27.0817 63.6004C27.1028 63.5041 27.3355 63.5957 27.3167 63.4242C28.6186 62.6204 38.9543 56.8603 39.6429 56.7334C39.7745 56.7099 39.9461 56.395 40.348 57.086V57.0883Z"
        fill="#ECF5F8"
      />
      <path
        d="M26.4941 63.8979C26.4729 63.9167 26.5834 64.3749 26.2003 64.3679C25.3261 63.3338 22.7786 63.0683 23.3238 61.4326L26.4941 63.8979Z"
        fill="#B1CFD9"
      />
      <path
        d="M27.4342 71.7638C27.5235 72.8543 27.5752 74.1022 27.7867 75.1691C27.5869 75.3242 27.7608 75.7566 27.2579 75.7566C26.7291 75.7566 19.4039 70.8614 18.1583 70.4149C18.1207 69.8414 18.0173 69.2774 17.9233 68.7134C19.2864 68.0084 22.1112 66.2105 23.6176 66.6007C24.6705 66.8733 23.8926 67.6441 23.9114 67.717C23.9302 67.7898 24.513 68.0577 24.9689 68.7745C25.0441 68.892 25.5141 70.0342 26.3766 71.1222C26.7056 71.5382 27.1663 72.3161 27.4342 71.7685V71.7638Z"
        fill="#5E98AC"
      />
      <path
        d="M30.3695 62.6064C30.9758 63.06 31.89 63.2903 32.6585 63.7815C33.6079 64.3902 34.6584 65.4642 34.7712 65.5417C35.2013 65.8425 36.562 66.5358 36.649 66.5993C36.837 66.7285 38.0896 68.3477 40.4655 68.947C40.435 69.2596 40.5149 69.6168 40.2893 69.887C39.5419 70.4205 38.6818 70.7495 37.8828 71.1796C36.5691 71.8846 29.0911 76.0278 28.4918 76.1101L27.5518 64.1951C27.6246 63.9131 29.9606 62.8462 30.3695 62.6112V62.6064Z"
        fill="url(#paint3_linear_139_2951)"
      />
      <path
        d="M56.6673 70.0017C53.0928 72.2179 49.3985 74.2507 45.7488 76.3423C43.7042 77.5126 30.6259 85.2021 29.7235 85.3831C27.7142 85.7849 23.8436 82.7087 21.9165 81.7428C21.9612 81.7122 22.3983 81.2704 22.739 81.0378C23.0093 80.8544 23.383 80.7604 23.5616 80.4502C23.5992 80.4502 23.6438 80.4549 23.6791 80.4502C25.6108 81.7428 28.0244 83.5148 30.4285 82.798C31.6365 82.4384 49.3491 71.9147 52.3243 70.2955C52.6863 69.5834 52.1457 69.4776 51.6781 69.0617L40.5833 62.7211C40.5833 62.0373 40.7172 61.257 40.7008 60.6084C43.2342 62.237 55.4711 68.8314 56.3736 69.5317C56.7472 69.8208 56.6462 69.9829 56.6673 70.0017Z"
        fill="#D9E8ED"
      />
      <path
        d="M2.42711 71.882C4.83125 73.3579 7.37406 74.6998 9.82285 76.1075C11.0049 76.7866 18.5111 81.3223 19.0399 81.3905C19.2044 81.4116 20.074 81.2424 20.2737 81.038C21.4911 81.7007 21.5099 81.6349 22.739 81.038C22.3982 81.2683 21.9611 81.7124 21.9164 81.743C21.6908 81.8934 21.0892 82.2342 20.7414 82.448C20.0223 82.8898 19.1668 83.2447 18.6286 83.6231C15.973 82.1284 3.44235 75.1063 2.36836 74.3496C1.29437 73.5905 0.500037 73.0852 0.431885 71.6493C0.504738 71.1629 0.549389 71.0219 0.784399 70.5918C1.21447 71.2545 1.79494 71.4942 2.42711 71.8843V71.882Z"
        fill="#7DADBD"
      />
      <path
        d="M57.3697 70.7063C57.9078 71.8696 57.7292 75.578 56.1946 76.1068C53.6565 77.2725 51.3276 78.8282 48.9164 80.2148C48.4229 80.4991 47.8917 80.7459 47.3912 81.0373C47.269 81.1078 47.1444 81.2112 47.0387 81.2723C46.625 81.5144 46.1762 81.7212 45.7461 81.9774C45.7273 81.9891 45.6545 82.0103 45.5111 82.0949C45.0434 82.3745 44.564 82.6236 44.1034 82.9174C43.9835 82.9926 43.8684 83.0772 43.7509 83.1524C43.1751 83.2911 42.7239 83.6906 42.2257 83.9749C39.697 85.4085 37.1354 86.7903 34.5949 88.2004L33.8899 86.7927C34.0426 86.7104 34.2072 86.6399 34.3599 86.5577C36.9333 85.1641 55.0219 74.866 56.0207 74.1139C57.3415 73.1175 57.7175 72.4407 57.137 70.8262C57.1464 70.8168 57.1018 70.6664 57.372 70.7087L57.3697 70.7063Z"
        fill="#97BECB"
      />
      <path
        d="M18.6283 83.6202C18.6283 83.6202 18.5907 83.6508 18.7458 83.7377C18.3721 84.0268 17.183 84.2618 17.3968 84.6777C20.1041 86.2617 22.8843 87.8504 25.6151 89.2557C26.5293 89.7257 26.4094 90.1676 28.3741 90.5483C28.3858 90.5483 28.3341 90.7692 28.4328 90.7833C29.159 90.8796 30.139 90.6352 30.8393 90.4308C30.3482 91.1687 28.3788 91.1005 27.6103 90.9008C27.0251 90.748 18.546 85.7306 17.1031 84.9128C14.948 83.6907 2.49018 76.6381 1.7828 76.107C0.579554 75.2069 0.635955 75.1176 0.433847 73.6417C0.332793 72.9085 0.323393 72.3915 0.433847 71.6465C0.502 73.0824 1.29633 73.5877 2.37032 74.3467C3.44432 75.1035 15.975 82.1279 18.6306 83.6202H18.6283Z"
        fill="#5491A7"
      />
      <path
        d="M17.1032 60.8461C17.1314 61.2879 17.2701 62.1387 17.3382 62.9588C17.1972 62.9776 17.0045 62.9283 16.927 62.9588C16.2901 63.2103 6.67116 68.9492 5.12715 69.8846C4.50438 70.2629 3.8722 70.6953 3.24942 71.0596C2.46684 71.5155 1.98742 71.0432 2.42689 71.8821C1.79237 71.4944 1.21425 71.2523 0.78418 70.5896C1.02154 70.1572 1.3905 69.7106 1.78297 69.4145C2.79116 68.6578 14.5393 62.4606 17.1032 60.8438V60.8461Z"
        fill="#D9E8ED"
      />
      <path
        d="M56.6673 70.001C56.7026 70.0362 57.0269 69.9587 57.3723 70.706C57.1021 70.6637 57.1467 70.8141 57.1373 70.8235C56.2396 71.8199 47.9955 76.1935 46.1013 77.2816C46.0543 76.788 46.0167 77.3168 45.8663 77.2228L45.7488 76.3415C49.3985 74.2523 53.0905 72.2195 56.6673 70.001Z"
        fill="#FAFEFF"
      />
      <path
        d="M17.9235 68.7113C18.0175 69.2753 18.1209 69.8393 18.1585 70.4127C19.4017 70.8592 26.7293 75.7545 27.258 75.7545C27.7609 75.7545 27.587 75.3221 27.7868 75.167C27.8009 75.2445 27.9772 75.4584 27.9043 76.0482C28.1863 76.4125 28.3602 76.1258 28.4918 76.107C29.0935 76.0247 36.5714 71.8815 37.8828 71.1765C36.8394 72.1212 33.4693 73.3151 34.36 73.7005C31.3284 75.6182 28.2592 77.5076 25.2017 79.3948C23.4156 78.8425 13.2491 72.2763 12.5229 72.234L17.9235 68.5938C17.9305 68.6314 17.9164 68.6737 17.9235 68.7113Z"
        fill="#37819A"
      />
      <path
        d="M17.3383 62.9589C17.4041 63.7791 17.444 64.8554 17.4558 64.9541C16.2032 65.7672 10.2598 68.914 9.88379 69.2971C9.01425 70.1831 9.74748 70.4181 10.4126 70.9398C11.0471 71.438 11.8038 71.7012 12.4078 72.2323C12.1422 72.27 11.1458 73.0784 11.4677 73.6401C11.0753 73.4144 10.798 73.3087 10.7627 73.2875C8.86619 72.1947 6.99552 71.0244 5.1272 69.8823C6.66886 68.9446 16.2901 63.2056 16.927 62.9565C17.0046 62.926 17.1949 62.9753 17.3383 62.9565V62.9589Z"
        fill="#B9D4DC"
      />
      <path
        d="M17.6906 66.7153L10.4124 70.9408C9.74732 70.419 9.01409 70.184 9.88362 69.2981C10.2596 68.915 16.203 65.7682 17.4556 64.9551C17.5167 65.5191 17.6201 66.1395 17.6906 66.7153Z"
        fill="#5491A7"
      />
      <path
        d="M17.9234 68.5935L12.5229 72.2338C12.4853 72.2338 12.4406 72.2291 12.4054 72.2338C11.8014 71.7027 11.0447 71.4419 10.4102 70.9413L17.6884 66.7158C17.7636 67.3292 17.827 67.9872 17.9234 68.5935Z"
        fill="#93BBC9"
      />
      <path
        d="M23.6765 80.451C23.8481 80.4251 24.8962 79.5015 25.2017 79.5109C25.3004 79.5133 26.1794 80.0773 26.9619 80.451C27.2369 80.5826 28.3814 81.767 29.721 81.391C30.3696 81.21 39.9486 75.5604 41.4033 74.7003C42.724 73.9177 44.0988 73.1704 45.3937 72.3525C46.0705 71.9272 46.7497 71.5488 47.3889 71.06C47.4783 70.9918 48.7473 70.5007 48.564 69.711C48.4418 69.187 41.6665 65.596 40.6982 64.9568C40.717 64.2682 40.5807 63.448 40.5807 62.7266L51.6755 69.0671C52.1432 69.4807 52.6837 69.5865 52.3218 70.3009C49.3466 71.9225 31.6339 82.4438 30.426 82.8034C28.0195 83.5202 25.6083 81.7482 23.6765 80.4557V80.451Z"
        fill="#FAFEFF"
      />
      <path
        d="M46.1011 77.2828C41.8733 79.7081 37.7113 82.3755 33.4224 84.6786C31.923 85.4846 30.2028 86.756 28.4332 86.5563C26.9667 86.3894 22.471 83.1157 20.7437 82.4483C21.0915 82.2345 21.6931 81.8937 21.9187 81.7433C23.8458 82.7068 27.7164 85.7831 29.7257 85.3836C30.6281 85.2026 43.7064 77.5155 45.751 76.3428L45.8685 77.2241C46.0189 77.3181 46.0565 76.7893 46.1035 77.2828H46.1011Z"
        fill="#ECF5F8"
      />
      <path
        d="M11.4677 73.6426C12.9576 74.498 22.8656 80.4296 23.5589 80.4508C23.3803 80.761 23.009 80.8526 22.7364 81.0383C21.5073 81.6352 21.4885 81.701 20.2711 81.0383C20.2547 81.0289 20.3299 80.8174 20.0949 80.6858C16.8752 78.8786 13.611 77.123 10.4101 75.2853C10.389 75.2735 10.3232 75.0855 10.0576 74.9328L10.7626 73.29C10.7979 73.3088 11.0752 73.4169 11.4677 73.6426Z"
        fill="#8FB8C7"
      />
      <path
        d="M12.523 72.2344C13.2491 72.2767 23.418 78.8428 25.2017 79.3951V79.5126C24.8962 79.5032 23.8481 80.4244 23.6765 80.4526C23.6412 80.4573 23.5966 80.4526 23.559 80.4526C22.8657 80.4315 12.9577 74.4999 11.4678 73.6444C11.1458 73.0828 12.1399 72.272 12.4078 72.2367C12.4431 72.232 12.4877 72.2367 12.5253 72.2367L12.523 72.2344Z"
        fill="#97BECB"
      />
      <path
        d="M42.4609 67.8893C40.1625 69.3041 45.7886 70.5755 45.3962 72.3498C44.0989 73.1676 42.7265 73.9149 41.4057 74.6975C41.3 73.8374 42.1484 73.8139 42.2283 73.5812C42.7147 72.1383 40.2401 71.9761 39.5868 71.7623L26.9668 80.4506C26.1842 80.0769 25.3052 79.5152 25.2065 79.5105V79.393C28.264 77.5059 31.3309 75.6164 34.3648 73.6987C33.4718 73.3133 36.8419 72.1195 37.8876 71.1747C38.6867 70.7447 39.5468 70.418 40.2941 69.8822C40.5197 69.6119 40.4398 69.2547 40.4704 68.9422C40.5362 68.2395 40.4328 67.4334 40.4704 66.7119C41.1073 67.1467 41.8005 67.4968 42.4656 67.887L42.4609 67.8893Z"
        fill="#B5D1DB"
      />
      <path
        d="M47.3915 71.0587C47.502 70.8613 47.5466 70.8683 47.3328 70.7061C47.1072 70.5369 43.4434 68.4618 42.461 67.8884C41.796 67.5006 41.1027 67.1505 40.4658 66.7133C40.4987 66.1023 40.6867 65.4208 40.7008 64.9531C41.6691 65.5924 48.4444 69.1833 48.5666 69.7074C48.7522 70.4993 47.4832 70.9882 47.3915 71.0563V71.0587Z"
        fill="#5491A7"
      />
      <path
        d="M33.4222 84.6779C33.8146 85.2396 34.3645 85.8647 34.3622 86.5556C34.2094 86.6379 34.0449 86.7084 33.8922 86.7906C31.9745 87.8176 31.0086 88.9974 28.6092 88.7859C28.2707 88.7553 27.8266 88.7671 27.4929 88.6684C27.0111 88.525 20.0266 84.457 18.7458 83.7379C18.5907 83.6509 18.6354 83.6251 18.6283 83.6204C19.1665 83.2443 20.0219 82.8895 20.7411 82.4453C22.4684 83.1127 26.9641 86.3888 28.4306 86.5533C30.2002 86.753 31.9204 85.4793 33.4198 84.6755L33.4222 84.6779Z"
        fill="#B1CFD9"
      />
      <path
        d="M20.2736 81.0372C20.0739 81.2417 19.2043 81.4109 19.0398 81.3897C18.5134 81.3216 11.0049 76.7859 9.82275 76.1067L10.4103 75.2842C13.6111 77.122 16.8754 78.8775 20.095 80.6847C20.33 80.8163 20.2548 81.0278 20.2713 81.0372H20.2736Z"
        fill="#B1CFD9"
      />
      <path
        d="M10.0579 74.9345C10.3235 75.0872 10.3893 75.2752 10.4104 75.287L9.82289 76.1095C7.37409 74.6995 4.83129 73.3576 2.42715 71.8841C1.98768 71.0451 2.46475 71.5198 3.24968 71.0615C5.45407 72.4622 7.79711 73.6372 10.0579 74.9368V74.9345Z"
        fill="#B9D4DC"
      />
      <path
        d="M34.5952 88.2007C33.5588 88.7764 33.4531 89.371 33.3614 88.9057C32.5741 89.3052 31.6294 90.2006 30.8374 90.4309C30.1371 90.6354 29.1571 90.8798 28.4309 90.7834C28.3322 90.7693 28.3839 90.5508 28.3722 90.5484L28.6072 88.7882C31.0066 88.9974 31.9725 87.8176 33.8902 86.793L34.5952 88.2007Z"
        fill="#7DADBD"
      />
      <path
        d="M34.3602 86.5557C34.3626 85.8648 33.815 85.2373 33.4202 84.678C37.7091 82.3749 41.8735 79.7076 46.0989 77.2823C47.9931 76.1942 56.2349 71.8207 57.1349 70.8242C57.7178 72.4387 57.3418 73.1156 56.0187 74.112C55.0222 74.864 36.9312 85.1621 34.3579 86.5557H34.3602Z"
        fill="#B9D4DC"
      />
      <path
        d="M28.6067 88.7857L28.3717 90.5459C26.407 90.1652 26.5269 89.7257 25.6127 89.2534C22.8819 87.848 20.1017 86.2594 17.3944 84.6754C17.1806 84.2594 18.3697 84.0244 18.7434 83.7354C20.0242 84.4545 27.0087 88.5248 27.4904 88.6658C27.8241 88.7646 28.266 88.7528 28.6067 88.7834V88.7857Z"
        fill="#669EB1"
      />
      <path
        d="M10.7628 73.2895L10.0577 74.9322C7.79459 73.6326 5.4539 72.4575 3.24951 71.0569C3.87229 70.6926 4.50446 70.2626 5.12724 69.8818C6.99556 71.024 8.86623 72.192 10.7628 73.2871V73.2895Z"
        fill="#97BECB"
      />
      <path
        d="M41.4057 74.6993C39.9533 75.5595 30.372 81.2091 29.7234 81.39C28.3838 81.7661 27.2393 80.5816 26.9644 80.45L39.5844 71.7617C40.2377 71.9779 42.7123 72.1401 42.2259 73.5807C42.1483 73.8133 41.2976 73.8345 41.4033 74.697L41.4057 74.6993Z"
        fill="#B5D1DB"
      />
      <path
        d="M47.3913 71.0595C46.752 71.5483 46.0729 71.9244 45.396 72.3521C45.7885 70.5754 40.1647 69.3064 42.4608 67.8916C43.4431 68.465 47.1093 70.5402 47.3325 70.7094C47.5464 70.8715 47.5017 70.8645 47.3913 71.0619V71.0595Z"
        fill="#93BBC9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_139_2951"
          x1="50.3616"
          y1="19.4048"
          x2="16.3887"
          y2="51.8244"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.16" stopColor="#C3DAE2" />
          <stop offset="1" stopColor="#A1C4D0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_139_2951"
          x1="29.4884"
          y1="28.0545"
          x2="13.6934"
          y2="38.6464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87B3C3" />
          <stop offset="1" stopColor="#A5C7D2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_139_2951"
          x1="35.1683"
          y1="2.60333"
          x2="23.5729"
          y2="14.0859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C5DCE3" />
          <stop offset="1" stopColor="#AFCDD8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_139_2951"
          x1="35.7136"
          y1="65.8378"
          x2="27.3567"
          y2="71.29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCBD6" />
          <stop offset="1" stopColor="#95BCCA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
