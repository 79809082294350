import { NxModal } from "web/common/components/NxModal/NxModal";
import {
  HideProjectModal,
  ShowCreateOrEditProjectModal,
} from "../../context/abstraction/ProjectsModal";
import { useProjectModal } from "../../context/ProjectModalProvider";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { t } from "i18next";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { NxTextAreaField } from "../../../../../common/components/TextFields/NxTextAreaField";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { CreateProjectParams } from "web/features/projects/domain/params/CreateProjectParams";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";
import { useEditProjectHook } from "../../hooks/UseEditProjectHook";
import { useCreateProjectHook } from "../../hooks/UseCreateProjectHook";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { validateEmptyField } from "web/common/forms/validators/ValidateEmptyField";

type ModalParams = {
  project?: ProjectEntity;
};

interface IProjectInputs {
  name: string;
  description: string;
}

export function CreateOrEditProjectModal({ project }: ModalParams) {
  const { modal, setModal } = useProjectModal();

  const { showSnackbar } = useNxSnackBarContext();
  const { createProject, projectState: createState } = useCreateProjectHook();
  const { editProject, projectState: editState } = useEditProjectHook();

  const [isEdit, setIsEdit] = useState(false);

  const state = isEdit ? editState : createState;

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProjectInputs>();

  const closeModal = useCallback(
    (reloadProjects: boolean) => {
      setModal(new HideProjectModal({ reloadProjects }));
    },
    [setModal]
  );

  useEffect(() => {
    if (modal instanceof ShowCreateOrEditProjectModal && project) {
      reset({
        name: project.name,
        description: project.description,
      });
    }
  }, [modal, project, reset]);

  const handleSubmitData = (data: CreateProjectParams) => {
    if (project) {
      setIsEdit(true);

      const updatedData = {
        id: project.id!,
        name: data.name,
        description: data.description,
      };

      editProject(updatedData);
    } else {
      setIsEdit(false);
      createProject(data);
    }
  };

  useEffect(() => {
    if (state.isError) {
      showSnackbar({
        type: NxSnackbarType.error,
        label: isEdit
          ? t("editProjectFailureMessage")
          : t("createProjectFailureMessage"),
      });
    }
  }, [closeModal, createState, editState, isEdit, showSnackbar, state.isError]);

  useEffect(() => {
    if (state.isSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        label: isEdit
          ? t("editedProjectWithSuccess")
          : t("createdProjectWithSuccess"),
      });
      setTimeout(() => {
        closeModal(true);
      }, 1000);
    }
  }, [
    showSnackbar,
    isEdit,
    editState,
    createState,
    closeModal,
    state.isSuccess,
  ]);

  useEffect(() => {
    if (project !== undefined) {
      setValue("name", project!.name!);
    }
  }, [setValue, project]);

  return (
    <NxModal
      showModal={modal instanceof ShowCreateOrEditProjectModal}
      onOutsideClick={() => closeModal(false)}
    >
      <div className="w-[432px]">
        <NxModalTitle
          title={project !== undefined ? t("editProject") : t("newProject")}
          subtitle={t("savedData")}
          onClosedTapped={() => closeModal(false)}
        />
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <NxFormField
            placeholder={t("writeHere")}
            labelText={t("projectName")}
            register={register("name", { validate: validateEmptyField })}
            error={errors.name}
            className="mt-4"
          />
          <NxTextAreaField
            placeholder={t("projectDescription")}
            labelText={t("description")}
            register={register("description")}
            error={errors.description}
            className="mt-4"
            textAreaClassName="pb-32"
          />
          <NxFilledButton
            className="mt-4 py-5"
            isLoading={createState.isLoading || editState.isLoading}
          >
            <label
              className={CSName(FontStyles.bodyLargeBold)
                .combine("cursor-pointer")

                .build()}
            >
              {project
                ? t("editProject")
                : project !== undefined
                ? t("saveProject")
                : t("createProject")}
            </label>
          </NxFilledButton>
        </form>
      </div>
    </NxModal>
  );
}
