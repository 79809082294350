import { ItemEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { useState, useRef, useMemo, useCallback } from "react";
import PageView from "web/common/components/PageView";
import { PivotOptionsPage } from "./PivotOptionsPage";
import { PivotHectarePage } from "./PivotHectarePage";

type Params = {
  item: ItemEntity;
  onPrevious: () => void;
  onClose: () => void;
  onCreated: () => void;
};

export function PivotForm({ item, onPrevious, onClose, onCreated }: Params) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const previousRef = useRef(0);

  const memoCurrentPageIndex = useMemo(
    () => currentPageIndex,
    [currentPageIndex]
  );

  const handlePageChange = useCallback(
    (index: number) => {
      if (index === currentPageIndex) return;
      previousRef.current = currentPageIndex;
      setCurrentPageIndex(index);
    },
    [currentPageIndex]
  );

  return (
    <PageView
      previousPageIndex={previousRef.current}
      currentPageIndex={memoCurrentPageIndex}
      pages={[
        <PivotOptionsPage
          item={item}
          onPrevious={onPrevious}
          onClose={onClose}
          onNext={() => {
            handlePageChange(1);
          }}
          onCreated={onCreated}
        />,
        <PivotHectarePage
          item={item}
          onPrevious={() => {
            handlePageChange(0);
          }}
          onClose={onClose}
        />,
      ]}
    />
  );
}
