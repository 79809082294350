import { useEffect, useRef, useState } from "react";
import { HomeNavFooterMenu } from "./HomeNavFooterMenu";
import { ChangeOrgModal } from "./ChangeOrg/ChangeOrgModal";
import { useAppContext } from "web/features/app/context/AppContext";
import { AppSuccess } from "web/features/app/hooks/UseAppHook";

export const HomeNavFooter = () => {
  const ref = useRef<HTMLUListElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const { appState } = useAppContext();
  if (!(appState instanceof AppSuccess)) return <></>;
  const account = appState.user;
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === ref.current) return;
      }
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const closeModal = () => {
    ref.current!.classList.remove("visible");
    ref.current?.classList.add("invisible");
    ref.current?.classList.remove("opacity-100");
  };

  const openModal = () => {
    if (ref.current) {
      ref.current.classList.remove("invisible");
      ref.current.classList.add("visible");
      ref.current.classList.add("opacity-100");
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [isOpen]);

  return (
    <div className="relative">
      <ChangeOrgModal />
      <button
        className="group/menu  w-full flex flex-row min-h-16 px-6 py-4 gap-2 items-center cursor-pointer
      bg-nx-gray-100 dark:bg-nx-dark-1300"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        <div className="w-8 h-8 rounded-full border-2	border-nx-main-600 bg-nx-main-600/10">
          {account?.image_url ? (
            <img
              className="w-full h-full rounded-full object-cover"
              src={account.image_url}
              alt=""
            />
          ) : (
            <span className="text-xs text-nx-main-600 font-normal">
              {account?.first_name[0].toUpperCase()}
              {account?.last_name[0].toUpperCase()}
            </span>
          )}
        </div>
        <div className="flex flex-col items-start gap-0.5">
          <div className="text-xs text-nx-main-700 dark:text-nx-white font-medium">
            {account?.first_name} {account?.last_name}
          </div>
          <div className="text-[0.625rem] text-nx-gray-400 font-normal">
            Nome_Org • Admin
          </div>
        </div>
        <HomeNavFooterMenu
          ref={ref}
          onNeedCloseModal={() => {
            setIsOpen(false);
          }}
        />
      </button>
    </div>
  );
};
