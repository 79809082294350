export const IconCulture = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 10.5769C16.5 14.1243 13.6243 17 10.0769 17C8.29558 17 6.96828 16.3959 5.94961 15.309C4.91393 14.2041 4.16683 12.5667 3.63897 10.4556C3.18092 8.62373 2.7169 5.27326 2.55697 3.45358C2.53556 3.20996 2.72379 3.0042 2.98519 3.01187C4.42518 3.0541 6.83328 3.2471 9.93959 4.1346C11.6304 4.61766 13.2853 5.36569 14.5084 6.42966C15.7201 7.48373 16.5 8.83754 16.5 10.5769Z"
        stroke="stroke"
      />
      <path
        d="M18 18.5C15 14 11 9.5 8 8.5"
        stroke="stroke"
        strokeLinecap="round"
      />
    </svg>
  );
};
