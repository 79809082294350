import { useState } from "react";
import { CreateProjectParams } from "../../domain/params/CreateProjectParams";
import { createProjectUsecase } from "../../domain/usecase/CreateProjectUsecase";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";

abstract class CreateProjectState {}

class CreateProjectLoading extends CreateProjectState {}

class CreateProjectSuccess extends CreateProjectState {}

class CreateProjectFailure extends CreateProjectState {}

export const useCreateProjectHook = () => {
  const [projectState, setState] = useState<CreateProjectState>();
  return {
    projectState: {
      isLoading: projectState instanceof CreateProjectLoading,
      isSuccess: projectState instanceof CreateProjectSuccess,
      isError: projectState instanceof CreateProjectFailure,
    },
    createProject: async (params: CreateProjectParams) => {
      setState(new CreateProjectLoading());
      const result = await createProjectUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new CreateProjectFailure());
      }
      setState(new CreateProjectSuccess());
    },
  };
};
