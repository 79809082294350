import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { t } from "i18next";
import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { NxSVG } from "web/common/components/NxSvg";
import { IconRestore } from "web/common/fonts/icons/components/IconRestore";
import { ArchiveProjectModal } from "../ArchiveProjectModal/ArchiveProjectModal";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";

import { format } from "date-fns";
import {
  ShowArchiveProjectModal,
  ShowCreateOrEditProjectModal,
  ShowProjectsPanelsModal,
} from "../../context/abstraction/ProjectsModal";

export interface ProjectsTbodyProps {
  project: ProjectEntity;
  showBorder?: boolean;
  onClick?: () => void;
  setController?: any;
  controller: any;
  modal: any;
  setModal: any;
  onRestore?: () => void;
}

export interface ProjectTbodyController {
  project?: ProjectEntity;
  isRestoring?: boolean;
  searchText: string;
}

export function ProjectsTbody({
  project,
  showBorder,
  onClick,
  setModal,
  controller,
  setController,
  modal,
  onRestore,
}: ProjectsTbodyProps) {
  return (
    <div
      className={CSName(
        "pl-6 py-4 flex flex-row text-nx-gray-200 items-center w-full h-16 hover:bg-nx-main-600/10 transition"
      )
        .combine(
          showBorder
            ? "border-b-[0.5px] dark:border-nx-gray-500"
            : ""
        )
        .build()}
    >
      <div className={CSName("w-[24%]").combine().build()}>
        <div
          className="flex flex-row  cursor-pointer h-full "
          onClick={onClick}
        >
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "text-start text-nx-black dark:text-nx-white cursor-pointer"
                )
                .build()}
            >
              {project.name}
            </label>
          </div>
        </div>
      </div>
      <div className="w-[24%]">
        <div className="flex flex-row">
          {project.members?.map((member, index) => {
            let user = member.user;
            if (user?.avatar)
              return (
                <div
                  key={index}
                  className={CSName(
                    "bg-center bg-cover w-6 h-6 bg-nx-white rounded-full cursor-pointer"
                  )
                    .combine(user.avatar)
                    .build()}
                  onClick={() => {}}
                />
              );
            else
              return (
                <div
                  key={index}
                  className={CSName(
                    "bg-center bg-cover w-6 h-6 bg-nx-blue-soft rounded-full cursor-pointer"
                  ).build()}
                  onClick={() => {}}
                >
                  <label
                    className={CSName(FontStyles.bodyRegular)
                      .combine(
                        "text-nx-blue-dark cursor-pointer flex justify-center pt-1"
                      )
                      .build()}
                  >
                    {user?.first_name?.split("")[0]}
                    {user?.last_name?.split("")[0]}
                  </label>
                </div>
              );
          })}
        </div>
      </div>
      <div className="w-[30%] ">
        <div className="flex flex-row items-center">
          {project.widgets?.length === 0 && (
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "rounded-full border-dashed border px-3 py-1.5 bg-nx-gray-200/20 dark:bg-nx-black/20 border-nx-gray-300 text-nx-gray-300 truncate  max-w-28"
                )
                .build()}
            >
              {t("noPanels")}
            </label>
          )}
          <div className="flex">
            {project.widgets?.map((e, index) => {
              ///TODO: Replace key with the unique ID for the widget
              if (index < 3)
                return (
                  <div
                    key={index}
                    className={CSName(
                      "text-[10px] font-normal text-center mr-1 max-w-28 truncate" 
                    )
                      .combine(
                        project.archived
                          ? "rounded-full border-dashed border px-3 py-1.5  bg-nx-gray-200/20 dark:bg-nx-black/20 border-nx-gray-300 text-nx-gray-300  "
                          : "rounded-full px-3 py-1.5 bg-nx-focus-dark text-nx-main-500 dark:text-nx-main-200  "
                      )
                      .build()}
                  >
                    {e}
                  </div>
                );
              return <div key={index}></div>;
            })}
          </div>
          {(project.widgets?.length ?? 0) > 3 && (
            <label
              className={CSName("text-[10px] font-normal text-center")
                .combine(
                  "text-nx-main-600 dark:text-nx-main-500  cursor-pointer"
                )
                .build()}
              onClick={() => {
                setModal(
                  new ShowProjectsPanelsModal({
                    panelsList: project.widgets,
                  })
                );
              }}
            >
              {t("more")} {project.widgets!.length - 3}...
            </label>
          )}
        </div>
      </div>
      <div className="w-[18%] ">
        <label
          className={CSName(FontStyles.caption)
            .combine("text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {format(project.createdAt!, "dd/MM/yyyy")}
        </label>
      </div>
      <div className="text-start ">
        <NxDropElement
          options={[
            {
              icon: <IconEdit />,
              label: t("edit"),
              onClick: () => {
                setModal(new ShowCreateOrEditProjectModal({ project }));
              },
            },
            {
              icon: project.archived ? <IconRestore /> : <IconArchive />,
              label: project.archived ? t("restore") : t("archive"),
              onClick: () => {
                if (project.archived && onRestore) {
                  onRestore!();
                } else {
                  setController({
                    isRestoring: controller?.isRestoring,
                    project: project,
                  });
                  setModal(new ShowArchiveProjectModal());
                }
              },
            },
          ]}
          dimension={{
            x: 100,
            y: 10,
          }}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
            <IconMore />
          </NxSVG>
        </NxDropElement>
        <ArchiveProjectModal
          isOpen={
            modal instanceof ShowArchiveProjectModal &&
            controller?.project !== undefined
          }
          key={controller?.project?.id}
          project={controller?.project ?? ""}
        />
      </div>
    </div>
  );
}
