import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { AppSuccess } from "../../hooks/UseAppHook";
import { useNavigate } from "react-router-dom";

export const AppNotFoundPage = () => {
  const { appState } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (appState instanceof AppSuccess) {
      const orgs = appState.organizations;
      if (orgs.length > 0) {
        navigate(`/org/${orgs[0].id}`);
      }
    }
  });
  return <div></div>;
};
