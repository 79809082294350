import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";

const routes = createBrowserRouter(AppRoutes);

function App() {
  return <RouterProvider router={routes}></RouterProvider>;
}

export default App;
