import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";

type Params = {
  title: String;
  icon: React.ReactElement;
};

export function GroupDivider({ title, icon }: Params) {
  return (
    <div className="flex flex-row">
        <NxSVG
          onClick={() => {}}
          className="w-5 h-5 cursor-pointer fill-nx-gray-400"
        >
          {icon}
        </NxSVG>
        <label
          className={CSName(FontStyles.subtitle)
            .combine("dark:text-nx-white ml-2")
            .build()}
        >
          {title}
        </label>
      </div>
  );
}
