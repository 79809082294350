import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { t } from "i18next";
import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { NxSVG } from "web/common/components/NxSvg";
import { IconRestore } from "web/common/fonts/icons/components/IconRestore";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { PanelEntity } from "web/features/panels/domain/entities/PanelEntity";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { usePanelModal } from "../../context/PanelsModalProvider";
import { HidePanelModal, ShowCreateOrEditPanelModal } from "../../context/abstraction/PanelsModal";
import { PanelRestored, useRestorePanelHook } from "../../hook/UseRestorePanelHook";

export interface PanelsTbodyParams {
  id: string;
  data: PanelEntity;
  showBorder?: boolean;
  onClick?: () => void;
}

export function PanelsTbody(params: PanelsTbodyParams) {
  const { resotrePanelState, restorePanel } = useRestorePanelHook();
  const [isRestoring, setRestoring] = useState<boolean>();
  const { setModal } = usePanelModal();

  const setLoading = () => {
    setRestoring(false);
    restorePanel(params.data.id ?? "");
  };

  useEffect(() => {
    if (resotrePanelState instanceof PanelRestored) {
      setRestoring(true);
      setTimeout(() => {
        setModal(
          new HidePanelModal({
            reload: true,
          })
        );
      }, 3000);
    }
  }, [resotrePanelState, setModal]);

  return (
    <div
      className={CSName("py-4 pl-6 flex flex-row text-nx-gray-200 items-center hover:bg-nx-main-600/10 transition")
        .combine(
          params.showBorder
            ? "border-b-[0.5px] border-nx-gray-100 dark:border-nx-gray-500"
            : ""
        )
        .build()}
    >
      <div className={CSName("w-[62%]").combine().build()}>
        <div
          className="flex flex-row cursor-pointer h-full "
          onClick={params.onClick}
        >
          <div className="flex flex-col mr-4">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "text-start text-nx-black dark:text-nx-white cursor-pointer"
                )
                .build()}
            >
              {params.data.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "text-start text-nx-gray-400 dark:text-nx-gray-200 cursor-pointer"
                )
                .build()}
            >
              {t("createdAt") + format(params.data.createdAt!, "dd/MM/yyyy")}
            </label>
          </div>
        </div>
      </div>
      <div className="w-[23.5%] text-start">
        <label
          className={CSName(FontStyles.caption)
            .combine("text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {format(params.data.archivedAt!, "dd/MM/yyyy")}
        </label>
      </div>
      <div className="w-[14.5%] text-end flex justify-end">
        <NxDropElement
          options={[
            {
              icon: <IconEdit />,
              label: t("edit"),
              onClick: () => {
                setModal(
                  new ShowCreateOrEditPanelModal({
                    panel: {
                      id: params.data.id,
                      name: params.data.name,
                      project: params.id,
                      s3Key: params.data.s3Key,
                      widgets: params.data.widgets?.map((e) => e.name ?? ""),
                    },
                  })
                );
              },
            },
            {
              icon: <IconRestore />,
              label: t("restore"),
              onClick: () => {
                setLoading();
              },
            },
          ]}
          dimension={{
            x: 100,
            y: 10,
          }}
        >
          <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
            <IconMore />
          </NxSVG>
        </NxDropElement>
      </div>

      <NxSnackbar
        showIcon={true}
        showSnackbar={isRestoring === false}
        type={NxSnackbarType.loading}
        time={4000}
        label={t("restoring")}
      />
      <NxSnackbar
        showIcon={true}
        showSnackbar={isRestoring === true}
        type={NxSnackbarType.success}
        label={t("successfullyRestored")}
        onSnackbarClose={() => {}}
      />
    </div>
  );
}
