import { useState } from "react";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import { GetWidgetsUsecase } from "../../domain/usecases/GetWidgetsUsecase";

abstract class WidgetsState {}

export class FetchingWidgets extends WidgetsState {}

export class WidgetsFetched extends WidgetsState {
  widgets: WidgetEntity[];
  constructor({ widgets }: Required<WidgetsFetched>) {
    super();
    this.widgets = widgets;
  }
}

export class WidgetsFetchingFailure extends WidgetsState {}

export function useGetWidgetsHook() {
  const usecase = GetWidgetsUsecase();
  const [state, setState] = useState<WidgetsState>();

  return {
    widgetsState: state,
    getWidgets: async () => {
      setState(new FetchingWidgets());
      setState(
        new WidgetsFetched({
          widgets: usecase,
        })
      );
    },
  };
}
