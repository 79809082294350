import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import {
  GetTermsUserLoading,
  GetTermsUserSuccess,
  useGetTermsUserHook,
} from "../../hooks/useGetTermsUserHook";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";

interface MyProfileTermsModalProps {
  isOpen: boolean;
  onClosedTapped: () => void;
}

export const MyProfileTermsModal = ({
  isOpen,
  onClosedTapped,
}: MyProfileTermsModalProps) => {
  const { getTermsUser, getTermsUserState } = useGetTermsUserHook();
  const [terms, setTerms] = useState<string | null>();

  useEffect(() => {
    getTermsUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      terms === undefined ||
      getTermsUserState instanceof GetTermsUserLoading
    ) {
      setTimeout(() => {
        if (getTermsUserState instanceof GetTermsUserSuccess) {
          setTerms(getTermsUserState.termsUser.text);
        }
      }, 3000);
    }
  }, [getTermsUserState, terms]);

  return (
    <NxModal showModal={isOpen} onOutsideClick={onClosedTapped}>
      <div className="min-w-72 max-w-[40rem] max-h-[90vh] p-2">
        <NxModalTitle
          title={t("Terms")}
          subtitle={t("Documentation")}
          onClosedTapped={onClosedTapped}
        />

        {getTermsUserState instanceof GetTermsUserLoading ||
        terms === undefined ? (
          <div className="flex flex-col mt-[15%] m-auto">
            <CircularProgressBar
              className="stroke-nx-main-600"
              width={20}
              height={20}
            />
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine("text-nx-gray-200")
                .build()}
            >
              {t("loadingData")}
            </label>
          </div>
        ) : (
          <>
            <div className="my-6 text-nx-gray-700 dark:text-nx-white max-h-[70vh] overflow-y-auto text-sm">
              <p
                className="w-full"
                dangerouslySetInnerHTML={{ __html: terms || "" }}
              ></p>
            </div>

            <NxFilledButton onClick={onClosedTapped}>
              {t("back")}
            </NxFilledButton>
          </>
        )}
      </div>
    </NxModal>
  );
};
