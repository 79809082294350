import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { MemberEntity } from "../../domain/entities/MemberEntity";
import { GetMembersOrganizationUsecase } from "../../domain/usecase/GetMembersOrganizationUsecase";
import { GetMembersOrganizationParams } from '../../domain/params/GetMembersOrganizationParams';

abstract class GetMembersOrganizationState {}

export class GetMembersOrganizationLoading extends GetMembersOrganizationState {}

export class GetMembersOrganizationSuccess extends GetMembersOrganizationState {
  members: MemberEntity[];

  constructor({ members }: Required<GetMembersOrganizationSuccess>) {
    super();
    this.members = members;
  }
}

export class GetMembersOrganizationFailure extends GetMembersOrganizationState {}

export const useGetMembersOrganizationHook = ({organizationId}: GetMembersOrganizationParams) => {
  const [getMembersOrganizationState, setState] = useState<GetMembersOrganizationState>();

  return {
    getMembersOrganizationState,
    getMembersOrganization: async () => {
      setState(new GetMembersOrganizationLoading());

      const resultPagination = await GetMembersOrganizationUsecase({organizationId});

      if (resultPagination instanceof PaginationEntity) {
        return setState(
          new GetMembersOrganizationSuccess({
            members: resultPagination.results,
          })
        );
      }

      return setState(new GetMembersOrganizationFailure());
    },
  };
};
