import { LocationEntity } from "web/features/locations/domain/entities/LocationEntity";

export abstract class LocationsModal {}

export class HideLocationModal extends LocationsModal {
  reload?: boolean;
  constructor({ reload = false }: Partial<HideLocationModal>) {
    super();
    this.reload = reload;
  }
}

export class ShowArchiveModal extends LocationsModal {}

export class ShowCreateLocationModal extends LocationsModal {
  content?: LocationEntity;
  constructor({content}:Partial<ShowCreateLocationModal>){
    super();
    this.content = content;
  }
}

export class ShowLocationsWidgetsModal extends LocationsModal {}
