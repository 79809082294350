import { NumberFormatter } from "./formatter/NumberFormater";

export function CpfFormatter(value: any) {
  return NumberFormatter({
    value: value,
    regex: /(\d[0-9]{2})?(\d[0-9]{2})?(\d[0-9]{2})?(\d[0-9]{1})/,
    groupExpression: "$1.$2.$3-$4",
    specialChars: [".", ".", "-"],
    stringMaxLength: 11,
  });
}
