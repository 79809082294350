import { t } from "i18next";
import { NxAddActionButton } from "web/common/components/Buttons/NxAddActionButton";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";
import { IconAddUser } from "web/common/fonts/icons/components/IconAddUser";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { IconDropButton } from "web/common/fonts/icons/components/IconDropButton";
import { usePanelModal } from "../../context/PanelsModalProvider";
import { ShowCreateOrEditPanelModal } from "../../context/abstraction/PanelsModal";

type Params = {
  project?: ProjectEntity;
};

export function PanelsHeader({ project }: Params) {
  const {setModal} = usePanelModal();

  return (
    <header className="flex flex-row w-full">
      <div className="w-full">
        <div className="w-full grid grid-rows-2 gap-2 place-content-start place-items-start">
          <label
            className={CSName(FontStyles.title)
              .combine("dark:text-nx-main-500 text-nx-dark-800")
              .build()}
          >
            {project?.name}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-dark-800")
              .build()}
          >
            {project?.description}
          </label>
        </div>
        <div className="w-72">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-dark-800")
              .build()}
          >
            {t("membersWithAccess")}
          </label>
          <div className="grid grid-flow-col auto-cols-max gap-2">
            <div className="flex flex-row mr-2">
              {project?.members?.map((e, index) => {
                ///TODO: Replace key with the unique ID for the widget
                if (e.user?.avatar !== undefined)
                  return (
                    <div
                      key={index}
                      className={CSName(
                        "bg-center bg-cover w-6 h-6 bg-nx-white -mr-2 rounded-full cursor-pointer"
                      )
                        .combine(e.user?.avatar!)
                        .build()}
                      onClick={() => {}}
                    />
                  );
                else
                  return (
                    <div
                      key={index}
                      className={CSName(
                        "bg-center bg-cover w-6 h-6 bg-nx-blue-soft -mr-2 rounded-full cursor-pointer"
                      ).build()}
                      onClick={() => {}}
                    >
                      <label
                        className={CSName(FontStyles.bodyRegular)
                          .combine(
                            "text-nx-blue-dark cursor-pointer flex justify-center pt-1"
                          )
                          .build()}
                      >
                        {e.user?.first_name!.split(" ")[0][0]}
                      </label>
                    </div>
                  );
              })}
            </div>
            <div className="border-solid border-[0.5px] rounded-full border-nx-gray-400 w-6 h-6 place-content-center">
              <NxSVG
                onClick={() => {}}
                className="w-3.5 h-3.5 cursor-pointer fill-nx-main-600 stroke-nx-main-600 m-auto"
              >
                <IconAddUser />
              </NxSVG>
            </div>
          </div>

          <div className="mb-6 mt-4">
            <NxFormField
              placeholder={t("projectsSearchPlaceholder")}
              prefix={
                <NxSVG className="stroke-nx-gray-400">
                  <IconSearch />
                </NxSVG>
              }
            />
          </div>
        </div>
      </div>
      <div className="grid grid-flow-col gap-2 w-56 h-8">
        <NxOutlinedButton className="h-8 grid grid-flow-col gap-3.5 px-4 place-content-center">
          <label className={CSName(FontStyles.bodyBold).combine("text-nx-main-500 cursor-pointer").build()}>
            {t("actions")}
          </label>
          <NxSVG
            className={CSName(`w-2 h-2`).combine("fill-nx-main-500 m-auto").build()}
          >
            <IconDropButton />
          </NxSVG>
        </NxOutlinedButton>
        <NxAddActionButton
          label={t("newPanel")}
          onClick={() => {
            setModal(new ShowCreateOrEditPanelModal({}));
          }}
        />
      </div>
    </header>
  );
}
