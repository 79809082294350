import { ReactNode, useEffect} from "react";
import { NxDropFormField } from "web/common/components/TextFields/NxDropFormField";
import { LocationCreateMiniArea } from "./LocationCreateMiniArea";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { NxSVG } from "web/common/components/NxSvg";
import { IconAdd } from "web/common/fonts/icons/components/IconAdd";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLocationsModal } from "../../../context/LocationsModalProvider";
import { HideLocationModal } from "../../../context/abstraction/LocationModal";
import { CreatingLocation } from "../../../hooks/UseCreateLocationHook";
import { UpdatingLocation } from "../../../hooks/UseUpdateLocationHook";

interface LocationsCreateSideMenuProps {
  draws: Array<DrawLayer | EmptyLayer>;
  onAddMoreClick: () => void;
  currentIndex: number;
  onDeleted: (index: number) => void;
  onEdit: (index: number) => void;
  onFinish: (name: string, draw: DrawLayer) => void;
  state: any;
  name?: string;
}

export interface ILocationInputs {
  name: string;
}

export const LocationsCreateSideMenu = ({
  draws,
  name = "",
  onAddMoreClick,
  onDeleted,
  onEdit,
  onFinish,
  state,
}: LocationsCreateSideMenuProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ILocationInputs>();
  const { modal } = useLocationsModal();
  const setName = () => {
    if (name.length > 0 && (getValues("name") === undefined || getValues("name").length === 0)) {
      setValue("name", name);
      name = "";
    }
  };

  useEffect(() => {
    if (
      modal instanceof HideLocationModal &&
      getValues("name") !== undefined &&
      getValues("name").length > 0
    ) {
      setValue("name", "");
    }
  }, [modal, setValue, getValues]);

  const onSubmit = (data: ILocationInputs) => {
    if (!(draws instanceof EmptyLayer)) {
      onFinish(data.name, (draws as DrawLayer[])[0]);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="rounded-lg flex flex-col w-72 p-6 bg-nx-white dark:bg-nx-dark-1100 self-start max-h-[47.25rem] overflow-auto "
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-sm text-nx-dark-800 dark:text-nx-white font-medium mb-1">
          {t("createdLocations")}
        </div>
        <div className="text-caption  text-nx-gray-200 mb-6">
          {t("createdLocationsDesc")}
        </div>
        {draws.map((draw, index) => {
          setName();
          return (
            <LocationCreateMiniArea
              key={draw.id}
              draw={draw}
              errors={errors}
              register={register}
              onDeleteClick={() => {
                onDeleted(index);
              }}
              onEditClick={() => {
                onEdit(index);
              }}
            />
          );
        })}

        {draws.length ? (
          <>
            {draws[draws.length - 1] instanceof DrawLayer && (
              <div
                onClick={onAddMoreClick}
                className="p-2 flex flex-row items-center justify-center gap-2 border-[0.5px] rounded border-nx-gray-200 cursor-pointer"
              >
                <span className="text-xs font-medium text-nx-main-500">
                  {t("newLocation")}
                </span>
                <NxSVG className="w-4 h-4 fill-nx-main-500">
                  <IconAdd />
                </NxSVG>
              </div>
            )}

            <div className="mt-4">
              <NxDropFormField
                labelText={t("insertAnEntity")}
                placeholder={t("none")}
                options={[
                  {
                    label: t("none"),
                    value: null,
                  },
                ]}
                labelBuilder={function (opt): ReactNode {
                  return <div>{opt.label}</div>;
                }}
                valueBuilder={function (opt) {
                  return opt.value;
                }}
              />
            </div>
            <NxFilledButton
              isLoading={state instanceof CreatingLocation || state instanceof UpdatingLocation}
              className="mt-6"
            >
              {" "}
              {t("finalize")}
            </NxFilledButton>
          </>
        ) : (
          <div className="text-caption  text-nx-gray-200">
            {t("createdLocationsInfo")}
          </div>
        )}
      </form>
    </div>
  );
};
