import L from "leaflet";

import "leaflet-draw";

import { MapDrawModes } from "./MapDrawModes";

export class EmptyLayer {
  id?: string;

  constructor({ id }: Partial<EmptyLayer>) {
    this.id = id;
  }
}

export class DrawLayer {
  points: L.LatLngExpression[];
  radius?: number;
  drawMode: MapDrawModes;
  bounds?: L.LatLngBoundsExpression;
  sizeHa?: string;
  id?: string;

  constructor({
    points,
    bounds,
    drawMode,
    radius,
    sizeHa,
    id,
  }: Awaited<DrawLayer>) {
    this.points = points;
    this.bounds = bounds;
    this.drawMode = drawMode;
    this.radius = radius;
    this.sizeHa = sizeHa;
    this.id = id;
  }

  static fromGeoJSON(layer: any, drawMode: MapDrawModes) {
    const geoJson = layer.toGeoJSON();

    const points = this.converGeoJsonToLatLngs(geoJson);
    let sizeHa: string | undefined;
    if (points.length) {
      if (drawMode === MapDrawModes.polygon) {
        const area = L.GeometryUtil.geodesicArea(
          points.map((p) => {
            return { lat: p[0], lng: p[1] };
          })
        );
        sizeHa = L.GeometryUtil.readableArea(area, true);
      } else if (drawMode === MapDrawModes.circle) {
        sizeHa = L.GeometryUtil.readableArea(
          this.calculateGeodesicCircleArea(layer.getRadius()),
          true
        );
      }
    }

    return new DrawLayer({
      points: this.converGeoJsonToLatLngs(geoJson),
      drawMode: drawMode,
      bounds: layer.getBounds?.(),
      radius: layer.getRadius?.(),
      sizeHa,
    });
  }

  static converGeoJsonToLatLngs(geoJson: any) {
    if (geoJson.geometry.type === "Point") {
      return [geoJson.geometry.coordinates.slice().reverse()];
    } else {
      return [
        ...geoJson.geometry.coordinates[0].map((c: any) => c.slice().reverse()),
      ];
    }
  }
  private static calculateGeodesicCircleArea(radius: number) {
    const R = 6371000; // Radius aproximated of the earth
    return 2 * Math.PI * Math.pow(R, 2) * (1 - Math.cos(radius / R));
  }
}
