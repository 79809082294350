import { useState } from "react";
import { NexusFailure } from "web/core/domain/errors/NexusFailure";
import { useAppContext } from "web/features/app/context/AppContext";
import { EditAccountParams } from "web/features/auth/domain/params/EditAccountParams";
import { editAccountUsecase } from "web/features/auth/domain/usecase/EditAccountUsecase";

abstract class EditAccountState {}

class EditAccountLoading extends EditAccountState {}

class EditAccountSuccess extends EditAccountState {}

class EditAccountFailure extends EditAccountState {}

export const useEditAccountHook = () => {
  const [accountState, setState] = useState<EditAccountState>();
  const { updateState } = useAppContext();

  return {
    accountState: {
      isLoading: accountState instanceof EditAccountLoading,
      isSuccess: accountState instanceof EditAccountSuccess,
      isError: accountState instanceof EditAccountFailure,
    },
    editAccount: async (params: EditAccountParams) => {
      setState(new EditAccountLoading());
      const result = await editAccountUsecase(params);
      if (result instanceof NexusFailure) {
        return setState(new EditAccountFailure());
      } else {
        setState(new EditAccountSuccess());
        updateState({ user: result });
      }
    },
  };
};
