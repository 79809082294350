import { NumberFormatter } from "./formatter/NumberFormater";

export function CpnjFormatter(value: any) {
  
  return NumberFormatter({
    value: value,
    regex: /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
    groupExpression: "$1.$2.$3/$4-$5",
    specialChars: [".", ".", "/", "-"],
    stringMaxLength: 14,
  });
}
