import { NxSVG } from "../../../../common/components/NxSvg";
import { useNavigate } from "react-router-dom";
import { CSName } from "../../../../common/utils/ClassName";
import { useEffect, useRef, useState } from "react";
import { HomeNavPopoverMenu } from "./HomeNavPopoverMenu/HomeNavPopoverMenu";

interface HomeNavBarOptionProps {
  title: string;
  icon: JSX.Element;
  route?: string;
  isSelected: boolean;
  isSvg?: boolean;
  fill?: boolean;
  stroke?: boolean;
  menu?: boolean;
}

export const HomeNavBarOption = ({
  title,
  icon,
  route,
  isSelected,
  isSvg = true,
  fill = true,
  stroke = true,
  menu = false,
}: HomeNavBarOptionProps) => {
  const navigate = useNavigate();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const ref = useRef<HTMLUListElement>(null);

  const closeModal = () => {
    if (menu) {
      ref.current!.classList.remove("visible");
      ref.current?.classList.add("invisible");
      ref.current?.classList.remove("opacity-100");
    }
  };

  const openModal = () => {
    if (ref.current && menu) {
      ref.current.classList.remove("invisible");
      ref.current.classList.add("visible");
      ref.current.classList.add("opacity-100");
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === ref.current) return;
      }
      setIsOpenMenu(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (isOpenMenu) {
      openModal();
    } else {
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMenu]);

  return (
    <div
      className={CSName(
        `group flex flex-row  min-h-8 mb-2 w-full
         px-6 gap-4 items-center hover:bg-nx-main-600/10 
         transition cursor-pointer`
      )
        .concatIf(isSelected, "bg-nx-main-600/10 ")
        .build()}
      onClick={
        menu
          ? (e) => {
              e.preventDefault();
              setIsOpenMenu(true);
            }
          : () => navigate("." + route)
      }
    >
      <div className="flex flex-row items-center justify-center w-5 h-5">
        {isSvg ? (
          <NxSVG
            className={CSName("")
              .concatIf(
                isSelected,
                `${fill ? "fill-nx-main-500" : ""} ${
                  stroke ? "stroke-nx-main-500" : ""
                }`,
                `${stroke ? "stroke-nx-gray-400" : ""} ${
                  fill ? "fill-nx-gray-400" : ""
                } `
              )
              .build()}
            width={20}
            height={20}
          >
            {icon}
          </NxSVG>
        ) : (
          icon
        )}
      </div>
      <div
        className={CSName("flex-1 text-xs font-medium truncate")
          .concatIf(
            isSelected,
            "text-nx-main-500",
            `text-nx-gray-600 dark:text-nx-white`
          )
          .build()}
      >
        {title}
      </div>

      {menu && (
        <HomeNavPopoverMenu
          ref={ref}
          onNeedCloseModal={() => {
            setIsOpenMenu(false);
          }}
        />
      )}
    </div>
  );
};

export const HomeNavSectionTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="text-[0.625rem] text-nx-gray-400 font-semibold tracking-[1.5px] px-6 py-2 mb-2">
      {children}
    </div>
  );
};
