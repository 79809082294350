import { AgentEntity } from "web/features/agents/domain/entities/AgentEntity";

export abstract class AgentsModal {}

export class HideAgentModal extends AgentsModal {
  needReloadAgents: boolean;

  constructor(needReloadAgents: boolean = false) {
    super();
    this.needReloadAgents = needReloadAgents;
  }
}

export class ShowAgentArchiveModal extends AgentsModal {
  agentId: string;

  constructor(agentId: string) {
    super();
    this.agentId = agentId;
  }
}

export class ShowAgentCreateModal extends AgentsModal {
  agent?: AgentEntity;

  constructor(agent?: AgentEntity) {
    super();
    this.agent = agent;
  }
}

export class ShowAgentsWidgetsModal extends AgentsModal {}
