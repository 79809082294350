import { t } from "i18next";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { CSName } from "web/common/utils/ClassName";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";

type Params = {
  showModal: boolean;
  projects: ProjectEntity[];
  onClose: () => void;
};

export function SelectProjectModal({ showModal, projects, onClose }: Params) {
  return (
    <NxModal showModal={showModal} onOutsideClick={onClose}>
      <div className="w-full">
        <NxModalTitle
          title={t("newPanel")}
          subtitle={t("savedData")}
          onClosedTapped={onClose}
        />
        <div className="my-4">
          <label
            className={CSName(FontStyles.caption)
              .combine("text-nx-gray-200")
              .build()}
          >
            {t("location")}
          </label>
          <div className="mt-2 bg-nx-gray-50 dark:bg-nx-dark-1200 w-fit h-fit flex flex-col pt-4 rounded">
            {projects.map((p) => (
              <div
                className="grid grid-flow-col gap-[352px] place-content-between mx-4 mb-4"
                key={p.id}
              >
                <div className="flex flex-col">
                  <label
                    className={CSName(FontStyles.bodyRegular)
                      .combine("text-nx-gray-700 dark:text-nx-white text-start")
                      .build()}
                  >
                    {p.name}
                  </label>
                  <label
                    className={CSName(FontStyles.caption)
                      .combine(
                        "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                      )
                      .build()}
                  >
                    {p.description}
                  </label>
                </div>
                <NxSVG className="stroke-nx-main-500 w-5 h-5 cursor-pointer">
                  <IconArrowRight />
                </NxSVG>
              </div>
            ))}
          </div>
        </div>
      </div>
    </NxModal>
  );
}
