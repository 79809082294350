import { NexusIconsProps } from "../interfaces/NexusIconsProps";

function IconPassword({ color = "white" }: NexusIconsProps) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 9A1.5 1.5 0 016 7.5h8A1.5 1.5 0 0115.5 9v3a5.5 5.5 0 11-11 0V9zM7 7.5V5a3 3 0 013-3v0a3 3 0 013 3v2.5"
        stroke={color}
      />
      <circle cx={10} cy={12} r={1} fill={color} />
      <path d="M9.5 14a.5.5 0 001 0h-1zm0-2v2h1v-2h-1z" fill={color} />
    </svg>
  );
}

export default IconPassword;
