export const IconProduct = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9.5 16.7828V17.5089C9.5 17.9447 8.97312 18.1629 8.66498 17.8547L2.64525 11.835C2.33711 11.5269 2.55535 11 2.99113 11H3.71723C3.84696 11 3.97138 11.0515 4.06311 11.1433L4.41666 10.7897L4.06311 11.1433L9.35673 16.4369C9.44846 16.5286 9.5 16.653 9.5 16.7828Z"
        stroke="stroke"
      />
      <path
        d="M10.5 4.21723V3.49113C10.5 3.05535 11.0269 2.83711 11.335 3.14525L17.3547 9.16498C17.6629 9.47312 17.4447 10 17.0089 10H16.2828C16.153 10 16.0286 9.94847 15.9369 9.85673L15.5833 10.2103L15.9369 9.85673L10.6433 4.56311C10.5515 4.47138 10.5 4.34696 10.5 4.21723Z"
        stroke="stroke"
      />
      <path
        d="M9 16.5V16.5C12.1929 15.2228 14.7228 12.6929 16 9.5V9.5"
        stroke="stroke"
      />
      <path
        d="M11 4.5V4.5C7.8071 5.77716 5.27716 8.3071 4 11.5V11.5"
        stroke="stroke"
      />
      <circle cx="10" cy="10.5" r="2.5" stroke="stroke" />
    </svg>
  );
};
