export class MemberEntity {
  id?: string;
  user?: {
    id: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    avatar?: string | null;
    created_at?: string;
    updated_at?: string;
    last_used_organization?: string;
  };
  status?: string;
  role?: string;
  created_at?: string;
  updated_at?: string;
  organization?: string;

  constructor({
    id,
    user,
    status,
    role,
    created_at,
    updated_at,
    organization,
  }: Partial<MemberEntity>) {
    this.id = id;
    this.user = user;
    this.status = status;
    this.role = role;
    this.created_at = created_at ?? Date.now.toString();
    this.updated_at = updated_at ?? Date.now.toString();
    this.organization = organization ?? "";
  }

  static fromJSON(data: any): MemberEntity {
    return new MemberEntity({
      id: data.id,
      user: data.user,
      status: data.status,
      role: data.role,
      created_at: data.created_at,
      updated_at: data.updated_at,
      organization: data.organization,
    });
  }
}