export class OrganizationEntity {
  id: string;
  thumbnail: string;
  imageS3Key: string;
  name: string;
  category: string;
  memberRole: string;
  categoryName: string;
  createdAt: Date;

  constructor({
    id,
    thumbnail,
    imageS3Key,
    name,
    category,
    memberRole,
    categoryName,
    createdAt,
  }: Required<OrganizationEntity>) {
    this.id = id;
    this.thumbnail = thumbnail;
    this.imageS3Key = imageS3Key;
    this.name = name;
    this.category = category;
    this.memberRole = memberRole;
    this.categoryName = categoryName;
    this.createdAt = createdAt;
  }

  static fromJSON(data: any): OrganizationEntity {
    return new OrganizationEntity({
      id: data.id,
      thumbnail: data.image_url,
      imageS3Key: data.image_s3_key,
      name: data.name,
      category: data.category,
      memberRole: data.member_role,
      categoryName: data.category_name,
      createdAt: new Date(data.created_at),
    });
  }
}
