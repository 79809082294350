export class FeatureEntity<T> {
  type: string;
  features: T[];
  constructor({ type, features }: Required<FeatureEntity<T>>) {
    this.type = type;
    this.features = features;
  }
  static fromJSON<T>(data: any, 
    resultCallback: (results: any) => T): FeatureEntity<T> {
    return new FeatureEntity({
      type: data.type,
      features: data.features?.map((e: any)=>resultCallback(e)),
    });
  }
}
