import { v4 as uuidv4 } from "uuid";

export default function getUrlGoogleLoginUsecase() {
  const baseUrl = process.env.REACT_APP_OAUTH_BASE_URL;
  const path = "auth/realms/nexus/protocol/openid-connect/auth";
  const clientId = "web-client";
  const redirectUri = `${window.location.origin}/loading-oauth`;
  const responseType = "code";
  const scope = "openid";
  const kcIdpHint = "google";
  const prompt = "select_account";
  const state = uuidv4();
  const url = new URL(`${baseUrl}/${path}`);
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("redirect_uri", redirectUri);
  url.searchParams.append("response_type", responseType);
  url.searchParams.append("scope", scope);
  url.searchParams.append("kc_idp_hint", kcIdpHint);
  url.searchParams.append("state", state);
  url.searchParams.append("prompt", prompt);
  return url.toString();
}
