import { useState } from "react";
import { ImageUtils } from "web/common/utils/ImageUtils";
import { UploadFailed } from "web/core/domain/errors/UploadFailed";
import { uploadImageUsecase } from "web/core/domain/usecase/UploadImageUsecase";
import { useAppContext } from "web/features/app/context/AppContext";
import { EditAccountFailure } from "web/features/auth/domain/errors/EditAccountFailure";
import { editAccountUsecase } from "web/features/auth/domain/usecase/EditAccountUsecase";

export class UpdateUserImageState {}

export class UpdateUserImageFailure extends UpdateUserImageState {}

export class UpdateUserImageLoading extends UpdateUserImageState {}

export class UpdateUserImageSuccess extends UpdateUserImageState {}

export const useUpdateUserImageHook = () => {
  const [updateUserImageState, setState] = useState<UpdateUserImageState>();
  const { updateState } = useAppContext();

  return {
    updateUserImageState,
    updateUserImage: async (imageBase64: string) => {
      setState(new UpdateUserImageLoading());
      const resultImage = await uploadImageUsecase({
        file: ImageUtils.convertToUint8(imageBase64),
        fileName: "profile-image",
        folder: "profile",
      });

      if (resultImage instanceof UploadFailed) {
        return setState(new UpdateUserImageFailure());
      }

      const resultEditUser = await editAccountUsecase({
        imageS3Key: resultImage as string,
      });

      if (resultEditUser instanceof EditAccountFailure) {
        return setState(new UpdateUserImageFailure());
      }
      setState(new UpdateUserImageSuccess());
      updateState({ user: resultEditUser });
    },
  };
};
