import { NxModal } from "web/common/components/NxModal/NxModal";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import { t } from "i18next";
import { CSName } from "web/common/utils/ClassName";
import { FontStyles } from "web/common/fonts/FontStyles";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { NxSVG } from "web/common/components/NxSvg";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";

type Params = {
  widget: WidgetEntity;
  onKeep: () => void;
  onRemove: () => void;
};

export function RemoveWidgetModal({ widget, onKeep, onRemove }: Params) {
  return (
    <NxModal showModal={true} onOutsideClick={onKeep}>
      <div className="w-fit h-fit flex flex-col z-50 m-auto dark:bg-nx-dark-1100 rounded-lg">
        <label
          className={CSName(FontStyles.subtitle)
            .combine("dark:text-nx-main-200 text-start")
            .build()}
        >
          {t("removeWidget")}
        </label>
        <div className="w-96">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
              .build()}
          >
            {t("removeWidgetDescription")}
          </label>
        </div>
        <NxFilledButton
          className="w-full px-2 py-3 mt-6 grid grid-rows-0.5 place-content-center "
          backgroundColor="bg-nx-danger-600"
          onClick={onRemove}
        >
          <div className="flex justify-center items-center">
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("text-nx-white cursor-pointer pr-2")
                .build()}
            >
              {t("remove")}
            </label>
            <NxSVG className="w-5 h-5 fill-nx-white">
              <IconDelete />
            </NxSVG>
          </div>
        </NxFilledButton>
        <SecondaryButton
          className="w-full px-2 py-3 mt-4 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={onKeep}
        >
          <label
            className={CSName(FontStyles.bodyBold)
              .combine("dark:text-nx-main-500 cursor-pointer pr-2")
              .build()}
          >
            {t("keep")}
          </label>
        </SecondaryButton>
      </div>
    </NxModal>
  );
}
