import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { NxDropFormField } from "web/common/components/TextFields/NxDropFormField";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconClose } from "web/common/fonts/icons/components/IconClose";
import { CSName } from "web/common/utils/ClassName";
import { LocationEntity } from "web/features/locations/domain/entities/LocationEntity";
import { useAgentsModal } from "../../context/AgentsModalProvider";
import {
  HideAgentModal,
  ShowAgentCreateModal,
} from "../../context/abstraction/AgentsModal";
import { CpfFormatter } from "web/common/forms/formatters/CpfFormatter";
import { validateCpf } from "web/common/forms/validators/ValidateCpf";
import { validateCategory } from "web/common/forms/validators/ValidateCategory";
import { validateType } from "web/common/forms/validators/ValidateType";
import { CpnjFormatter } from "web/common/forms/formatters/CpnjFormatter";
import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import {
  CreateAgentFailure,
  CreateAgentLoading,
  CreateAgentSuccess,
  useCreateAgentHook,
} from "../../hooks/CreateAgentHook";
import { AgentType } from "web/features/agents/domain/entities/AgentType";
import { onlyDitigsText } from "web/common/utils/StringUtils";
import {
  EditAgentsFailure,
  EditAgentsLoading,
  EditAgentsSuccess,
  useEditAgentsHook,
} from "../../hooks/EditAgentHook";

type CreateAgentInputs = {
  name: string;
  category: string;
  type: string;
  cpnj: string;
  cpf: string;
  nis: string;
};

interface CreateAgentController {
  inputs?: CreateAgentInputs;
  isPhisics?: boolean;
  selectedLocations: LocationEntity[];
  showSnackbar?: boolean;
  submit?: boolean;
}

export function CreateAgentModal() {
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
    handleSubmit,
    control,
  } = useForm<CreateAgentInputs>();
  const typeWatch = watch("type");
  const [controller, setController] = useState<CreateAgentController>();
  const { modal, setModal } = useAgentsModal();
  const { createAgent, createAgentState } = useCreateAgentHook();
  const { editAgentState, editAgent } = useEditAgentsHook();

  const types = [AgentType.person, AgentType.company];

  const categories = [t("supplier"), t("farm"), t("cooperative"), t("company")];

  const onSubmit = (input: CreateAgentInputs) => {
    setController({
      isPhisics: controller?.isPhisics,
      inputs: input,
      selectedLocations: controller?.selectedLocations ?? [],
      submit: true,
    });
    if (isEditing()) {
      editAgent({
        id: getEditAgent()!.id,
        category: input.category,
        name: input.name,
        type: input.type as AgentType,
        company:
          input.type === AgentType.company
            ? { cnpj: onlyDitigsText(input.cpnj) }
            : undefined,
        person:
          input.type === AgentType.person
            ? { cpf: onlyDitigsText(input.cpf), nis: onlyDitigsText(input.nis) }
            : undefined,
      });
    } else {
      createAgent({
        category: input.category,
        name: input.name,
        type: input.type as AgentType,
        company:
          input.type === AgentType.company
            ? { cnpj: onlyDitigsText(input.cpnj) }
            : undefined,
        person:
          input.type === AgentType.person
            ? { cpf: onlyDitigsText(input.cpf), nis: onlyDitigsText(input.nis) }
            : undefined,
      });
    }
  };

  const isEditing = () => {
    return modal instanceof ShowAgentCreateModal && modal.agent;
  };

  const getEditAgent = () => {
    return modal instanceof ShowAgentCreateModal ? modal.agent : undefined;
  };

  const clearAll = useCallback(() => {
    setValue("name", "");
    setValue("category", "");
    setValue("type", "");
    setValue("cpnj", "");
    setValue("cpf", "");
    setValue("nis", "");
    clearErrors();
  }, [setValue, clearErrors]);

  useEffect(() => {
    if (modal instanceof ShowAgentCreateModal) {
      if (
        typeWatch.length !== 0 &&
        typeWatch?.includes(AgentType.person) &&
        controller?.isPhisics !== true
      ) {
        setController({
          isPhisics: true,
          inputs: controller?.inputs,
          selectedLocations: controller?.selectedLocations ?? [],
        });
      }

      if (
        typeWatch.length !== 0 &&
        typeWatch?.includes(AgentType.company) &&
        controller?.isPhisics !== false
      ) {
        setController({
          isPhisics: false,
          inputs: controller?.inputs,
          selectedLocations: controller?.selectedLocations ?? [],
        });
      }

      if (modal.agent) {
        setValue("name", modal.agent.name);
        setValue("category", modal.agent.category);
        setValue("type", modal.agent.type);
        setValue("cpnj", modal.agent.company?.cnpj ?? "");
        setValue("cpf", modal.agent.person?.cpf ?? "");
        setValue("nis", modal.agent.person?.nis ?? "");
      }
    }

    if (controller?.inputs === undefined) {
      clearAll();
      setController({
        inputs: watch(),
        selectedLocations: controller?.selectedLocations ?? [],
      });
    }
  }, [modal, typeWatch, watch, controller?.isPhisics, controller?.inputs, controller?.selectedLocations, clearAll, setValue]);

  const closeModal = (needReloadAgents = false) => {
    setModal(new HideAgentModal(needReloadAgents));
    clearAll();
    setController({
      inputs: controller?.inputs,
      selectedLocations: [],
    });
  };

  return (
    <div>
      <NxModal
        showModal={modal instanceof ShowAgentCreateModal}
        onOutsideClick={() => {
          closeModal();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-flow-col place-content-between pb-7 w-[510px]">
            <div className="grid grid-rows-1">
              <label
                className={CSName(FontStyles.subtitle)
                  .combine("text-nx-dark-800 dark:text-nx-white text-start")
                  .build()}
              >
                {t("entityData")}
              </label>
              <label
                className={CSName(FontStyles.caption)
                  .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
                  .build()}
              >
                {t("exibingSavedInformation")}
              </label>
            </div>
            <NxSVG
              className="cursor-pointer dark:stroke-nx-main-500"
              width={20}
              height={20}
              onClick={() => {
                closeModal();
              }}
            >
              <IconClose />
            </NxSVG>
          </div>
          <NxFormField
            register={register("name", { required: t("requiredField") })}
            labelText={t("entityName")}
            error={errors.name}
          />
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Controller
              name="category"
              control={control}
              rules={{ validate: validateCategory }}
              render={({ field: { value } }) => (
                <NxDropFormField
                  labelText={t("category")}
                  defaultValue={value}
                  options={categories}
                  register={register("category", { validate: validateCategory })}
                  placeholder={t("select")}
                  labelBuilder={(category) => category}
                  valueBuilder={(category) => category}
                ></NxDropFormField>
              )}
            />

            <Controller
              name="type"
              control={control}
              rules={{ validate: validateType }}
              render={({ field: { value } }) => (
                <NxDropFormField
                  labelText={t("type")}
                  options={types}
                  register={register("type", { validate: validateType })}
                  defaultValue={value}
                  placeholder={t("select")}
                  labelBuilder={(type) => {
                    if (type === AgentType.person) return t("naturalPerson");
                    if (type === AgentType.company) return t("juridicalPerson");
                  }}
                  valueBuilder={(type) => type}
                />
              )}
            />
          </div>
          {controller?.isPhisics === true && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              <NxFormField
                register={register("cpf", { validate: validateCpf })}
                labelText={"CPF"}
                placeholder={t("optionalOrNumber")}
                formatter={CpfFormatter}
                error={errors.cpf}
              />

              <NxFormField
                register={register("nis", {
                  required: t("requiredField"),
                })}
                formatter={CpfFormatter}
                labelText={"NIS"}
                placeholder={t("optionalOrNumber")}
                error={errors.nis}
              />
            </div>
          )}

          {controller?.isPhisics === false && (
            <div className="mt-4">
              <NxFormField
                register={register("cpnj", { validate: validateCpf })}
                labelText={"CPNJ"}
                placeholder={t("optionalOrNumber")}
                formatter={CpnjFormatter}
                error={errors.cpnj}
              />
            </div>
          )}
          <div className="my-4">
            {/* TODO */}
            {/*
            
            <label
              className={CSName(FontStyles.caption)
                .combine("text-nx-gray-200")
                .build()}
            >
              {t("location")}
            </label>
            <div className="mt-2 bg-nx-gray-50 dark:bg-nx-dark-1200 w-fit h-fit flex flex-col pt-4 rounded">
              {locations.map((l) => (
                <div
                  className="grid grid-flow-col gap-[352px] place-content-between mx-4 mb-4"
                  key={l.id}
                >
                  <div className="flex flex-col">
                    <label
                      className={CSName(FontStyles.bodyRegular)
                        .combine(
                          "text-nx-gray-700 dark:text-nx-white text-start"
                        )
                        .build()}
                    >
                      {l.properties.name}
                    </label>
                    <label
                      className={CSName(FontStyles.caption)
                        .combine(
                          "text-nx-gray-400 dark:text-nx-gray-200 text-start"
                        )
                        .build()}
                    >
                      {l.type}
                    </label>
                  </div>
                  <CheckBoxField
                    className={"bg-nx-focus-light dark:bg-nx-focus-dark"}
                    onClick={(c) => {
                      if (c) selectedLocations.push(l);
                      else
                        selectedLocations.splice(selectedLocations.indexOf(l));
                    }}
                  />
                </div>
              ))}
            </div> */}
          </div>
          <NxFilledButton
            isLoading={
              createAgentState instanceof CreateAgentLoading ||
              editAgentState instanceof EditAgentsLoading
            }
          >
            {t("save")}
          </NxFilledButton>
        </form>
      </NxModal>

      {createAgentState instanceof CreateAgentSuccess && (
        <NxSnackbar
          type={NxSnackbarType.success}
          label={t("createdWithSuccess") + "!!"}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            closeModal(true);
          }}
        />
      )}
      {createAgentState instanceof CreateAgentFailure && (
        <NxSnackbar
          type={NxSnackbarType.error}
          label={t("failToCreate")}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            closeModal();
          }}
        />
      )}
      {editAgentState instanceof EditAgentsFailure && (
        <NxSnackbar
          type={NxSnackbarType.error}
          label={t("failToEdit")}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            closeModal();
          }}
        />
      )}
      {editAgentState instanceof EditAgentsSuccess && (
        <NxSnackbar
          type={NxSnackbarType.success}
          label={t("editedWithSuccess")}
          showIcon={true}
          showSnackbar={true}
          onSnackbarClose={() => {
            closeModal(true);
          }}
        />
      )}
    </div>
  );
}
