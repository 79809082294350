import { format } from "date-fns";
import { t } from "i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconCheck } from "web/common/fonts/icons/components/IconCheck";
import { IconWarning } from "web/common/fonts/icons/components/IconWarning";
import { CSName } from "web/common/utils/ClassName";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";

type Params = {
  widget: WidgetEntity;
  i: number;
};

export function InlineCard({ widget, i }: Params) {
  return (
    <div
      id={widget.queries![i].id}
      className="w-fit h-fit dark:bg-nx-dark-1200 bg-nx-gray-50 flex flex-col my-4 p-2 rounded"
    >
      <div className="flex flex-row">
        <div className="flex flex-col mr-4">
          <label
            className={CSName(FontStyles.caption)
              .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
              .build()}
          >
            CPF:
          </label>
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("dark:text-nx-white text-nx-gray-700 text-start")
              .build()}
          >
            {widget.queries![i].description}
          </label>
        </div>
        <div className="w-40">
          {(widget.queries![i].queryResults === undefined ||
            widget.queries![i].queryResults!.length === 0) && (
            <div className="flex flex-row float-right">
              <label
                className={CSName(FontStyles.caption)
                  .combine("dark:text-nx-gray-200 text-nx-gray-400")
                  .build()}
              >
                {t("noRegisters")}
              </label>
              <NxSVG className="w-5 h-5 fill-nx-success-600 ml-1.5 -mt-0.5">
                <IconCheck />
              </NxSVG>
            </div>
          )}
          {widget.queries![i].queryResults !== undefined &&
            widget.queries![i].queryResults!.length > 0 && (
              <div className="flex flex-row float-right">
                <label
                  className={CSName(FontStyles.caption)
                    .combine("dark:text-nx-white text-nx-gray-700")
                    .build()}
                >
                  {t("involvementRecord")}
                </label>
                <NxSVG className="w-5 h-5 fill-nx-danger-600 ml-1.5 -mt-0.5">
                  <IconWarning />
                </NxSVG>
              </div>
            )}
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
            .build()}
        >
          {t("firstName")}:
        </label>
        <label
          className={CSName(FontStyles.bodyRegular)
            .combine("dark:text-nx-white text-nx-gray-700 text-start")
            .build()}
        >
          {widget.queries![i].name}
        </label>
      </div>

      {widget.queries![i].queryResults !== undefined &&
        widget.queries![i].queryResults!.length > 0 && (
          <div className="flex flex-col">
            <div className="flex flex-col mt-2">
              <label
                className={CSName(FontStyles.caption)
                  .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
                  .build()}
              >
                {t("numberOfWorkers")}
              </label>
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-white text-nx-gray-700 text-start")
                  .build()}
              >
                {widget.queries![i].requestBody}
              </label>
            </div>
            <div className="flex flex-col mt-2">
              <label
                className={CSName(FontStyles.caption)
                  .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
                  .build()}
              >
                {t("employerRegistry")}
              </label>
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-white text-nx-gray-700 text-start")
                  .build()}
              >
                {format(widget.queries![i].createdAt!, "dd/MM/yyyy")}
              </label>
            </div>
            <div className="flex flex-col mt-2">
              <label
                className={CSName(FontStyles.caption)
                  .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start")
                  .build()}
              >
                CNAE
              </label>
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("dark:text-nx-white text-nx-gray-700 text-start")
                  .build()}
              >
                {widget.queries![i].parameters}
              </label>
            </div>
          </div>
        )}
      <div className="relative mt-2 mb-4">
        <label
          className={CSName(FontStyles.caption)
            .combine("dark:text-nx-gray-200 text-nx-gray-400 text-start absolute right-0")
            .build()}
        >
          {t("source")} {widget.queries![i].method}
        </label>
      </div>
    </div>
  );
}
