import { MapComponent } from "web/common/components/Maps/MapComponent";

import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MapComponentController } from "web/common/components/Maps/controller/MapController";
import {
  DrawLayer,
  EmptyLayer,
} from "web/common/components/Maps/types/DrawLayer";
import { MapDrawModes } from "web/common/components/Maps/types/MapDrawModes";

import { PivotLocationFooter } from "./components/PivotLocationFooter";
import { NxFormField } from "web/common/components/TextFields/NxFormField";
import { IconSearch } from "web/common/fonts/icons/components/IconSearch";
import { NxSVG } from "web/common/components/NxSvg";
import { CSName } from "web/common/utils/ClassName";
import { useTranslation } from "react-i18next";
import { useLocationsModal } from "web/features/locations/presentation/context/LocationsModalProvider";
import {
  ShowCreateLocationModal,
  HideLocationModal,
} from "web/features/locations/presentation/context/abstraction/LocationModal";
import {
  useCreateLocationHook,
  LocationCreated,
} from "web/features/locations/presentation/hooks/UseCreateLocationHook";
import {
  useUpdateLocationHook,
  LocationUpdated,
} from "web/features/locations/presentation/hooks/UseUpdateLocationHook";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { ItemEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";
import { FontStyles } from "web/common/fonts/FontStyles";
import { PivotLocationSideMenu } from "./components/PivotLocationSideMenu";

interface PivotLocationProps {
  className?: string;
  item: ItemEntity;
  onCreated: () => void;
  onClickOutside?: () => void;
}

export const PivotLocationModal = ({
  className,
  item,
  onCreated,
  onClickOutside,
}: PivotLocationProps) => {
  const { t } = useTranslation();
  const { createLocationState, createLocation, setState } =
    useCreateLocationHook();
  const { updateLocationState, updateLocation, setUpdateState } =
    useUpdateLocationHook();
  const mapController = useRef<MapComponentController>();
  const { modal, setModal } = useLocationsModal();

  const [eventDraw, setEventDraw] = useState<{
    type: "created" | "edited";
    draw: DrawLayer | EmptyLayer;
  }>();

  const [draws, setDraws] = useState<Array<DrawLayer | EmptyLayer>>([]);
  const drawsRef = useRef(draws);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const currentIndexRef = useRef(currentIndex);

  const [mapModeState, setMapModeState] = useState<MapDrawModes>(
    MapDrawModes.circlemarker
  );
  const changeDraws = (draws: Array<DrawLayer | EmptyLayer>) => {
    drawsRef.current = draws;
    setDraws(draws);
  };

  const changeCurrentIndex = (index: number) => {
    currentIndexRef.current = index;
    setCurrentIndex(index);
  };
  useEffect(() => {
    const content = modal as ShowCreateLocationModal;
    if (
      modal instanceof ShowCreateLocationModal &&
      content.content !== undefined
    ) {
      const options = [
        {
          type: MapDrawModes.circle,
          value: "Point",
        },
        {
          type: MapDrawModes.circlemarker,
          value: "Point",
        },
        {
          type: MapDrawModes.polygon,
          value: "Polygon",
        },
      ];
      const locations = content.content!;

      const draw = new DrawLayer({
        points: DrawLayer.converGeoJsonToLatLngs(locations),
        radius: locations.properties.radius,
        drawMode: options.filter((o) => o.value === locations.geometry.type)[0]
          .type,
      });
      setDraws([draw]);
    }
  }, [modal]);

  useEffect(() => {
    const index = currentIndexRef.current;

    if (eventDraw?.type === "created") {
      eventDraw.draw.id = uuidv4();
      if (index === -1) {
        changeCurrentIndex(0);
        changeDraws([eventDraw.draw]);
      } else {
        drawsRef.current[index] = eventDraw.draw;
        changeDraws([...drawsRef.current]);
      }
    }
    if (eventDraw?.type === "edited") {
      eventDraw.draw.id = uuidv4();
      drawsRef.current[index] = eventDraw.draw;
      changeDraws([...drawsRef.current]);
    }
  }, [eventDraw]);

  useEffect(() => {
    mapController.current?.clearEdit();
    mapController.current?.enableMode(mapModeState);
  }, [mapModeState]);

  const onDrawCreated = (cleyton: any, e: DrawLayer) => {
    setEventDraw({ type: "created", draw: e });
  };

  const onEditCreated = (e: DrawLayer) => {
    setEventDraw({ type: "edited", draw: e });
  };
  const updateDrawToEdit = useCallback(
    (draw: DrawLayer | EmptyLayer) => {
      setTimeout(() => {
        mapController.current?.clearEdit();
        if (draw instanceof EmptyLayer) {
          mapController.current?.enableMode(mapModeState);
          return;
        }
        mapController.current?.addLayer(draw);
        mapController.current?.fitBounds(draw);
        mapController.current?.enableEditMode();
      }, 100);
    },
    [mapModeState]
  );

  const deleteDraw = useCallback(
    (index: number) => {
      const newDraws = draws.filter((_, i) => i !== index);
      changeDraws(newDraws);
      if (newDraws.length) {
        changeCurrentIndex(newDraws.length - 1);
        return updateDrawToEdit(newDraws[newDraws.length - 1]);
      }
      changeCurrentIndex(-1);
      setTimeout(() => {
        mapController.current?.clearEdit();
        mapController.current?.enableMode(mapModeState);
      }, 100);
    },
    [draws, mapModeState, updateDrawToEdit]
  );

  useEffect(() => {
    if (
      (createLocationState instanceof LocationCreated ||
        updateLocationState instanceof LocationUpdated) &&
      draws.length > 0 &&
      !(modal instanceof HideLocationModal)
    ) {
      setModal(new HideLocationModal({ reload: true }));
      setDraws([]);
      setState(undefined);
      setUpdateState(undefined);
      deleteDraw(0);
    }
  }, [
    createLocationState,
    updateLocationState,
    setModal,
    setState,
    setUpdateState,
    modal,
    draws.length,
    deleteDraw,
  ]);

  return (
    <NxModal showModal={true}>
      <div
        onClick={() => {
          setDraws([]);
          onClickOutside!();
        }}
        className={CSName(
          "fixed flex justify-center items-center left-0 top-0 w-full h-full bg-nx-black/50"
        )
          .combine(className)
          .build()}
      >
        <div onClick={onClickOutside} className="flex flex-row gap-4">
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex flex-col p-6 rounded-lg h-[47.25rem] w-[37.5rem] bg-nx-white dark:bg-nx-dark-1100"
          >
            <header className="flex flex-col items-center mb-2">
              <div className="flex flex-col w-full mb-4">
                <h3
                  className={CSName(FontStyles.subtitle)
                    .combine(
                      "text-nx-dark-800 dark:text-nx-main-200 text-start"
                    )
                    .build()}
                >
                  {item.name}
                </h3>
                <label
                  className={CSName(FontStyles.caption)
                    .combine("dark:text-nx-gray-200 text-start")
                    .build()}
                >
                  {t("configureAppointment")}
                </label>
              </div>
              <div className="flex flex-row">
                <h5 className="text-caption text-nx-gray-200">
                  {`${t("createLocationWarning1")} ${t("or").toLowerCase()}`}
                </h5>
                <h5 className="ml-1 text-caption dark:text-nx-main-500">
                  {`${t('selectLocations')}`}
                </h5>
              </div>
            </header>
            <NxFormField
              className="mb-6"
              labelText={t("searchOnMap")}
              prefix={
                <NxSVG className="stroke-nx-gray-400">
                  <IconSearch />
                </NxSVG>
              }
            />

            <MapComponent
              className="w-full h-96 rounded-lg overflow-hidden"
              onMonted={(controller) => {
                mapController.current = controller;
                mapController.current.enableMode(mapModeState);
              }}
              onEditCreated={onEditCreated}
              onDrawCreated={(e) => {
                onDrawCreated(draws, e);
              }}
            />
            <h5 className="my-6 text-caption text-nx-gray-200  self-center">
              {t("createLocationWarning2")}
            </h5>
            <PivotLocationFooter
              mode={mapModeState}
              onModeChange={(newMode) => setMapModeState(newMode)}
            />
          </div>
          <PivotLocationSideMenu
            currentIndex={currentIndex}
            draws={draws}
            state={createLocationState || updateLocationState}
            name={(modal as ShowCreateLocationModal).content?.properties.name}
            onCreated={onCreated}
            onFinish={(n, d) => {
              const location = (modal as ShowCreateLocationModal).content;
              const coordinates = d.points.map((e) => {
                const [lat, long] = e.toString().split(",").map(Number);
                return [lat, long];
              });
              if (location !== undefined) {
                updateLocation({
                  location: {
                    id: location.id,
                    type: "Location",
                    properties: {
                      radius: d.radius ?? location.properties.radius,
                      name: n,
                      widgetIds: [],
                      entityIds: [],
                    },
                    geometry: {
                      type: d.drawMode,
                      coordinates: coordinates,
                    },
                  },
                });
              } else {
                createLocation({
                  location: {
                    type: "Location",
                    properties: {
                      radius: d.radius ?? 0,
                      name: n,
                      widgetIds: [],
                      entityIds: [],
                    },
                    geometry: {
                      type: d.drawMode,
                      coordinates: coordinates,
                    },
                  },
                });
              }
            }}
            onDeleted={(index) => {
              deleteDraw(index);
            }}
            onEdit={(index) => {
              const draw = draws[index];
              if (draw instanceof EmptyLayer) return updateDrawToEdit(draw);

              setMapModeState(draw.drawMode);
              changeCurrentIndex(index);
              updateDrawToEdit(draw);
            }}
            onAddMoreClick={() => {
              mapController.current?.clearEdit();
              changeDraws([
                ...draws,
                new EmptyLayer({
                  id: uuidv4(),
                }),
              ]);
              changeCurrentIndex(draws.length);
              mapController.current?.enableMode(mapModeState);
            }}
          />
        </div>
      </div>
    </NxModal>
  );
};
