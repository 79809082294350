import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";

export abstract class ProjectsModal {}

export class HideProjectModal extends ProjectsModal {
  reloadProjects: boolean;

  constructor(params: Partial<HideProjectModal> | undefined) {
    super();
    this.reloadProjects = params?.reloadProjects ?? false;
  }
}

export class ShowCreateOrEditProjectModal extends ProjectsModal {
  project?: ProjectEntity | undefined;
  constructor({ project }: Partial<ShowCreateOrEditProjectModal>) {
    super();
    this.project = project;
  }
}

export class ShowArchiveProjectModal extends ProjectsModal {}

export class ShowShareProjectModal extends ProjectsModal {
  // members: MembersEntity[] | undefined;
  // constructor({ members }: Partial<ShowShareProjectModal>) {
  //   super();
  //   this.members = members;
  // }
}

export class ShowProjectsPanelsModal extends ProjectsModal {
  panelsList: WidgetEntity[] | undefined;
  constructor({ panelsList }: Partial<ShowProjectsPanelsModal>) {
    super();
    this.panelsList = panelsList;
  }
}
