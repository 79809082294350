export const IconCreditCardFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      stroke="transparent"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C2.89543 4.5 2 5.39543 2 6.5V8H18V6.5C18 5.39543 17.1046 4.5 16 4.5H4ZM18 9H2V13.5C2 14.6046 2.89543 15.5 4 15.5H16C17.1046 15.5 18 14.6046 18 13.5V9Z"
        fill="#667C8E"
      />
    </svg>
  );
};
