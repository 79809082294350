import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHomeNavQuickSearchModal } from "./HomeNavQuickSearchModalContext";

interface HomeNavPopoverMenuProps {
  onNeedCloseModal: () => void;
}
export const HomeNavPopoverMenu = forwardRef<
  HTMLUListElement,
  HomeNavPopoverMenuProps
>(({ onNeedCloseModal }, ref) => {

  const { showModal} = useHomeNavQuickSearchModal();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ul
      ref={ref}
      className="invisible opacity-0 flex flex-col gap-2 absolute left-64 w-32 py-2 z-10 mb-12  rounded transition bg-nx-white dark:bg-nx-dark-1200 border-nx-gray-400 border-[0.5px]"
    >
      <div
        className="pl-2 pr-2 mb-2"
      >
        <li
          className="group/change-theme relative flex flex-row items-center justify-between text-xs w-full h-8 rounded text-start px-2  
          text-nx-gray-600 dark:nx-text-white 
          hover:bg-nx-main-600 hover:text-nx-white focus:bg-nx-main-600 focus:text-nx-white transition dark:text-nx-white
          "
          onClick={() => showModal()}
        >
          {t("quickSearch")}
        </li>
      </div>
      <div className="pl-2 pr-2">
        <li
          className="w-full h-8 px-2 text-start text-xs flex flex-col justify-center text-nx-gray-600 dark:text-nx-white  rounded transition
           hover:bg-nx-main-600 hover:text-nx-white
        "
          onClick={() => {
            onNeedCloseModal();
            // eslint-disable-next-line no-useless-concat
            navigate("." + "/projects")
          }}
        >
          {t("project")}
        </li>
      </div>
    </ul>
  );
});
