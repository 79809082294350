import { NxFilledButton } from "web/common/components/Buttons/NxFilledButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { t } from "i18next";
import { useProjectModal } from "../../context/ProjectModalProvider";
import { HideProjectModal } from "../../context/abstraction/ProjectsModal";
import { WidgetEntity } from "web/core/domain/entities/WidgetEntity";

type WidgetsParams = {
  panelsList: WidgetEntity[] | undefined;
};

export function PanelsProjecModal({ panelsList }: WidgetsParams) {
  const { setModal } = useProjectModal();

  return (
    <div>
      <NxModal
        showModal={true}
        onOutsideClick={() => {
          setModal(new HideProjectModal({ reloadProjects: false }));
        }}
      >
        <div className="grid grid-flow-col place-content-between w-fit">
          <div className="flex flex-col">
            <label
              className={CSName(FontStyles.subtitle)
                .combine("text-nx-dark-800 dark:text-nx-main-200 text-start")
                .build()}
            >
              {t("associatedPanels")}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
                .build()}
            >
              {t("associatedPanelsDescription")}
            </label>
          </div>
        </div>

        <div className="my-4">
          <div className="px-4 pt-4 bg-nx-gray-50 dark:bg-nx-dark-1200 w-fit h-fit grid grid-cols-4 gap-1  rounded">
            {panelsList?.map((p, index) => (
              <label
                className={CSName(FontStyles.caption)
                  .combine(
                    "rounded-full px-3 py-1.5 bg-nx-focus-dark text-nx-main-500 dark:text-nx-main-200 truncate max-w-28 mb-4"
                  )
                  .build()}
                key={index}
              >
                {p.name}
              </label>
            ))}
          </div>
        </div>
        <NxFilledButton
          onClick={() => {
            setModal(new HideProjectModal({ reloadProjects: false }));
          }}
        >
          {t("back")}
        </NxFilledButton>
      </NxModal>
    </div>
  );
}
