import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { CSName } from "web/common/utils/ClassName";
import { useEffect, useRef } from "react";

import { IconSort } from "web/common/fonts/icons/components/IconSort";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { LocationTbody } from "../LocationTbody/LocationTbody";
import {
  useGetLocationsHook,
  GetLocationsLoading,
  GetLocationsSuccess,
} from "../../hooks/UseGetLocationsHook";
import {
  SortByDate,
  SortByName,
  SortByWidgets,
  useSortLocationBy,
} from "../../hooks/UseSortLocationBy";
import { useLocationsModal } from "../../context/LocationsModalProvider";
import { HideLocationModal } from "../../context/abstraction/LocationModal";

export function LocationTable() {
  const { t } = useTranslation();
  const { getLocationState, getLocations } = useGetLocationsHook();
  // const [isCopied, setIsCopied] = useState<boolean>(false);
  const { sortState, setState, sortedBy, filterLocations } =
    useSortLocationBy();
  const isFetching = useRef<boolean>();
  const isFiltered = useRef<boolean>(false);
  const { modal, setModal } = useLocationsModal();

  useEffect(() => {
    if (modal instanceof HideLocationModal) {
      if (!isFetching.current) {
        getLocations();
        setTimeout(() => {
          isFiltered.current = false;
          isFetching.current = true;
        }, 50);
      }
      if (modal.reload === true) {
        isFetching.current = false;
        setModal(new HideLocationModal({}));
      }
    }
  }, [modal, setModal, getLocations]);

  useEffect(() => {
    if (sortState !== undefined) {
      isFiltered.current = false;
    }
  }, [sortState]);

  useEffect(() => {
    if (
      getLocationState instanceof GetLocationsSuccess &&
      !isFiltered.current
    ) {
      filterLocations({
        instance: sortState,
        locations: {
          archived: getLocationState.archived,
          restored: getLocationState.restored,
        },
      });
      isFiltered.current = true;
    }
  }, [getLocationState, sortState, filterLocations]);

  return (
    <div>
      {getLocationState instanceof GetLocationsSuccess && (
          <div>
            <div
              className={
                "pl-6 flex flex-row text-[10px] font-semibold text-nx-gray-200 uppercase"
              }
            >
              <div
                className="w-[24%] flex flex-row"
                onClick={() => {
                  setState(
                    new SortByName({
                      orderByAsc: !sortedBy.name || !sortState?.orderByAsc,
                    })
                  );
                }}
              >
                <label className="cursor-pointer tracking-[1.5px]">
                  {t("firstName")}
                </label>
                {sortedBy.name && (
                  <NxSVG
                    className={CSName(
                      "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                    )
                      .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                      .build()}
                  >
                    <IconSort />
                  </NxSVG>
                )}
              </div>
              <div
                className="w-[53.5%] flex flex-row"
                onClick={() => {
                  setState(
                    new SortByWidgets({
                      orderByAsc: !sortedBy.widgets || !sortState?.orderByAsc,
                    })
                  );
                }}
              >
                <label className="cursor-pointer tracking-[1.5px]">
                  {t("associatedWidgets")}
                </label>
                {sortedBy.widgets && (
                  <NxSVG
                    className={CSName(
                      "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                    )
                      .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                      .build()}
                  >
                    <IconSort />
                  </NxSVG>
                )}
              </div>
              <div
                className="w-[18%] flex flex-row"
                onClick={() => {
                  setState(
                    new SortByDate({
                      orderByAsc: !sortedBy.date || !sortState?.orderByAsc,
                    })
                  );
                }}
              >
                <label className="cursor-pointer">{t("creationDate")}</label>
                {sortedBy.date && (
                  <NxSVG
                    className={CSName(
                      "ml-2 fill-nx-focus-light stroke-nx-main-600 dark:fill-nx-focus-dark dark:stroke-nx-main-500 w-4 h-4 cursor-pointer"
                    )
                      .combine(!sortState?.orderByAsc ? "rotate-180" : "")
                      .build()}
                  >
                    <IconSort />
                  </NxSVG>
                )}
              </div>
              <div className="mr-3 flex flex-row"></div>
            </div>

            <div className="flex flex-col">
              {getLocationState?.restored?.map((location, index) => {
                
                return (
                  <LocationTbody
                    key={location.id}
                    data={location}
                    isArchived={false}
                    showBorder={index + 1 < getLocationState!.restored!.length}
                    onClick={() => {}}
                  />
                );
              })}
            </div>

            {(getLocationState?.archived ?? []).length > 0 && (
              <div>
                <div className="flex flex-row mt-8 ml-6">
                  <NxSVG className="fill-nx-gray-400 w-5 h-5">
                    <IconArchive />
                  </NxSVG>
                  <label
                    className={CSName(FontStyles.bodyLargeBold)
                      .combine("text-nx-gray-600 dark:text-nx-white ml-2")
                      .build()}
                  >
                    {t("archived")}
                  </label>
                </div>

                <div className="flex flex-col">
                  {getLocationState!.archived!.map((location, index) => {
                    
                    return (
                      <LocationTbody
                        key={location.id}
                        data={location}
                        isArchived={true}
                        showBorder={index + 1 < getLocationState!.archived!.length}
                        onClick={() => {}}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

      {getLocationState instanceof GetLocationsLoading && (
        <div className="flex flex-col mt-[15%] m-auto">
          <CircularProgressBar
            className="stroke-nx-main-600"
            width={20}
            height={20}
          />
          <label
            className={CSName(FontStyles.bodyRegular)
              .combine("text-nx-gray-200")
              .build()}
          >
            {t("loadingData")}
          </label>
        </div>
      )}
    </div>
  );
}
