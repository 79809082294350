import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { LocationEntity } from "../../domain/entities/LocationEntity";
import { GetLocationsUsecase } from "../../domain/usecase/GetLocationsUsecase";

abstract class GetLocationsState {}

export class GetLocationsLoading extends GetLocationsState {}

export class GetLocationsSuccess extends GetLocationsState {
  restored: LocationEntity[];
  archived: LocationEntity[];

  constructor({ restored, archived }: Required<GetLocationsSuccess>) {
    super();
    this.restored = restored;
    this.archived = archived;
  }
}

export class GetLocationsFailure extends GetLocationsState {}

export const useGetLocationsHook = () => {
  const [getLocationState, setState] = useState<GetLocationsState>();

  return {
    getLocationState,
    getLocations: async () => {
      setState(new GetLocationsLoading());

      const [restoredPagination, archivedPagination] = await Promise.all([
        GetLocationsUsecase(false),
        GetLocationsUsecase(true),
      ]);

      if (
        restoredPagination instanceof PaginationEntity &&
        archivedPagination instanceof PaginationEntity
      ) {
        return setState(
          new GetLocationsSuccess({
            restored: restoredPagination.results[0].features,
            archived: archivedPagination.results[0].features,
          })
        );
      }

      return setState(new GetLocationsFailure());
    },
  };
};