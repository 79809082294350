import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NxSVG } from "web/common/components/NxSvg";
import { IconDelete } from "web/common/fonts/icons/components/IconDelete";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { CSName } from "web/common/utils/ClassName";

interface LocationCreateMiniAreaPopMenuProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
}

export const LocationCreateMiniAreaPopMenu = ({
  onEditClick,
  onDeleteClick,
}: LocationCreateMiniAreaPopMenuProps) => {
  const [visibility, setVisibility] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      let parent = e.target as Node;
      while (parent) {
        parent = parent.parentNode as Node;
        if (parent === divRef.current) return;
      }
      setVisibility(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div ref={divRef} className="relative">
      <NxSVG
        className="cursor-pointer fill-nx-main-500 w-5 h-5"
        onClick={() => {
          setVisibility(!visibility);
        }}
      >
        <IconMore />
      </NxSVG>

      <div
        className={CSName(
          `flex flex-col gap-2 absolute z-[9999] right-0 rounded  p-2 bg-nx-gray-50 dark:bg-nx-dark-1200 transition`
        )
          .concatIf(visibility, "visible opacity-1", "invisible opacity-0")
          .build()}
      >
        <div
          className="flex flex-row gap-2  rounded items-center p-2 group hover:bg-nx-main-600 cursor-pointer transition"
          onClick={() => {
            onEditClick();
            setVisibility(false);
          }}
        >
          <NxSVG className=" group-hover:fill-nx-white fill-nx-gray-200 w-4 h-4 ">
            <IconEdit />
          </NxSVG>
          <span className="text-xs text-nx-gray-700 dark:text-nx-white  group-hover:text-nx-white font-normal">
            {t("edit")}
          </span>
        </div>
        <div
          className="flex flex-row gap-2  rounded items-center p-2 group hover:bg-nx-main-600 cursor-pointer transition"
          onClick={() => {
            onDeleteClick();
            setVisibility(false);
          }}
        >
          <NxSVG className=" group-hover:fill-nx-white fill-nx-gray-200 w-4 h-4 ">
            <IconDelete />
          </NxSVG>
          <span className="text-xs  text-nx-gray-700 dark:text-nx-white  group-hover:text-nx-white font-normal">
            {t("delete")}
          </span>
        </div>
      </div>
    </div>
  );
};
