import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { PoliciesTermsEntity } from "web/features/auth/domain/entities/PoliciesTermsEntity";
import { GetPoliciesUserUsecase } from "web/features/auth/domain/usecase/GetPoliciesUserUsecase";

abstract class GetPoliciesUserState {}

export class GetPoliciesUserFailure extends GetPoliciesUserState {}

export class GetPoliciesUserLoading extends GetPoliciesUserState {}

export class GetPoliciesUserSuccess extends GetPoliciesUserState {
  policiesUser: PoliciesTermsEntity;

  constructor({ policiesUser }: Required<GetPoliciesUserSuccess>) {
    super();
    this.policiesUser = policiesUser;
  }
}

export const useGetPoliciesUserHook = () => {
  const [getPoliciesUserState, setState] = useState<GetPoliciesUserState>();

  return {
    getPoliciesUserState,
    getPoliciesUser: async () => {
      setState(new GetPoliciesUserLoading());

      const result = (await GetPoliciesUserUsecase());
      if (result instanceof PaginationEntity) {
        return setState(
          new GetPoliciesUserSuccess({
            policiesUser: result.results[0],
          })
        );
      }

      return setState(new GetPoliciesUserFailure());
    },
  };
};
