import { createContext, useContext, useState } from "react";
import { CreateOrUpdateOrg } from "../components/CreateOrUpdateOrg/CreateOrUpdateOrg";
import {
  HideOrganizationModal,
  OrganizationsModal,
  ShowCreateApiKeyModal,
  ShowCreateOrEditOrganizationModal,
  ShowLeaveOrganizationModal,
  ShowNewAdminModal,
  ShowRemoveApiKeyModal,
} from "./abstraction/OrganizationModal";
import { LeaveOrgModal } from "../components/LeaveOrgModal/LeaveOrgModal";
import { SelectAdminModal } from "../components/SelectAdminModal/SelectAdminModal";
import { OrganizationEntity } from "../../domain/entities/OrganizationEntity";
import RemoveApiKeyModal from "../components/RemoveApiKeyModal/RemoveApiKeyModal";
import CreateApiKeyModal from "../components/CreateApiKeyModal/CreateApikKeyModal";

type OrgModalType = {
  modal: OrganizationsModal;
  setModal: (modal: OrganizationsModal) => void;
  organization: OrganizationEntity | null;
  setOrganization: (organization: OrganizationEntity | null) => void;
};

export const OrgModalContext = createContext<OrgModalType>({} as OrgModalType);

export function useOrgModal() {
  return useContext(OrgModalContext);
}

export function OrgModalProvider(props: any) {
  const [modal, changeModal] = useState<OrganizationsModal>(
    new HideOrganizationModal({ reloadOrgs: false })
  );
  const [organization, setOrganization] = useState<OrganizationEntity | null>(
    null
  );

  const setModal = (newModal: OrganizationsModal) => {
    changeModal(newModal);
  };

  return (
    <OrgModalContext.Provider
      value={{
        modal,
        setModal,
        organization,
        setOrganization,
      }}
    >
      <div className="w-full h-full">
        {props.children}
        {modal instanceof ShowCreateOrEditOrganizationModal && (
          <CreateOrUpdateOrg
            onSuccess={(modal as ShowCreateOrEditOrganizationModal).onSuccess}
            org={(modal as ShowCreateOrEditOrganizationModal).createOrgParams}
           
          />
        )}
        {modal instanceof ShowLeaveOrganizationModal && <LeaveOrgModal />}
        {modal instanceof ShowNewAdminModal && <SelectAdminModal />}
        {modal instanceof ShowCreateApiKeyModal && (
          <CreateApiKeyModal org={(modal as ShowCreateApiKeyModal).org} />
        )}
        {modal instanceof ShowRemoveApiKeyModal && (
          <RemoveApiKeyModal
            apiKey={(modal as ShowRemoveApiKeyModal).apiKey}
            org={(modal as ShowRemoveApiKeyModal).org}
          />
        )}
      </div>
    </OrgModalContext.Provider>
  );
}
