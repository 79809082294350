import { useState } from "react";
import { GetPanelsUsecase } from "../../domain/usecases/GetPanelsUsecase";
import { PanelEntity } from "../../domain/entities/PanelEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { getPanelsFailure } from "../../domain/errors/GetPanelsFailure";

abstract class PanelState {}

export class FetchingPanels extends PanelState {}

export class PanelsFetched extends PanelState {
  archiveds: PanelEntity[];
  restoreds: PanelEntity[];
  constructor({ archiveds, restoreds }: Required<PanelsFetched>) {
    super();
    this.archiveds = archiveds;
    this.restoreds = restoreds;
  }
}

export class PanelsFetchingFailure extends PanelState {}

export const useGetPanelsHook = () => {
  const [fetchPanelState, setState] = useState<PanelState>();

  return {
    fetchPanelState,
    getPanels: async (id: string) => {
      setState(new FetchingPanels());

      const [archived, restored] = await Promise.all([
        GetPanelsUsecase(true),
        GetPanelsUsecase(false),
      ]);

      if (
        archived instanceof getPanelsFailure ||
        restored instanceof getPanelsFailure
      ) {
        setState(new PanelsFetchingFailure());
      }
      let archiveds = archived as PaginationEntity<PanelEntity>;
      let restoreds = restored as PaginationEntity<PanelEntity>;
      setState(
        new PanelsFetched({
          archiveds: archiveds.results.filter(e=>e.project === id),
          restoreds: restoreds.results.filter(e=>e.project === id),
        })
      );
    },
  };
};
