import { IconAdd } from "../fonts/icons/components/IconAdd";
import { IconImage } from "../fonts/icons/components/IconImage";
import { IconMore } from "../fonts/icons/components/IconMore";
import { CSName } from "../utils/ClassName";
import { NxDropElement } from "./NxDropElement/NxDropElement";
import { NxSVG } from "./NxSvg";
import { OptionsParams } from "./NxDropElement/params/OptionsParams";

export interface DashboardEntity {
  title: string;
  description: string;
  image?: string;
}

interface DashboardCardProps {
  dashboard?: DashboardEntity;
  newButtonLabel: string;
  options?: OptionsParams[];
  onProjectClick: () => void;
}

interface ImageCardProps {
  dashboard?: DashboardEntity;
  newButtonLabel: string;
}
const ImageCard = ({ dashboard, newButtonLabel }: ImageCardProps) => {
  if (!dashboard) {
    return (
      <div className="m-auto flex flex-col">
        <div className="rounded-full p-3 bg-nx-main-600 cursor-pointer mb-2 self-center">
          <NxSVG className="fill-white stroke-nx-white" width={22} height={22}>
            <IconAdd />
          </NxSVG>
        </div>
        <h5 className="text-xs font-medium text-nx-gray-600 dark:text-nx-white">
          {newButtonLabel}
        </h5>
      </div>
    );
  }
  if (!dashboard.image) {
    return (
      <NxSVG className="w-8 h-8 fill-nx-gray-600 m-auto dark:fill-nx-white ">
        <IconImage />
      </NxSVG>
    );
  }
  return (
    <img src={dashboard.image} alt="" className="w-full h-full object-cover " />
  );
};

export const NxDashboardCard = ({
  onProjectClick,
  dashboard,
  newButtonLabel,
  options = [],
}: DashboardCardProps) => {
  return (
    <div
      className="flex flex-row flex-wrap items-start gap-6 mt-6 cursor-pointer"
      onClick={onProjectClick}
    >
      <div className="w-80 flex flex-col overflow-x justify-end">
        <div
          className="flex w-full aspect-[331/195]
          rounded-lg border-[0.5px] border-nx-gray-200 dart:border-nx-gray-500 overflow-hidden "
        >
          <ImageCard dashboard={dashboard} newButtonLabel={newButtonLabel} />
        </div>

        <div
          className={CSName("flex flex-row justify-between mt-1")
            .concatIf(dashboard !== undefined, "visible", "invisible")
            .build()}
        >
          <div className="flex flex-col ">
            <h6 className="text-sm text-gray-600 font-medium dark:text-white">
              {dashboard?.title}
            </h6>
            <h6 className="text-start text-caption text-gray-400 font-normal">
              {dashboard?.description}
            </h6>
          </div>
          <NxDropElement
            options={options}
            dimension={{
              x: options.length > 0 ? 120 : 10,
              y: options.length > 0 ? 20 : 0
            }}
          >
            <NxSVG
              className="cursor-pointer fill-nx-gray-600 dark:fill-nx-white w-5 h-5"
              onClick={(e) => {
                
              }}
            >
              <IconMore />
            </NxSVG>
          </NxDropElement>
        </div>
      </div>
    </div>
  );
};
