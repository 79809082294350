import { RouteObject } from "react-router-dom";
import { AuthPageComponent } from "../pages/AuthPageComponent";
import LoginPage from "../../features/login/presentation/page/LoginPage";
import { SignUpPage } from "../../features/sign-up/presentation/page/SignUpPage";
import { loginGuardLoader } from "./loaders/LoginGuardLoader";

const AuthRoutes: RouteObject = {
  path: "/auth",
  loader: loginGuardLoader(),
  element: <AuthPageComponent />,
  children: [
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "sign-up",
      element: <SignUpPage />,
    },
  ],
};

export default AuthRoutes;
