import { t } from "i18next";

export const validateCpf = (value: string): string | boolean => {
    if (!value) {
      return t('invalidDocument');
    } else if (value.length < 11) {
      return t('fieldFilledQuestion');
    }
    return true;
  };
  