import { useState } from "react";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { PoliciesTermsEntity } from "web/features/auth/domain/entities/PoliciesTermsEntity";
import { GetTermsUserUsecase } from "web/features/auth/domain/usecase/GetTermsUserUsecase";


abstract class GetTermsUserState {}

export class GetTermsUserFailure extends GetTermsUserState {}

export class GetTermsUserLoading extends GetTermsUserState {}

export class GetTermsUserSuccess extends GetTermsUserState {
  termsUser: PoliciesTermsEntity;

  constructor({ termsUser }: Required<GetTermsUserSuccess>) {
    super();
    this.termsUser = termsUser;
  }
}

export const useGetTermsUserHook = () => {
  const [getTermsUserState, setState] = useState<GetTermsUserState>();

  return {
    getTermsUserState,
    getTermsUser: async () => {
      setState(new GetTermsUserLoading());

      const result = await GetTermsUserUsecase();
      if (result instanceof PaginationEntity) {
        return setState(
          new GetTermsUserSuccess({
            termsUser: result.results[0],
          })
        );
      }

      return setState(new GetTermsUserFailure());
    },
  };
};
