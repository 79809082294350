import { Outlet, RouteObject } from "react-router-dom";
import React from "react";
import HomeRoutes from "./web/features/home/presentation/routes/HomeRoutes";
import AuthRoutes from "./web/features/auth/presentation/routes/AuthRoutes";
import { LoadingOAuthPage } from "web/features/loading/presentation/pages/LoadingOAuthPage";
import { AppOutletPage } from "web/features/app/presentation/pages/AppOutletPage";
import { AppNotFoundPage } from "web/features/app/presentation/pages/AppNotFoundPage";
import { ManagementRoutes } from "web/features/management/routes/ManagementRoutes";
import { WidgetsPage } from "web/features/widgets/presentation/pages/WidgetsPage";
import { WidgetsModalProvider } from "web/features/widgets/presentation/context/WidgetsModalProvider";

export const AppRoutes: RouteObject[] = [
  AuthRoutes,
  {
    path: "/",
    element: <AppOutletPage />,
    children: [
      {
        path: "/loading-oauth",
        element: <LoadingOAuthPage />,
      },
      {
        path: "/management",
        children: [ManagementRoutes],
      },
      {
        path: "/org/:organizationId",
        element: <Outlet />,
        children: [HomeRoutes],
      },
      {
        path: "/widgets",
        element: (
          <WidgetsModalProvider>
            <WidgetsPage />
          </WidgetsModalProvider>
        ),
      },
      {
        path: "*",
        element: <AppNotFoundPage />,
      },
    ],
  },
];
