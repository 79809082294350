import { useState } from "react";

import { archiveProjectUsecase } from "../../domain/usecase/ArchiveProjectUsecase";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";

abstract class ArchiveProjectState {}

export class ArchiveProjectLoading extends ArchiveProjectState {}

export class ArchiveProjectSuccess extends ArchiveProjectState {}

export class ArchiveProjectFailure extends ArchiveProjectState {}

export const useArchiveProjectsHook = () => {
  const [archiveProjectState, setState] = useState<ArchiveProjectState>();

  return {
    archiveProjectState,
    archiveProject: async (id: string) => {
      setState(new ArchiveProjectLoading());

      const result = await archiveProjectUsecase(id);

      if (result instanceof EmptyEntity) {
        return setState(new ArchiveProjectSuccess());
      }

      return setState(new ArchiveProjectFailure());
    },
  };
};
