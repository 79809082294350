import { networkAdapter } from "web/core/adapters/AdaptersInstances";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { CreateProjectFailure } from "../errors/CreateProjectFailure";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { CreateProjectParams } from "../params/CreateProjectParams";
import { EditProjectParams } from "../params/EditProjectParams";
import { EditProjectFailure } from "../errors/EditProjectFailure";
import { GetProjectsFailure } from "../errors/GetProjectFailure";
import { ProjectEntity } from "../entity/ProjectEntity";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { GetProjectsParams } from "../params/GetProjectsParams";
import { ArchiveProjectsFailure } from "../errors/ArchiveProjectFailure";
import { RestoreProjectsFailure } from "../errors/RestoreProjectFailure";

export const ProjectRepositoryImpl = {
  async createProject({ name, description }: CreateProjectParams) {
    return networkAdapter
      .post({
        path: "/core/projects/",
        body: { name, description },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new CreateProjectFailure();
        }
        return new EmptyEntity();
      });
  },
  async editProject({
    id,
    name,
    description,
  }: EditProjectParams): Promise<EditProjectFailure | EmptyEntity> {
    return networkAdapter
      .patch({
        path: `/core/projects/${id}/`,
        body: { name, description },
      })
      .then((response) => {
        if (response instanceof NetworkError) {
          return new EditProjectFailure();
        }
        return new EmptyEntity();
      });
  },
  async getProject(
    id: string): Promise<
    GetProjectsFailure | ProjectEntity
  > {
    const result = await networkAdapter.get<ProjectEntity>({
      path: `/core/projects/${id}/`,
    });
    if (result instanceof NetworkError) {
      return new GetProjectsFailure();
    }
    return ProjectEntity.fromJSON(result.data);
  },
  async getProjects({
    deleted,
  }: GetProjectsParams): Promise<
    GetProjectsFailure | PaginationEntity<ProjectEntity>
  > {
    const result = await networkAdapter.get<PaginationEntity<ProjectEntity>>({
      path: `/core/projects/`,
      params: {
        deleted,
      },
    });
    if (result instanceof NetworkError) {
      return new GetProjectsFailure();
    }
    return PaginationEntity.fromJSON(result.data, (e) => {
      e.archived = deleted ?? false;
      return ProjectEntity.fromJSON(e);
    });
  },
  async archiveProject(
    id: string
  ): Promise<ArchiveProjectsFailure | EmptyEntity> {
    const result = await networkAdapter.delete({
      path: `/core/projects/${id}/`,
    });
    if (result instanceof NetworkError) {
      return new ArchiveProjectsFailure();
    }
    return new EmptyEntity();
  },

  async restoreProject(id: string) {
    const result = await networkAdapter.post({
      path: `/core/projects/${id}/restore/`,
    });

    if (result instanceof NetworkError) {
      return new RestoreProjectsFailure();
    }

    return new EmptyEntity();
  },
};
