import { useEffect, useState } from "react";

interface Elements {
  isChecked?: boolean;
  onClick: (isChecked: boolean) => void;
}

export function CheckBoxRounded({ onClick, isChecked }: Elements) {
  const [checked, setCheck] = useState(isChecked);

  useEffect(() => {
    setCheck(isChecked);
  }, [isChecked]);
  return (
    <div className="inline-flex items-center">
      <label
        className={"block relative h-5"}
        onClick={() => {
          setCheck(!checked);
          onClick(!checked);
        }}
      >
        <input
          checked
          type="checkbox"
          className={`peer h-5 w-5 cursor-pointer transition-all appearance-none rounded-full border border-slate-300 ${checked && 'checked:bg-nx-dark-600'} dark:checked:bg-nx-dark-600 checked:border-slate-800`}
          id="check-custom-icon"
          onChange={() => {}}
        />
        {checked ? (
          <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
            >
              <path
                d="M7.25 3.5C7.25 5.29493 5.79493 6.75 4 6.75C2.20507 6.75 0.75 5.29493 0.75 3.5C0.75 1.70507 2.20507 0.25 4 0.25C5.79493 0.25 7.25 1.70507 7.25 3.5Z"
                fill="white"
              />
            </svg>
          </span>
        ) : null}
      </label>
    </div>
  );
}
