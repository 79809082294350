import { useState } from "react";
import { SortEntity } from "web/core/domain/entities/SortEntity";
import { ProjectEntity } from "../../domain/entity/ProjectEntity";

export class SortByName extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByName>) {
    super(orderByAsc!);
  }
}

export class SortByPanels extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByPanels>) {
    super(orderByAsc!);
  }
}

export class SortByMembers extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByMembers>) {
    super(orderByAsc!);
  }
}

export class SortByDate extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByDate>) {
    super(orderByAsc!);
  }
}

export interface SortByParameters {
  instance: SortEntity;
  projects: {
    archived?: ProjectEntity[],
    restored?: ProjectEntity[]
  };
}

export const useSortProjectBy = () => {
  const [sortState, setState] = useState<SortEntity>();

  return {
    setState: setState,
    sortState: sortState,
    sortedBy: {
      name: sortState instanceof SortByName,
      panels: sortState instanceof SortByPanels,
      members: sortState instanceof SortByMembers,
      date: sortState instanceof SortByDate,
    },
    projectsParams: (param: SortByParameters) => {
      setState(param.instance);
      if (param.instance instanceof SortByName) {

        return {
          restored: param.projects.restored?.sort((a, b) => {
            if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
            else return b.name! < a.name! ? -1 : 1;
          }),
          archived: param.projects.archived?.sort((a, b) => {
            if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
            else return b.name! < a.name! ? -1 : 1;
          }),
        };
      }

      if (param.instance instanceof SortByMembers) {

        return {
            restored: param.projects.restored?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.members?.sort()! < b.members?.sort()! ? -1 : 1;
                else return b.members?.sort()! < a.members?.sort()! ? -1 : 1;
            }),
            archived: param.projects.archived?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.members?.sort()! < b.members?.sort()! ? -1 : 1;
                else return b.members?.sort()! < a.members?.sort()! ? -1 : 1;
            }),
          };
      }

      if (param.instance instanceof SortByPanels) {

        return {
            restored: param.projects.restored?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.panels?.sort()! < b.panels?.sort()! ? -1 : 1;
                else return b.panels?.sort()! < a.panels?.sort()! ? -1 : 1;
            }),
            archived: param.projects.archived?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.panels?.sort()! < b.panels?.sort()! ? -1 : 1;
                else return b.panels?.sort()! < a.panels?.sort()! ? -1 : 1;
            }),
          };
      }

      if (param.instance instanceof SortByDate) {
        return {
            restored: param.projects.restored?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.createdAt! < b.createdAt! ? -1 : 1;
                else return b.createdAt! < a.createdAt! ? -1 : 1;
            }),
            archived: param.projects.archived?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.createdAt! < b.createdAt! ? -1 : 1;
                else return b.createdAt! < a.createdAt! ? -1 : 1;
            }),
          };
      }
      return param.projects;
    },
  };
};
