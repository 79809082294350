import { NxOutlinedButton } from "web/common/components/Buttons/NxOutlinedButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxModalTitle } from "web/common/components/NxModalTitle";
import { NxSVG } from "web/common/components/NxSvg";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconImage } from "web/common/fonts/icons/components/IconImage";
import ImageUploading from "react-images-uploading";
import { CSName } from "web/common/utils/ClassName";
import { useEffect, useState } from "react";
import { NxImageCropper } from "web/common/components/NxImageCropper/NxImageCropper";
import { useTranslation } from "react-i18next";
import {
  UpdateUserImageFailure,
  UpdateUserImageSuccess,
  useUpdateUserImageHook,
} from "../../../hooks/UseUpdateUserImageHook";
import { useNxSnackBarContext } from "web/common/providers/NxSnackBarProvider";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";

interface MyProfileEditUserAvatarModalProps {
  isOpen: boolean;
  name: string;
  lastName: string;
  initialImage?: string;
  onClosedTapped: () => void;
}

export const MyProfileEditUserAvatarModal = ({
  isOpen,
  initialImage,
  name,
  lastName,
  onClosedTapped,
}: MyProfileEditUserAvatarModalProps) => {
  const [imageToEdit, setImageToEdit] = useState<string | undefined>(undefined);
  const [currentImage, setCurrentImage] = useState<string | undefined>(
    initialImage
  );
  const { t } = useTranslation();
  const { updateUserImage, updateUserImageState } = useUpdateUserImageHook();
  const { showSnackbar } = useNxSnackBarContext();

  useEffect(() => {
    if (updateUserImageState instanceof UpdateUserImageSuccess) {
      setImageToEdit(undefined);
      showSnackbar({
        label: t("successSnackbar"),
        type: NxSnackbarType.success,
      });
    }

    if (updateUserImageState instanceof UpdateUserImageFailure) {
      showSnackbar({
        label: t("errorSnackbar"),
        type: NxSnackbarType.error,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserImageState]);

  return (
    <NxModal
      removeBg={!!imageToEdit}
      showModal={isOpen}
      onOutsideClick={onClosedTapped}
    >
      <div className="flex flex-col w-[500px] items-center">
        {imageToEdit && (
          <NxImageCropper
            title={t("profilePicture")}
            subtitle={t("personalData")}
            initialImage={imageToEdit}
            onClosedTapped={() => setImageToEdit("")}
            onCropComplete={async (croppedImage) => {
              setCurrentImage(croppedImage);
              await updateUserImage(croppedImage);
            }}
          />
        )}
        {!imageToEdit && (
          <>
            <NxModalTitle
              title={t("profilePicture")}
              subtitle={t("personalData")}
              onClosedTapped={onClosedTapped}
            />
            <div className="w-44 h-44 my-6 overflow-hidden cursor-pointer rounded-full border border-nx-main-500">
              {!!currentImage ? (
                <img
                  className="w-full h-full object-cover"
                  src={currentImage}
                  alt="user avatar"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full bg-nx-main-600/20">
                  <span className="text-7xl text-nx-main-600 font-normal">
                    {name[0] + lastName[0]}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-2 w-full">
              <NxOutlinedButton
                className="basis-1/2 cursor-default"
                onClick={() => {
                  if (currentImage) setImageToEdit(currentImage);
                }}
              >
                <div
                  className={CSName(
                    "text-xs font-medium flex gap-2 justify-center items-center "
                  )
                    .concatIf(
                      !!currentImage,
                      "text-nx-main-600 fill-nx-main-500  cursor-pointer",
                      "text-nx-gray-300 fill-nx-gray-300"
                    )
                    .build()}
                >
                  {t("edit")}
                  <NxSVG className="w-4 h-4 ">
                    <IconEdit />
                  </NxSVG>
                </div>
              </NxOutlinedButton>
              <ImageUploading
                value={[]}
                onChange={(imageList) => {
                  setImageToEdit(imageList[0].dataURL);
                }}
                maxNumber={1}
              >
                {({ onImageUpload }) => (
                  <NxOutlinedButton
                    onClick={onImageUpload}
                    className="basis-1/2"
                  >
                    <div className="text-xs font-medium text-nx-main-600   fill-nx-main-500 flex gap-2 justify-center items-center">
                      {t("uploadPicture")}
                      <NxSVG className="w-4 h-4">
                        <IconImage />
                      </NxSVG>
                    </div>
                  </NxOutlinedButton>
                )}
              </ImageUploading>
            </div>
          </>
        )}
      </div>
    </NxModal>
  );
};
