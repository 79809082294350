import { createContext, useContext, useState } from "react";
import { LocationsModal, HideLocationModal } from "./abstraction/LocationModal";

interface LocationsModalType {
  modal: LocationsModal;
  setModal: (modal: LocationsModal) => void;
}

export const LocationsModalContext = createContext<LocationsModalType>({
  modal: new HideLocationModal({}),
  setModal: (modal: LocationsModal) => {},
});

export function useLocationsModal(){
  return useContext(LocationsModalContext);
};

export function LocationsModalProvider(props: any) {
  const [modal, changeModal] = useState(new HideLocationModal({}));

  const setModal = (newModal: LocationsModal) => {
    changeModal(newModal);
  };

  return (
    <LocationsModalContext.Provider value={{ modal, setModal }}>
      {props.children}
    </LocationsModalContext.Provider>
  );
}
