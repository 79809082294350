import { useState } from "react";
import { SortEntity } from "web/core/domain/entities/SortEntity";
import { PanelEntity } from "../../domain/entities/PanelEntity";

export class SortByName extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByName>) {
    super(orderByAsc!);
  }
}
export class SortByDate extends SortEntity {
  constructor({ orderByAsc }: Partial<SortByDate>) {
    super(orderByAsc!);
  }
}

export interface SortByParameters {
  instance: SortEntity;
  panels: {
    archived?: PanelEntity[],
    restored?: PanelEntity[]
  };
}

export const useSortPanelsBy = () => {
  const [sortState, setState] = useState<SortEntity>();

  return {
    setState: setState,
    sortState: sortState,
    sortedBy: {
      name: sortState instanceof SortByName,
      date: sortState instanceof SortByDate,
    },
    filterPanels: (param: SortByParameters) => {
      setState(param.instance);
      if (param.instance instanceof SortByName) {

        return {
          restored: param.panels.restored?.sort((a, b) => {
            if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
            else return b.name! < a.name! ? -1 : 1;
          }),
          archived: param.panels.archived?.sort((a, b) => {
            if (param.instance.orderByAsc) return a.name! < b.name! ? -1 : 1;
            else return b.name! < a.name! ? -1 : 1;
          }),
        };
      }

      if (param.instance instanceof SortByDate) {
        return {
            restored: param.panels.restored?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.archivedAt! < b.archivedAt! ? -1 : 1;
                else return b.archivedAt! < a.archivedAt! ? -1 : 1;
            }),
            archived: param.panels.archived?.sort((a, b) => {
                if (param.instance.orderByAsc)
                  return a.archivedAt! < b.archivedAt! ? -1 : 1;
                else return b.archivedAt! < a.archivedAt! ? -1 : 1;
            }),
          };
      }
      return param.panels;
    },
  };
};
