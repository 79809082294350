import { useEffect } from "react";
import { PrimaryButton } from "web/common/components/Buttons/PrimaryButton";
import { SecondaryButton } from "web/common/components/Buttons/SecundayButton";
import { NxModal } from "web/common/components/NxModal/NxModal";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { CSName } from "web/common/utils/ClassName";
import { NxSnackbarType } from "web/common/components/NxSnackbar/NxSnackbar";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import CircularProgressBar from "web/common/components/ProgressBar/CircularProgressBar";
import { useProjectModal } from "../../context/ProjectModalProvider";
import { HideProjectModal } from "../../context/abstraction/ProjectsModal";
import {
  ArchiveProjectLoading,
  ArchiveProjectSuccess,
  useArchiveProjectsHook,
} from "../../hooks/UseArchiveProjecHook";
import { useNxSnackBarContext } from "../../../../../common/providers/NxSnackBarProvider";
import { useTranslation } from "react-i18next";
import { ProjectEntity } from "web/features/projects/domain/entity/ProjectEntity";

type ArchiveParams = {
  isOpen: boolean;
  project: Partial<ProjectEntity>;
};

export function ArchiveProjectModal({ isOpen, project }: ArchiveParams) {
  const { setModal } = useProjectModal();
  const { archiveProjectState, archiveProject } = useArchiveProjectsHook();
  const { showSnackbar } = useNxSnackBarContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (archiveProjectState instanceof ArchiveProjectSuccess) {
      showSnackbar({
        type: NxSnackbarType.success,
        label: t("archivedSuccessfull"),
      });
      setTimeout(() => {
        setModal(new HideProjectModal({ reloadProjects: true }));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveProjectState]);

  return (
    <div className="opacity-60">
      <NxModal
        showModal={isOpen}
        onOutsideClick={() => {
          setModal(new HideProjectModal({ reloadProjects: false }));
        }}
      >
        <div className="grid grid-rows-1 pb-6 w-96">
          <label
            className={CSName(FontStyles.subtitle)
              .combine("text-nx-dark-800 dark:text-nx-main-200 text-start pb-1")
              .build()}
          >
            {t("archivedProject")}
          </label>
          <label
            className={CSName(FontStyles.caption)
              .combine("text-start text-nx-gray-400 dark:text-nx-gray-200")
              .build()}
          >
            {t("archivedProjectDescriptionBefore")}
            {project.name}
            {t("archivedProjectDescriptionAfter")}
          </label>
        </div>
        <div className="pb-2">
          <PrimaryButton
            className="w-full px-2 grid grid-rows-0.5 place-content-center py-3 bg-nx-main-600 rounded"
            onClick={() => {
              setModal(new HideProjectModal({ reloadProjects: false }));
            }}
          >
            <label
              className={CSName(FontStyles.bodyBold)
                .combine("cursor-pointer")
                .build()}
            >
              {t("maintain")}
            </label>
          </PrimaryButton>
        </div>
        <SecondaryButton
          className="w-full px-2 py-3 grid grid-rows-0.5 place-content-center border-nx-danger-600"
          onClick={() => {
            archiveProject(project.id as string);
          }}
        >
          {!(archiveProjectState instanceof ArchiveProjectLoading) ? (
            <div className="flex justify-center">
              <label
                className={CSName(FontStyles.bodyBold)
                  .combine("text-nx-danger-600 cursor-pointer pr-2")
                  .build()}
              >
                {t("archive")}
              </label>
              <NxSVG className="w-5 h-5 fill-nx-danger-600">
                <IconArchive />
              </NxSVG>
            </div>
          ) : (
            <CircularProgressBar
              className="stroke-nx-danger-500"
              width={20}
              height={20}
            />
          )}
        </SecondaryButton>
      </NxModal>
    </div>
  );
}
