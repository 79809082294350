import { RouteObject } from "react-router-dom";
import { MyProfilePage } from "../presentation/pages/MyProfilePage";
import { MultiContextProvider } from "web/core/context/MultiContextProvider";
import { MyProfileLogoutModalProvider } from "../presentation/components/MyProfileLogoutModal/MyProfileLogoutModalContext";
import { MyProfileTermsModalProvider } from "../presentation/components/MyProfileTermsModal/MyProfileTermsModalContext";
import { MyProfileEditNameModalProvider } from "../presentation/components/MyProfileEditNameModal/MyProfileEditNameModalContext";
import { MyProfileEditPasswordModalProvider } from "../presentation/components/MyProfileEditPasswordModal/MyProfileEditPasswordModalContext";
import { MyProfileEditUseAvatarModalProvider } from "../presentation/components/MyProfileUserAvatar/components/MyProfileEditUseAvatarModalContext";
import { NxSnackbarProvider } from "web/common/providers/NxSnackBarProvider";
import { MyProfilePoliciesModalProvider } from "../presentation/components/MyProfilePoliciesModal/MyProfilePoliciesModalContext";

export enum MyProfileRoutesNames {
  myProfile = "my-profile",
}

export const MyProfileRoutes: RouteObject = {
  children: [
    {
      path: MyProfileRoutesNames.myProfile,
      element: (
        <MultiContextProvider
          providers={[
            MyProfileLogoutModalProvider,
            MyProfileTermsModalProvider,
            MyProfilePoliciesModalProvider,
            MyProfileEditNameModalProvider,
            MyProfileEditPasswordModalProvider,
            MyProfileEditUseAvatarModalProvider,
            NxSnackbarProvider
          ]}
        >
          <MyProfilePage />
        </MultiContextProvider>
      ),
    },
  ],
};
