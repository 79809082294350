import { OrganizationHeader } from "../components/OrganizationHeader/OrganizationHeader";
import { OrganizationTable } from "../components/OrganizationTable/OrganizationTable";

export function OrganizationPage() {
  return (
    <div>
        <div className="pl-6 pr-8 pt-8">
          <OrganizationHeader />
        </div>
        <OrganizationTable />
    </div>
  );
}
