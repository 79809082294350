export const IconCoin = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00059 13.3977C10.5352 13.3977 13.4006 10.5323 13.4006 6.99766C13.4006 3.46303 10.5352 0.597656 7.00059 0.597656C3.46596 0.597656 0.600586 3.46303 0.600586 6.99766C0.600586 10.5323 3.46596 13.3977 7.00059 13.3977ZM8.52474 10.1977H10.2006V3.79766H8.19246V7.14766H8.13467L5.50533 3.79766H3.80059V10.1977H5.80871V6.83516H5.85205L8.52474 10.1977Z"
        fill="fill"
      />
    </svg>
  );
};
