import React from "react";
import { CSName } from "web/common/utils/ClassName";

type NxModalProps = {
  showModal?: boolean;
  children: React.ReactNode;
  removeBg?: boolean;
  onOutsideClick?(): void;
};

export function NxModal({
  showModal,
  children,
  removeBg,
  onOutsideClick,
}: NxModalProps): React.ReactElement {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div>
      {showModal && (
        <div
          ref={ref}
          className="w-screen h-creen min-w-full bg-black bg-opacity-70 fixed z-50 inset-x-0 inset-y-0 flex justify-center"
          onClick={(e) => {
            console.log(ref.current);
            if (e.target === ref.current) {
              onOutsideClick && onOutsideClick();
            }
          }}
        >
          <div
            className={CSName("m-auto p-4  rounded-lg z-50")
              .concatIf(!!!removeBg, "bg-nx-white dark:bg-nx-dark-1100")
              .build()}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
