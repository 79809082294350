export const IconGroup = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <rect x="7.5" y="2" width="5" height="5" rx="1" fill="fill" />
      <rect x="2" y="13" width="5" height="5" rx="1" fill="fill" />
      <rect x="13" y="13" width="5" height="5" rx="1" fill="fill" />
      <path
        d="M4.5 12V11C4.5 10.4477 4.94772 10 5.5 10H10M10 10V8M10 10H14.5C15.0523 10 15.5 10.4477 15.5 11V12"
        stroke="fill"
        fill="none"
        stroke-linejoin="round"
      />
    </svg>
  );
};
