import { NexusIconsProps } from "../interfaces/NexusIconsProps";

export const IconSort = ({ color = "white" }: NexusIconsProps) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="fill"
      stroke="stroke"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="15.5"
        height="15.5"
        rx="3.75"
        fill="fill"
        fillOpacity="0.2"
      />
      <rect
        x="0.25"
        y="0.25"
        width="15.5"
        height="15.5"
        rx="3.75"
        stroke="gray"
        strokeWidth="0.5"
      />
      <path
        d="M4 6L8 10L12 6"
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
