import { useContext, createContext, useState } from "react";
import { MyProfileEditUserAvatarModal } from "./MyProfileEditUserAvatarModal";
interface ShowModalProps {
  initialImage?: string;
  lastName: string;
  name: string;
  onSave?: (image: string) => void;
}

interface MyProfileEditUseAvatarModalContextProps {
  showModal: ({ initialImage }: ShowModalProps) => void;
}

export const MyProfileEditUseAvatarModalContext =
  createContext<MyProfileEditUseAvatarModalContextProps>(
    {} as MyProfileEditUseAvatarModalContextProps
  );

export const useMyProfileEditUseAvatarModal = () => {
  return useContext(MyProfileEditUseAvatarModalContext);
};

export const MyProfileEditUseAvatarModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModalProps, setShowModalProps] = useState<ShowModalProps>({
    lastName: "",
    name: "",
  } as ShowModalProps);

  return (
    <MyProfileEditUseAvatarModalContext.Provider
      value={{
        showModal: ({ initialImage, lastName, name, onSave }) => {
          setShowModalProps({ initialImage, lastName, name, onSave });
          setIsOpen(true);
        },
      }}
    >
      <div className="w-full h-full">
        {children}
        <MyProfileEditUserAvatarModal
          key={showModalProps.initialImage}
          isOpen={isOpen}
          onClosedTapped={() => setIsOpen(false)}
          initialImage={showModalProps.initialImage}
          lastName={showModalProps.lastName}
          name={showModalProps.name}
        />
      </div>
    </MyProfileEditUseAvatarModalContext.Provider>
  );
};
