export const IconArrowRight = () => {
  return (
    <svg
      viewBox="0 0 10 17"
      fill="none"
      stroke="stroke"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 1L8.5 8.5L1.5 16" stroke="stroke" strokeWidth="2" />
    </svg>
  );
};
