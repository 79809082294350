import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "web/features/app/context/AppContext";
import {
  AppFailure,
  AppLoading,
  AppSuccess,
} from "web/features/app/hooks/UseAppHook";
import { LoadingAppPage } from "web/features/loading/presentation/pages/LoadingAppPage";
import { OrganizationActioRequiredPage } from "web/features/organizations/features/organization-action-required/presentation/pages/OrganizationActionRequiredPage";
import { OrgModalProvider } from "web/features/organizations/presentation/context/OrgModalProvider";
import { useHomeUsecase } from "web/features/home/presentation/hooks/UseHomeUsecase";

export const AppOutletPage = () => {
  const { fetchInitialData, appState } = useAppContext();
  const navigate = useNavigate();
  const home = useHomeUsecase();
  const location = useLocation();

  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appState instanceof AppSuccess) {
      const { currentOrgId } = appState;
      if (
        currentOrgId &&
        !location.pathname.includes(currentOrgId.orgId) &&
        (location.pathname.includes("org") || location.pathname === "/")
      ) {
        navigate("org/" + currentOrgId.orgId);
      }
    }
    if (appState instanceof AppFailure) {
      home.logout(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  if (appState instanceof AppLoading) {
    return <LoadingAppPage />;
  }

  if (appState instanceof AppSuccess && appState.organizations.length === 0)
    return (
      <OrgModalProvider>
        <OrganizationActioRequiredPage
          onSuccess={() => {
            fetchInitialData();
          }}
        />
      </OrgModalProvider>
    );

  return <Outlet />;
};
