import { RouteObject } from "react-router-dom";
import { HomeOutlet } from "../pages/HomeOutlet";
import { HomePage } from "../pages/HomePage";
import { authGuardLoader } from "web/core/presentation/routes/loaders/AuthGuardLoader";
import { LocationsPage } from "web/features/locations/presentation/pages/LocationsPage";
import { LocationsModalProvider } from "web/features/locations/presentation/context/LocationsModalProvider";
import { AgentsPage } from "web/features/agents/presentation/pages/AgentsPage";
import { ManageProjects } from "../../../projects/presentation/pages/ManageProjectsPage";
import { PanelsModalProvider } from "web/features/panels/presentation/context/PanelsModalProvider";
import { PanelsMenu } from "web/features/panels/presentation/pages/PanelsMenu";
import { MemberListPage } from "web/features/members/presentation/pages/MemberListPage";
import { MyProfileRoutes } from "web/features/my-profile/routes/MyProfileRoutes";
import { MultiContextProvider } from "web/core/context/MultiContextProvider";
import { ProjectModalProvider } from "web/features/projects/presentation/context/ProjectModalProvider";
import { NxSnackbarProvider } from "web/common/providers/NxSnackBarProvider";
import { QuickSearchModalProvider } from "../components/HomeNavPopoverMenu/HomeNavQuickSearchModalContext";

const HomeRoutes: RouteObject = {
  path: "",
  element: (
    <QuickSearchModalProvider>
      <HomeOutlet />
    </QuickSearchModalProvider>
  ),
  loader: authGuardLoader(),
  children: [
    {
      index: true,
      element: <HomePage />,
    },
    {
      path: "projects",
      element: (
        <MultiContextProvider
          providers={[ProjectModalProvider, NxSnackbarProvider]}
        >
          <ManageProjects />
        </MultiContextProvider>
      ),
    },
    {
      path: "projects/:id",
      element: (
        <PanelsModalProvider>
          <PanelsMenu />
        </PanelsModalProvider>
      ),
    },
    {
      path: "agents",
      element: <AgentsPage />,
    },
    {
      path: "locations",
      element: (
        <LocationsModalProvider>
          <LocationsPage />
        </LocationsModalProvider>
      ),
    },
    {
      path: "members",
      element: <MemberListPage />,
    },
    {
      path: "signatures",
      element: <div>signatures</div>,
    },
    MyProfileRoutes,
  ],
};

export default HomeRoutes;
