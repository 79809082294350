import L from "leaflet";
import { DrawLayer } from "../types/DrawLayer";
import { MapDrawModes } from "../types/MapDrawModes";

export class MapComponentController {
  control: any;
  currentMode?: MapDrawModes;
  editModeIsActive = false;
  isReadOnly: boolean;
  mapRef: L.Map;

  private onModeChangeCallback?: (controller: MapComponentController) => void;

  constructor({
    control,
    isReadOnly,
    mapRef,
  }: Pick<MapComponentController, "control" | "isReadOnly" | "mapRef">) {
    this.control = control;
    this.isReadOnly = isReadOnly;
    this.mapRef = mapRef;
  }

  enableMode(mode: MapDrawModes) {
    this.currentMode = mode;
    this.control._toolbars.draw._modes[mode].handler.enable();
    this.onModeChangeCallback?.(this);
  }
  addLayer(layer: DrawLayer, isReadOnly = false) {
    const featureGroup = this.control.options.edit.featureGroup;
    let leafletLayer: L.Layer | undefined;
    if (layer.drawMode === MapDrawModes.circle) {
      leafletLayer = L.circle(layer.points[0], {
        radius: layer.radius!,
      });
    }

    if (layer.drawMode === MapDrawModes.polygon) {
      leafletLayer = L.polygon(layer.points);
    }

    if (layer.drawMode === MapDrawModes.circlemarker) {
      leafletLayer = L.circleMarker(layer.points[0]);
    }
    if (!leafletLayer) return;

    if (!this.isReadOnly && !isReadOnly) {
      leafletLayer.on("click", () => {
        this.enableEditMode();
      });
    }

    featureGroup.addLayer(leafletLayer);
  }
  disableMode(mode: MapDrawModes) {
    this.control._toolbars.draw._modes[mode].handler.disable();
    this.currentMode = undefined;
    this.onModeChangeCallback?.(this);
  }

  enableEditMode() {
    this.control._toolbars.edit._modes.edit.handler.enable();
    this.editModeIsActive = true;
    this.onModeChangeCallback?.(this);
  }

  saveEditMode() {
    this.control._toolbars.edit._modes.edit.handler.save();
    this.control._toolbars.edit._modes.edit.handler.disable();
    this.onModeChangeCallback?.(this);
  }

  cancelEditMode() {
    this.control._toolbars.edit._modes.edit.handler.revertLayers();
    this.control._toolbars.edit._modes.edit.handler.disable();
    this.onModeChangeCallback?.(this);
  }

  clearEdit() {
    var layerContainer = this.control.options.edit.featureGroup,
      layers = layerContainer._layers,
      layer_ids = Object.keys(layers),
      layer;

    layer_ids.forEach((id) => {
      layer = layers[id];
      layerContainer.removeLayer(layer);
    });
  }

  undo() {
    if (!this.currentMode) return;

    if (!this.editModeIsActive) {
      this.clearEdit();
    }

    if (this.currentMode === MapDrawModes.polygon) {
      this.enableMode(this.currentMode);
      return this.control._toolbars.draw._modes[
        this.currentMode
      ]?.handler?.deleteLastVertex();
    } else {
      this.clearEdit();
    }

    this.enableMode(this.currentMode);
  }

  fitBounds(draw: DrawLayer) {
    if (draw.bounds) {
      this.mapRef.fitBounds(draw.bounds);
    } else {
      this.mapRef.setView(draw.points[0], 13);
    }
  }

  onModeChange(callback: (controller: MapComponentController) => void) {
    this.onModeChangeCallback = callback;
  }
}
