import { useState } from "react";
import { restoreAgentUsecase } from "../../domain/usecase/RestoreAgentUsecase";
import { RestoreAgentError } from "../../domain/errors/RestoreAgentError";
import delay from "web/common/utils/Delayed";

abstract class RestoreAgentState {}

export class RestoreAgentLoading extends RestoreAgentState {}

export class RestoreAgentSuccess extends RestoreAgentState {}

export class RestoreAgentFailure extends RestoreAgentState {}

export const useRestoreAgentHook = () => {
  const [restoreAgentState, setState] = useState<RestoreAgentState>();

  return {
    restoreAgentState,
    restoreAgent: async (id: string) => {
      setState(new RestoreAgentLoading());
      const [result] = await Promise.all([
        restoreAgentUsecase(id),
        delay(2000),
      ]);

      if (result instanceof RestoreAgentError) {
        return setState(new RestoreAgentFailure());
      }

      return setState(new RestoreAgentSuccess());
    },
  };
};
