import { NxDropElement } from "web/common/components/NxDropElement/NxDropElement";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconEdit } from "web/common/fonts/icons/components/IconEdit";
import { IconMore } from "web/common/fonts/icons/components/IconMore";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { CSName } from "web/common/utils/ClassName";
import { useTranslation } from "react-i18next";
import { useAgentsModal } from "../../context/AgentsModalProvider";
import {
  HideAgentModal,
  ShowAgentArchiveModal,
  ShowAgentCreateModal,
} from "../../context/abstraction/AgentsModal";
import { useEffect, useState } from "react";
import { AgentEntity } from "web/features/agents/domain/entities/AgentEntity";

import {
  NxSnackbar,
  NxSnackbarType,
} from "web/common/components/NxSnackbar/NxSnackbar";
import { AgentTbodyParams } from "./params/AgentTbodyParams";
import { AgentType } from "web/features/agents/domain/entities/AgentType";
import { IconArchive } from "web/common/fonts/icons/components/IconArchive";
import { IconRestore } from "web/common/fonts/icons/components/IconRestore";
import {
  RestoreAgentLoading,
  RestoreAgentSuccess,
  useRestoreAgentHook,
} from "../../hooks/RestoreAgentHook";
import { RestoreAgentError } from "web/features/agents/domain/errors/RestoreAgentError";

interface AgentTbodyController {
  entity?: AgentEntity;
  isRestoring?: boolean;
}

export function AgentTbody(params: AgentTbodyParams) {
  const { modal, setModal } = useAgentsModal();
  const [controller, setController] = useState<AgentTbodyController>();
  const { t } = useTranslation();
  const { restoreAgentState, restoreAgent } = useRestoreAgentHook();

  useEffect(() => {
    if (modal instanceof HideAgentModal)
      setController({
        isRestoring: undefined,
        entity: undefined,
      });
  }, [modal]);

  useEffect(() => {
    if (restoreAgentState instanceof RestoreAgentSuccess) {
      setModal(new HideAgentModal(true));
    }
  }, [restoreAgentState, setModal]);

  return (
    <div className="pl-6 pt-8 flex flex-row text-nx-gray-200">
      <div
        className={CSName("w-[24%]")
          .combine(params.extended === undefined ? "pl-4" : "")
          .build()}
      >
        <div
          className="flex flex-row pb-4 cursor-pointer"
          onClick={params.onClick}
        >
          <div className="flex flex-col mr-4">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "text-start text-nx-black dark:text-nx-white cursor-pointer"
                )
                .build()}
            >
              {params.data.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "text-start text-nx-gray-400 dark:text-nx-gray-200 cursor-pointer"
                )
                .build()}
            >
              {params.data.type === AgentType.company
                ? "Pessoa Jurídica"
                : "Pessoa física"}
            </label>
          </div>
          {params.extended !== undefined && (
            <NxSVG
              className={CSName(
                "stroke-nx-main-500 w-5 h-5 active:transition duration-100"
              )
                .combine(params.extended! ? "rotate-90" : "")
                .build()}
            >
              <IconArrowRight />
            </NxSVG>
          )}
        </div>
      </div>
      <div className="w-[34.5%]">
        <div className="flex flex-row mr-4">
          <label
            className={CSName(FontStyles.caption)
              .combine(
                " rounded-full border-dashed border px-3 py-1.5 bg-nx-gray-200/20 dark:bg-nx-black/20 border-nx-gray-300 text-nx-gray-300 truncate  max-w-28"
              )
              .build()}
          >
            {t('noWidgets')}
          </label>
          {/* // TODO: Uncomment this code when work with widgets */}
          {/* {params.data.widgets!.map((e, index) => {
            ///TODO: Replace key with the unique ID for the widget
            if (index < 3)
              return (
                <label
                  key={index}
                  className={CSName(FontStyles.caption)
                    .combine(
                      "rounded-full px-3 py-1.5 bg-nx-focus-dark text-nx-main-500 dark:text-nx-main-200 truncate mr-1 max-w-28"
                    )
                    .build()}
                >
                  {e.name}
                </label>
              );
            return <div key={index}></div>;
          })} */}
          {/* // TODO: Uncomment this code when work with widgets */}
          {/* {params.data.widgets!.length > 2 && (
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "text-nx-main-600 dark:text-nx-main-500 place-content-center cursor-pointer"
                )
                .build()}
              onClick={() => {
                setModal(new ShowAgentsWidgetsModal());
                setController({
                  isRestoring: controller?.isRestoring,
                  entity: params.data,
                });
              }}
            >
              {t("more")} {params.data.widgets!.length - 3}...
            </label>
          )} */}
        </div>
      </div>
      <div className="w-[19%] text-start">
        <label
          className={CSName(FontStyles.caption)
            .combine("text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {params.data.category}
        </label>
      </div>
      <div className="w-[18%] text-start">
        <label
          className={CSName(FontStyles.caption)
            .combine("text-nx-gray-400 dark:text-nx-gray-200")
            .build()}
        >
          {params.data.createdAt.toLocaleDateString()}
        </label>
      </div>
      <div className="mr-3 text-start">
        {params.extended !== undefined && (
          <NxDropElement
            options={[
              {
                icon: <IconEdit />,
                label: t("edit"),
                onClick: () => {
                  setModal(new ShowAgentCreateModal(params.data));
                },
              },
              {
                icon: params.data.archived ? <IconRestore /> : <IconArchive />,
                label: params.data.archived ? t("restore") : t("archive"),
                onClick: () => {
                  if (!params.data.archived) {
                    setController({
                      isRestoring: controller?.isRestoring,
                      entity: params.data,
                    });
                    setModal(new ShowAgentArchiveModal(params.data.id));
                  } else {
                    restoreAgent(params.data.id);
                  }
                },
              },
            ]}
            dimension={{
              x: 100,
              y: 10,
            }}
          >
            <NxSVG className="fill-nx-main-500 w-5 h-5 cursor-pointer">
              <IconMore />
            </NxSVG>
          </NxDropElement>
        )}

        {/* <WidgetsAgentModal
          isOpen={
            modal instanceof ShowAgentsWidgetsModal &&
            controller?.entity !== undefined
          }
          widgetsList={controller?.entity?.widgets}
        /> */}
      </div>
      {restoreAgentState instanceof RestoreAgentLoading && (
        <NxSnackbar
          showIcon={true}
          showSnackbar={true}
          type={NxSnackbarType.loading}
          label={t('loading')}
        />
      )}
      {restoreAgentState instanceof RestoreAgentSuccess && (
        <NxSnackbar
          showIcon={true}
          showSnackbar={true}
          type={NxSnackbarType.success}
          label={t('restoreWithSuccess')}
        />
      )}
      {restoreAgentState instanceof RestoreAgentError && (
        <NxSnackbar
          showIcon={true}
          showSnackbar={true}
          type={NxSnackbarType.error}
          label={t('restoreWithFail')}
        />
      )}
    </div>
  );
}