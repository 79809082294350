import { useState } from "react";
import { NxSVG } from "web/common/components/NxSvg";
import { FontStyles } from "web/common/fonts/FontStyles";
import { IconArrowRight } from "web/common/fonts/icons/components/IconArrowRight";
import { CSName } from "web/common/utils/ClassName";
import { IconCoin } from "web/common/fonts/icons/components/IconCoin";
import { WidgetCategoryEntity } from "web/features/widgets/domain/entities/WidgetCategoryEntity";

type Params = {
  category: WidgetCategoryEntity;
  onSelect: (category:WidgetCategoryEntity, itemId: number) => void;
};

export function ExtendableCategory({ category, onSelect }: Params) {
  const [isExtended, setExtension] = useState<boolean>();

  return (
    <div>
      <div
        className="cursor-pointer flex flex-row justify-between place-content-between mx-2 py-2 mb-2 hover:dark:bg-nx-focus-dark/20 hover:rounded"
        key={1}
        onClick={() => {
          setExtension(!isExtended);
        }}
      >
        <div className="flex flex-row">
          <NxSVG
            className={CSName(
              "cursor-pointer dark:stroke-nx-gray-400 dark:fill-nx-gray-400 stroke-nx-focus-light mt-0.5 ml-2"
            ).build()}
            width={20}
            height={21}
          >
            {category.icon}
          </NxSVG>
          <div className="flex flex-col ml-2">
            <label
              className={CSName(FontStyles.bodyRegular)
                .combine(
                  "cursor-pointer text-nx-gray-700 dark:text-nx-white text-start"
                )
                .build()}
            >
              {category.name}
            </label>
            <label
              className={CSName(FontStyles.caption)
                .combine(
                  "cursor-pointer text-nx-gray-400 dark:text-nx-gray-200 text-start"
                )
                .build()}
            >
              {category.description}
            </label>
          </div>
        </div>
        <NxSVG
          className={CSName(
            "cursor-pointer dark:stroke-nx-main-500 stroke-nx-focus-light mt-1.5 mr-2"
          )
            .combine(isExtended! ? "rotate-90" : "")
            .build()}
          width={20}
          height={20}
        >
          <IconArrowRight />
        </NxSVG>
      </div>
      {isExtended &&
        category.items?.map((i, index) => (
          <div
            className="flex flex-row justify-between place-content-between mx-2 py-2 mb-2 hover:dark:bg-nx-focus-dark/20 hover:rounded"
            key={index}
            onClick={() => {
              setExtension(!isExtended);
            }}
          >
            <div className="w-full flex flex-row justify-between mx-2" onClick={()=>{
              onSelect(category, i.id);
            }}>
              <label
                className={CSName(FontStyles.bodyRegular)
                  .combine("text-nx-gray-700 dark:text-nx-white text-start cursor-pointer")
                  .build()}
              >
                {i.name}
              </label>
              <div className="flex flex-row">
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine(
                      "dark:text-nx-main-200 text-nx-dark-600 text-start"
                    )
                    .build()}
                >
                  {i.value}
                </label>
                <NxSVG className="dark:fill-nx-main-200 fill-nx-dark-600 w-5 h-5 ml-1.5">
                  <IconCoin />
                </NxSVG>
                <label
                  className={CSName(FontStyles.bodyRegular)
                    .combine(
                      "dark:text-nx-main-200 text-nx-dark-600 text-start"
                    )
                    .build()}
                >/Consulta
                </label>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
