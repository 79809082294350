export class ApiKeyEntity {
  name: string;
  usedAt: Date;
  id: string;
  permission: string;
  clientId: string;
  createdAt: Date;

  constructor({
    name,
    usedAt,
    id,
    permission,
    clientId,
    createdAt,
  }: Required<ApiKeyEntity>) {
    this.name = name;
    this.usedAt = usedAt;
    this.id = id;
    this.permission = permission;
    this.createdAt = createdAt;
    this.clientId = clientId;
  }

  static fromJSON(data: any): ApiKeyEntity {
    return new ApiKeyEntity({
      name: data.name,
      usedAt: new Date(data.used_at),
      id: data.id,
      permission: data.permission,
      clientId: data.client_id,
      createdAt: new Date(data.created_at),
    });
  }
}
