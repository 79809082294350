export const IconDrawArea = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="1"  />
      <circle cx="6" cy="17" r="1"  />
      <circle cx="17" cy="15" r="1" />
      <circle cx="17" cy="7" r="1"  />
      <path
        d="M4 4.5L5.87146 16.5M6.5 17L16.5 15M17 14.5V8M4.5 4L16.5 6.86463"
       
      />
    </svg>
  );
};
