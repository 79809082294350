import { locationAdapter, networkAdapter } from "web/core/adapters/AdaptersInstances";
import { CreateLocationParams } from "../params/CreateLocationParams";
import { PaginationEntity } from "web/core/domain/entities/PaginationEntity";
import { LocationEntity } from "../entities/LocationEntity";
import { CreateLocationFailure } from "../errors/CreateLocationFailure";
import { NetworkError } from "web/core/adapters/network/errors/NetworkError";
import { getLocationsFailure } from "../errors/GetLocationsFailure";
import { FeatureEntity } from "web/core/domain/entities/FeatureEntity";
import { ArchiveLocationFailure } from "../errors/ArchiveLocationFailure";
import { EmptyEntity } from "web/core/domain/entities/EmptyEntity";
import { updateLocationFailure } from "../errors/UpdateLocationFailure";
import { RestoreLocationFailure } from "../errors/RestoreLocationFailure";

export const LocationRepository = {
  async createLocation(
    params: CreateLocationParams
  ): Promise<EmptyEntity | CreateLocationFailure> {
    const location = locationAdapter.buildbody(params);
    const response = await networkAdapter.post({
      path: "core/locations/",
      body: {
        type: location.type,
        geometry: location.geometry,
        properties: location.properties,
      },
    });
    if (response instanceof NetworkError) {
      return new CreateLocationFailure();
    }
    return new EmptyEntity();
  },
  async getLocations(
    isDeleted: boolean
  ): Promise<
    PaginationEntity<FeatureEntity<LocationEntity>> | getLocationsFailure
  > {
    const response = await networkAdapter.get({
      path: "core/locations/",
      params: {
        deleted:isDeleted,
      },
    });
    if (response instanceof NetworkError) {
      return new CreateLocationFailure();
    }
    return PaginationEntity.fromJSON(response.data, (f) =>
      FeatureEntity.fromJSON(f, (l) => LocationEntity.fromJSON(l))
    );
  },
  async updateLocation(
    params: CreateLocationParams
  ): Promise<ArchiveLocationFailure | EmptyEntity> {
    const location = locationAdapter.buildbody(params);
    const response = await networkAdapter.put({
      path: `core/locations/${params.id}/`,
      body: {
        type: location.type,
        geometry: location.geometry,
        properties: location.properties,
      },
    });
    if (response instanceof NetworkError) {
      return new updateLocationFailure();
    }
    return new EmptyEntity();
  },
  async archiveLocation(
    id: string
  ): Promise<ArchiveLocationFailure | EmptyEntity> {
    const result = await networkAdapter.delete({
      path: `/core/locations/${id}/`,
    });
    if (result instanceof NetworkError) {
      return new ArchiveLocationFailure();
    }
    return new EmptyEntity();
  },
  async restoreLocation(
    id: string
  ): Promise<ArchiveLocationFailure | EmptyEntity> {
    const result = await networkAdapter.post({
      path: `/core/locations/${id}/restore/`,
    });
    if (result instanceof NetworkError) {
      return new RestoreLocationFailure();
    }
    return new EmptyEntity();
  },
};
