import { ProjectsHeader } from "../components/ProjectsHeader/ProjectsHeader";
import { ProjectModalProvider } from "../context/ProjectModalProvider";
import { ProjectsTable } from "../components/ProjectsTable/ProjectsTable";

export function ManageProjects() {
  return (
    <ProjectModalProvider>
      <div className="pl-6 pr-8 pt-8">
        <ProjectsHeader />
      </div>
      <ProjectsTable />
    </ProjectModalProvider>
  );
}