export const IconClosedEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M2.14648 7.99993C3.74335 10.6901 6.72804 12.5 10.1465 12.5C13.565 12.5 16.5496 10.6901 18.1465 8"
        stroke="#667C8E"
        strokeLinecap="round"
      />
      <path d="M3.14648 15L4.14648 13" stroke="#667C8E" strokeLinecap="round" />
      <path d="M17.1465 15L16.1465 13" stroke="#667C8E" strokeLinecap="round" />
      <path d="M10.1465 14.5V16.5" stroke="#667C8E" strokeLinecap="round" />
    </svg>
  );
};
