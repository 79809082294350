export const IconRefresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18 6.7V2.72426C18 2.45699 17.6769 2.32314 17.4879 2.51213L13.5121 6.48787C13.3231 6.67686 13.457 7 13.7243 7H17.7C17.8657 7 18 6.86568 18 6.7Z"
        fill="fill"
        stroke="stroke"
      />
      <path
        d="M16.9297 6C15.5465 3.60879 12.9611 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C13.1631 18 15.8975 16.1642 17.1958 13.5"
        stroke="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
