export const IconEsg = () => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0409 9.86389L12.1237 10.422C12.7072 10.5783 13.3069 10.232 13.4633 9.64857V9.64857C13.6196 9.06509 13.2736 8.46543 12.6902 8.30909C11.5921 8.01486 10.1957 7.64069 9.62606 7.48806C7.58451 6.94103 6.16029 7.60957 5.57633 8.25939C5.34307 8.51895 5.39769 8.89267 5.57218 9.19489L6.82346 11.3622C6.97967 11.6327 7.23698 11.8302 7.53877 11.911L11.8194 13.058C12.2539 13.1745 12.7172 13.1047 13.0982 12.8655L17.6394 10.0145C18.1633 9.68564 18.3448 9.00833 18.0555 8.46156V8.46156C17.7311 7.84831 16.9506 7.6453 16.3685 8.02277L13.3795 9.961"
        stroke="stroke"
        strokeLinecap="round"
      />
      <path
        d="M5.14625 8.45724L7.12272 11.8806C7.40301 12.3661 7.25365 12.986 6.78304 13.2906L6.04778 13.7665C5.5421 14.0937 4.86542 13.9307 4.56425 13.4091L2.58778 9.98573C2.3075 9.50025 2.45685 8.88028 2.92746 8.5757L3.66272 8.09985C4.1684 7.77258 4.84508 7.93559 5.14625 8.45724Z"
        stroke="stroke"
      />
    </svg>
  );
};
