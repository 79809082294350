import {
  DeleteParams,
  EndpointParams,
  GetParams,
  PatchParams,
  PostParams,
  PutParams,
} from "./entity/EndpointEntity";
import axios, { AxiosError, AxiosInstance } from "axios";
import { NetworkResponse } from "./entity/NetworkResponse";
import {
  NetworkBadRequestError,
  NetworkError,
  NetworkInternalServerError,
  NetworkResponseError,
  NetworkUnauthorizedError,
} from "./errors/NetworkError";

import { CredentialEntity } from "web/features/auth/domain/entities/CredentialEntity";
import { LoginDTO } from "web/features/auth/domain/dto/LoginDTO";

export interface NetworkAdapter {
  get<T>(params: GetParams): Promise<NetworkResponse<T> | NetworkError>;
  post<T>(params: PostParams): Promise<NetworkResponse<T> | NetworkError>;
  put<T>(params: PatchParams): Promise<NetworkResponse<T> | NetworkError>;
  patch<T>(params: PatchParams): Promise<NetworkResponse<T> | NetworkError>;
  delete<T>(params: DeleteParams): Promise<NetworkResponse<T> | NetworkError>;
}

export type NetworkAdapterParams = {
  baseURL?: string;
};

export class NetworkAdapterImpl implements NetworkAdapter {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
  }

  async get<T>({ path, headers, params, needToken }: GetParams) {
    return this.performRequest<T>({
      path,
      headers: headers,
      params,
      needToken,
      method: "GET",
    });
  }

  async post<T>({
    path,
    headers,
    params,
    body,
    needToken,
    checkCredential,
  }: PostParams) {
    return this.performRequest<T>({
      path,
      headers: headers,
      params,
      body,
      method: "POST",
      needToken,
      checkCredential,
    });
  }

  async put<T>({
    baseUrl,
    path,
    headers,
    params,
    body,
    needToken,
  }: PutParams): Promise<NetworkResponse<T> | NetworkError> {
    return this.performRequest<T>({
      baseUrl,
      path,
      headers,
      params,
      body,
      method: "PUT",
      needToken,
    });
  }

  async patch<T>({ path, headers, params, body, needToken }: PatchParams) {
    return this.performRequest<T>({
      path,
      headers,
      params,
      body,
      method: "PATCH",
      needToken,
    });
  }

  async delete<T>({ path, headers, params, needToken }: DeleteParams) {
    return this.performRequest<T>({
      path,
      headers,
      params,
      method: "DELETE",
      needToken,
    });
  }

  private async performRequest<T>({
    baseUrl,
    path,
    headers,
    params,
    body,
    method,
    needToken = true,
    retryRefreshToken = true,
    checkCredential,
  }: EndpointParams): Promise<NetworkResponse<T> | NetworkError> {
    try {
      const defaultHeaders: Record<string, string> = {};
      if (needToken) {
        const localCredential =
          localStorage.getItem("credential") ||
          sessionStorage.getItem("credential");
        if (localCredential) {
          const credential = CredentialEntity.fromJson(
            JSON.parse(localCredential)
          );
          defaultHeaders["Authorization"] = `Bearer ${credential.access_token}`;
        }
      }
      const currentOrganization = localStorage.getItem(
        "currentOrganization"
      ) as string

      const currentOrgId = JSON.parse(currentOrganization)

      if (currentOrganization) {
        defaultHeaders["x-organization-id"] = currentOrgId.orgId;
      }

      const axiosReponse = await this.instance.request<NetworkResponse<T>>({
        baseURL: baseUrl,
        url: path,
        method,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        params,
        data: body,
      });
      return {
        status: axiosReponse.status,
        data: axiosReponse.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const status = error.response?.status;
        if (status === 500) return new NetworkInternalServerError();
        if (status === 400) return new NetworkBadRequestError();
        if (status === 401 && !checkCredential) {
          if (retryRefreshToken) {
            try {
              await this.refreshToken();
            } catch (e) {
              return new NetworkUnauthorizedError();
            }

            const result = await this.performRequest<T>({
              path,
              headers,
              params,
              body,
              method,
              needToken,
              retryRefreshToken: false,
            });

            if (result instanceof NetworkUnauthorizedError && needToken) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = "/auth/login";
            }

            return result;
          }
          return new NetworkUnauthorizedError();
        }
        return new NetworkResponseError({
          message: error.message,
          statusCode: error.response?.status,
          body: error.response?.data,
        });
      }
      throw error;
    }
  }

  async refreshToken(): Promise<boolean> {
    const localCredential = localStorage.getItem("credential");
    if (!localCredential) return false;

    const { refresh_token } = CredentialEntity.fromJson(
      JSON.parse(localCredential)
    );

    const response = await this.performRequest<LoginDTO>({
      path: "/auth/protocol/openid-connect/token",
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: {
        refresh_token,
        grant_type: "refresh_token",
      },
      retryRefreshToken: false,
    });

    if (response instanceof NetworkError) {
      return false;
    }

    const data = response.data;

    localStorage.setItem(
      "credential",
      JSON.stringify(CredentialEntity.fromJson(data))
    );
    return true;
  }
}
