import { RouteObject } from "react-router-dom";
import { OrgModalProvider } from "../presentation/context/OrgModalProvider";
import { OrganizationPage } from "../presentation/pages/OrganizationPage";
import { OrganizationActioRequiredPage } from "../features/organization-action-required/presentation/pages/OrganizationActionRequiredPage";
import { MultiContextProvider } from "web/core/context/MultiContextProvider";
import { NxSnackbarProvider } from "web/common/providers/NxSnackBarProvider";

export enum OrganizationRouteNames {
  organizations = "organizations",
  actionRequired = "action-required",
}

export const OrganizationRoutes: RouteObject = {
  children: [
    {
      path: OrganizationRouteNames.organizations,
      element: (
        <MultiContextProvider
          providers={[OrgModalProvider, NxSnackbarProvider]}
        >
          <OrganizationPage />
        </MultiContextProvider>
      ),
    },
    {
      path: OrganizationRouteNames.actionRequired,
      element: <OrganizationActioRequiredPage />,
    },
  ],
};
