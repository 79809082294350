import { createContext, useContext, useState } from "react";
import { MyProfileEditNameModal } from "./MyProfileEditNameModal";

interface MyProfileEditNameModalContextProps {
  showModalEditName: (firstName: string, lastName: string) => void;
}

export const MyProfileEditNameModalContext = createContext<MyProfileEditNameModalContextProps>(
  {} as MyProfileEditNameModalContextProps
);

export const MyProfileEditNameModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const showModalEditName = (firstName: string, lastName: string) => {
    setFirstName(firstName);
    setLastName(lastName);
    setIsOpen(true);
  };

  return (
    <MyProfileEditNameModalContext.Provider value={{ showModalEditName }}>
      {children}
      <MyProfileEditNameModal
        isOpen={isOpen}
        firstName={firstName}
        lastName={lastName}
        onClosedTapped={() => setIsOpen(false)}
      />
    </MyProfileEditNameModalContext.Provider>
  );
};

export function useMyProfileEditNameModal() {
  return useContext(MyProfileEditNameModalContext);
}
