import { CategoryEntity } from "../entities/CategoryEntity";
import { CreateAccountFailure } from "../errors/CreateAccountFailure";
import { CreateAccountParams } from "../params/CreateAccountParams";
import { AuthRepositoryImpl } from "../repositories/AuthRepository";

export function createAccountUsecase(
  params: CreateAccountParams
): Promise<CreateAccountFailure | CategoryEntity[]> {
  return AuthRepositoryImpl.createUser(params);
}
