import { GeometryEntity } from "./GeometryEntity";
import { PropertyEntity } from "./PropertyEntity";

export class LocationEntity {
  id?: string;
  type: string;
  geometry: GeometryEntity;
  properties: PropertyEntity;
  constructor({
    id,
    type,
    geometry,
    properties,
  }: Required<LocationEntity>) {
    this.id = id;
    this.type = type;
    this.geometry = geometry;
    this.properties = properties;
  }

  static fromJSON(data: any): LocationEntity {
    return new LocationEntity({
      id: data.id,
      type: data.type,
      geometry: data.geometry,
      properties: PropertyEntity.fromJSON(data.properties),
    });
  }
}
